import {Component, Input, OnInit} from '@angular/core';
import {GeneralModel} from '../../positions/position-operations/general/general.model';

@Component({
    selector: 'app-candidates-dashboard-view',
    templateUrl: './candidates-dashboard-view.component.html',
    styleUrls: ['./candidates-dashboard-view.component.scss']
})
export class CandidatesDashboardViewComponent implements OnInit {

    @Input() position: GeneralModel;

    constructor() {
    }

    ngOnInit(): void {
    }

}
