import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContactPersonModel} from '../../contact-person.model';
import {AuthService} from '../../../../services/auth/auth.service';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';

@Component({
    selector: 'app-edit-contact-person',
    templateUrl: './edit-contact-person.component.html',
    styleUrls: ['./edit-contact-person.component.scss']
})
export class EditContactPersonComponent implements OnInit {

    @Input() clientId: string;
    @Input() contactPerson: ContactPersonModel;
    @Output() updateContactPersons = new EventEmitter();

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.contactPerson = new ContactPersonModel();
    }

    save() {
        this.authService.ServerPut(server.clients + '/' + this.clientId + '/contact-persons/' + this.contactPerson.id, this.contactPerson)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updateContactPersonsList();
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.cancel();
                this.spinner.hide();
            });
    }

    cancel() {
        this.closeEditContactPerson();
    }

    updateContactPersonsList() {
        this.contactPerson = new ContactPersonModel();
        this.updateContactPersons.emit();
    }

    closeEditContactPerson() {
        // @ts-ignore
        $('#editContactPerson').modal('hide');
    }

}
