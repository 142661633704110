import {Component, OnInit} from '@angular/core';
import {Items} from '../../shared/appItems';
import {OAuthService} from 'angular-oauth2-oidc';
import {AuthService} from '../../services/auth/auth.service';
import {PermissionService} from '../../services/permission/permission.service';
import {server} from 'src/app/services/server';
import {take} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    appLogo: string;
    tempUserImg: string;
    company: any;
    userId: string;

    constructor(private oauthService: OAuthService, private authService: AuthService,
                public permissionService: PermissionService, private spinner: NgxSpinnerService) {
        this.appLogo = Items.appLogo;
        this.tempUserImg = Items.tempUserImg;

    }

    get userFullName() {
        const parsedToken = JSON.parse(decodeURIComponent(escape(window.atob((this.authService.getToken()).split('.')[1]))));
        const fullNameMerge = parsedToken.first_name + ' ' + parsedToken.last_name;
        return fullNameMerge.trim();
    }

    getUserInfo(){
        const parsedToken = JSON.parse(window.atob((this.authService.getToken()).split('.')[1]));
        this.userId = parsedToken.sub;
        const photoApiUrl = server.storageUser + '/' + this.userId + '/profile-photo';

        this.getUserProfileImage(photoApiUrl);
    }

    getUserProfileImage(photoApiUrl){
        this.authService.ServerGet(photoApiUrl)
        .subscribe((res: { url: string }) => {
            if (res.url && res.url !== '') {
                this.tempUserImg = res.url;
            }

        }, err => {
            /*if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }*/
        }, () => {
            this.spinner.hide();
        });
    }

    public toggleSidebar() {
        document.getElementById('toggle_sidebar_button').click();
    }

    ngOnInit(): void {
        this.getCompany();
        this.getUserInfo();
    }

    getCompany() {
        const companyId = this.authService.companyId;
        this.authService.ServerGet(server.companies + '/' + companyId).pipe(take(1))
            .subscribe((res: any) => {
                this.company = res;
            }, (err) => {
            });
    }


    async logout() {
        await this.oauthService.logOut();
        await this.authService.ServerLogout();
    }

    openSearchModal() {
        // @ts-ignore
        $('#searchModal').modal('show');
         // @ts-ignore
        $('#searchModal').on('shown.bs.modal', function() {
            // @ts-ignore
            $(this).find('[autofocus]').focus();
          });
    }

    openMessageModal() {
        // @ts-ignore
        $('#message-modal').modal('show');
    }
}
