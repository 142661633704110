<div aria-hidden="true" aria-labelledby="addClientOperationTitle" class="modal fade mt-5" id="message-modal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-xl" style="margin-bottom: 100px !important;" role="document">
        <div class="modal-content" style="height: 720px;">
            <div class="modal-header gradient-bg">
                 <img (click)="messageUsersArea.getConversations()"  class="ml-2 mb-2 cursor-pointer"
                src="./assets/img/refresh.png" style="width: auto;height: 14.5px;margin-top:2px;" > 
                <h5 class="modal-title">
                    {{ "messages" | translate }}
                </h5>
                <button type="button" class="close ml-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="container-fluid height-100">
                    <div class="row height-100">
                        <div [ngClass]="{'d-sm-block d-block':isMobile,
                         'd-sm-none d-none':!isMobile}"
                             class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 height-100 pr-0 d-xl-block d-lg-block d-md-block"
                             style="border-right: 1px solid lightgray; border-left: 1px solid lightgray;">
                            <app-message-users-area #messageUsersArea (isEmptyCheck)="isEmptyCheck($event)" (emitOpenContent)="openContent($event)"></app-message-users-area>
                        </div>
                        <div [ngClass]="{'d-sm-block d-block':!isMobile,
                         'd-sm-none d-none':isMobile}" 
                             class="col-xl-9 col-lg-8 col-md-6 col-sm-12 col-12 height-100 d-xl-block d-lg-block d-md-block">
                            <app-message-content-area (emitGoBack)="goBack()" [conversation]="conversation"
                                                      [hidden]="!isOpen"></app-message-content-area>
                            <div *ngIf="isEmpty" style="font-size:20px;" >
                                <app-empty-table-info tableInfoTranslateKey="messageNotExistInfo"></app-empty-table-info>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
