<div class="card-body col-12"   >
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12"
                 style="border-right: 3px solid #a7a7a7;">
                <div class='user-img-div mx-auto mt-0 text-center'
                     style="max-width: 150px; cursor: pointer;">
                    <img [src]="tempUserImg"
                         class="rounded-circle img-thumbnail img-fluid user-img">
                    <div class="mt-3 mx-auto">
                        <p>
                            <b>
                                {{interview?.meta?.candidate?.name}}
                            </b>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 pl-xl-5 pl-lg-5 pl-md-5 pl-sm-0">
                <div class="mb-3">
                    <label class="modal-input-label">
                        <i class="fas fa-mobile-alt"
                           style="color: #a7a7a7; min-width: 25px;"></i>&nbsp;{{interview?.meta?.candidate.phone}}
                    </label>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label">
                        <i class="fas fa-envelope"
                           style="color: #a7a7a7; min-width: 25px;"></i>&nbsp;{{interview?.meta?.candidate.email}}
                    </label>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label">
                        <i class="fas fa-briefcase"
                           style="color: #a7a7a7; min-width: 25px;"></i>&nbsp;{{interview?.meta?.position.name}}
                    </label>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label">
                        <i class="fas fa-align-justify"
                           style="color: #a7a7a7; min-width: 25px;"></i>&nbsp;{{interview?.meta?.recruitmentStep.name}}
                    </label>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label">
                        <i class="fas fa-user-clock"
                           style="color: #a7a7a7; min-width: 25px;"></i>&nbsp;
                        {{interview?.meta?.date + ' ' + interview?.meta?.startTime + ' - ' + interview?.meta?.endTime }}
                    </label>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label">
                        <i class="fas fa-map-marker-alt"
                           style="color: #a7a7a7; min-width: 25px;"></i>&nbsp;
                        {{interview?.meta?.location }}
                    </label>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label">
                        <i class="far fa-file-alt"
                           style="color: #a7a7a7; min-width: 25px;"></i>&nbsp;
                        {{interview?.meta?.description }}
                    </label>
                </div>
                <div class="mb-3 mt-4">
                    <label><b>{{'attendee' | translate}}</b></label><br>
                    <label class="modal-input-label">
                        <i class="fas fa-id-card-alt"
                           style="color: #a7a7a7; min-width: 25px;"></i>&nbsp;
                        <span *ngFor="let attendee of interview?.meta?.attendees; let i = index;">{{attendee.name}}<span
                                *ngIf="interview.meta.attendees.length>1 && i < interview.meta.attendees.length - 1">,&nbsp;</span></span>
                    </label>
                </div>
                <div class="mb-3 mt-5">
                    <div class="container-fluid p-0">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-1">
                                <button (click)="openCandidateDetails()"
                                        class="btn btn-blue border-radius-0 col">{{'viewCandidate' | translate}}</button>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-1">
                                <button (click)="openEditInterview()"
                                        class="btn btn-blue border-radius-0 col">{{'editEvent' | translate}}</button>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-1">
                                <button (click)="openDeleteInterview()"
                                        class="btn btn-danger border-radius-0 col">{{'deleteEvent' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
