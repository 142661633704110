import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-multiselect-dropdown',
    templateUrl: './multiselect-dropdown.component.html',
    styleUrls: ['./multiselect-dropdown.component.scss']
})
export class MultiselectDropdownComponent implements OnInit, OnChanges {

    @Input() dropdownName = 'select';
    @Input() isSearchable = true;
    @Input() translateObjectKey = 'name';
    @Input() existItemKey = 'id';
    @Input() items: any[] = [];
    @Input() existItems: any[] = [];
    existItemArray: any[] = [];
    @Output() selectResult = new EventEmitter();

    selectedItems = [];
    selectedItemsView = [];
    storedItems: any[] = [];

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(): void {
        this.storedItems = [...this.items];
        if (this.existItems) {
            this.existItemArray = [];
            this.selectedItems = [];
            for (const existItem of this.existItems) {
                this.existItemArray.push(existItem[this.existItemKey]);
            }
            for (const storedItem of this.storedItems) {
                if (this.existItemArray.indexOf(storedItem.id) > -1) {
                    this.selectedItems.push(storedItem);
                }
                if (this.selectedItems.length >= this.existItemArray.length) {
                    break;
                }
            }
        }
    }

    stopClose(event): void {
        event.stopPropagation();
    }

    selectData(event, item: {}): void {
        if (event.target.checked) {
            const index = this.selectedItems.indexOf(item);
            if (index <= -1) {
                this.selectedItems.push(item);
            }
        } else {
            const index = this.selectedItems.indexOf(item);
            if (index > -1) {
                this.selectedItems.splice(index, 1);
            }
        }
        this.selectedItemsView = [...this.selectedItems];
        this.selectResult.emit(this.selectedItemsView);
    }

    emitData(): void {
        this.selectedItemsView = [...this.selectedItems];
        this.selectResult.emit(this.selectedItemsView);
    }

    search(data) {
        // tslint:disable-next-line:max-line-length
        this.items = ((this.storedItems).filter((item: any) => ((item[this.translateObjectKey]).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1));
    }

}
