<div class="container-fluid height-100">
    <div class="row height-100 width-1000">
        <div class="height-100 bg-orange width-300"> 
            <div class="container-fluid height-100">
                <div class="row">
                    <div class="col-12">
                        <h4 class="mt-4 main-color">{{'contact' | translate}}</h4>
                    </div>
                    <div *ngIf="candidate.primaryPhone" class="col-12 color-white">
                        <label class="mb-0 font-weight-bold">{{'mobile' | translate}}</label>
                        <p>{{candidate.primaryPhone}}</p>
                    </div>
                    <div *ngIf="candidate.primaryEmail" class="col-12 color-white">
                        <label class="mb-0 font-weight-bold">{{'email' | translate}}</label>
                        <p>{{candidate.primaryEmail}}</p>
                    </div>
                    <div *ngIf="candidate.country && candidate.country.value" class="col-12 color-white">
                        <label class="mb-0 font-weight-bold">{{'address' | translate}}</label>
                        <p>
                            <span>{{candidate.country.value}}</span><span
                                *ngIf="candidate.country.value">,&nbsp;</span><span>{{candidate.city.value}}</span><span
                                *ngIf="candidate.country.value">,&nbsp;</span><span>{{candidate.address}}</span>
                        </p>
                    </div>
                    <div *ngIf="candidate.linkedinAccount" class="col-12 color-white">
                        <label class="mb-0 font-weight-bold">{{'linkedin' | translate}}</label>
                        <p>{{candidate.linkedinAccount}}</p>
                    </div>
                    <div *ngIf="candidate.facebookAccount" class="col-12 color-white">
                        <label class="mb-0 font-weight-bold">{{'facebook' | translate}}</label>
                        <p>{{candidate.facebookAccount}}</p>
                    </div>
                    <div *ngIf="candidate.twitterAccount" class="col-12 color-white">
                        <label class="mb-0 font-weight-bold">{{'twitter' | translate}}</label>
                        <p>{{candidate.twitterAccount}}</p>
                    </div>
                    <div *ngIf="candidate.instagramAccount" class="col-12 color-white">
                        <label class="mb-0 font-weight-bold">{{'instagram' | translate}}</label>
                        <p>{{candidate.instagramAccount}}</p>
                    </div>
                    <div *ngIf="candidate.youtubeAccount" class="col-12 color-white">
                        <label class="mb-0 font-weight-bold">{{'youtube' | translate}}</label>
                        <p>{{candidate.youtubeAccount}}</p>
                    </div>
                    <div *ngIf="candidate.behanceAccount" class="col-12 color-white">
                        <label class="mb-0 font-weight-bold">{{'behance' | translate}}</label>
                        <p>{{candidate.behanceAccount}}</p>
                    </div>
                    <div *ngIf="candidate.stackoverflowAccount" class="col-12 color-white">
                        <label class="mb-0 font-weight-bold">{{'stackoverflow' | translate}}</label>
                        <p>{{candidate.stackoverflowAccount}}</p>
                    </div>
                    <div *ngIf="candidate.githubAccount" class="col-12 color-white">
                        <label class="mb-0 font-weight-bold">{{'github' | translate}}</label>
                        <p>{{candidate.githubAccount}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h4 class="mt-4 main-color">{{'skills' | translate}}</h4>
                    </div>
                    <div class="col-12">
                        <div class="container-fluid">
                            <div class="row">
                                <div *ngFor="let technicalSkill of technicalSkills"
                                     class="color-white col-auto mr-2 mt-1 skill-style">
                                    {{technicalSkill.name}}
                                </div>
                                <div *ngFor="let softSkill of softSkills"
                                     class="color-white col-auto mr-2 mt-1 skill-style">
                                    {{softSkill.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h4 class="mt-4 main-color">{{'languages' | translate}}</h4>
                    </div>
                    <div *ngFor="let language of languages" class="col-12 color-white">
                        <p class="mb-1"><b>{{language.language.value}}</b></p>
                        <p class="mb-0">
                            <span>{{'reading' | translate}}</span>&nbsp;:&nbsp;<span>{{language.readingLevel}}</span>
                        </p>
                        <p class="mb-0">
                            <span>{{'writing' | translate}}</span>&nbsp;:&nbsp;<span>{{language.writingLevel}}</span>
                        </p>
                        <p class="mb-0">
                            <span>{{'speaking' | translate}}</span>&nbsp;:&nbsp;<span>{{language.speakingLevel}}</span>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h4 class="mt-4 main-color">{{'certificates' | translate}}</h4>
                    </div>
                    <div *ngFor="let certification of certifications" class="col-12 color-white">
                        <p class="mb-1"><b>{{certification.name}}</b></p>
                        <p class="mb-0"><span>{{certification.organisation}}</span></p>
                        <p class="mb-0"><span>{{'issuedAt' | translate}}&nbsp;{{certification.issueMonth}}
                            .{{certification.issueYear}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="height-100 width-700">
            <div class="container-fluid height-100">
                <div class="row">
                    <div class="col-12 pt-4">
                        <h3 class="mb-0"><b>{{candidate.fullName}}</b></h3>
                        <p *ngIf="generalInfo.preferredTitle">{{generalInfo.preferredTitle}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 pt-4">
                        <h4 class="mb-4 underline">{{'generalInfo' | translate}}</h4>
                        <p *ngIf="generalInfo.dateOfBirth">
                            <span><b>{{'dateOfBirth' | translate}}</b></span>&nbsp;&nbsp;<span>{{generalInfo.dateOfBirth}}</span>
                        </p>
                        <p *ngIf="generalInfo.workingStatus">
                            <span><b>{{'workingStatus' | translate}}&nbsp;:</b></span>&nbsp;&nbsp;<span>{{generalInfo.workingStatus}}</span>
                        </p>
                        <p *ngIf="generalInfo.gender">
                            <span><b>{{'gender' | translate}}&nbsp;:</b></span>&nbsp;&nbsp;<span>{{generalInfo.gender}}</span>
                        </p>
                        <p *ngIf="generalInfo.militaryService">
                            <span><b>{{'militaryService' | translate}}&nbsp;:</b></span>&nbsp;&nbsp;<span>{{generalInfo.militaryService}}</span>
                        </p>
                        <p *ngIf="generalInfo.nationality">
                            <span><b>{{'nationality' | translate}}&nbsp;:</b></span>&nbsp;&nbsp;<span>{{generalInfo.nationality.value}}</span>
                        </p>
                        <p *ngIf="generalInfo.drivingLicence">
                            <span><b>{{'drivingLicence' | translate}}&nbsp;:</b></span>&nbsp;&nbsp;<span>{{ (generalInfo.drivingLicence === true ? 'yes' : (generalInfo.drivingLicence !== null ? 'no' : 'unspecified')) | translate}}</span>
                        </p>
                        <p *ngIf="generalInfo.smoking">
                            <span><b>{{'smoking' | translate}}&nbsp;:</b></span>&nbsp;&nbsp;<span>{{generalInfo.smoking}}</span>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 pt-4">
                        <h4 class="mb-4 underline">{{'experience' | translate}}</h4>
                        <div *ngFor="let experience of experiences" class="mb-4">
                            <p *ngIf="experience.title || experience.startMonth || experience.startYear || experience.endMonth || experience.endYear"
                               class="mb-1"><span *ngIf="experience.title"><b>{{experience.title}}</b></span><span
                                    class="float-right"><span>{{experience.startMonth}}.{{experience.startYear}}</span>&nbsp;-&nbsp;<span>{{experience.endMonth}}
                                .{{experience.endYear}}</span></span>
                            </p>
                            <p *ngIf="experience.employmentType" class="mb-1"><span
                                    class="opacity-text">{{experience.employmentType}}</span></p>
                            <p *ngIf="experience.summary" class="mb-1"><span
                                    class="opacity-text">{{experience.summary}}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 pt-4">
                        <h4 class="mb-4 underline">{{'education' | translate}}</h4>
                        <div *ngFor="let education of educations" class="mb-4">
                            <p *ngIf="education.department || education.startYear || education.endYear" class="mb-1">
                                <span *ngIf="education.department"><b>{{education.department.value}}</b></span><span
                                    *ngIf="education.startYear || education.endYear"
                                    class="float-right">{{education.startYear}}&nbsp;-&nbsp;{{education.endYear}}</span>
                            </p>
                            <p class="mb-1"><span class="opacity-text">{{education.degree}}</span></p>
                            <p class="mb-1"><span class="opacity-text">{{education.university.value}}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
