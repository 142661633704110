<!--Add pool education Modal -->
<div aria-hidden="true" aria-labelledby="deletePoolOperationTitle" class="modal fade" id="deletePool"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">
                    {{ "deletePool" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteName">{{"name" | translate}}</label>
                                <input [value]="pool.name" class="form-control border-radius-0"
                                       disabled
                                       id="deleteName"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="category">{{"category" | translate}}</label>
                                <input [value]="pool.category?.value"
                                       class="form-control border-radius-0"
                                       disabled
                                       id="category"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="country">{{"country" | translate}}</label>
                                <input [value]="pool.country?.value"
                                       class="form-control border-radius-0"
                                       disabled
                                       id="country"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="city">{{"city" | translate}}</label>
                                <input [value]="pool.city?.value"
                                       class="form-control border-radius-0"
                                       disabled
                                       id="city"
                                       type="text">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="close()" class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="deletePool()" class="btn btn-danger border-radius-0" type="button">
                    {{ "delete" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>