<div class="container-fluid">
    <div class="row">
        <div class="col-auto mb-1">
            <app-candidate-list-filter (applyFilterOperation)="applyFilter($event)"></app-candidate-list-filter>
        </div>
        <div class="col-auto mb-1">
            <button (click)="addToPositionCandidate()"
                    class="exactalent-button btn btn-blue border-radius-0">{{'addToPosition' | translate}}</button>
        </div>
    </div>
</div>
<div class="container-fluid mt-2">
    <div class="row">
        <div class="col-12">
            <app-candidate-list (removeFromPositionOperation)="removeFromPosition($event)"
                                (updateCandidates)="getCandidates($event)"
                                [candidates]="candidates"
                                [count]="count"
                                [referenceId]="positionId"
                                [referencePage]="'position'"></app-candidate-list>
        </div>
    </div>
</div>
<app-remove-from-position-modal (updateCandidates)="getCandidates(1)" [candidate]="selectedCandidate"
                                [positionId]="positionId"></app-remove-from-position-modal>
<app-add-to-position-candidate-modal (updateCandidates)="getCandidates(1)"
                                     [positionId]="positionId"></app-add-to-position-candidate-modal>
