<div class="container mt-5">
    <div class="row mb-2">
        <div class="col-12">
            <button (click)="openImportAttachment()"
                    class="btn btn-blue exactalent-button border-radius-0 float-right">{{'upload' | translate}}</button>
        </div>
    </div>
    <app-attachment-list (emitOpenRemoveAttachment)="openRemoveAttachment($event)"
                         [attachments]="attachments"></app-attachment-list>
</div>
<app-attachment-import [attachmentUrl]="attachmentUrl" (emitUpdateAttachmentList)="getCandidateAttachments()"></app-attachment-import>
<app-attachment-delete-modal (emitUpdateAttachmentList)="getCandidateAttachments()"
                             [attachmentDeleteUrl]="attachmentDeleteUrl"></app-attachment-delete-modal>
