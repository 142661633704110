<!-- Modal -->
<div aria-hidden="true" aria-labelledby="stepModalCenterTitle" class="modal fade"
     id="stepModal" 
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="stepModalLongTitle">{{'changeStep' | translate}}</h5>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <label class="modal-input-label" for="position">{{'position' | translate}}</label>
                    <select (change)="selectPosition($event)" class="custom-select border-radius-0"
                            id="position">
                        <option selected value="">{{'unspecified' | translate}}</option>
                        <option *ngFor="let position of positions"
                                [value]="position | json">{{position.positionName | translate}}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="step">{{'step' | translate}}</label>
                    <select (change)="selectStep($event)" class="custom-select border-radius-0"
                            id="step">
                        <option selected value="">{{'unspecified' | translate}}</option>
                        <option *ngFor="let step of steps"
                                [selected]="tempPosition.recruitmentStepId === step.recruitmentStepId"
                                [value]="step.recruitmentStepId">{{step.recruitmentStepName | translate}}</option>
                    </select>
                </div>
                <div class="mb-1">
                    <label class="ex-check" style="color: #649a65;">
                        <input [(ngModel)]="tempPosition.clientHrPermitted" type="checkbox">
                        <span class="ml-2">
                                {{'clientHrPermitted' | translate}}
                            </span>
                    </label>
                </div>
                <div class="mb-1">
                    <label class="ex-check" style="color: #649a65;">
                        <input [(ngModel)]="tempPosition.clientTechnicalPermitted" type="checkbox">
                        <span class="ml-2">
                                {{'clientTechnicalPermitted' | translate}}
                            </span>
                    </label>
                </div>
                <div class="mb-1">
                    <label class="ex-check" style="color: #649a65;">
                        <input [(ngModel)]="tempPosition.hideSensitiveDataForClient" type="checkbox">
                        <span class="ml-2">
                            {{'hideSensitiveDataForClient' | translate}}
                        </span>
                         
                    </label>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closeChangeStepModal()" class="btn btn-warning border-radius-0"
                        data-dismiss="modal"
                        type="button">{{'cancel' | translate}}</button>
                <button (click)="updateStep()" [disabled]="!tempPosition.positionId || tempStep === ''"
                        class="btn btn-success border-radius-0"
                        type="button">{{'save' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<app-add-interview-modal *ngIf="permissionService.permissionsOnly(['CREATE_INTERVIEW'])"
                         [InterviewInfo]="requestObject"></app-add-interview-modal>



