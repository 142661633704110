import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    TOKEN_KEY = 'access_token';
    lang = localStorage.getItem('lang');

    constructor(private httpClient: HttpClient) {
    }

    setHeadersWithToken() {
        const token = localStorage.getItem(this.TOKEN_KEY);
        let headersWithToken: HttpHeaders;
        headersWithToken = new HttpHeaders()
            .set('Accept', 'application/json')
            .set('Cache-Control', ' no-cache')
            .set('Accept-Language', this.lang)
            .set('Authorization', 'Bearer ' + token);
        return headersWithToken;
    }

    public upload(file, address: { url: string; }) {
        return this.httpClient.put<any>(address.url, file, {
            reportProgress: true
        });
    }

    public importCv(data) {
        const options = this.setHeadersWithToken();
        return this.httpClient.post<any>(data.url, data.file, {
            headers: options,
            reportProgress: true,
            observe: 'events'
        });
    }
}
