<!--Delete contactPerson education Modal -->
<div aria-hidden="true" aria-labelledby="deleteContactPersonOperationTitle" class="modal fade" id="deleteContactPerson"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">
                    {{ "deleteContactPerson" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteFirstName">{{"firstName" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="contactPerson.firstName" class="form-control border-radius-0"
                                       disabled
                                       id="deleteFirstName"
                                       name="firstName"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteMiddleName">{{"middleName" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="contactPerson.middleName" class="form-control border-radius-0"
                                       disabled
                                       id="deleteMiddleName"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteLastName">{{"lastName" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="contactPerson.lastName" class="form-control border-radius-0"
                                       disabled
                                       id="deleteLastName"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteTitle">{{"title" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="contactPerson.title" class="form-control border-radius-0"
                                       disabled
                                       id="deleteTitle"
                                       type="text">
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteEmail">{{"email" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="contactPerson.email" class="form-control border-radius-0"
                                       disabled
                                       id="deleteEmail"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deletePhone">{{"phone" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="contactPerson.phone" class="form-control border-radius-0"
                                       disabled
                                       id="deletePhone"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteMobile">{{"mobile" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="contactPerson.mobile" class="form-control border-radius-0"
                                       disabled
                                       id="deleteMobile"
                                       type="text">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="cancel()" class="btn btn-warning border-radius-0"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="delete()" class="btn btn-danger border-radius-0" type="button">
                    {{ "delete" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>