import {Component, Input, OnInit} from '@angular/core';
import {PermissionService} from '../../services/permission/permission.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    @Input() opened: boolean;

    constructor(public permissionService: PermissionService) {
    }

    ngOnInit(): void {
    }

}
