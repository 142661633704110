import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PermissionService} from '../../../services/permission/permission.service';

@Component({
    selector: 'app-positions-dashboard-view',
    templateUrl: './positions-dashboard-view.component.html',
    styleUrls: ['./positions-dashboard-view.component.scss']
})
export class PositionsDashboardViewComponent implements OnInit {

    allPositionsComponentState: boolean;
    myPositionsComponentState: boolean;
    operationType: string;
    tabState: string;
    @Output() getPositionCandidatesOperation = new EventEmitter();

    constructor(public permissionService: PermissionService) {
        this.setTab('myPositions');
    }

    ngOnInit(): void {
    }

    setTab(component) {
        this.allPositionsComponentState = false;
        this.myPositionsComponentState = false;
        switch (component) {
            case 'allPositions':
                this.allPositionsComponentState = true;
                this.tabState = 'allPositions';
                break;
            case 'myPositions':
                this.myPositionsComponentState = true;
                this.tabState = 'myPositions';
                break;
            default:
                this.allPositionsComponentState = true;
                break;
        }
    }

    emitPositionCandidateOperation(position) {
        this.getPositionCandidatesOperation.emit(position);
    }
}
