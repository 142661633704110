import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserModel} from '../../user-management/user.model';
import {InterviewModel} from '../../../services/parameters/models/interview.model';
import {AuthService} from '../../../services/auth/auth.service';
import {ParameterService} from '../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../services/permission/permission.service';
import {NotificationService} from '../../../services/notification/notification.service';
import {server} from '../../../services/server';
import {CalendarEvent} from 'angular-calendar';

@Component({
    selector: 'app-edit-interview-modal',
    templateUrl: './edit-interview-modal.component.html',
    styleUrls: ['./edit-interview-modal.component.scss']
})
export class EditInterviewModalComponent implements OnInit {

    @Input() interviewInfo: CalendarEvent;
    @Output() interviewUpdate = new EventEmitter();
    interviewers: UserModel[];
    minSearchValueCount: number;
    selectedUser: UserModel;
    scheduleInterviewData: InterviewModel;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
        this.scheduleInterviewData = new InterviewModel();
        this.interviewers = [];
    }

    ngOnInit(): void {
        // @ts-ignore
        $('.datepicker').datepicker({
            todayHighlight: true,
            autoclose: true,
            format: 'yyyy-mm-dd',
            startDate: new Date(),
            language: this.authService.getSessionLanguage(),
        });
        this.getInterviewers();
    }

    getInterviewers() {
        const companyId = this.authService.companyId;
        this.authService.ServerGet(server.users + '/' + '?filter=companyId::' +companyId)
            .subscribe((res: { content: [] }) => {
                this.interviewers = res.content;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    selectInterviewers(users) {
        this.interviewInfo.meta.attendees = users;
    }

    updateInterview(date, startTime, endTime) {
        this.scheduleInterviewData.id = this.interviewInfo.id;
        this.scheduleInterviewData.date = date;
        this.scheduleInterviewData.startTime = startTime;
        this.scheduleInterviewData.endTime = endTime;
        this.scheduleInterviewData.candidate.id = this.interviewInfo.meta.candidate.id;
        this.scheduleInterviewData.position.id = this.interviewInfo.meta.position.id;
        this.scheduleInterviewData.recruitmentStep.id = this.interviewInfo.meta.recruitmentStep.id;
        this.scheduleInterviewData.location = this.interviewInfo.meta.location;
        this.scheduleInterviewData.description = this.interviewInfo.meta.description;
        this.scheduleInterviewData.attendees = this.interviewInfo.meta.attendees;

        this.authService.ServerPut(server.interviews + '/' + this.interviewInfo.id, this.scheduleInterviewData)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.closeEditInterviewModal();
                this.updateInterviews();
                this.spinner.hide();
            });
    }

    cancelEditInterview() {
        this.closeEditInterviewModal();
        // @ts-ignore
        $('#calendarInterviewDetailsModal').modal('show');
    }

    closeEditInterviewModal() {
        // @ts-ignore
        $('#editInterviewModal').modal('hide');
    }

    updateInterviews() {
        this.interviewUpdate.emit();
    }

}
