<div class="row mt-1">
    <div class="col-12">
        <div class="row step-row">
            <div class="col-12 border-radius-0 step-col mt-2">
                <div class="row">
                    <span class="float-left ml-4 step-text col">{{attachment.name}}</span>
                    <span class="float-left ml-4 step-text col">{{attachment.createdAt | date}}</span>
                    <span class="float-right control-panel col">
                            <span (click)="openRemoveAttachment(attachment)" class="ml-4 float-right">
                                <i class="fas fa-times"></i>
                            </span>
                        </span>
                </div>
            </div>
        </div>

    </div>
</div>
