import { TitleCasePipe } from "@angular/common";
import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: '[appTitlecaseLower]'
})
export class TitlecaseLowerDirective {

  constructor( private elementRef: ElementRef,
               public Lower: TitleCasePipe) { }

  @HostListener("keyup") titleCaseOnKeyup() {
  const value = this.elementRef.nativeElement.value;
  const titleCaseStr =
      this.Lower
          .transform(value)
          .substring(0, 1)
          .toUpperCase() +
      this.Lower
          .transform(value)
          .substring(1)
          .toLowerCase();
  this.elementRef.nativeElement.value = titleCaseStr;
}
}

