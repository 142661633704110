import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import {DefaultLocalizationModel} from '../multiselect-dropdown-m/defaultLocalization.model';

@Component({
    selector: 'app-searchable-dropdown-with-add',
    templateUrl: './searchable-dropdown-with-add.component.html',
    styleUrls: ['./searchable-dropdown-with-add.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SearchableDropdownWithAddComponent implements OnInit, OnChanges {

    @Input() fieldName: string;
    @Input() disabled = false;
    @Input() dropdownName = 'unspecified';
    @Input() isSearchable = true;
    @Input() items: any[] = [];
    @Input() isRequired = false;
    @Input() isSaveClick = false;
    stateIsSaveClick = false;
    @Input() nameInput = '';
    @Output() selectResult = new EventEmitter();

    @Input() selectedItem: string;
    searchValue: string;
    storedItems: any[] = [];
    isFiltired = false;

    constructor() {
        this.selectedItem = '';
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.items && this.items.length > 0  && !this.isFiltired) {
            this.storedItems = [...this.items];
        }
        if (changes.isSaveClick) { this.stateIsSaveClick = changes.isSaveClick.currentValue; }
    }

    selectData(item: DefaultLocalizationModel): void {
        this.selectedItem = item.defaultLocalization.name;
        this.selectResult.emit(item);
    }

    search(data) {
        this.isFiltired = true;
        // tslint:disable-next-line:max-line-length
        this.items = ((this.storedItems).filter((item: any) => ((item.defaultLocalization.name).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1));
    }

    openAddNew() {
        // @ts-ignore
        $('#addItem').modal('show');
    }

    updateItems(item){
        this.searchValue = '';
        this.search(this.searchValue);
        this.items.push(item);
        this.storedItems = [];
        this.storedItems = [...this.items];
        this.selectData(item);
    }

}
