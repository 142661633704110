import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Items} from '../../../shared/appItems';
import {ConversationModel} from '../message-users-area/message-row/conversation.model';
import {server} from '../../../services/server';
import {PermissionService} from '../../../services/permission/permission.service';
import {AuthService} from '../../../services/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../../services/notification/notification.service';
import {MessageContentModel} from './message-content.model';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';

@Component({
    selector: 'app-message-content-area',
    templateUrl: './message-content-area.component.html',
    styleUrls: ['./message-content-area.component.scss']
})
export class MessageContentAreaComponent implements OnChanges {

    @Input() conversation: ConversationModel;
    messages: MessageContentModel[];
    tempUserImg: string;
    myUserId: string;
    newMessage: string;
    participantsProfilePictures: any = [];
    @Output() emitGoBack = new EventEmitter();

    constructor(public permissionService: PermissionService, private authService: AuthService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.newMessage = '';
        this.conversation = new ConversationModel();
        this.tempUserImg = Items.tempUserImg;
        this.myUserId = this.authService.myUserId;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.conversation.previousValue !== undefined) {
            this.setProfilePictureObject();
            this.getConversationContent();
        }
    }

    setProfilePictureObject() {
        for (const participant of this.conversation.participants) {
            this.participantsProfilePictures[participant.id] = participant.profilePhotoUrl;
        }
    }

    getConversationContent() {
        this.authService.ServerGet(server.conversations + '/' + this.conversation.id + '/messages?sort=createdAt,ASC')
            .subscribe((res: { content: MessageContentModel[] }) => {
                this.messages = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.scrollBottom();
                this.focusInput();
                this.spinner.hide();
            });
    }

    sendMessage() {
        const obj = {
            content: this.newMessage
        };

        this.authService.ServerPost(server.conversations + '/' + this.conversation.id + '/messages', obj)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.getConversationContent();
                this.scrollBottom();
                this.clearInput();
                this.spinner.hide();
            });
    }

    scrollBottom() {
        // @ts-ignore
        $('#message-content').animate({scrollTop: $('#message-content')[0].scrollHeight}, 1000);
    }

    focusInput() {
        document.getElementById('message-input').focus();
    }

    clearInput() {
        this.newMessage = '';
    }

    goBack() {
        this.emitGoBack.emit();
    }

}
