export class RecruitmentPreferencesModel {

    id: string;
    name: string;
    position: number;
    successfulLastStep: boolean;
    hideOnMobile: boolean;

    constructor() {
        this.successfulLastStep = false;
        this.hideOnMobile = false;
    }
}
