import {Component, Input, OnInit} from '@angular/core';
import {NoteModel} from '../note.model';

@Component({
    selector: 'app-view-note-modal',
    templateUrl: './view-note-modal.component.html',
    styleUrls: ['./view-note-modal.component.scss']
})
export class ViewNoteModalComponent implements OnInit {

    @Input() note: NoteModel;

    constructor() {
    }

    ngOnInit(): void {
    }

}
