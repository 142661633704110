import {InjectionToken} from '@angular/core';

import {LANG_EN_NAME, LANG_EN_TRANS} from '../../shared/language/lang-en';
import {LANG_TR_NAME, LANG_TR_TRANS} from '../../shared/language/lang-tr';
import {LANG_DE_NAME, LANG_DE_TRANS} from '../../shared/language/lang-de';

export const TRANSLATIONS = new InjectionToken('translations');

export const dictionary = {
    [LANG_EN_NAME]: LANG_EN_TRANS,
    [LANG_TR_NAME]: LANG_TR_TRANS,
    [LANG_DE_NAME]: LANG_DE_TRANS,
};

export const TRANSLATION_PROVIDERS = [
    {provide: TRANSLATIONS, useValue: dictionary},
];
