import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../services/notification/notification.service';
import {server} from '../../../../../services/server';
import {PositionModel} from '../../../../positions/position.model';
import {PipelineStepModel} from '../../../../../services/parameters/models/pipeline-step.model';
import {StepModel} from '../candidate-change-step/step.model';

@Component({
    selector: 'app-candidate-change-position',
    templateUrl: './candidate-change-position.component.html',
    styleUrls: ['./candidate-change-position.component.scss']
})
export class CandidateChangePositionComponent implements OnInit {

    @Input() candidateId: string;
    detailsTab: boolean;
    addTab: boolean;
    position: PositionModel;
    positions: PositionModel[];
    candidatePositions: PositionModel[];
    selectedPosition: StepModel;
    pipelineSteps: any;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                private notification: NotificationService) {
        this.detailsTab = false;
        this.addTab = false;
        this.position = new PositionModel();
    }

    ngOnInit(): void {
        this.setTab('candidatePositions');
    }

    selectPosition(e) {
        this.position.id = e.target.value;
    }

    setTab(component) {
        switch (component) {
            case 'candidatePositions':
                this.detailsTab = true;
                this.addTab = false;
                this.getCandidatePositions();
                break;
            case 'addToCandidate':
                this.detailsTab = false;
                this.addTab = true;
                this.getPositions();
                break;
            default:
                this.detailsTab = true;
                break;
        }
    }

    getPositions() {
        this.authService.ServerGet(server.positions)
            .subscribe((res: any) => {
                this.positions = res.content;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    closeChangePositionModal() {
        // @ts-ignore
        $('#candidatePositionModal').modal('hide');
    }

    getCandidatePositions() {
        this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/pipeline-steps?onlyCurrentSteps=true')
            .subscribe((res: { content: PipelineStepModel }) => {
                this.pipelineSteps = res.content;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    addPosition() {
        const obj = {
            candidateId: this.candidateId,
            positionId: this.position.id
        };
        this.authService.ServerPost(server.pipelineStepCandidates, obj)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.spinner.hide();
            });
    }

    openRemovePositionModal(position) {
        this.selectedPosition = position;
        // @ts-ignore
        $('#deleteCandidatePosition').modal('show');
    }

}
