import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserModel} from '../../../../../user-management/user.model';
import {ClientRoleList} from '../../../../../../shared/lists/client-role.list';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../../../services/notification/notification.service';
import {server} from '../../../../../../services/server';

@Component({
    selector: 'app-edit-client-user',
    templateUrl: './edit-client-user.component.html',
    styleUrls: ['./edit-client-user.component.scss']
})
export class EditClientUserComponent implements OnInit {

    @Input() clientId: string;
    @Output() emitUpdateTable = new EventEmitter();
    @Input() clientUser: UserModel;
    clientUserRoles = ClientRoleList;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.clientUser = new UserModel();
    }

    getSelectedRole(e) {
        this.clientUser.role = e.target.id;
    }

    editClientUser() {
        this.clientUser.companyId = this.authService.companyId;
        this.clientUser.clientId = this.clientId;
        this.clientUser.preferredLanguage = this.authService.getSessionLanguage();
        this.authService.ServerPut(server.users + '/' + this.clientUser.id, this.clientUser)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updateTable();
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#editClientUserModal').modal('hide');
                this.spinner.hide();
            });
    }

    updateTable() {
        this.emitUpdateTable.emit();
    }

}
