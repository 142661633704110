<!--User Modal -->
<div aria-hidden="true" aria-labelledby="userModalTitle" class="modal fade" id="deleteClientUserModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="addUserLongTitle">{{'deleteClientUser' | translate}}</h5>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <label class="modal-input-label" for="deleteName">{{'name' | translate}}<span
                            class="text-danger">*</span></label>
                    <input [(ngModel)]="clientUser.firstName" class="form-control border-radius-0"
                           disabled
                           id="deleteName"
                           name="deleteName"
                           required="required" type="text">
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="deleteMiddleName">{{'middleName' | translate}}</label>
                    <input [(ngModel)]="clientUser.middleName" class="form-control border-radius-0"
                           disabled
                           id="deleteMiddleName"
                           name="deleteMiddleName" type="text">
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="deleteSurname">{{'surname' | translate}}<span
                            class="text-danger">*</span></label>
                    <input [(ngModel)]="clientUser.lastName" class="form-control border-radius-0"
                           disabled
                           id="deleteSurname"
                           name="deleteSurname"
                           required="required" type="text">
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="deleteEmail">{{'email' | translate}}<span
                            class="text-danger">*</span></label>
                    <input [(ngModel)]="clientUser.email" class="form-control border-radius-0"
                           disabled
                           id="deleteEmail"
                           name="deleteEmail"
                           required="required" type="email">
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="deleteUserType">{{'userType' | translate}}<span
                            class="text-danger">*</span></label>
                    <div class="dropdown" id="deleteUserType">
                        <button class="btn border-radius-0 form-control" disabled type="button">
                            {{clientUser.role | translate}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">{{'cancel' | translate}}</button>
                <button (click)="deleteClientUser()" class="btn btn-success border-radius-0"
                        type="button">{{'delete' | translate}}</button>
            </div>
        </div>
    </div>
</div>
