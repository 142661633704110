import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {CalendarEvent, CalendarView} from 'angular-calendar';
import {Subject} from 'rxjs';
import {CalendarFilterModel, InterviewModel} from '../../../services/parameters/models/interview.model';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../services/permission/permission.service';

const colors: any = {
    org: {
        primary: '#eba73d',
    },
};

@Component({
    selector: 'app-calendar-view',
    templateUrl: './calendar-view.component.html',
    styleUrls: ['./calendar-view.component.scss']
})
export class CalendarViewComponent implements OnInit, OnChanges {

    @Input() interviews: InterviewModel[] = [];
    @Output() getInterviews = new EventEmitter();
    @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;

    view: CalendarView = CalendarView.Month;

    CalendarView = CalendarView;

    viewDate: Date = new Date();
    selectedDay: any = [];
    currentMonth: number;

    eventObject: any[];
    modalData: {
        action: string;
        event: CalendarEvent;
    };
    refresh: Subject<any> = new Subject();
    events: CalendarEvent[] = [];
    activeDayIsOpen = false;
    locale = this.authService.getSessionLanguage();

    selectedInterviewId: number;
    selectedInterview: CalendarEvent;

    calendarFilter: CalendarFilterModel;
    filterData: CalendarFilterModel;

    constructor(private authService: AuthService,
                public permissionService: PermissionService, ) {
        this.currentMonth = (new Date()).getMonth();
    }

    get monthValue(): number {
        return this.viewDate.getMonth() + 1;
    }

    get lastDayOfMonth() {
        const month = this.monthValue;
        const year = this.yearValue;
        return new Date(year, month, 0).getDate();
    }

    get yearValue(): number {
        return this.viewDate.getFullYear();
    }

    get monthlyFilter(): string {
        let month: number | string = this.monthValue;
        if (month < 10) {
            month = '0' + month;
        }
        return '?filter=date>:' + this.yearValue + '-' +
            month + '-01' + '&filter=date<:' + this.yearValue + '-' +
            month + '-' + this.lastDayOfMonth;
    }

    createCalendarObject() {
        this.events = [];
        if (this.interviews && this.interviews.length > 0) {
            for (const interview of this.interviews) {
                this.events.push(
                    {
                        id: interview.id,
                        start: new Date(interview.date + ' ' + interview.startTime),
                        end: new Date(interview.date + ' ' + interview.endTime),
                        title: 'Interview',
                        color: colors.org,
                        meta: interview
                    });
            }
        }
    }

    changeDate() {
        const newMonthValue = this.monthValue;
        if (this.currentMonth === newMonthValue) {
            return false;
        }
        this.currentMonth = newMonthValue;
        this.getInterviews.emit(this.monthlyFilter);
    }

    ngOnInit(): void {
        this.getInterviews.emit(this.monthlyFilter);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.createCalendarObject();
    }

    dateClicked(events): void {
        if (JSON.stringify(events) !== '[]') {
            this.openInterviewDetails(events);
        }
    }

    setView(view: CalendarView) {
        this.view = view;
    }

    openInterviewDetails(interview) {
        this.selectedDay = interview;
        // @ts-ignore
        $('#calendarInterviewDetailsModal').modal('show');
    }

    openDeleteInterview(interviewId) {
        this.selectedInterviewId = interviewId;
        // @ts-ignore
        $('#deleteInterviewModal').modal('show');
    }

    openEditInterview(interview) {
        this.selectedInterview = interview;
        // @ts-ignore
        $('#editInterviewModal').modal('show');
    }

    updateInterviews() {
        // @ts-ignore
        $('#calendarInterviewDetailsModal').modal('hide');
        this.getInterviews.emit(this.monthlyFilter);
    }

    applyFilter(filter) {
        this.calendarFilter = filter;
        if (JSON.stringify(this.filterData) === JSON.stringify(this.calendarFilter)) {
            return false;
        }
        this.filterData = new CalendarFilterModel();
        this.filterData = {...this.calendarFilter};
        const flt = this.monthlyFilter + this.requestFilter();
        this.getInterviews.emit(flt);
    }

    requestFilter() {
        return '&filter=positionId**' + this.filterData.position +
            '&filter=recruitmentStepId**' + this.filterData.recruitmentStep +
            '&filter=candidateId**' + this.filterData.candidate +
            '&filter=attendees.userId**' + this.filterData.attendees;
    }
}
