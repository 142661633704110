import {environment} from '../../environments/environment';

let serverAddress: string;
serverAddress = environment.baseUrl;
const companyUrl = serverAddress + '/company/v1';
const parameterUrl = serverAddress + '/parameter/v1';
const uaaUrl = serverAddress + '/uaa/v1';
const positionUrl = serverAddress + '/position/v1';
const candidateUrl = serverAddress + '/candidate/v1';
const searchUrl = serverAddress + '/search/v1';
const clientUrl = serverAddress + '/client/v1';
const calendarUrl = serverAddress + '/calendar/v1';
const storageUrl = serverAddress + '/storage/v1';
const messageUrl = serverAddress + '/message/v1';
const subscriptionUrl = serverAddress + '/subscription/v1';

export const server = {
    tags: candidateUrl + '/tags',
    pools: candidateUrl + '/pools',
    clients: clientUrl + '/clients',

    // pipeline step for candidate
    pipelineSteps: candidateUrl + '/pipeline-steps',
    pipelineStepCandidates: candidateUrl + '/pipeline-step-candidates',


    passwordRequest: uaaUrl + '/password-reset-requests',
    passwordReset: '/password/reset',
    closeSession: uaaUrl + '/auth/closeSession',

    // education-controller
    universities: parameterUrl + '/universities',
    faculties: parameterUrl + '/faculties',
    departments: parameterUrl + '/departments',

    // industry-controller
    industries: parameterUrl + '/industries',

    // currency-controller
    currencies: parameterUrl + '/currencies',

    // who-am-i-controller
    me: uaaUrl + '/users/me',

    // language-controller
    languages: parameterUrl + '/languages',

    // pool categories
    poolCategories: parameterUrl + '/pool-categories',

    // location-controller
    countries: parameterUrl + '/countries',
    cities: '/cities',

    // modular
    companies: companyUrl + '/companies',

    // users
    users: uaaUrl + '/users',

    // position
    positions: positionUrl + '/positions',
    positionRequests: positionUrl + '/position-requests',

    // candidate
    candidates: candidateUrl + '/candidates',

    // interview
    interviews: calendarUrl + '/interviews',

    // interview-requests
    interviewRequests: calendarUrl + '/interview-requests',
    
    //recruitment-step-interviews
    recruitmentStepInterviews: calendarUrl + '/recruitment-step-interviews',

    // time to fill
    timeToFill: candidateUrl + '/time-to-fill',

    // global-search
    globalSearch: searchUrl + '/global-search',

    // upload
    importCandidate: candidateUrl + '/import-candidate',
    storageUser: storageUrl + '/users',
    storageCandidate: storageUrl + '/candidates',
    storageClient: storageUrl + '/clients',
    storageCompany: storageUrl + '/companies',

    // messaging
    conversations: messageUrl + '/conversations',

    //subscription
    subscriptions:subscriptionUrl+'/subscriptions',
    
};
