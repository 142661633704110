<div class="row cursor-pointer" style="border-bottom: 1px solid lightgray;">
    <div class="col-12 px-0 py-2">
        <div class='col-2 px-0 user-img-div'>
            <img [src]="conversation.participants[0].profilePhotoUrl"
                 class="rounded-circle img-thumbnail img-fluid user-img">
        </div>
        <div class="col-10 px-0 user-info">
            <p class="user-name ml-2">
                <b>{{conversation.participants[0].name}}</b><span
                    class="float-right text-muted"><small [style.font-weight]="conversation.unread ? 'bold' : 'normal'">{{conversation.lastMessage?.updatedAt | date}}</small></span>
            </p>
            <div [style.font-weight]="conversation.unread ? 'bold' : 'normal'" class="user-name ml-2 message-pro-content">
                {{conversation.lastMessage?.content}}
            </div>
        </div>
    </div>
</div>
