import { Component, OnInit } from '@angular/core';
import { server } from '../../services/server';
import { AuthService } from '../../services/auth/auth.service';
import { ParameterService } from '../../services/parameters/parameter.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslatePipe } from '../../pipes/translate/translate.pipe';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification/notification.service';
import {
  CalendarFilterModel,
  InterviewModel,
} from '../../services/parameters/models/interview.model';
import { PermissionService } from 'src/app/services/permission/permission.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  interviews: InterviewModel[];
  onListViewComponent: boolean;
  calendarViewComponent: boolean;
  interviewRequestsViewComponent: boolean;

  constructor(
    private authService: AuthService,
    private parameterService: ParameterService,
    private spinner: NgxSpinnerService,
    private translate: TranslatePipe,
    private router: Router,
    private notification: NotificationService,
    public permissionService: PermissionService
  ) {
    this.setTab('onListView');
  }

  ngOnInit(): void {}

  setTab(component): void {
    switch (component) {
      case 'onListView':
        this.onListViewComponent = true;
        this.calendarViewComponent = false;
        this.interviewRequestsViewComponent = false;
        break;
      case 'calendarView':
        this.onListViewComponent = false;
        this.calendarViewComponent = true;
        this.interviewRequestsViewComponent = false;
        break;
      case 'interviewRequests':
        this.onListViewComponent = false;
        this.calendarViewComponent = false;
        this.interviewRequestsViewComponent = true;
        break;
      default:
        this.onListViewComponent = true;
        break;
    }
  }

  getInterviews(monthlyFilter): void {

    this.authService.ServerGet(server.interviews + monthlyFilter).subscribe(
      (res: any) => {
        this.interviews = res.content;
      },
      (err) => {
        if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
          this.notification.error(err.message);
          this.spinner.hide();
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }
}
