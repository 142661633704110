import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Items} from '../../appItems';
import {AuthService} from '../../../services/auth/auth.service';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../../services/notification/notification.service';
import {UploadService} from '../../../services/upload/upload.service';

@Component({
    selector: 'app-photo-import',
    templateUrl: './photo-import.component.html',
    styleUrls: ['./photo-import.component.scss']
})
export class PhotoImportComponent implements OnInit {

    @Input() tempUserImg: string;
    @Input() photoApiUrl: string;
    @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;
    file: File;
    @Output() emitSuccessStatus = new EventEmitter();
    confirmData: any = {};

    constructor(private authService: AuthService,
                private translate: TranslatePipe,
                private uploadService: UploadService,
                private spinner: NgxSpinnerService,
                private notification: NotificationService) {
        this.tempUserImg = Items.tempUserImg;
    }

    ngOnInit(): void {
    }

    onClick() {
        const fileUpload = this.fileUpload.nativeElement;
        fileUpload.click();
    }

    onFileChange(event) {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                this.tempUserImg = reader.result as string;

            };
            this.file = file;

        }
    }

    saveImage() {
        this.saveImageNameAndGetUploadUrl();
    }

    saveImageNameAndGetUploadUrl() {
        const obj = {
            fileName: this.file.name
        };
        this.authService.ServerPut(this.photoApiUrl, obj)
            .subscribe((res: { url: string }) => {
                this.uploadFile(res.url);
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
            });
    }


    uploadFile(url: string) {
        this.uploadService.upload(this.file, {url}).subscribe(res => {
            this.notification.success(this.translate.transform('saveSuccessful', []));
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            window.location.reload();
        });
    }

    closePhotoImportModal() {
        // @ts-ignore
        $('#photoImport').modal('hide');
    }

    openConfirmInterviewRequest() {

        this.confirmData.title = 'deleteProfilePhoto';
        const confirmInterviewRequest = this.translate.transform('confirmdeleteProfilePhoto', []);
        this.confirmData.message = `<i class="fas fa-exclamation-triangle pr-1"></i> ${confirmInterviewRequest}`;

        // @ts-ignore
        $('#confirmModal').modal('show');
    }
    deleteUserProfilImage(){
        this.authService.ServerDelete(this.photoApiUrl)
            .subscribe((res: { url: string }) => {

                this.tempUserImg = Items.tempUserImg;

            }, err => {
                /*if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }*/
            }, () => {
                this.spinner.hide();
                // @ts-ignore
                $('#confirmModal').modal('hide');
                window.location.reload();
            });
    }
}
