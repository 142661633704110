import {Component, Input, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';
import {NoteModel} from '../../../candidates/edit-candidate/notes/note.model';

@Component({
    selector: 'app-client-notes',
    templateUrl: './client-notes.component.html',
    styleUrls: ['./client-notes.component.scss']
})
export class ClientNotesComponent implements OnInit {

    @Input() clientId: string;
    notes: NoteModel[];
    note: NoteModel;
    inputAreaState: boolean;
    sessionUserId;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.inputAreaState = false;
        this.note = new NoteModel();
    }

    get userId() {
        const parsedToken = JSON.parse(window.atob((this.authService.getToken()).split('.')[1]));
        return parsedToken.sub;
    }

    ngOnInit(): void {
        this.getNotes();
        this.sessionUserId = this.userId;
    }

    getNotes(): void {
        this.authService.ServerGet(server.clients + '/' + this.clientId + '/notes')
            .subscribe((res: any) => {
                this.notes = res.content;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    addNote(): void {
        this.note = new NoteModel();
        this.inputAreaState = true;
    }

    cancel(): void {
        this.inputAreaState = false;
    }

    save(): void {
        if (this.note.id) {
            this.updateNote();
        } else {
            this.saveNote();
        }
    }

    saveNote(): void {
        this.authService.ServerPost(server.clients + '/' + this.clientId + '/notes', this.note)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.getNotes();
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.cancel();
                this.spinner.hide();
            });
    }

    updateNote(): void {
        this.authService.ServerPut(server.clients + '/' + this.clientId + '/notes/' + this.note.id, this.note)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.getNotes();
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.cancel();
                this.spinner.hide();
            });
    }

    editNote(note: NoteModel): void {
        this.note = note;
        this.inputAreaState = true;
    }

    openDeleteModal(note) {
        this.note = note;
        // @ts-ignore
        $('#deleteNote').modal('show');
    }

    openViewModal(note) {
        this.note = note;
        // @ts-ignore
        $('#viewNote').modal('show');
    }

    deleteNote() {
        this.authService.ServerDelete(server.clients + '/' + this.clientId + '/notes/' + this.note.id)
            .subscribe(res => {
                this.notification.success(this.translate.transform('deleteSuccessful', []));
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#deleteNote').modal('hide');
                this.spinner.hide();
                this.getNotes();
            });
    }

}
