import {Component, Input, OnInit} from '@angular/core';
import {PipelineStepModel} from '../../../../../services/parameters/models/pipeline-step.model';
import {server} from '../../../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {TranslatePipe} from '../../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../../services/notification/notification.service';

@Component({
    selector: 'app-pipeline-step-note',
    templateUrl: './pipeline-step-note.component.html',
    styleUrls: ['./pipeline-step-note.component.scss']
})
export class PipelineStepNoteComponent implements OnInit {

    @Input() candidate: PipelineStepModel;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                private translate: TranslatePipe,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.candidate = new PipelineStepModel();
    }

    addCandidateStepNote() {
        const obj = {
            note: this.candidate.note
        };
        this.authService.ServerPut(server.pipelineSteps + '/' + this.candidate.id + '/note/', obj)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.closeAddCandidateStepNote();
                this.spinner.hide();
            });
    }

    closeAddCandidateStepNote() {
        // @ts-ignore
        $('#pipelineStepNoteModal').modal('hide');
    }

}
