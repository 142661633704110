<div class="container-fluid height-100">
    <div class="row height-100">
        <div class="col-xl-9 col-lg-8 col-md-8 col-sm-12 pr-0 pl-0">
            <app-positions-dashboard-view
                    (getPositionCandidatesOperation)="getPositionCandidate($event)"></app-positions-dashboard-view>
            <app-candidates-dashboard-view *ngIf="position" [position]="position"></app-candidates-dashboard-view>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12 p-0"
             style="background-color: #f6f6f6; border-left: 5px solid #f6f6f6;">
            <app-upcoming-events></app-upcoming-events>
        </div>
    </div>
</div>
