// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseUrl: 'https://api.exactalent-test.com',
    careerPortalUrl: 'http://localhost:4401/',

    // OIDC CONFIG START

    // Url of the Identity Provider
    issuer: 'https://api.exactalent-test.com/uaa/v1',

    tokenEndpoint: 'https://api.exactalent-test.com/uaa/v1/oauth/token',

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin + '/index.html',

    // URL of the SPA to redirect the user after silent refresh
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: 'spa',
    dummyClientSecret: '',

    requireHttps: false,

    // set the scope for the permissions the client should request
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: 'web',

    showDebugInformation: true,

    logoutUrl: '/',
    oidc: false,
    timeoutFactor: 0.70,
    requestAccessToken: true,

    // OIDC CONFIG END
};
