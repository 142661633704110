<div class="dropdown drp">
    <button [disabled]="disabled" aria-expanded="false" 
    [ngClass]="{'is-invalid':(!selectedItem && isRequired && stateIsSaveClick),'is-valid':(selectedItem && isRequired && stateIsSaveClick)}"
     aria-haspopup="true" class="btn dropdown-toggle col-12 text-left form-control " data-toggle="dropdown" type="button">
        <span *ngIf="selectedItem === '' || selectedItem === null">
            {{dropdownName | translate}}
        </span>
        <span *ngIf="selectedItem !== '' && selectedItem !== null">
            {{selectedItem}}
        </span>
    </button>
    <input *ngIf="isRequired" type="text" [ngModel]="selectedItem" hidden ngModel name="{{nameInput}}" required>
    <div aria-labelledby="multipleSelectDropdownMenuButton" class="dropdown-menu col-12 pb-0">
        <label *ngIf="isSearchable" class="col-12">
            <input #searchInput (keyup)="search(searchInput.value)" autocomplete="off" autofocus
                class="form-control border-radius-0" placeholder="{{'search' | translate}}">
        </label>
        <div class="col-12 drp-menu p-0">
            <label (click)="selectData({id:'',defaultLocalization:{name:'',language:''}})"
                class="dropdown-item m-0 cursor-pointer">
                <span class="ml-2">{{'unspecified' | translate}}</span>
            </label>
            <label (click)="selectData(item)" *ngFor="let item of items" class="dropdown-item m-0 cursor-pointer">
                <span class="ml-2">{{item.defaultLocalization.name}}</span>
            </label>
        </div>
    </div>
</div>
