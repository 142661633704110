import {Component, Input, OnInit} from '@angular/core';
import {StepModel} from '../../candidates/edit-candidate/candidate-profile-operations/candidate-change-step/step.model';
import {RequestPayload} from '../../../shared/requestPayload';
import {PipelineStepModel} from '../../../services/parameters/models/pipeline-step.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';
import {server} from '../../../services/server';
import {CalendarFilterModel, InterviewModel} from '../../../services/parameters/models/interview.model';

@Component({
    selector: 'app-onlist-view',
    templateUrl: './onlist-view.component.html',
    styleUrls: ['./onlist-view.component.scss']
})
export class OnlistViewComponent implements OnInit {

    @Input() positionId = '';
    @Input() candidateId = '';
    pipelineSteps: StepModel[];
    requestPayload: RequestPayload;
    interviews: InterviewModel[];
    count: number;
    selectedStepId: string;
    selectedCandidate: PipelineStepModel;
    calendarFilter: CalendarFilterModel;
    filterData: CalendarFilterModel;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.requestPayload = new RequestPayload();
        this.count = 0;
    }

    ngOnInit(): void {
        this.filterData = new CalendarFilterModel();
        this.getRecruitmentPreferences();
    }

    getRecruitmentPreferences() {
        if(this.positionId=='')
        {
            this.positionId=this.filterData.position;
        }
        this.authService.ServerGet(server.recruitmentStepInterviews + '?filter=positionId**' + this.positionId+ '&filter=candidateId**' 
        + this.filterData.candidate+ '&filter=recruitmentStepId**' + this.filterData.recruitmentStep  + '&filter=attendeeId**' + this.filterData.attendees)
            .subscribe((res: StepModel[]) => {
                this.pipelineSteps = res;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    openPipelineUserList(step: StepModel, event) {
        if ((event.target.tagName).toUpperCase() === 'DIV') {
            if (!this.selectedStepId || this.selectedStepId !== step.recruitmentStepId) {
                this.count = 0;
                this.interviews = [];
                if (this.selectedStepId) {
                    document.getElementById('stepId_' + this.selectedStepId).style.display = 'none';
                }
                document.getElementById('stepId_' + step.recruitmentStepId).style.display = 'block';
                this.selectedStepId = step.recruitmentStepId;
                this.getStepUsers('1');
            } else {
                if (document.getElementById('stepId_' + this.selectedStepId).style.display === 'none') {
                    document.getElementById('stepId_' + step.recruitmentStepId).style.display = 'block';
                } else {
                    document.getElementById('stepId_' + step.recruitmentStepId).style.display = 'none';
                }
            }

            this.selectedStepId = step.recruitmentStepId;
        }
    }

    getStepUsers(pageNo) {
        this.authService.ServerGet(server.interviews + this.requestPayload.payloadURL(pageNo) +
            '&filter=positionId**' + this.positionId +
            '&filter=recruitmentStepId::' + this.selectedStepId +
            '&filter=candidateId::' + this.candidateId + this.requestFilter())
            .subscribe((res: any) => {
                this.interviews = res.content;
                this.count = res.totalElements;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    applyFilter(filter) {
        this.calendarFilter = filter;
        if (JSON.stringify(this.filterData) === JSON.stringify(this.calendarFilter)) {
            return false;
        }
        this.filterData = new CalendarFilterModel();
        this.filterData = {...this.calendarFilter};
        this.getRecruitmentPreferences();
    }

    requestFilter() {
        console.log(this.filterData.candidate);
        return '&filter=positionId**' + this.filterData.position +
            '&filter=recruitmentStepId**' + this.filterData.recruitmentStep +
            '&filter=candidateId**' + this.filterData.candidate +
            '&filter=attendees.userId**' + this.filterData.attendees;
    }

}
