<!-- Modal -->
<div aria-hidden="true" aria-labelledby="positionModalCenterTitle" class="modal fade"
     id="exportModalPosition"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="statusModalLongTitle">{{'Export' | translate}}</h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col ">
                                <button
                                          class="btn btn-blue btn-reset border-radius-0 float-right exactalent-button "
                                          data-target="#exportAsPdf"
                                          data-toggle="modal">
                                    {{'ExportAsPdf' | translate}}
                                </button>
                    </div>
                        <div class="col">
                            <button
                                    class="btn btn-blue btn-reset border-radius-0  exactalent-button "
                                    data-target="#exportAsExcel"
                                    data-toggle="modal">
                                {{'ExportAsExcel' | translate}}
                            </button>
                        </div>
                </div>
                    </div>


        </div>
    </div>
</div>
</div>
