import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { server } from '../../../services/server';
import { CandidateModel } from '../candidate.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../../services/auth/auth.service';
import { PermissionService } from '../../../services/permission/permission.service';
import { TranslatePipe } from '../../../pipes/translate/translate.pipe';
import { NotificationService } from '../../../services/notification/notification.service';
import {
  CertificationModel,
  EducationModel,
  LanguageModel,
  TechnicalSkillModel,
} from '../../../services/parameters/models/education.model';
import { ExperienceModel } from '../edit-candidate/experience/experience.model';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
  selector: 'app-candidate-cv-modal',
  templateUrl: './candidate-cv-modal.component.html',
  styleUrls: ['./candidate-cv-modal.component.scss'],
})
export class CandidateCvModalComponent implements OnInit {
  @Input() candidateId: string;
  @Output() downloadPDF = new EventEmitter();
  candidate: CandidateModel;
  technicalSkills: TechnicalSkillModel[];
  softSkills: TechnicalSkillModel[];
  languages: LanguageModel[];
  certifications: CertificationModel[];
  experiences: ExperienceModel[];
  educations: EducationModel[];
  generalInfo: CandidateModel;

  @ViewChild('contentCvModal') contentCvModal: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    public permissionService: PermissionService,
    private translate: TranslatePipe,
    private notification: NotificationService,
    private utilitiesService: UtilitiesService
  ) {
    this.candidate = new CandidateModel();
    this.generalInfo = new CandidateModel();
  }

  ngOnInit(): void {
    this.getGeneralInfo();
    this.getCandidate();
    this.getCertifications();
    this.getEducation();
    this.getExperiences();
    this.getLanguages();
    this.getSoftSkills();
    this.getTechnicalSkills();
}


getGeneralInfo() {
  this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/general-info')
      .subscribe((res: CandidateModel) => {
          this.generalInfo = res;
      }, err => {
          if (err.status >= 400 || err.status < 500) {
              this.notification.error(err.message);
              this.spinner.hide();
          }
      }, () => {
          this.spinner.hide();
      });
}

getCandidate() {
  this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/profile')
      .subscribe((res: CandidateModel) => {
          this.candidate = res;
      }, err => {
          if (err.status >= 400 || err.status < 500) {
              this.notification.error(err.message);
              this.spinner.hide();
          }
      }, () => {
          this.spinner.hide();
      });
}

getTechnicalSkills() {
  this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/technical-skills')
      .subscribe((res: any) => {
          this.technicalSkills = res.content;

      }, err => {
          if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
              this.notification.error(err.message);
              this.spinner.hide();
          }
      }, () => {
          this.spinner.hide();
      });
}

getSoftSkills() {
  this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/soft-skills')
      .subscribe((res: any) => {
          this.softSkills = res.content;
      }, err => {
          if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
              this.notification.error(err.message);
              this.spinner.hide();
          }
      }, () => {
          this.spinner.hide();
      });
}

getLanguages() {
  this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/languages')
      .subscribe((res: any) => {
          this.languages = res.content;
      }, err => {
          if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
              this.notification.error(err.message);
              this.spinner.hide();
          }
      }, () => {
          this.spinner.hide();
      });
}

getCertifications() {
  this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/certifications')
      .subscribe((res: any) => {
          this.certifications = res.content;
      }, err => {
          if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
              this.notification.error(err.message);
              this.spinner.hide();
          }
      }, () => {
          this.spinner.hide();
      });
}

getExperiences() {
  this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/employments')
      .subscribe((res: any) => {
          this.experiences = res.content;
      }, err => {
          if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
              this.notification.error(err.message);
              this.spinner.hide();
          }
      }, () => {
          this.spinner.hide();
      });
}

getEducation() {
  this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/educations')
      .subscribe((res: any) => {
          this.educations = res.content;
      }, err => {
          if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
              this.notification.error(err.message);
              this.spinner.hide();
          }
      }, () => {
          this.spinner.hide();
      });
}

downloadPDFClick(){
this.downloadPDF.emit();
  // @ts-ignore
$('#candidateCvModal').modal('hide');

}


}
