import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { server } from 'src/app/services/server';
import { TranslatePipe } from 'src/app/services/translate';
import {UserModel} from '../../../../user-management/user.model';

@Component({
    selector: 'app-remove-team-member',
    templateUrl: './remove-team-member.component.html',
    styleUrls: ['./remove-team-member.component.scss']
})
export class RemoveTeamMemberComponent implements OnInit, OnChanges {

    @Input() selectedMember: UserModel;
    @Input() positionId: string;
    @Output() runOperation = new EventEmitter();

    hiringTeamMember: UserModel;


    constructor(private authService: AuthService,  private spinner: NgxSpinnerService,
                private translate: TranslatePipe, private notification: NotificationService) {
    }

    ngOnInit(): void {


    }

    ngOnChanges(changes: SimpleChanges) {
        this.hiringTeamMember = this.selectedMember;
    }

    removeUser(positionId, hiringTeamMember){
           this.authService.ServerDelete(server.positions + '/' + positionId + '/hiring-team-members/' + hiringTeamMember.userId)
            .subscribe(res => {
                this.notification.success(this.translate.transform('deleteSuccessful', []));
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.runOperation.emit();
                this.closeModal();
                this.spinner.hide();
            });

    }

    closeModal() {
        // @ts-ignore
        $('#removeTeamMemberModal').modal('hide');
    }

}
