<div class="container-fluid mt-3 pl-0">
    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-bg">
                    <thead class="table-bg">
                    <tr>
                        <th scope="col">{{'jobTitle' | translate}}</th>
                        <th scope="col">{{'positionType' | translate}}</th>
                        <th scope="col">{{'user' | translate}}</th>
                        <th scope="col">{{'requestDate' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr (click)="openRowModal($event,positionRequest)"
                        *ngFor="let positionRequest of positionRequests | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count }"
                        class="custom-table-row">
                        <td>{{positionRequest.name}}</td>
                        <td>{{positionRequest.jobType}}</td>
                        <td>
                            <app-user [userId]="positionRequest.createdBy"></app-user>
                        </td>
                        <td>{{positionRequest.createdAt}}</td>
                    </tr>
                    <tr [hidden]="count>0">
                        <td colspan="4">
                            <app-empty-table-info
                                    tableInfoTranslateKey="positionRequestNotExistInfo"></app-empty-table-info>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="width-100">
                        <td class="text-center" colspan="4">
                            <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
                                                 [autoHide]="count <= requestPayload.limit"
                                                 nextLabel="{{'next' | translate}}"
                                                 previousLabel="{{'previous' | translate}}"
                                                 responsive="true"></pagination-controls>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
<div (clickOutside)="onClickedOutside($event)" class="operation-list"
     id="row-modal">
    <ul>
        <li (click)="openPositionDetails()" class="operation-list-item">
            <span>{{'openPositionRequestDetail' | translate}}</span>
        </li>
    </ul>
</div>

<app-client-position-request-details-modal
        [positionDetails]="selectedPositionRequest"></app-client-position-request-details-modal>
