import {Component, Input, OnInit} from '@angular/core';
import {ExperienceModel, ProjectsModel} from './experience.model';
import {server} from '../../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';

@Component({
    selector: 'app-experience',
    templateUrl: './experience.component.html',
    styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {

    @Input() candidateId: string;
    experiences: ExperienceModel[];
    projects: ProjectsModel[];
    selectedExperience: ExperienceModel;
    selectedProject: ProjectsModel;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.getExperiences();
        this.getProjects();
    }

    getExperiences() {
        this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/employments')
            .subscribe((res: any) => {
                this.experiences = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    getProjects() {
        this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/projects')
            .subscribe((res: any) => {
                this.projects = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    openAddWorkExperience() {
        // @ts-ignore
        $('#addWorkExperience').modal('show');
    }

    openEditWorkExperience(experience, event) {
        this.selectedExperience = experience;
        if (event.target.id === 'deleteWorkExperienceButton') {
            // @ts-ignore
            $('#deleteWorkExperience').modal('show');
        } else {
            // @ts-ignore
            $('#editWorkExperience').modal('show');
        }
    }

    openAddProject() {
        // @ts-ignore
        $('#addProject').modal('show');
    }

    openEditProject(project, event) {
        this.selectedProject = project;
        if (event.target.id === 'deleteProjectButton') {
            // @ts-ignore
            $('#deleteProject').modal('show');
        } else {
            // @ts-ignore
            $('#editProject').modal('show');
        }
    }

}
