export class StepModel {
    recruitmentStepId: string;
    recruitmentStepName: string;
    candidateCount: number;
    order: number;
    addedAt: string;
    candidateId: string;
    email: string;
    id: string;
    name: string;
    note: string;
    phone: string;
    positionId: string;
    positionName: string;
    source: string;
    updatedAt: string;
    interviewCount:number;


    constructor() {
    }
}
