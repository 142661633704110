<div *ngIf="!addCandidateState" class="container-fluid">
    <div class="row">
        <div class="col-12 menu-item gradient-bg">
            <a class="nav-link active">{{'candidates' | translate}}</a>
        </div>
    </div>
</div>
<span *ngIf="!addCandidateState">
    <div class="container-fluid mt-4">
        <div class="row">
        <div class="col-auto mb-1 col">
            <app-candidate-list-filter (applyFilterOperation)="applyFilter($event)"></app-candidate-list-filter>
        </div>
        <div *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE'])" class="col-auto mb-1 col">
                <button (click)="addCandidateModal()"
                        class="btn btn-blue border-radius-0 float-right exactalent-button width-100"
                        data-target="#addUserCenter"
                        data-toggle="modal">
                    {{'addNewCandidate' | translate}}
                </button>
            </div>


                    <label *ngIf="isSearchable"  class="col">
            <input  [(ngModel)]="searchInput.value" autocomplete="off"  autofocus [value]="searchValue"
                   class="form-control border-radius-0" placeholder="{{'Search in candidates' | translate}}">
        </label>


             <div  class="col-auto mb-1 col">
                <button [disabled]="searchInput.value.length < 3  "    #searchInput (click)="search(searchInput.value)"
                        class="btn btn-blue btn-reset border-radius-0 float-right exactalent-button "
                        data-target="#addUserCenter"
                        data-toggle="modal">
                    {{'Search' | translate}}
                </button>
                </div>

                     <div  class="col-auto mb-1 col">
                <button   #searchButtonReset [hidden]="isShow"  (click)="resetle(searchButtonReset.value)"
                        class="btn btn-blue btn-reset border-radius-0 float-right exactalent-button "
                        data-target="#addUserCenter"
                        data-toggle="modal">
                    {{'ClearSearchResults' | translate}}
                </button>
                </div>
        </div>
    </div>


    <div class="container-fluid mt-2">
        <div class="row">
            <div class="col-12">
                <app-candidate-list (updateCandidates)="getCandidates($event)" [candidates]="candidates"
                                    [count]="count"
                                    [referencePage]="'candidate'"></app-candidate-list>
            </div>
        </div>
    </div>
</span>
<app-add-candidate (setCandidateScreen)="getCandidates(1)"
                   *ngIf="addCandidateState && permissionService.permissionsOnly(['CREATE_CANDIDATE'])"></app-add-candidate>
