<div class="card mt-3 cursor-pointer" style="border-radius: 14px;">
    <div class="card-body pb-2 pt-2">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mt-2">
                    <p><i class="fas fa-circle-notch text-success"
                            style="transform: rotate(30deg)"></i>&nbsp;&nbsp;{{position.name | uppercase}}</p>
                    <p class="mb-0" style="opacity: 0.75;"><i
                            class="fas fa-map-marker-alt"></i>&nbsp;&nbsp;{{position.city?.value + ' / ' + position.country?.value}}
                    </p>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 mt-2">
                    <div class="row">
                        <label class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                            <span (click)="openHiringProcess(position)" class=" text-success cursor-pointer"
                                style="text-decoration: underline;">{{'hiringProcess' | translate}}</span>
                        </label>
                        <label class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                            <span (click)="openPositionCandidates(position)" class=" text-success cursor-pointer"
                                style="text-decoration: underline;">{{'candidates' | translate}}</span>
                        </label>
                        <label class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                            <span (click)="emitAddCandidate(position)" class=" text-success cursor-pointer"
                                style="text-decoration: underline;">{{'addCandidate' | translate}}</span>
                        </label>
                    </div>

                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 mt-2" style="opacity: 0.75;">
                    <div class="row ">
                        <p *ngIf="permissionService.permissionsOnly(['UPDATE_POSITION_REQUEST'])"><i
                                (click)="openEditPosition(position)" class="fas fa-pencil-alt cursor-pointer ml-3"></i></p>
                        <p *ngIf="permissionService.permissionsOnly(['CREATE_HIRING_TEAM_MEMBER'])" class="mb-1"><i
                                (click)="emitAddHiringTeamMember(position)" class="fas fa-plus cursor-pointer ml-3"></i></p>
                    </div>
                    <div class="row">
                        <label class="col">
                            <app-hiring-team-member-icon-container [positionId]="position.id">
                            </app-hiring-team-member-icon-container>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>