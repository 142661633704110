<div class="card mt-3 pr-2" style="max-height: 360px; min-height: 360px; overflow-y: auto; border: none;">
    <app-positions-dashboard-view-list-item (addCandidateOperation)="openAddToPositionCandidate($event)" (click)="emitPositionCandidateOperation(position)"
                                            (addHiringTeamMemberOperation)="openAddToPositionHiringTeamMember($event)"
                                            *ngFor="let position of positions;"
                                            [position]="position"></app-positions-dashboard-view-list-item>
    <app-empty-table-info *ngIf="positions.length<=0"
                          tableInfoTranslateKey="positionNotExistInfo"></app-empty-table-info>
    <div [hidden]="positions.length <= maxItemCount" class="container-fluid mt-2">
        <div class="row">
            <div class="col-12">
                <p [routerLink]="['positions']" class="text-center cursor-pointer text-info"
                   style="text-decoration: underline;">
                    <i>{{'seeAllPositions' | translate}}</i>
                </p>
            </div>
        </div>
    </div>
</div>
<app-add-to-position-candidate-modal [positionId]="selectedPositionId"></app-add-to-position-candidate-modal>
<app-add-to-position (updateTable)="checkTab()" [positionId]="selectedPositionId"></app-add-to-position>
