<div class="card mt-3" style="border-radius: 14px;">
    <div class="card-body pb-2 pt-2">
        <div class="container-fluid"> 
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-2">
                    <p class="mb-0">
                        <i class="far fa-user-circle fa-2x color-orange" style="font-size: 40px;"></i>&nbsp;&nbsp;
                        <label class="mb-0">
                            <label [routerLink]="['edit-candidate',{id:candidate.id}]"
                                   class="mb-0 cursor-pointer" style="color: #353535">{{candidate.fullName | uppercase}}</label>
                            <br>
                            <label>
                                <small class="text-muted" *ngIf="candidate.preferredTitle">{{candidate.preferredTitle}}</small>
                                <small class="text-muted" *ngIf="candidate.preferredTitle === null">--</small>
                            </label>
                        </label>
                    </p>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 mt-2">
                    <div style="display: flex;justify-content: space-around;" class="row pt-2">
                        <label class="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                            <span  class="text-muted"><i style="float: left;"
                                    class="fas fa-envelope"></i>&nbsp;{{candidate.primaryEmail}}</span>
                        </label>
                        <label class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                            <span  class="text-muted"><i style="float: left;"
                                    class="fas fa-mobile-alt"></i>&nbsp;{{candidate.primaryPhone}}</span>
                        </label>
                    </div>
                </div>
                <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 mt-2" style="opacity: 0.75;">
                    <p><i (click)="downloadPDF()" class="fas fa-download cursor-pointer" style="font-size: 26px;"
                          title="{{'cv' | translate}}"></i></p>
                </div> 
            </div>
        </div>
    </div>
</div>

<app-candidate-cv-modal (downloadPDF)="downloadPDF()" [candidateId]="candidate?.id"></app-candidate-cv-modal>
