<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta content="width=device-width, initial-scale=1, shrink-to-fit=no" name="viewport">
    <title>{{'appTitle' | translate}}</title>
</head>
<body class="gradient-bg">
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 full_width_panel">
            <div class="panel_area">
                <form autocomplete="off" class="form-horizontal form-signin">
                    <div class="form-group">
                        <p class="welcome-title">{{'welcomeTo' | translate}} </p>
                    </div>
                    <div class="form-group">
                        <img [src]="appLogo" height="71">
                    </div>
                    <router-outlet></router-outlet>
                </form>
            </div>
        </div>
    </div>
</div>
</body>
</html>
<ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        color="#339CFF"
        size="default"
        type="ball-spin-clockwise">
</ngx-spinner>
