<div class="container-fluid p-0 overflow-hidden">
    <div class="row">
        <div  *ngIf="permissionService.permissionsOnly(['READ_CANDIDATE_PROFILE'])" class="col-xl-3 col-lg-2 col-md-12 col-sm-12 p-0">
            <div class="container-fluid h-100">
                <div class="row h-100">
                    <div class="col-12 user-panel">
                        <div class="row">
                            <div class='user-img-div mx mt-5 text-center'
                                style="max-width: 140px; cursor: pointer; margin: auto;">
                                <img (click)="openChangePhoto()" [src]="tempUserImg"
                                    class="rounded-circle img-thumbnail img-fluid" style="width: 140px; height: 140px;">
                                <div class="mt-3 mx">
                                    <p>
                                        {{candidate?.fullName}}
                                    </p>
                                    <p>
                                        ......
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row pl-3">
                            <div class="col-12">
                                <span
                                    style="background-color: white;padding: 5px 9px;border-radius: 50%;text-align: center;">
                                    <i class="fas fa-mobile-alt" style="color: orange"></i>
                                </span>
                            </div>
                            <div style="word-break: break-all;" class="col-12 mt-3">
                                <p>{{candidate?.primaryPhone}}</p>
                                <p>{{candidate?.secondaryPhone}}</p>
                            </div>
                        </div>
                        <div class="row pl-3">
                            <div class="col-12">
                                <span
                                    style="background-color: white;padding: 5px 6px;border-radius: 50%;text-align: center;">
                                    <i class="fas fa-envelope" style="color: orange"></i>
                                </span>
                            </div>
                            <div style="word-break: break-all;" class="col-12 mt-3">
                                <p>{{candidate?.primaryEmail}}</p>
                                <p>{{candidate?.secondaryEmail}}</p>
                            </div>
                        </div>
                        <div class="row pl-3">
                            <div class="col-12">
                                <span
                                    style="background-color: white;padding: 5px 6px;border-radius: 50%;text-align: center;">
                                    <i class="fas fa-user" style="color: orange"></i>
                                </span>
                            </div>
                            <div class="col-12 mt-3">
                                <p>{{'dateOfBirth' | translate}}:&nbsp;{{candidate?.dateOfBirth | date}}</p>
                                <p style="word-break: break-all;">{{'source' | translate}}:&nbsp;{{candidate?.source}}</p>
                                <p>
                                    {{'address' | translate}}:&nbsp;<span>{{candidate.country?.value}}</span><span
                                        *ngIf="candidate.country?.value">,&nbsp;</span><span style="display: inline-block;">{{candidate.city?.value}}</span><span
                                        *ngIf="candidate.country?.value">,&nbsp;</span><span style="display: inline-block;">{{candidate?.address}}</span>
                                </p>
                                <p *ngIf="permissionService.permissionsOnly(['READ_ALL_TAGS',
                                'READ_ALL_CANDIDATE_TAGS',
                                'UPDATE_ALL_CANDIDATE_TAGS'])">{{'tags' | translate}}
                                    :&nbsp;{{tagList}}
                                    <span (click)="addTag()" class="ml-1"
                                        style="text-decoration: underline #353535; color: #353535; cursor: pointer;">
                                        +{{'addTag' | translate}}
                                    </span>
                                </p>
                            </div>
                        </div>

                        <!--Social Media Icons Begin-->
                        <div class="row pl-3">
                            <div class="col-12">
                                <a *ngIf="candidate?.facebookAccount" [href]=" facebookUrl" target="_blank">
                                    <img alt="facebook" class="ml-2 mb-2 cursor-pointer"
                                        src="./assets/img/social/facebook.png" style="width: 25px; height: 25px;">
                                </a>
                                <a *ngIf="candidate?.twitterAccount" [href]=" twitterUrl" target="_blank">
                                    <img alt="twitter" class="ml-2 mb-2 cursor-pointer"
                                        src="./assets/img/social/twitter.png" style="width: 25px; height: 25px;">
                                </a>
                                <a *ngIf="candidate?.instagramAccount" [href]=" instagramUrl" target="_blank">
                                    <img alt="instagram" class="ml-2 mb-2 cursor-pointer"
                                        src="./assets/img/social/instagram.png" style="width: 25px; height: 25px;">
                                </a>
                                <a *ngIf="candidate?.linkedinAccount"  [href]=" linkedinUrl" target="_blank">
                                    <img alt="linkedin" class="ml-2 mb-2 cursor-pointer"
                                        src="./assets/img/social/linkedin.png" style="width: 25px; height: 25px;">
                                </a>
                                <a *ngIf="candidate?.youtubeAccount" [href]=" youtubeUrl" target="_blank">
                                    <img alt="youtube" class="ml-2 mb-2 cursor-pointer"
                                        src="./assets/img/social/youtube.png" style="width: 25px; height: 25px;">
                                </a>
                                <a *ngIf="candidate?.behanceAccount" [href]="behanceUrl" target="_blank">
                                    <img alt="behance" class="ml-2 mb-2 cursor-pointer"
                                        src="./assets/img/social/behance.png" style="width: 25px; height: 25px;">
                                </a>
                                <a *ngIf="candidate?.stackoverflowAccount" [href]="stackoverflowUrl" target="_blank">
                                    <img alt="stack-overflow" class="ml-2 mb-2 cursor-pointer"
                                        src="./assets/img/social/stack-overflow.png" style="width: 25px; height: 25px;">
                                </a>
                                <a *ngIf="candidate?.githubAccount" [href]="githubUrl" target="_blank">
                                    <img alt="github" class="ml-2 mb-2 cursor-pointer"
                                        src="./assets/img/social/github.png" style="width: 25px; height: 25px;">
                                </a>
                            </div>
                        </div> 
                        <!--Social Media Icons End-->

                        <div *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE','UPDATE_CANDIDATE_PROFILE'])"
                            class="row pl-3">
                            <div class="col-12 mt-3">
                                <p (click)="openEditProfile()" class="cursor-pointer"
                                    style="color: #353535; text-decoration: underline #353535">
                                    {{'editProfileInfo' | translate}}</p>
                            </div>
                        </div>
                        <div class="row pl-3">
                            <div class="col-12">
                                <hr style="background-color: #f6f6f6; width: 100%">
                            </div>
                        </div>
                        <div class="row pl-3">
                            <div class="col-12 mt-3">
                                <p>{{'status' | translate}}:&nbsp;<span (click)="openUpdateStatusModal()"
                                        class="cursor-pointer">
                                        {{candidate?.status}}
                                        <i class="far fa-edit ml-2"></i>
                                    </span></p>
                                <p *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_POOLS'])">
                                    {{'pools' | translate}}
                                    :&nbsp;<span (click)="openUpdatePoolModal()" class="cursor-pointer">
                                        <i class="far fa-edit ml-2"></i>
                                    </span></p>
                                <p *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_POSITIONS'])">
                                    {{'positions' | translate}}
                                    :&nbsp;<span (click)="openUpdatePositionModal()" class="cursor-pointer">
                                        <i class="far fa-edit ml-2"></i>
                                    </span></p>
                                <p *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_STEPS'])">
                                    {{'step' | translate}}
                                    :&nbsp;<span (click)="openUpdateStepModal()" class="cursor-pointer">
                                        <i class="far fa-edit ml-2"></i>
                                    </span></p>
                            </div>
                        </div>
                        <div class="row pl-3">
                            <div class="col-12">
                                <hr style="background-color: #f6f6f6; width: 100%">
                            </div>
                        </div>
                        <div class="row pl-3">
                            <div class="col-12 mt-3">
                                <button (click)="viewCv = true;" onclick="$('.ng-sidebar__content').animate({ scrollTop: 0 }, 'fast');"  class="btn col-12 border-radius-0 mb-1"
                                    style="background-color: white; color: black;">
                                    {{'viewCv' | translate}}
                                </button>
                                <button  (click)="openDownloadCvModal()" class="btn col-12 border-radius-0 mb-1"
                                    style="background-color: white; color: black;">
                                    {{'viewCandidateCard' | translate}} 
                                </button>
                                <button (click)="openUpdateStepModal()" class="btn col-12 border-radius-0 mb-1"
                                style="background-color: white; color: black;">
                                {{'createInterview' | translate}} 
                            </button>
                            </div>
                        </div>
                        <div class="row pl-3">
                            <div class="col-12">
                                <hr style="background-color: #f6f6f6; width: 100%">
                            </div>
                        </div>
                        <div class="row pl-3">
                                
                                    <div class="col-12 mt-3 mb-3">
                                        <div class="row">
                                            <i *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_EVENTS'])"
                                                class="far fa-calendar-alt col text-white" ></i>
                                            <i (click)="downloadPDF('printAsDocumnet')" class="fas fa-print col text-white cursor-pointer" tooltip="<p>{{'printCandidateCard' | translate}}</p>" placement="top" show-delay="150"></i>
                                            <i (click)="openImportAttachment()"
                                                class="fas fa-cloud-upload-alt col text-white cursor-pointer" tooltip="<p>{{'candidateDetailBottomUploadTooltip' | translate}}</p>" placement="top" show-delay="150"></i>
                                            <i (click)="openDeleteCandidate()"
                                                class="far fa-trash-alt col text-white cursor-pointer" tooltip="<p>{{'candidateDetailBottomDeleteTooltip' | translate}}</p>" placement="top" show-delay="150"
                                                *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE'])"></i>
                                            <a [href]="'mailto:'+candidate.primaryEmail"> <i
                                                    class="far fa-envelope col text-white" tooltip="<p>{{'candidateDetailBottomMailTooltip' | translate}}</p>" placement="top" show-delay="150"></i></a>
                                        </div>
                                    </div>
                                
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!viewCv" class="col-xl-9 col-lg-10 col-md-12 col-sm-12 p-0">
            <div class="container-fluid">
                <div class="row">
                    <div (click)="setTab('general')"
                        *ngIf="permissionService.permissionsOnly(['READ_CANDIDATE_GENERAL_INFO'])" [ngClass]="{'gradient-bg':!generalComponentState,
                         'top-line':generalComponentState}" class="menu-item col-xl col-lg col-md col-sm-12">
                        <a class="nav-link active">{{'general' | translate}}</a>
                    </div>
                    <div (click)="setTab('education')" *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_EDUCATIONS',
                         'READ_CANDIDATE_EDUCATION',
                         'READ_ALL_CANDIDATE_LANGUAGES',
                         'READ_CANDIDATE_LANGUAGES',
                         'READ_ALL_CANDIDATE_CERTIFICATIONS',
                         'READ_CANDIDATE_CERTIFICATIONS',
                         'READ_ALL_CANDIDATE_TECHNICAL_SKILLS',
                         'READ_ALL_CANDIDATE_SOFT_SKILLS'])" [ngClass]="{'gradient-bg':!educationComponentState,
                         'top-line':educationComponentState}" class="menu-item col-xl col-lg col-md col-sm-12">
                        <a class="nav-link">{{'education' | translate}}</a>
                    </div>
                    <div (click)="setTab('experience')" *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_EMPLOYMENTS',
                         'READ_CANDIDATE_EMPLOYMENTS',
                         'READ_ALL_CANDIDATE_PROJECTS',
                         'READ_CANDIDATE_PROJECTS'])" [ngClass]="{'gradient-bg':!experienceComponentState,
                         'top-line':experienceComponentState}" class="menu-item col-xl col-lg col-md col-sm-12">
                        <a class="nav-link">{{'experience' | translate}}</a>
                    </div>
                    <div (click)="setTab('attachments')"
                        *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_ATTACHMENTS','READ_CANDIDATE_ATTACHMENT'])"
                        [ngClass]="{'gradient-bg':!attachmentsComponentState,
                         'top-line':attachmentsComponentState}" class="menu-item col-xl col-lg col-md col-sm-12">
                        <a class="nav-link">{{'attachments' | translate}}</a>
                    </div>
                    <div (click)="setTab('notes')"
                        *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_NOTES','READ_CANDIDATE_NOTE'])"
                        [ngClass]="{'gradient-bg':!notesComponentState,
                         'top-line':notesComponentState}" class="menu-item col-xl col-lg col-md col-sm-12">
                        <a class="nav-link">{{'notes' | translate}}</a>
                    </div>
                    <div (click)="setTab('calendar')" [ngClass]="{'gradient-bg':!calendarComponentState,
                         'top-line':calendarComponentState}" class="menu-item col-xl col-lg col-md col-sm-12">
                        <a class="nav-link">{{'calendar' | translate}}</a>
                    </div>
                    <div (click)="setTab('history')"
                        *ngIf="permissionService.permissionsOnly(['READ_CANDIDATE_HISTORY'])" [ngClass]="{'gradient-bg':!historyComponentState,
                         'top-line':historyComponentState}" class="menu-item col-xl col-lg col-md col-sm-12">
                        <a class="nav-link">{{'history' | translate}}</a>
                    </div>
                    <div (click)="setTab('recommendation')"
                        *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_RECOMMENDATIONS','READ_CANDIDATE_RECOMMENDATIONS'])"
                        [ngClass]="{'gradient-bg':!recommendationComponentState,
                         'top-line':recommendationComponentState}" class="menu-item col-xl col-lg col-md col-sm-12">
                        <a class="nav-link">{{'recommendation' | translate}}</a>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <app-candidate-general
                            *ngIf="generalComponentState && permissionService.permissionsOnly(['READ_CANDIDATE_GENERAL_INFO'])"
                            [candidateId]="id"></app-candidate-general>
                        <app-education *ngIf="educationComponentState" [candidateId]="id"></app-education>
                        <app-experience *ngIf="experienceComponentState" [candidateId]="id"></app-experience>
                        <app-attachments *ngIf="attachmentsComponentState" [candidateId]="id"></app-attachments>
                        <app-notes *ngIf="notesComponentState" [candidateId]="id"></app-notes>
                        <app-candidate-calendar *ngIf="calendarComponentState" [candidateId]="id">
                        </app-candidate-calendar>
                        <app-candidate-history *ngIf="historyComponentState" [candidateId]="id"></app-candidate-history>
                        <app-recommendation *ngIf="recommendationComponentState" [candidateId]="id">
                        </app-recommendation>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="viewCv" class="col-xl-9 col-lg-10 col-md-12 col-sm-12 p-0">
            <!--view CV from attachment file-->
           
            <div class="container pr-5 pl-5 pt-2">
                <div class="row">
                    <div class="col-sm-12">
                        <span (click)="viewCv = false" class="float-right text-danger cursor-pointer"><i
                                class="fa fa-times"></i></span>
                        <div class="mt-5">
    
                            <app-candidate-view-attachment-cv [candidate]="candidate" ></app-candidate-view-attachment-cv>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<app-candidate-cv-modal (downloadPDF)="downloadPDF()" [candidateId]="id"></app-candidate-cv-modal>
<app-candidate-update-status [candidate]="candidate"></app-candidate-update-status>
<app-candidate-edit-profile (updateProfileInfo)="getCandidate()"
    *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE'])" [candidate]="candidate">
</app-candidate-edit-profile>
<app-candidate-update-tags (updateTags)="getCandidateTags()" *ngIf="permissionService.permissionsOnly(['READ_ALL_TAGS',
                                'READ_ALL_CANDIDATE_TAGS',
                                'UPDATE_ALL_CANDIDATE_TAGS'])" [candidateId]="id" [candidateTags]="tagObj">
</app-candidate-update-tags>
<app-candidate-change-pool *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_POOLS'])" [candidateId]="id">
</app-candidate-change-pool>
<app-candidate-change-position [candidateId]="id"></app-candidate-change-position>
<app-candidate-change-step (updateStepInfo)="updateStepInfo()" [candidateId]="id"></app-candidate-change-step>
<app-photo-import (emitSuccessStatus)="getUserProfileImage()" [photoApiUrl]="photoApiUrl" [tempUserImg]="tempUserImg"></app-photo-import>

<app-confirm-modal *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE'])" 
 (operationInit)="deleteCandidate()" [confirmData]="confirmData"></app-confirm-modal>


<app-attachment-import (emitParentFunction)="setTab('attachments')" [attachmentUrl]="attachmentUrlCv"
    [fromPage]="'edit-candidate'"></app-attachment-import>

    <div id="printDiv" hidden="true"></div> 

