import {Component, Input, OnInit} from '@angular/core';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
import {CandidateModel} from '../../../candidates/candidate.model';

@Component({
    selector: 'app-candidate-dashboard-view-list-item',
    templateUrl: './candidate-dashboard-view-list-item.component.html',
    styleUrls: ['./candidate-dashboard-view-list-item.component.scss']
})
export class CandidateDashboardViewListItemComponent implements OnInit {

    @Input() candidate: CandidateModel;

    constructor(private utilitiesService: UtilitiesService) {
        this.candidate = new CandidateModel();
    }

    ngOnInit(): void {

    }

    downloadPDF(){
        const candidateCvModal = document.getElementById('candidateCvModal');
        const content = document.getElementById('contentCvModal');
        const sidebarCvModal = document.getElementById('sidebarCvModal');
        const menuCvModal = document.getElementById('menuCvModal');


        candidateCvModal.style.display = 'block';
        content.style.width = '790px';
        content.style.fontSize = '14px';
        sidebarCvModal.style.cssText = 'padding-left: 0px;padding-right: 0px; word-break: break-all; width: 170px;';
        sidebarCvModal.classList.remove('col-md-12', 'col-lg-3');
        sidebarCvModal.classList.add('col-4');
        menuCvModal.classList.remove('col-md-12', 'col-lg-9');
        menuCvModal.classList.add('col-8');


        // download pdf doc a4 size
        this.utilitiesService.getPdfDocument(content, this.candidate.fullName, 'dpwnloadAsPdf');

        candidateCvModal.style.display = 'none';
        content.style.width = '';
        content.style.fontSize = '14px';
        sidebarCvModal.style.cssText = 'padding-left: 0px;padding-right: 0px; word-break: break-all; width: 170px;';
        sidebarCvModal.classList.add('col-md-12', 'col-lg-3');
        sidebarCvModal.classList.remove('col-4');
        menuCvModal.classList.add('col-md-12', 'col-lg-9');
        menuCvModal.classList.remove('col-8');
    }

}
