<!--Add candidate education Modal -->
<div aria-hidden="true" aria-labelledby="addCandidateLanguageTitle" class="modal fade" id="addCandidateLanguage"
    role="dialog" tabindex="-1">

    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">

        <div class="modal-content">
            <form #addCandidateLanguage="ngForm" class="needs-validation">
                <div class="modal-header gradient-bg">
                    <h5 class="modal-title" id="addUserLongTitle">
                        {{ "addCandidateLanguage" | translate }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="languages">{{"languages" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <app-searchable-dropdown (selectResult)="selectLanguage($event)" [items]="languages"
                                        id="languages" [isSaveClick]="isSaveClick" isRequired="true"
                                        nameInput="languages"></app-searchable-dropdown>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="readingLevel">{{"readingLevel" | translate}}</label>
                                    <select (change)="selectReadingLevel($event)" class="custom-select border-radius-0"
                                        id="readingLevel" name="readingLevel">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let readingLevel of languageLevels"
                                            [value]="readingLevel.value">{{readingLevel.name | translate}}</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="writingLevel">{{"writingLevel" | translate}}</label>
                                    <select (change)="selectWritingLevel($event)" class="custom-select border-radius-0"
                                        id="writingLevel" name="writingLevel">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let writingLevel of languageLevels"
                                            [value]="writingLevel.value">{{writingLevel.name | translate}}</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="speakingLevel">{{"speakingLevel" | translate}}</label>
                                    <select (change)="selectSpeakingLevel($event)" class="custom-select border-radius-0"
                                        id="speakingLevel" name="speakingLevel">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let speakingLevel of languageLevels"
                                            [value]="speakingLevel.value">{{speakingLevel.name | translate}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="closeAddLanguageModal()" class="btn btn-warning border-radius-0"
                        data-dismiss="modal" type="button">
                        {{ "cancel" | translate }}
                    </button>
                    <button (click)="saveCandidateLanguage(addCandidateLanguage)"
                        class="btn btn-success border-radius-0" type="button">
                        {{ "save" | translate }}
                    </button>
                </div>
            </form>
        </div>

    </div>


</div>