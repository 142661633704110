import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslatePipe } from 'src/app/pipes/translate/translate.pipe';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {


@Input()confirmData: any;
@Output() operationInit = new EventEmitter();

  constructor(private translate: TranslatePipe, ) { }

  ngOnInit(): void {
  }

  operationClick(){
    this.operationInit.emit();
  }


}
