export const ColorList = [
    {colorName: 'black', colorCode: '#000000'},
    {colorName: 'white', colorCode: '#FFFFFF'},
    {colorName: 'green', colorCode: '#008000'},
    {colorName: 'blue', colorCode: '#0000FF'},
    {colorName: 'yellow', colorCode: '#FFFF00'},
    {colorName: 'gray', colorCode: '#808080'},
    {colorName: 'red', colorCode: '#FF0000'},
    {colorName: 'orange', colorCode: '#FFA500'},
];
