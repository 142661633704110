import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GeneralModel} from '../../position-operations/general/general.model';

@Component({
    selector: 'app-delete-position-modal',
    templateUrl: './delete-position-modal.component.html',
    styleUrls: ['./delete-position-modal.component.scss']
})
export class DeletePositionModalComponent implements OnInit {

    @Input() position: GeneralModel;
    @Output() deletePosition = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

    deleteOperation() {
        this.deletePosition.emit();
    }
}
