import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProjectsModel} from '../../experience.model';
import {EmploymentTypeList} from '../../../../../../shared/lists/employment-type.list';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../../services/permission/permission.service';
import {NotificationService} from '../../../../../../services/notification/notification.service';
import {server} from '../../../../../../services/server';

@Component({
    selector: 'app-delete-candidate-project',
    templateUrl: './delete-candidate-project.component.html',
    styleUrls: ['./delete-candidate-project.component.scss']
})
export class DeleteCandidateProjectComponent implements OnInit {

    @Input() project: ProjectsModel;
    @Input() candidateId: string;
    @Output() updateProjects = new EventEmitter();
    positionTypes = EmploymentTypeList;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.project = new ProjectsModel();
    }

    saveProject() {
        this.authService.ServerDelete(server.candidates + '/' + this.candidateId + '/projects/' + this.project.id)
            .subscribe(res => {
                this.notification.success(this.translate.transform('deleteSuccessful', []));
                this.updateProjects.emit();
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.closeDeleteProjectModal();
                this.spinner.hide();
            });
    }

    closeDeleteProjectModal() {
        // @ts-ignore
        $('#deleteProject').modal('hide');
    }

}
