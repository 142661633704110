<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-12 text-center">
            <div class="container text-center">
                <p>
                    <i class="fas fa-minus-circle" style="font-size: 25px; color: #d6d6d6"></i>
                </p>
                <p class="mt-2"
                   style="color: #d6d6d6 !important;">{{tableInfoTranslateKey | translate}}</p>
            </div>
        </div>
    </div>
</div>