import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConversationModel} from './message-users-area/message-row/conversation.model';
import { MessageUsersAreaComponent } from './message-users-area/message-users-area.component';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

    conversation: ConversationModel;
    isOpen: boolean;
    isMobile: boolean;
    isEmpty = false;

   // @ViewChild(MessageUsersAreaComponent) child: MessageUsersAreaComponent;

    constructor(private spinner: NgxSpinnerService) {
        this.conversation = new ConversationModel();
        this.isMobile = true;
    }

    ngOnInit(): void {
        this.spinner.hide();
    }

    userAreaFunctionCall(){
       // this.child.getConversations();
    }

    openContent(conversation) {
        this.isOpen = true;
        this.isMobile = false;
        this.conversation = conversation;
        this.isEmpty = false;
    }
    isEmptyCheck(isEmpty){
        if (isEmpty === 'empty'){
            this.isEmpty = true;
        }else{
            this.isEmpty = false;
        }
    }

    goBack() {
        this.isMobile = true;
    }

}
