import {Component, OnInit} from '@angular/core';
import {RequestPayload} from '../../../../shared/requestPayload';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';
import {StepModel} from '../../../candidates/edit-candidate/candidate-profile-operations/candidate-change-step/step.model';
import {PipelineStepModel} from '../../../../services/parameters/models/pipeline-step.model';
import {ReportFilterModel} from '../../report-filter/report-filter.model';


@Component({
    selector: 'app-report-time-fill-list',
    templateUrl: './report-time-fill-list.component.html',
    styleUrls: ['./report-time-fill-list.component.scss']
})

export class ReportTimeFillListComponent implements OnInit {

    timeToFillData: any[] = [];
    pipelineSteps: StepModel[] = [];
    requestPayload: RequestPayload;
    candidates: PipelineStepModel[] = [];
    count: number;
    selectedCandidate: PipelineStepModel;
    activePage: any;
    currentPage: number;

    positionFilter: ReportFilterModel;
    filterData: ReportFilterModel;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.requestPayload = new RequestPayload();
        this.filterData = new ReportFilterModel();
        this.count = 0;
    }

    ngOnInit() {
        this.getTimeToFillData(1);
    }

    getTimeToFillData(pageNo) {
        this.authService.ServerGet(server.timeToFill + this.requestPayload.payloadURL(pageNo) + this.requestFilter())
            .subscribe((res: { content: [] }) => {
                this.timeToFillData = res.content;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    pagination(pageNo) {
        this.getTimeToFillData(pageNo);
    }

    applyFilter(filter) {
        this.positionFilter = filter;
        if (JSON.stringify(this.filterData) === JSON.stringify(this.positionFilter)) {
            return false;
        }
        this.filterData = new ReportFilterModel();
        this.filterData = {...this.positionFilter};
        this.getTimeToFillData(1);
    }

    requestFilter() {
        return '&filter=positionId**' + this.filterData.position +
            '&filter=clientId**' + this.filterData.client +
            '&filter=createdBy**' + this.filterData.user +
            '&filter=hiredAt**' + this.filterData.hiredAt +
            '&filter=candidateId**' + this.filterData.candidate;
    }
}
