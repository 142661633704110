import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserModel} from '../../../../../user-management/user.model';
import {ClientRoleList} from '../../../../../../shared/lists/client-role.list';
import {server} from '../../../../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../../../services/notification/notification.service';

@Component({
    selector: 'app-add-client-user',
    templateUrl: './add-client-user.component.html',
    styleUrls: ['./add-client-user.component.scss']
})
export class AddClientUserComponent implements OnInit {

    @Input() clientId: string;
    @Output() emitUpdateTable = new EventEmitter();
    clientUser: UserModel;
    clientUserRoles = ClientRoleList;
    valueOne: string;
    valueTwo: string;
    valueThree: string;
    valueFour: string;
    valueFive: string;


    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.clientUser = new UserModel();
    }

    ngOnInit(): void {
        this.clientUser.role = this.clientUserRoles[0].value;
    }

    getSelectedRole(e) {
        this.clientUser.role = e.target.id;
    }

    addClientUser() {
        this.clientUser.companyId = this.authService.companyId;
        this.clientUser.clientId = this.clientId;
        this.clientUser.preferredLanguage = this.authService.getSessionLanguage();
        this.authService.ServerPost(server.users, this.clientUser)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updateTable();
                this.valueOne = ' ';
                this.valueTwo = ' ';
                this.valueThree = ' ';
                this.valueFour = ' ';
                this.valueFive = ' ';
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#userModal').modal('hide');
                this.spinner.hide();
            });
    }

    updateTable() {
        this.emitUpdateTable.emit();
    }

}
