<!-- Modal -->
<div aria-hidden="true" aria-labelledby="recruitmentPreferencesModalCenterTitle" class="modal fade"
     id="recruitmentPreferencesModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="recruitmentPreferencesModalLongTitle">{{'recruitmentPreferences' | translate}}</h5>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <label class="modal-input-label" for="name">{{'stepName' | translate}}</label>
                    <input [(ngModel)]="recruitmentPreference.name" [disabled]="isDelete"
                           class="form-control border-radius-0"
                           id="name"
                           name="name"
                           required="required" type="text">
                </div>
                <div class="mb-1">
                    <label class="ex-check" style="color: #649a65;">
                        <input [(ngModel)]="recruitmentPreference.successfulLastStep" [disabled]="isDelete" type="checkbox">
                        <span class="ml-2">
                                    {{'successfulLastStep' | translate}}
                                </span>
                    </label>
                </div>
                <div class="mb-1">
                    <label class="ex-check" style="color: #649a65;">
                        <input [(ngModel)]="recruitmentPreference.hideOnMobile" [disabled]="isDelete" type="checkbox">
                        <span class="ml-2">
                                    {{'hideOnMobile' | translate}}
                                </span>
                    </label>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">{{'cancel' | translate}}</button>
                <button (click)="recruitmentPreferenceOperation()"
                        class="btn btn-success border-radius-0"
                        type="button">{{operationType | translate}}</button>
            </div>
        </div>
    </div>
</div>
