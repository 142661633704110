import {CountryModel} from '../../services/parameters/models/country.model';
import {CityModel} from '../../services/parameters/models/city.model';
import {IndustryModel} from '../../services/parameters/models/industry.model';

export class ClientModel {
    id: string;
    status: string;
    name: string;
    website: string;
    phone: string;
    contactPerson: string;
    contactPersonTitle: string;
    contactEmail: string;
    contactPhone: string;
    country: CountryModel;
    city: CityModel;
    address: string;
    industry: IndustryModel;
    clientGroup: string;
    service: string;
    accountManagers: [AccountManager];

    constructor() {
        this.id = '';
        this.country = new CountryModel();
        this.city = new CityModel();
        this.industry = new IndustryModel();
        this.status = 'ACTIVE';
    }
}

export class AccountManager {
    id: string;
    name: string;
}


export class ClientFilterModel {
    status: string;
    name: string;
    country: string;
    city: string;
    industry: string;
    clientGroup: string;
    service: string;

    constructor() {
        this.country = '';
        this.city = '';
        this.name = '';
        this.status = '';
        this.industry = '';
        this.clientGroup = '';
        this.service = '';
    }
}
