export class SearchModel {
    id: string | number;
    type: string;
    text: string;
    value: string;

    constructor() {
        this.value = '';
    }
}

