import {DefaultLocalization} from './default-localization.model';

export class EducationModel {

    id: number;
    degree: string;
    university: UniversityModel;
    faculty: FacultyModel;
    department: DepartmentModel;
    startYear: number;
    endYear: number;
    grade: number;

    constructor() {
        this.university = new UniversityModel();
        this.faculty = new FacultyModel();
        this.department = new DepartmentModel();
    }
}

export class UniversityModel {
    id: string;
    value: string;
    defaultLocalization: DefaultLocalization;

    constructor() {
        this.id = '';
        this.value = '';
        this.defaultLocalization = new DefaultLocalization();
    }
}

export class FacultyModel {
    id: string;
    value: string;
    defaultLocalization: DefaultLocalization;

    constructor() {
        this.id = '';
        this.value = '';
        this.defaultLocalization = new DefaultLocalization();
    }
}

export class DepartmentModel {
    id: string;
    value: string;
    defaultLocalization: DefaultLocalization;

    constructor() {
        this.id = '';
        this.value = '';
        this.defaultLocalization = new DefaultLocalization();
    }
}

export class LanguageModel {
    id: string;
    language: LanguagesModel;
    readingLevel: string;
    writingLevel: string;
    speakingLevel: string;

    constructor() {
        this.id = '';
        this.language = new LanguagesModel();
    }
}

export class LanguagesModel {
    id: string;
    value: string;
    defaultLocalization: DefaultLocalization;

    constructor() {
        this.id = '';
        this.value = '';
    }
}

export class CertificationModel {
    id: number;
    name: string;
    organisation: string;
    issueMonth: number;
    issueYear: number;
    expirationMonth: number;
    expirationYear: number;
    defaultLocalization: DefaultLocalization;

    constructor() {
        this.defaultLocalization = new DefaultLocalization();
    }
}


export class TechnicalSkillModel {
    id: number;
    name: string;
    defaultLocalization: DefaultLocalization;

    constructor() {
        this.defaultLocalization = new DefaultLocalization();
    }
}
