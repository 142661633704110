export class PasswordResetModel {

    email: string;
    resetCode: string;
    newPassword: string;
    reNewPassword: string;
    search: string;

    constructor() {
    }
}
