import {Component, OnInit} from '@angular/core';
import {server} from '../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../services/auth/auth.service';
import {Router} from '@angular/router';
import {TranslatePipe} from '../../pipes/translate/translate.pipe';
import {NotificationService} from '../../services/notification/notification.service';
import {UserModel} from './user.model';
import {RequestPayload} from '../../shared/requestPayload';
import {PermissionService} from '../../services/permission/permission.service';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

    user: UserModel;
    users: UserModel[];
    operationType;
    count: number;
    currentPage: number;
    requestPayload: RequestPayload;
    myUserId: string;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                private translate: TranslatePipe,
                public permissionService: PermissionService,
                private notification: NotificationService) {
        this.user = new UserModel();
        this.requestPayload = new RequestPayload();
    }

    ngOnInit(): void {
        this.myUserId = this.authService.myUserId;
        this.getUsers(1);
    }

    getUsers(pageNo) {
        this.authService.ServerGet(server.users + this.requestPayload.payloadURL(pageNo) + '&filter=roles.name**HR_MANAGER,RECRUITER')
            .subscribe((res: any) => {
                this.users = res.content;
                this.count = res.totalElements;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    pagination(pageNo) {
        this.getUsers(pageNo);
    }

    addUserModal() {
        this.user = new UserModel();
        this.operationType = 'add';
        // @ts-ignore
        $('#userModal').modal('show');
    }

    editUserModal(user) {
        this.user = user;
        this.operationType = 'edit';
        // @ts-ignore
        $('#userModal').modal('show');
    }

    deleteUserModal(user) {
        this.user = user;
        this.operationType = 'delete';
        // @ts-ignore
        $('#userModal').modal('show');
    }
}
