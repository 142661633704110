import { Component, OnInit } from '@angular/core';
import { RegisterModel } from './register.model';
import { CompanyRegisterModel } from 'src/app/components/register/company-register/company-register-model';
import { HrManagerRegisterModel } from 'src/app/components/register/hr-manager-register/hr-manager-register.model';
import {server} from 'src/app/services/server';
import {AuthService} from 'src/app/services/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    activeSection: string = 'company';
    registerCompany: CompanyRegisterModel;
    registerHrManager: HrManagerRegisterModel;
    registerModel:RegisterModel;
    constructor(private authService: AuthService,
        private spinner: NgxSpinnerService
        ) {
       /*  this.registerCompany = new RegisterModel; */
    }
    
    ngOnInit(): void {
        this.registerModel=new RegisterModel();
        this.registerHrManager = new HrManagerRegisterModel();
        this.registerCompany = new CompanyRegisterModel();
        this.registerModel.newCompany=this.registerCompany;
        this.registerModel.initialUser=this.registerHrManager;
        document.getElementById('hr-manager-register').style.display = 'none';
    }

    saveInfo(typeOfComponent: string): void {
        if (typeOfComponent !== 'hr-manager') {
            document.getElementById('hr-manager-register').style.display = 'block';
            document.getElementById('company-register').style.display = 'none';
        }else if(typeOfComponent == 'hr-manager')
        {
            this.authService.ServerPostWithoutLogin(server.subscriptions , this.registerModel)
            .subscribe(res => {
                // this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    // this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.spinner.hide();
                // @ts-ignore
                // $('#resetPasswordModal').modal('hide');
            });
        }


    }

    goBack(typeOfComponent: string): void {
        if (typeOfComponent !== 'company') {
            document.getElementById('hr-manager-register').style.display = 'none';
            document.getElementById('company-register').style.display = 'block';
        }
    }

}
