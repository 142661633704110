import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContactPersonModel} from '../../contact-person.model';
import {server} from '../../../../services/server';
import {AuthService} from '../../../../services/auth/auth.service';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {NotificationService} from '../../../../services/notification/notification.service';

@Component({
    selector: 'app-add-contact-person',
    templateUrl: './add-contact-person.component.html',
    styleUrls: ['./add-contact-person.component.scss']
})
export class AddContactPersonComponent implements OnInit {

    @Input() clientId: string;
    contactPerson: ContactPersonModel;
    @Output() updateContactPersons = new EventEmitter();

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
        this.contactPerson = new ContactPersonModel();
    }

    ngOnInit(): void {
    }

    save() {
        this.authService.ServerPost(server.clients + '/' + this.clientId + '/contact-persons', this.contactPerson)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updateContactPersonsList();
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.cancel();
                this.spinner.hide();
            });
    }

    numericOnly(event): boolean {    
        let patt = /^([0-9])$/;
        let result = patt.test(event.key);
        return result;
    }

    cancel() {
        this.closeAddContactPerson();
        this.openContactPersonModal();
    }

    updateContactPersonsList() {
        this.contactPerson = new ContactPersonModel();
        this.updateContactPersons.emit();
    }

    closeAddContactPerson() {
        // @ts-ignore
        $('#addContactPerson').modal('hide');
    }

    openContactPersonModal() {
        // @ts-ignore
        $('#contactPersonModal').modal('show');
    }

}
