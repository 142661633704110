<div class="container-fluid mt-3">
    <div class="row">
        <div class="col-12 gradient-bg menu-item">
            <a class="nav-link">{{'positionCandidates' | translate}}</a>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <app-candidate-dashboard-view-list [position]="position"></app-candidate-dashboard-view-list>
        </div>
    </div>
</div>
