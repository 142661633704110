import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PermissionService} from '../../../services/permission/permission.service';
import {PoolModel} from '../pool.model';
import {server} from '../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';

@Component({
    selector: 'app-pool-details',
    templateUrl: './pool-details.component.html',
    styleUrls: ['./pool-details.component.scss']
})
export class PoolDetailsComponent implements OnInit {

    candidateComponentState: boolean;
    historyComponentState: boolean;
    id: string;
    pool: PoolModel;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private route: ActivatedRoute,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.pool = new PoolModel();
    }

    ngOnInit(): void {
        // @ts-ignore
        if (this.route.params.value.id && this.route.params.value.id !== '') {
            // @ts-ignore
            this.id = this.route.params.value.id;
        } else {
            this.router.navigate(['/dashboard/pools']);
        }
        this.getPool();
        this.setTab('candidate');
    }

    setTab(component) {
        switch (component) {
            case 'candidate':
                this.candidateComponentState = true;
                this.historyComponentState = false;
                break;
            case 'history':
                this.candidateComponentState = false;
                this.historyComponentState = true;
                break;
            default:
                this.candidateComponentState = true;
                break;
        }

    }

    getPool() {
        this.authService.ServerGet(server.pools + '/' + this.id)
            .subscribe((res: any) => {
                this.pool = res;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    openPoolStatusModal() {
        // @ts-ignore
        $('#statusModal').modal('show');
    }
    updateCandidates(){

    }
}
