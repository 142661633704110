<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-12">
            <label class="header-title career-head-title">{{'header' | translate}}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div class="image-container">
                <label class="modal-input-label width-100" for="companyLogo">&nbsp;</label>
                <img [src]="companyImage" alt="companyLogo" class="img-fluid app-image" style="max-height: 200px;"
                     id="companyLogo">
                <div (click)="openChangePhoto('company')"
                     *ngIf="permissionService.permissionsOnly(['UPDATE_CAREER_PORTAL_SETTINGS'])" class="mt-3">
                    <p class="logo-title"><i class="fas fa-plus-circle"></i>&nbsp;Add Company Logo</p>
                </div>
            </div>
            <div class="mb-3 mt-5">
                <label class="modal-input-label" for="title">{{'title' | translate}}</label>
                <input [(ngModel)]="careerPortalSettings.title"
                       [disabled]="!permissionService.permissionsOnly(['UPDATE_CAREER_PORTAL_SETTINGS'])"
                       class="form-control border-radius-0"
                       id="title"
                       name="title"
                       required="required" type="text">
            </div>
            <div class="mb-3">
                <label class="modal-input-label" for="subtitle">{{'subtitle' | translate}}</label>
                <input [(ngModel)]="careerPortalSettings.subtitle"
                       [disabled]="!permissionService.permissionsOnly(['UPDATE_CAREER_PORTAL_SETTINGS'])"
                       class="form-control border-radius-0"
                       id="subtitle"
                       name="subtitle"
                       required="required" type="text">
            </div>
            <div class="mb-3">
                <label class="modal-input-label" for="description">{{'description' | translate}}</label>
                <textarea [(ngModel)]="careerPortalSettings.description"
                          [disabled]="!permissionService.permissionsOnly(['UPDATE_CAREER_PORTAL_SETTINGS'])"
                          class="form-control border-radius-0"
                          id="description"
                          name="description"
                          required="required"
                          rows="4" appTitlecaseLower></textarea>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div class="image-container">
                <label class="modal-input-label width-100" for="headerImage">&nbsp;</label>
                <img [src]="headerImage" alt="headerImage" class="img-fluid app-image" style="max-height: 200px;"
                     id="headerImage">
            </div>
            <div (click)="openChangePhoto('header')"
                 *ngIf="permissionService.permissionsOnly(['UPDATE_CAREER_PORTAL_SETTINGS'])" class="mt-3">
                <p class="logo-title"><i class="fas fa-plus-circle"></i>&nbsp;Add Header Image</p>
            </div>
            <div class="mb-3 mt-5">
                <label class="modal-input-label" for="title">{{'headerColor' | translate}}</label>
                <div class="dropdown">
                    <button [disabled]="!permissionService.permissionsOnly(['UPDATE_CAREER_PORTAL_SETTINGS'])"
                            aria-expanded="false"
                            aria-haspopup="true"
                            class="btn btn-secondary dropdown-toggle border-radius-0 form-control"
                            data-toggle="dropdown"
                            id="headerColorButton"
                            type="button">
                        {{headerColorText | translate}}
                    </button>
                    <div [style.border-bottom]="headerColorStyle" class="width-100"></div>
                    <div (click)="getHeaderColorText($event)" aria-labelledby="dropdownMenuButton"
                         class="dropdown-menu width-100 color-dropdown">
                        <a *ngFor="let color of colorList"
                           [title]="color.colorCode"
                           class="dropdown-item color-dropdown-item">{{ color.colorName| translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Social Media Begin -->
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div class="mb-3">
                <label class="modal-input-label" for="websiteLink">{{'websiteLink' | translate}}</label>

                <div class="input-group margin-bottom-sm mt-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text login-input-icon-password">
                            <i class="fas fa-globe-europe"></i>
                        </span>
                    </div>
                    <input [(ngModel)]="careerPortalSettings.websiteLink"
                           [disabled]="!permissionService.permissionsOnly(['UPDATE_CAREER_PORTAL_SETTINGS'])"
                           class="form-control border-radius-0"
                           id="websiteLink"
                           name="websiteLink"
                           placeholder="{{'websiteLinkPlaceholder' | translate}}" type="text">
                </div>
            </div>
            <div class="mb-3">
                <label class="modal-input-label" for="twitterAccount">{{'twitterAccount' | translate}}</label>
                <div class="input-group margin-bottom-sm mt-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text login-input-icon-password">
                            <i class="fab fa-twitter"></i>
                        </span>
                    </div>
                    <input (focusout)="getSocialMediaUsername('twitter')"
                           [(ngModel)]="careerPortalSettings.twitterLink"
                           [disabled]="!permissionService.permissionsOnly(['UPDATE_CAREER_PORTAL_SETTINGS'])"
                           class="form-control border-radius-0"
                           id="twitterAccount"
                           name="twitterAccount"
                           placeholder="{{'twitterAccountPlaceholder' | translate}}" type="text">
                </div>
            </div>
            <div class="mb-3">
                <label class="modal-input-label" for="facebookAccount">{{'facebookAccount' | translate}}</label>
                <div class="input-group margin-bottom-sm mt-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text login-input-icon-password">
                            <i class="fab fa-facebook"></i>
                        </span>
                    </div>
                    <input (focusout)="getSocialMediaUsername('facebook')"
                           [(ngModel)]="careerPortalSettings.facebookLink"
                           [disabled]="!permissionService.permissionsOnly(['UPDATE_CAREER_PORTAL_SETTINGS'])"
                           class="form-control border-radius-0"
                           id="facebookAccount"
                           name="facebookAccount"
                           placeholder="{{'facebookAccountPlaceholder' | translate}}" type="text">
                </div>
            </div>
            <div class="mb-3">
                <label class="modal-input-label" for="twitterAccount">{{'linkedinAccount' | translate}}</label>
                <div class="input-group margin-bottom-sm mt-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text login-input-icon-password">
                            <i class="fab fa-linkedin"></i>
                        </span>
                    </div>
                    <input (focusout)="getSocialMediaUsername('linkedin')"
                           [(ngModel)]="careerPortalSettings.linkedinLink"
                           [disabled]="!permissionService.permissionsOnly(['UPDATE_CAREER_PORTAL_SETTINGS'])"
                           class="form-control border-radius-0"
                           id="linkedinAccount"
                           name="linkedinAccount"
                           placeholder="{{'linkedinAccountPlaceholder' | translate}}" type="text">
                </div>
            </div>
            <div class="mb-3">
                <label class="modal-input-label" for="twitterAccount">{{'instagramAccount' | translate}}</label>
                <div class="input-group margin-bottom-sm mt-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text login-input-icon-password">
                            <i class="fab fa-instagram"></i>
                        </span>
                    </div>
                    <input (focusout)="getSocialMediaUsername('instagram')"
                           [(ngModel)]="careerPortalSettings.instagramLink"
                           [disabled]="!permissionService.permissionsOnly(['UPDATE_CAREER_PORTAL_SETTINGS'])"
                           class="form-control border-radius-0"
                           id="instagramAccount"
                           name="instagramAccount"
                           placeholder="{{'instagramAccountPlaceholder' | translate}}" type="text">
                </div>
            </div>
        </div>
        <!-- Social Media End -->
    </div>
    <div *ngIf="permissionService.permissionsOnly(['UPDATE_CAREER_PORTAL_SETTINGS'])" class="row mt-3">
        <div class="col-12 p-0">
            <div class="modal-footer">
                <button (click)="cancelCareerPortalSettings()"
                        class="btn btn-warning border-radius-0 col-xl-auto col-lg-auto col-md-auto col-sm-12"
                        data-dismiss="modal"
                        type="button">{{'cancel' | translate}}</button>
                <button (click)="saveCareerPortalSettings()"
                        class="btn btn-success border-radius-0 col-xl-auto col-lg-auto col-md-auto col-sm-12"
                        type="button">{{'save' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<app-photo-import (emitSuccessStatus)="getCompanyPhoto()" [photoApiUrl]="photoApiUrl" [tempUserImg]="companyImage"></app-photo-import>
