<div class="container-fluid">
    <div class="row">
        <div class="col p-0">
            <app-report-filter (applyFilterOperation)="applyFilter($event)"></app-report-filter>
        </div>
        <div class="col">
            <div class="input-group  ">
                <input
                        class="form-control border-radius-0 datepicker" data-provide="datepicker"
                        id="closingDateForJobPost" name="closingDateForJobPost" placeholder="mm-dd-yyyy">
                <div class="input-group-append">
                    <div class="input-group-text">
                        <i class="far fa-calendar"></i>
                    </div>
                </div>
            </div>
        </div>

<div class="col">

    <div class="input-group validate-me ">
        <input
                class="form-control border-radius-0 datepicker" data-provide="datepicker" id="openingDate"
                name="openingDate" placeholder="mm-dd-yyyy" required>
        <div class="input-group-append">
            <div class="input-group-text">
                <i class="far fa-calendar"></i>
            </div>
        </div>


    </div>

</div>
        <!--

        <div  class="col">
            <button   #searchButton [hidden]="isShow"
                      class="btn btn-blue btn-reset border-radius-0 float-right exactalent-button "
                      data-target="#addUserCenter"
                      data-toggle="modal">
                {{'ClearDateFilter' | translate}}
            </button>
        </div>
        -->

    </div>
    <div class="row table-bg mt-3" style="border-top: 12px #f6f6f6">
        <div (click)="openPipelineUserList(step,$event)" *ngFor="let step of pipelineSteps"
             class="cursor-pointer table-row col-12">
            <div>
                <span class="badge badge-light float-left rounded-circle step-badge p-1">&nbsp;</span>
                <span class="float-left step-name">{{step.recruitmentStepName}}</span>
                <i class="fa fa-caret-down float-right"></i>
            </div>
            <div [id]="'stepId_' + step.recruitmentStepId" class="step-table-div">
                <app-report-event-list (updateCandidates)="getStepUsers($event)"
                                       [count]="count"
                                       [interviews]="interviews"></app-report-event-list>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <button   #exportButtonCandidate (click)="ExportCandidate()"
                  class="btn btn-blue btn-reset border-radius-0 float-right exactalent-button "
                  data-target="#exportButtonCandidate"
                  data-toggle="modal">
            {{'ExportOnCandidate' | translate}}
        </button>
    </div>
</div>

<app-export-modal-candidate></app-export-modal-candidate>

