<div class="container-fluid mt-4">
    <div *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_EMPLOYMENTS','READ_CANDIDATE_EMPLOYMENT'])"
         class="row">
        <div class="col-12 pl-5">
            <p><b>{{'workExperience' | translate}}</b><i
                    (click)="openAddWorkExperience()"
                    *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE_EMPLOYMENT'])"
                    [title]="'add' | translate"
                    class="fas fa-plus-circle ml-2 cursor-pointer text-info"></i>
            </p>
            <div class="row">
                <div (click)="openEditWorkExperience(experience,$event)" *ngFor="let experience of experiences;"
                     class="col-xl-6 col-lg-12 col-md-12 col-sm-12 edit-card">
                    <p>
                        <i *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_EMPLOYMENT'])"
                           class="far fa-edit float-right text-success"></i>
                        <i *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_EMPLOYMENT'])"
                           class="far fa-trash-alt float-right text-danger mr-2"
                           id="deleteWorkExperienceButton"></i>
                    </p>
                    <p>{{'company' | translate}}&nbsp;:&nbsp;{{experience.company}}</p>
                    <p>{{'jobTitle' | translate}}&nbsp;:&nbsp;{{experience.title}}</p>
                    <p>{{'employmentType' | translate}}&nbsp;:&nbsp;{{experience.employmentType | translate}}</p>
                    <p>{{'years' | translate}}&nbsp;:&nbsp;
                        {{experience.startMonth}}.{{experience.startYear}}-
                        {{experience.endMonth}}.{{experience.endYear}}
                    </p>
                    <p>{{'experienceSummary' | translate}}&nbsp;:&nbsp;{{experience.summary}}</p>
                    <div class="dropdown-divider"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_PROJECTS','READ_CANDIDATE_PROJECT'])"
     class="container-fluid">
    <div class="row mt-1">
        <div class="col-12">
            <div class="row">
                <p class="col" style="border-bottom: 3px solid #f6f6f6"></p>
            </div>
        </div>
    </div>
</div>
<div *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_PROJECTS','READ_CANDIDATE_PROJECT'])"
     class="container-fluid mt-1">
    <div class="row">
        <div class="col-12 pl-5">
            <p><b>{{'projects' | translate}}</b><i (click)="openAddProject()"
                                                   *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE_PROJECT'])"
                                                   [title]="'add' | translate"
                                                   class="fas fa-plus-circle ml-2 cursor-pointer text-info"></i></p>
            <div class="row">
                <div (click)="openEditProject(project,$event)" *ngFor="let project of projects"
                     class="col-xl-6 col-lg-12 col-md-12 col-sm-12 edit-card">
                    <p>
                        <i *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_PROJECT'])"
                           class="far fa-edit float-right text-success"></i>
                        <i *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_PROJECT'])"
                           class="far fa-trash-alt float-right text-danger mr-2"
                           id="deleteProjectButton"></i>
                    </p>
                    <p>{{'name' | translate}}&nbsp;:&nbsp;{{project.name}}</p>
                    <p>{{'organisation' | translate}}&nbsp;:&nbsp;{{project.organisation}}</p>
                    <p>{{'years' | translate}}&nbsp;:&nbsp;
                        {{project.startMonth}}.{{project.startYear}}-
                        {{project.endMonth}}.{{project.endYear}}
                    </p>
                    <p>{{'projectSummary' | translate}}&nbsp;:&nbsp;{{project.summary}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row mt-1">
        <div class="col-12">
            <div class="row">
                <p class="col" style="border-bottom: 3px solid #f6f6f6"></p>
            </div>
        </div>
    </div>
</div>
<app-add-work-experience (updateWorkExperiences)="getExperiences()"
                         *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE_EMPLOYMENT'])"
                         [candidateId]="candidateId"></app-add-work-experience>
<app-edit-work-experience (updateWorkExperiences)="getExperiences()"
                          *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_EMPLOYMENT'])"
                          [candidateId]="candidateId"
                          [workExperience]="selectedExperience"></app-edit-work-experience>
<app-delete-work-experience (updateWorkExperiences)="getExperiences()"
                            *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_EMPLOYMENT'])"
                            [candidateId]="candidateId"
                            [workExperience]="selectedExperience"></app-delete-work-experience>
<app-add-candidate-project (updateProjects)="getProjects()"
                           *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE_PROJECT'])"
                           [candidateId]="candidateId"></app-add-candidate-project>
<app-edit-candidate-project (updateProjects)="getProjects()"
                            *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_PROJECT'])"
                            [candidateId]="candidateId"
                            [project]="selectedProject"></app-edit-candidate-project>
<app-delete-candidate-project (updateProjects)="getProjects()"
                              *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_PROJECT'])"
                              [candidateId]="candidateId"
                              [project]="selectedProject"></app-delete-candidate-project>
