import {Injectable} from '@angular/core';

@Injectable()
export class PermissionService {

    constructor() {
    }

    private get permissions(): string[] {
        return JSON.parse(localStorage.getItem('permissions'));
    }

    permissionsOnly(perm: string[]): boolean {
        for (let i = 0; i <= perm.length; i++) {
            if (this.permissions.includes(perm[i])) {
                return true;
            }
        }
        return false;
    }
}
