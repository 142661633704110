<!--Delete client education Modal -->
<div aria-hidden="true" aria-labelledby="deleteClientOperationTitle" class="modal fade" id="deleteClient"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">
                    {{ "deleteClient" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="organisationName">{{"organisationName" | translate}}</label>
                                <input [value]="client.name" class="form-control border-radius-0"
                                       disabled
                                       id="organisationName"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteOrganisationWebsiteLink">{{'organisationWebsiteLink' | translate}}</label>

                                <div class="input-group">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text login-input-icon-password">
                                        <i class="fas fa-globe-europe"></i>
                                    </span>
                                    </div>
                                    <input [value]="client.website" class="form-control border-radius-0"
                                           disabled
                                           id="deleteOrganisationWebsiteLink"
                                           name="deleteOrganisationWebsiteLink"
                                           placeholder="{{'websiteLinkPlaceholder' | translate}}" type="text">
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteOrganisationPhone">{{'organisationPhone' | translate}}</label>

                                <div class="input-group">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text login-input-icon-password">
                                        <i class="fas fa-phone"></i>
                                    </span>
                                    </div>
                                    <input [value]="client.phone" class="form-control border-radius-0"
                                           disabled
                                           id="deleteOrganisationPhone"
                                           name="deleteOrganisationPhone"
                                           placeholder="{{'phonePlaceholder' | translate}}" type="text">
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteCountry">{{"country" | translate}}</label>
                                <input [value]="client.country.value" class="form-control border-radius-0"
                                       disabled
                                       id="deleteCountry"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteCity">{{"city" | translate}}</label>
                                <input [value]="client.city.value" class="form-control border-radius-0"
                                       disabled
                                       id="deleteCity"
                                       type="text">
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteAddress">{{"address" | translate}}</label>
                                <input [value]="client.address" class="form-control border-radius-0"
                                       disabled
                                       id="deleteAddress"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteClientGroup">{{"clientGroup" | translate}}</label>
                                <input [value]="client.clientGroup" class="form-control border-radius-0"
                                       disabled
                                       id="deleteClientGroup"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteIndustry">{{"industry" | translate}}</label>
                                <input [value]="client.industry.value" class="form-control border-radius-0"
                                       disabled
                                       id="deleteIndustry"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteService">{{"service" | translate}}</label>
                                <input [value]="client.service" class="form-control border-radius-0"
                                       disabled
                                       id="deleteService"
                                       type="text">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="cancel()" class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="deleteClient()" class="btn btn-danger border-radius-0" type="button">
                    {{ "delete" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
