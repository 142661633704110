import {Component, Input, OnInit} from '@angular/core';
import {
    CertificationModel,
    EducationModel,
    LanguageModel,
    TechnicalSkillModel
} from '../../../../services/parameters/models/education.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';

@Component({
    selector: 'app-education',
    templateUrl: './education.component.html',
    styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {

    @Input() candidateId: string;
    educations: EducationModel[];
    languages: LanguageModel[];
    certifications: CertificationModel[];
    technicalSkills: TechnicalSkillModel[];
    softSkills: TechnicalSkillModel[];
    selectedEducation: EducationModel;
    selectedLanguage: LanguageModel;
    selectedCertificate: CertificationModel;
    skills: string;
    parsedTechnicalSkill: string[];
    tempSoftSkills: string;
    parsedSoftSkill: string[];

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.getEducation();
        this.getLanguages();
        this.getCertifications();
        this.getTechnicalSkills();
        this.getSoftSkills();
    }

    getEducation() {
        this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/educations')
            .subscribe((res: any) => {
                this.educations = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    getLanguages() {
        this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/languages')
            .subscribe((res: any) => {
                this.languages = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    getCertifications() {
        this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/certifications')
            .subscribe((res: any) => {
                this.certifications = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    getTechnicalSkills() {
        this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/technical-skills')
            .subscribe((res: any) => {
                this.technicalSkills = res.content;
                if (this.technicalSkills.length > 0) {
                    for (let i = 0; i < this.technicalSkills.length; i++) {
                        if (i === 0) {
                            this.skills = this.technicalSkills[i].name;
                        } else {
                            this.skills = this.skills + ', ' + this.technicalSkills[i].name;
                        }
                    }
                    this.parsedTechnicalSkill = this.skills.split(',');
                }
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    getSoftSkills() {
        this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/soft-skills')
            .subscribe((res: any) => {
                this.softSkills = res.content;
                if (this.softSkills.length > 0) {
                    for (let i = 0; i < this.softSkills.length; i++) {
                        if (i === 0) {
                            this.tempSoftSkills = this.softSkills[i].name;
                        } else {
                            this.tempSoftSkills = this.tempSoftSkills + ', ' + this.softSkills[i].name;
                        }
                    }
                    this.parsedSoftSkill = this.tempSoftSkills.split(',');
                }
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    openAddEducation() {
        // @ts-ignore
        $('#addCandidateEducation').modal('show');
    }

    openEditEducation(education, event) {
        this.selectedEducation = education;
        if (event.target.id === 'deleteEducationButton') {
            // @ts-ignore
            $('#deleteCandidateEducation').modal('show');
        } else {
            // @ts-ignore
            $('#editCandidateEducation').modal('show');
        }
    }

    openAddLanguage() {
        // @ts-ignore
        $('#addCandidateLanguage').modal('show');
    }

    openEditLanguage(language, event) {
        this.selectedLanguage = language;
        if (event.target.id === 'deleteLanguageButton') {
            // @ts-ignore
            $('#deleteCandidateLanguage').modal('show');
        } else {
            // @ts-ignore
            $('#editCandidateLanguage').modal('show');
        }
    }

    openAddCertificate() {
        // @ts-ignore
        $('#addCandidateCertificate').modal('show');
    }

    openEditCertificate(certificate, event) {
        this.selectedCertificate = certificate;
        if (event.target.id === 'deleteCertificateButton') {
            // @ts-ignore
            $('#deleteCandidateCertificate').modal('show');
        } else {
            // @ts-ignore
            $('#editCandidateCertificate').modal('show');
        }
    }

    openTechnicalSkillOperation() {
        // @ts-ignore
        $('#candidateTechnicalSkillOperation').modal('show');
    }

    openSoftSkillOperation() {
        // @ts-ignore
        $('#candidateSoftSkillOperation').modal('show');
    }

}
