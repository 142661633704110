import {AuthConfig} from 'angular-oauth2-oidc';
import {environment} from '../../../environments/environment';

export const authPasswordFlowConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: environment.issuer,

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin + '/index.html',

    // URL of the SPA to redirect the user after silent refresh
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: environment.clientId,

    tokenEndpoint: environment.tokenEndpoint,

    requireHttps: environment.requireHttps,
    // set the scope for the permissions the client should request
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: environment.scope,
    dummyClientSecret: environment.dummyClientSecret,

    showDebugInformation: environment.showDebugInformation,
    logoutUrl: '/',
    oidc: environment.oidc,
    timeoutFactor: environment.timeoutFactor,
    requestAccessToken: environment.requestAccessToken,
    useHttpBasicAuth: true,
};
