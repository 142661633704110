<!--Add client education Modal -->
<div aria-hidden="true" aria-labelledby="addClientOperationTitle" class="modal fade mt-5" data-backdrop="static"
    data-keyboard="false" id="addClient" role="dialog" tabindex="-1">
    <form #formClient="ngForm" class="needs-validation">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header gradient-bg">
                    <h5 class="modal-title">
                        {{ "addNewClient" | translate }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="mb-3 validate-me">
                                    <label class="modal-input-label" for="name">{{"organisationName" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <input  #name [(ngModel)]="client.name" class="form-control border-radius-0"
                                        id="name" name="name" appTitleCaseDirective required type="text">
                                </div>
                                <div class="mb-3 ">
                                    <label class="modal-input-label"
                                        for="websiteLink">{{'organisationWebsiteLink' | translate}}<span
                                            class="text-danger">*</span></label>

                                    <div class="input-group validate-me">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text login-input-icon-password">
                                                <i class="fas fa-globe-europe"></i>
                                            </span>
                                        </div>
                                        <input #websiteLink [(ngModel)]="client.website"
                                            class="form-control border-radius-0" id="websiteLink" name="websiteLink"
                                            placeholder="{{'websiteLinkPlaceholder' | translate}}" required type="text">
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="organisationPhone">{{'organisationPhone' | translate}}<span
                                            class="text-danger">*</span></label>

                                    <div class="input-group validate-me">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text login-input-icon-password">
                                                <i class="fas fa-phone"></i>
                                            </span>
                                        </div>
                                        <input #organisationPhone [(ngModel)]="client.phone"
                                            class="form-control border-radius-0" id="organisationPhone"
                                            name="organisationPhone" name="organisationPhone"
                                            placeholder="{{'phonePlaceholder' | translate}}" required type="text"
                                            maxlength="11"
                                            (keypress)="numericOnly($event)">
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label" for="country">{{"country" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <div>
                                        <app-searchable-dropdown (selectResult)="selectCountry($event);cityChild.selectedItem=null;cityChild.isFiltired=false"
                                            [items]="countries"  [isSaveClick]="isSaveClick" isRequired="true" id="country" nameInput="country">
                                        </app-searchable-dropdown>
                                    </div>

                                </div>
                                <div class="mb-3 ">
                                    <label class="modal-input-label" for="city">{{"city" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <div>
                                        <app-searchable-dropdown #cityChild (selectResult)="selectCity($event)" 
                                        [items]="cities"
                                        id="city"
                                        [isSaveClick]="isSaveClick" 
                                        isRequired="true"
                                        nameInput="city"
                                        ></app-searchable-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="address">{{"address" | translate}}</label>
                                    <input [(ngModel)]="client.address" class="form-control border-radius-0"
                                        id="address" name="address" type="text" appTitleCaseDirective>
                                </div>
                                <div class="mb-3 validate-me">
                                    <label class="modal-input-label"
                                        for="addClientGroup">{{"clientGroup" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <select #addClientGroup="ngModel" (change)="selectClientGroup($event)"
                                        [(ngModel)]="client.clientGroup" class="custom-select border-radius-0"
                                        id="addClientGroup" name="addClientGroup" required>
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let clientGroup of clientGroupList" [value]="clientGroup.value">
                                            {{clientGroup.name | titlecase}}</option>
                                    </select>
                                </div>
                                <div class="mb-3 validate-me">
                                    <label class="modal-input-label"
                                        for="preferredIndustry">{{"industry" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <div>
                                        <app-searchable-dropdown-with-add (selectResult)="selectIndustry($event)"
                                            [items]="industries" fieldName="industry" id="preferredIndustry"
                                            [isSaveClick]="isSaveClick" 
                                             isRequired="true"
                                            nameInput="industryF"
                                            >
                                        </app-searchable-dropdown-with-add>
                                    </div>
                                </div>
                                <div class="mb-3 validate-me">
                                    <label class="modal-input-label" for="service">{{"service" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <select #service="ngModel" (change)="selectService($event)"
                                        [(ngModel)]="client.service" class="custom-select border-radius-0" id="service"
                                        name="service" required>
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let service of serviceList" [value]="service.value">
                                            {{service.name | titlecase}}</option>
                                    </select>
                                </div>
                                <div class="mb-3  validate-me">
                                    <label class="modal-input-label"
                                        for="accountManagers">{{"accountManagers" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <div
                                        [ngClass]="{'invalid':(!client.accountManagers && isSaveClick),'valid':(client.accountManagers && isSaveClick)}">
                                        <app-multiselect-dropdown (selectResult)="selectAccountManager($event)"
                                            [dropdownName]="'selectAccountManager'" [isSearchable]="true"
                                            [items]="accountManagers" [translateObjectKey]="'fullName'"
                                            id="accountManagers"></app-multiselect-dropdown>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="cancel()" class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">
                        {{ "cancel" | translate }}
                    </button>
                    <button (click)="save(formClient)" class="btn btn-success border-radius-0" type="button">
                        {{ "save" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
