<!--Delete candidate education Modal -->
<div aria-hidden="true" aria-labelledby="deleteCandidateTitle" class="modal fade" id="deleteCandidate"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title" id="deleteUserLongTitle">
                    {{ "deleteCandidate" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="firstName">{{'name' | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="candidate.firstName" class="form-control border-radius-0"
                                       disabled
                                       id="firstNamelastNameDeleteCandidate"
                                       name="firstNamelastNameDeleteCandidate"
                                       type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="middleName">{{'middleName' | translate}}</label>
                                <input [value]="candidate.middleName" class="form-control border-radius-0"
                                       disabled
                                       id="middleName"
                                       name="middleName"
                                       type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12"> 
                            <div class="mb-3">
                                <label class="modal-input-label" for="lastName">{{'surname' | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="candidate.lastName" class="form-control border-radius-0"
                                       disabled
                                       id="lastNameDeleteCandidate"
                                       name="lastNamelastNameDeleteCandidate"
                                       type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="phone">{{'phone' | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="candidate.primaryPhone" class="form-control border-radius-0"
                                       disabled
                                       id="phone"
                                       name="phone"
                                       type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="email">{{'email' | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="candidate.primaryEmail" class="form-control border-radius-0"
                                       disabled
                                       id="email"
                                       name="email" type="email">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closeDeleteCandidateModal()" class="btn btn-warning border-radius-0"
                        data-dismiss="modal"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="deleteCandidate()" class="btn btn-danger border-radius-0" type="button">
                    {{ "delete" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
