import {Component, OnInit} from '@angular/core';
import {Items} from '../../appItems';

@Component({
    selector: 'app-login-layout',
    templateUrl: './login-layout.component.html',
    styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent implements OnInit {

    appLogo: string;

    constructor() {
        this.appLogo = Items.appLogo2;
    }

    ngOnInit(): void {
    }

}
