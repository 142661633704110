import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CandidateModel} from '../../../../candidates/candidate.model';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../services/notification/notification.service';
import {server} from '../../../../../services/server';

@Component({
    selector: 'app-add-to-position-candidate-modal',
    templateUrl: './add-to-position-candidate-modal.component.html',
    styleUrls: ['./add-to-position-candidate-modal.component.scss']
})
export class AddToPositionCandidateModalComponent implements OnInit {

    @Input() positionId: string;
    selectedCandidate: CandidateModel;
    candidates: CandidateModel[];
    @Output() updateCandidates = new EventEmitter();

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                private notification: NotificationService) {
        this.selectedCandidate = new CandidateModel();
        this.positionId = '';
    }

    ngOnInit(): void {
        this.getCandidates();
    }

    selectCandidate(e) {
        this.selectedCandidate.id = e.target.value;
    }

    closeChangePositionModal() {
        // @ts-ignore
        $('#addToPositionModal').modal('hide');
    }

    getCandidates() {
        this.authService.ServerGet(server.candidates + '?filter=status!:DELETED&filter=pipelineSteps.positionId!:' + this.positionId ? this.positionId : '')
            .subscribe((res: any) => {
                this.candidates = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    addPosition() {
        const obj = {
            candidateId: this.selectedCandidate.id,
            positionId: this.positionId,
            clientHrPermitted: this.selectedCandidate.clientHrPermitted,
            clientTechnicalPermitted: this.selectedCandidate.clientTechnicalPermitted,
            hideSensitiveDataForClient : this.selectedCandidate.hideSensitiveDataForClient
        };

        this.authService.ServerPost(server.pipelineStepCandidates, obj)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.updateCandidates.emit();
                this.spinner.hide();
                this.getCandidates();
                this.closeChangePositionModal();
            });
    }

}
