<div class="dropdown">
    <button (click)="filterState = !filterState"
            class="btn btn-secondary dropdown-toggle border-radius-0 form-control"
            id="filterButton"
            type="button">
        <span class="fas fa-filter float-left mt-1 mr-2"></span>{{'filter' | translate}}
    </button>
    <div (clickOutside)="closeFilterPopup($event)" [style.display]="filterState ? 'block':'none'"
         aria-labelledby="dropdownMenuButton" class="color-dropdown border-radius-0 mt-1"
         style="position: fixed; z-index: 5000; width: auto !important;">
        <div>
            <a aria-controls="positionStatus" aria-expanded="false"
               class="dropdown-item color-dropdown-item" data-toggle="collapse"
               href="#positionStatus"
               role="button">
                {{'status' | translate}}<span *ngIf="this.positionFilter.status !==''" class="text-danger">*</span>
            </a>
            <div class="collapse" id="positionStatus">
                <app-multiselect-dropdown (selectResult)="selectStatus($event)"
                                          [isSearchable]="true"
                                          [items]="positionStatusList"
                                          dropdownName="status"
                                          translateObjectKey="name">
                </app-multiselect-dropdown>
            </div>
        </div>
        <div>
            <a (click)="getCountries()" aria-controls="country"
               aria-expanded="false" class="dropdown-item color-dropdown-item"
               data-toggle="collapse" href="#country"
               role="button">
                {{'country' | translate}}<span *ngIf="this.positionFilter.country.id !==''" class="text-danger">*</span>
            </a>
            <div class="collapse" id="country">
                <select (change)="selectCountry($event)" class="custom-select">
                    <option selected value="all">{{'all' | translate}}</option>
                    <option *ngFor="let country of countries"
                            [value]="country.id">{{country.defaultLocalization.name}}</option>
                </select>
            </div>
        </div>
        <div>
            <a aria-controls="positionType"
               aria-expanded="false" class="dropdown-item color-dropdown-item"
               data-toggle="collapse" href="#city"
               role="button">
                {{'city' | translate}}<span *ngIf="this.positionFilter.city.id !==''" class="text-danger">*</span>
            </a>
            <div class="collapse" id="city">
                <app-multiselect-dropdown-m (selectResult)="selectCity($event)"
                                            [isSearchable]="true"
                                            [items]="cities"
                                            dropdownName="city"
                                            translateObjectKey="defaultLocalization.name">
                </app-multiselect-dropdown-m>
            </div>
        </div>
        <div>
            <a aria-controls="positionType"
               aria-expanded="false" class="dropdown-item color-dropdown-item"
               data-toggle="collapse" href="#positionType"
               role="button">
                {{'positionType' | translate}}<span *ngIf="this.positionFilter.positionType !==''" class="text-danger">*</span>
            </a>
            <div class="collapse" id="positionType">
                <app-multiselect-dropdown (selectResult)="selectPositionType($event)"
                                          [isSearchable]="true"
                                          [items]="positionTypes"
                                          dropdownName="positionType"
                                          translateObjectKey="name">
                </app-multiselect-dropdown>
            </div>
        </div>
        <div>
            <a aria-controls="positionType"
               aria-expanded="false" class="dropdown-item color-dropdown-item"
               data-toggle="collapse" href="#client"
               role="button">
                {{'client' | translate}}<span *ngIf="this.positionFilter.client !==''" class="text-danger">*</span>
            </a>
            <div class="collapse" id="client">
                <app-multiselect-dropdown (selectResult)="selectClient($event)"
                                          [isSearchable]="true"
                                          [items]="clients"
                                          dropdownName="client"
                                          translateObjectKey="name">
                </app-multiselect-dropdown>
            </div>
        </div>
        <div>
            <a aria-controls="positionType"
               aria-expanded="false" class="dropdown-item color-dropdown-item"
               data-toggle="collapse" href="#user"
               role="button">
                {{'user' | translate}}<span *ngIf="this.positionFilter.users !==''" class="text-danger">*</span>
            </a>
            <div class="collapse" id="user">
                <app-multiselect-dropdown (selectResult)="selectCompanyUser($event)"
                                          [isSearchable]="true"
                                          [items]="companyUsers"
                                          dropdownName="user"
                                          translateObjectKey="fullName">
                </app-multiselect-dropdown>
            </div>
        </div>
        <div>
            <button (click)="applyFilter()"
                    class="exactalent-button border-radius-0 apply-filter">{{'applyFilters' | translate}}</button>
        </div>
    </div>
</div>
