<div class="container-fluid p-0 overflow-hidden ">
    <div class="row ">
        <div  class="col-xl-3 col-lg-3 col-md-12 col-sm-12 p-0">
            <div class="container-fluid ">
                <div class="row ">
                    <div class="col-12 user-panel">
                        <div class="row">
                            <div class='user-img-div mx mt-5 text-center'
                                 style="max-width: 140px; cursor: pointer; margin: auto;">
                                <img (click)="openChangePhoto()" [src]="tempClientImg"
                                     class="rounded-circle img-thumbnail img-fluid user-img"
                                     style="width: 140px; height: 140px;">
                            </div> 
                        </div>
                        <div class="row pl-3">
                            <div class="col-12">
                                <hr style="background-color: #f6f6f6; width: 100%">
                            </div>
                        </div>
                        <div class="row pl-3">
                            <div class="col-12 mt-3">
                                <p class="mb-1" style="font-size: 18px;">{{client?.name}}</p>
                                <p>{{client?.city?.value + ' / ' + client?.country?.value}}</p>
                            </div>
                        </div>

                        <!--Social Media Icons Begin-->
                        <div style="word-break: break-all;" class="row pl-3 mt-2">
                            <div class="col-12">
                                <i class="fas fa-globe" style="font-size: 29px;"></i>
                            </div>
                            <div class="col-12 mt-2">
                                <p><a [href]="client?.website" style="color: white; text-decoration: none;"
                                      target="_blank">{{client?.website}}</a></p>
                            </div>

                        </div>
                        <!--Social Media Icons End-->
                        <div style="word-break: break-all;" class="row pl-3">
                            <div class="col-12">
                                <span style="background-color: white;padding: 5px 9px;border-radius: 50%;text-align: center;">
                                    <i class="fas fa-mobile-alt" style="color: orange"></i>
                                </span>
                            </div>
                            <div class="col-12 mt-2">
                                <p>{{client?.phone}}</p>
                            </div>
                        </div>
                        <div class="row pl-3 mt-4">
                            <div class="col-12">
                                <p>{{'address' | translate}}:&nbsp;{{client?.address}}</p>
                            </div>
                        </div>
                        <div class="row pl-3">
                            <div class="col-12">
                                <hr style="background-color: #f6f6f6; width: 100%">
                            </div>
                        </div>
                        <div class="row pl-3">
                            <div class="col-12 mt-3">
                                <p>{{'status' | translate}}:&nbsp;<span (click)="openUpdateStatusModal()"
                                                                        class="cursor-pointer">
                                    {{client?.status}}
                                    <i class="far fa-edit ml-2"></i>
                                </span></p>
                            </div>
                        </div>

                        <div class="row pl-3">
                            <div class="col-12">
                                <hr style="background-color: #f6f6f6; width: 100%">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 p-0">
            <div class="container-fluid">
                <div class="row">
                    <div (click)="setTab('general')"
                         [ngClass]="{'gradient-bg':!generalComponentState,
                         'top-line':generalComponentState}"
                         class="menu-item col-xl col-lg col-md col-sm-12">
                        <a class="nav-link active">{{'general' | translate}}</a>
                    </div>
                    <div (click)="setTab('positionRequest')"
                         [ngClass]="{'gradient-bg':!positionRequestComponentState,
                         'top-line':positionRequestComponentState}"
                         class="menu-item col-xl col-lg col-md col-sm-12">
                        <a class="nav-link active">{{'positionRequest' | translate}}</a>
                    </div>
                    <div (click)="setTab('attachments')"
                         [ngClass]="{'gradient-bg':!attachmentsComponentState,
                         'top-line':attachmentsComponentState}"
                         class="menu-item col-xl col-lg col-md col-sm-12">
                        <a class="nav-link">{{'attachments' | translate}}</a>
                    </div>
                    <div (click)="setTab('notes')"
                         *ngIf="permissionService.permissionsOnly(['READ_ALL_CLIENT_NOTES'])"
                         [ngClass]="{'gradient-bg':!notesComponentState,
                         'top-line':notesComponentState}"
                         class="menu-item col-xl col-lg col-md col-sm-12">
                        <a class="nav-link">{{'notes' | translate}}</a>
                    </div>
                    <div (click)="setTab('users')"
                         [ngClass]="{'gradient-bg':!usersComponentState,
                         'top-line':usersComponentState}"
                         class="menu-item col-xl col-lg col-md col-sm-12">
                        <a class="nav-link">{{'users' | translate}}</a>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <app-client-general *ngIf="generalComponentState" [generalInfo]="client"></app-client-general>
                        <app-client-position-requests *ngIf="positionRequestComponentState"
                                                      [clientId]="client.id"></app-client-position-requests>
                        <app-client-attachments *ngIf="attachmentsComponentState"
                                                [clientId]="client.id"></app-client-attachments>
                        <app-client-notes
                                *ngIf="notesComponentState && permissionService.permissionsOnly(['READ_ALL_CLIENT_NOTES','READ_CLIENT_NOTE'])"
                                [clientId]="client.id"></app-client-notes>
                        <app-client-users *ngIf="usersComponentState" [clientId]="client.id"></app-client-users>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-edit-client (setClientScreen)="getClient()" [client]="client"></app-edit-client>
<app-change-client-status-modal [client]="client"></app-change-client-status-modal>
<app-photo-import (emitSuccessStatus)="getClientProfileImage()" [photoApiUrl]="photoApiUrl" [tempUserImg]="tempClientImg"></app-photo-import>
