<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-12 p-0">
            <div class="table-responsive">
                <table class="table">
                    <thead class="table-head">
                    <tr style="border: none">
                        <th scope="col">{{'name' | translate}}</th>
                        <th scope="col">{{'email' | translate}}</th>
                        <th scope="col">{{'phone' | translate}}</th>
                        <th scope="col">{{'source' | translate}}</th>
                        <th scope="col">{{'addedDate' | translate}}</th>
                        <th scope="col">{{'lastActivityDate' | translate}}</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let candidate of candidates | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count }"
                        [hidden]="count<1"
                        class="history-row">
                        <td><a (click)="openCandidateDetails(candidate)" class="cursor-pointer"
                               style="text-decoration:underline #17a2b8">{{candidate.name}}</a></td>
                        <td>{{candidate.email}}</td>
                        <td>{{candidate.phone}}</td>
                        <td>{{candidate.source}}</td>
                        <td>{{candidate.addedAt | date}}</td>
                        <td>{{candidate.updatedAt | date}}</td>
                        <td>
                        <span (click)="openChangeCandidateStep(candidate)"
                              class="float-right cursor-pointer ml-3 text-info">
                            <i class="fas fa-retweet"></i>
                        </span>
                            <span (click)="openStepNote(candidate)" class="float-right cursor-pointer text-warning">
                        <i class="fa fa-pen"></i>
                        </span>
                        </td>
                    </tr>
                    <tr [hidden]="count>0">
                        <td colspan="7">
                            <app-empty-table-info tableInfoTranslateKey="candidateNotExistInfo"></app-empty-table-info>
                        </td> 
                    </tr>
                    </tbody>
                    <tfoot *ngIf="count > requestPayload.limit">
                    <tr class="width-100">
                        <td class="text-center" colspan="6">
                            <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
                                                 [autoHide]="count <= requestPayload.limit"
                                                 nextLabel="{{'next' | translate}}"
                                                 previousLabel="{{'previous' | translate}}"
                                                 responsive="true"></pagination-controls>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>