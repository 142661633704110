import {Component, Input, OnInit} from '@angular/core';
import {GeneralModel} from '../../position-operations/general/general.model';
import {PositionStatusList} from '../../../../shared/lists/position-status.list';
import {server} from '../../../../services/server';
import {AuthService} from '../../../../services/auth/auth.service';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../services/notification/notification.service';
import {PositionModel} from '../../position.model';

@Component({
    selector: 'app-position-status-modal',
    templateUrl: './position-status-modal.component.html',
    styleUrls: ['./position-status-modal.component.scss']
})
export class PositionStatusModalComponent implements OnInit {

    @Input() position: GeneralModel;

    positionStatusList = PositionStatusList;
    tempPositionStatus: string;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                private notification: NotificationService) {
        this.position = new GeneralModel();
    }

    ngOnInit(): void {
    }

    selectPositionStatus(e) {
        this.tempPositionStatus = e.target.value;
    }

    updateStatus() {

        const obj = {
            status: this.tempPositionStatus
        };
        this.authService.ServerPut(server.positions + '/' + this.position.id + '/status', obj)
            .subscribe(res => {
                this.position.status = this.tempPositionStatus;
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#positionStatusModal').modal('hide');
                this.spinner.hide();
            });
    }

}
