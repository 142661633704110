import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslatePipe } from 'src/app/pipes/translate/translate.pipe';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { server } from 'src/app/services/server';
import { RequestPayload } from 'src/app/shared/requestPayload';

@Component({
  selector: 'app-interview-requests-list',
  templateUrl: './interview-requests-list.component.html',
  styleUrls: ['./interview-requests-list.component.scss'],
})
export class InterviewRequestsListComponent implements OnInit {
  @Input() filterData: string;
  count;
  currentPage: number;
  requestPayload: RequestPayload;
  selectedInterviewRequest: any;
  tagList = ['TR', 'tr', 'TD', 'td'];
  openedElementId: string;
  interviewRequestsUrl: string;
  interviewRequestStatusUrl: string;
  interviewRequests: any;

  confirmData: any = {};

  constructor(
    public permissionService: PermissionService,
    private authService: AuthService,
    private translate: TranslatePipe,
    private notification: NotificationService,
    private spinner: NgxSpinnerService
  ) {
    this.requestPayload = new RequestPayload();
  }

  ngOnInit(): void {
    this.interviewRequestsUrl = server.interviewRequests;

    this.getInterviewRequests('1');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filterData.currentValue) {
      this.currentPage = 1;
      this.getInterviewRequests('1');
    }
  }

  openRowModal(e, interviewRequest) {
    if (this.tagList.indexOf(e.target.tagName) > -1) {
      if (this.openedElementId) {
        document.getElementById('row-modal').style.display = 'none';
      }
      this.selectedInterviewRequest = interviewRequest;
      this.openedElementId = this.selectedInterviewRequest.id;
      document.getElementById('row-modal').style.left = e.layerX + 'px';
      document.getElementById('row-modal').style.top = e.layerY - 5 + 'px';
      document.getElementById('row-modal').style.display = 'block';
    } else {
      this.closeRowModal();
    }
  }

  onClickedOutside(event) {
    if (this.tagList.indexOf(event.target.tagName) <= -1) {
      this.closeRowModal();
    }
  }

  closeRowModal() {
    if (this.openedElementId) {
      document.getElementById('row-modal').style.display = 'none';
    }
  }

  getInterviewRequests(pageNo) {
    this.authService
      .ServerGet(
        this.interviewRequestsUrl +
          `?size=${this.requestPayload.limit}&page=${pageNo - 1}` +
          this.filterData
      )
      .subscribe(
        (res: any) => {
          this.interviewRequests = res.content;
          this.count = res.totalElements;
        },
        (err) => {
          if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
            this.notification.error(err.message);
            this.spinner.hide();
          }
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  openInterviewRequestStatusModal(interviewRequest) {}

  openCreateInterview() {
     if (!this.checkRequestStatus()){return; }
    // @ts-ignore
     $('#createInterviewFromRequest').modal('show');
     this.closeRowModal();
  }

  openConfirmInterviewRequest() {
    if (!this.checkRequestStatus()){return; }
    this.closeRowModal();
    this.confirmData.title = 'interviewRequestStatus';
    const confirmInterviewRequest = this.translate.transform('confirmInterviewRequest', []);
    const candidateName = this.selectedInterviewRequest.candidate.name;
    this.confirmData.message = `<i class="fas fa-exclamation-triangle pr-1"></i>
                            <strong>${candidateName}</strong>${confirmInterviewRequest}`;

    // @ts-ignore
    $('#confirmModal').modal('show');
  }

  checkRequestStatus(){
    if (!this.selectedInterviewRequest) { return false; }
    if (this.selectedInterviewRequest.status !== 'CREATED'){
      const text = this.selectedInterviewRequest.status.toLowerCase();
      const message = this.translate.transform('warnInterviewRequestOperation', []) + ' ' + this.translate.transform(text, []);
      this.notification.warning(message);
      return false;
    }
    return true;
  }

  changeInterviewRequestStatus(status) {
    const obj = {
      status,
    };
    if (this.selectedInterviewRequest) {
      this.interviewRequestStatusUrl = server.interviewRequests + '/' + this.selectedInterviewRequest.id + '/status';
    }
    this.authService.ServerPut(this.interviewRequestStatusUrl, obj).subscribe(
      (res) => {
        this.selectedInterviewRequest.status = status;
        this.notification.success(
          this.translate.transform('saveSuccessful', [])
        );
      },
      (err) => {
        if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
          this.notification.warning(err.message);
        }
        this.spinner.hide();
      },
      () => {
        // @ts-ignore
        $('#confirmModal').modal('hide');
        this.spinner.hide();
      }
    );
  }
}
