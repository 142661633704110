<div class="container-fluid">
    <div class="row mb-3">
        <div class="col p-0">
            <app-report-position-filter (applyFilterOperation)="applyFilter($event)"></app-report-position-filter>
        </div>
        <div class="col">

            <div class="input-group h-100">
                <input matInput id="openingPickerText" placeholder="mm-dd-yyyy" [matDatepicker]="openingPicker" (dateChange)="openingDateFilter($event)" class="w-100">            
                <mat-datepicker-toggle matSuffix [for]="openingPicker" class="mat-datepicker position-absolute right-0"></mat-datepicker-toggle>
                <mat-datepicker #openingPicker></mat-datepicker>
            </div>
            
        </div>
        <div class="col">
            <div class="input-group h-100">
                <input matInput id="closingPickerText" placeholder="mm-dd-yyyy" [matDatepicker]="closingPicker" (dateChange)="closingDateFilter($event)" class="w-100">
                <mat-datepicker-toggle matSuffix [for]="closingPicker" class="mat-datepicker  position-absolute right-0"></mat-datepicker-toggle>
                <mat-datepicker #closingPicker></mat-datepicker>
            </div>
        </div>
    
          <div [ngClass]="isShow ? 'd-none': 'col-4 col-md-3 col-xl-2'" >
            <button (click)="clearDateFilter()"
              #searchButton 
                      class="btn btn-blue btn-reset border-radius-0 float-right exactalent-button "
                      data-target="#addUserCenter"
                      data-toggle="modal">
                {{'ClearDateFilter' | translate}}
            </button>
        </div>   
    </div>

    <div class="row table-bg mt-2" style="border-top: 12px #f6f6f6">
        <div class="table-responsive">
            <table class="table table-bg overflow-hidden">
                <thead class="table-bg">
                <tr>
                    <!-- <th scope="col">&nbsp;</th> -->
                    <th scope="col">{{'position' | translate}}</th>
                    <th scope="col">{{'client' | translate}}</th>
                    <th scope="col">{{'user' | translate}}</th>
                    <th scope="col" (click)="dateSort()"><a>{{'added' | translate }}</a>
                                        
                        <i *ngIf="sortFlag ==0" class="fa fa-sort-up sort-passive ml-1"></i>
                        <i *ngIf="sortFlag >0" [ngClass]="sortFlag == 1 ? 'fa fa-sort-up ml-1': 'fa fa-sort-down ml-1'"></i>
            
                    </th>
                    <th scope="col">{{'status' | translate}}</th>
                    <th scope="col">{{'currentStatusDate' | translate}}</th>
                    <th scope="col">{{'timeToFill' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        *ngFor="let position of positions | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count } "
                        class="position-row">

                    <td>{{position.name}}</td>
                    <td>{{position.client}}</td>
                    <td>{{'Max Mustermann'}}</td>
                    <td>{{position.createdAt | date}}</td>
                    <td>{{position.status }}</td>
                    <td>{{position.currentStatusDate | date}}</td>
                    <td>{{position.timeToFill }}</td>

                </tr>
                <tr [hidden]="count>0">
                    <td colspan="8">
                        <app-empty-table-info tableInfoTranslateKey="positionNotExistInfo"></app-empty-table-info>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="width-100">
                    <td class="text-center" colspan="7">
                        <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
                                             [autoHide]="count <= requestPayload.limit" nextLabel="{{'next' | translate}}"
                                             previousLabel="{{'previous' | translate}}" responsive="true"></pagination-controls>
                    </td>
                </tr>
                </tfoot>
            </table>
            <div class="alert alert-info m-auto">
                <p class="text-center" style="font-size: 15px">{{showingBetween}}</p>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <button   #exportButtonPosition (click)="ExportPosition()"
                  class="btn btn-blue btn-reset border-radius-0 float-right exactalent-button "
                  data-target="#exportButtonPosition"
                  data-toggle="modal">
            {{'ExportOnPosition' | translate}}
        </button>
    </div>
</div>
<app-export-modal-position></app-export-modal-position>



