<div *ngIf="permissionService.permissionsOnly(['READ_ALL_PIPELINE_STEPS'])" class="container-fluid">
    <div *ngIf="permissionService.permissionsOnly(['CREATE_PIPELINE_STEP'])"
         class="row mt-5">
        <div class="col-12">
            <button (click)="addStepModal()"
                    class="btn btn-blue border-radius-0 float-right exactalent-button col-xl-auto col-lg-auto col-md-auto col-sm-12">{{'addNewStep' | translate}}</button>
        </div>
    </div>
    <div [ngClass]="{'mt-5':!permissionService.permissionsOnly(['CREATE_PIPELINE_STEP'])}"
         class="row">
        <ngx-sortable [items]="recruitmentPreferences"
                      [listStyle]="{width:'auto', height:'auto'}" [showHeader]="false"
                      class="col-12 border-0">
            <ng-template let-item>
                <app-step-panel
                        (deleteStep)="deleteStepModal($event)"
                        (editStep)="editStepModal($event)"
                        [recruitmentPreference]="item"></app-step-panel>
            </ng-template>
        </ngx-sortable>
    </div>
    <div *ngIf="permissionService.permissionsOnly(['CREATE_PIPELINE_STEP','UPDATE_PIPELINE_STEP','DELETE_PIPELINE_STEP'])"
         class="row mt-3">
        <div class="col-12 p-0">
            <div class="modal-footer">
                <button (click)="cancelRecruitmentPreferences()"
                        class="btn btn-warning border-radius-0 col-xl-auto col-lg-auto col-md-auto col-sm-12"
                        data-dismiss="modal"
                        type="button">{{'cancel' | translate}}</button>
                <button (click)="saveRecruitmentPreferences()"
                        class="btn btn-success border-radius-0 col-xl-auto col-lg-auto col-md-auto col-sm-12"
                        type="button">{{'save' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<app-recruitment-preference-modal (addStep)="addNewStep($event)"
                                  (deleteStep)="deleteStep($event)"
                                  *ngIf="permissionService.permissionsOnly(['CREATE_PIPELINE_STEP','UPDATE_PIPELINE_STEP','DELETE_PIPELINE_STEP'])"
                                  [operationType]="operationType"
                                  [preferences]="preferences"
></app-recruitment-preference-modal>
