import {Component, Input, OnInit} from '@angular/core';
import {server} from '../../../../services/server';
import {AuthService} from '../../../../services/auth/auth.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {UploadService} from '../../../../services/upload/upload.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../../../services/notification/notification.service';
import {AttachmentModel} from '../../../../shared/components/attachments/attachment-import/attachment.model';

@Component({
    selector: 'app-attachments',
    templateUrl: './attachments.component.html',
    styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {

    @Input() candidateId: string;
    attachments: AttachmentModel[] = [];
    attachmentUrl: string;
    attachmentDeleteUrl: string;

    constructor(private authService: AuthService,
                private translate: TranslatePipe,
                private uploadService: UploadService,
                private spinner: NgxSpinnerService,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.attachmentUrl = server.storageCandidate + '/' + this.candidateId + '/attachments';
        this.getCandidateAttachments();
    }

    getCandidateAttachments() {
        this.authService.ServerGet(this.attachmentUrl)
            .subscribe((res: AttachmentModel[]) => {
                this.attachments = res;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    openImportAttachment() {
        // @ts-ignore
        $('#importAttachment').modal('show');
    }

    openRemoveAttachment(attachment: AttachmentModel) {
        this.attachmentDeleteUrl = this.attachmentUrl + '/' + attachment.id;
        // @ts-ignore
        $('#deleteAttachmentModal').modal('show');
    }

}
