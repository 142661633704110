import {Component, OnInit} from '@angular/core';
import {TranslatePipe} from '../../pipes/translate/translate.pipe';
import {ColorList} from '../../shared/lists/color.list';
import {CareerPortalSettingsModel} from './career-portal-settings.model';
import {server} from '../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../services/auth/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../services/notification/notification.service';
import {PermissionService} from '../../services/permission/permission.service';

@Component({
    selector: 'app-career-portal-settings',
    templateUrl: './career-portal-settings.component.html',
    styleUrls: ['./career-portal-settings.component.scss']
})
export class CareerPortalSettingsComponent implements OnInit {

    headerColorText = this.translate.transform('headerColor', []);
    headerColor = 'white';
    headerColorStyle = '3px solid ' + this.headerColor;
    colorList = ColorList;
    careerPortalSettings: CareerPortalSettingsModel;
    storedSettings;
    companyImage: string;
    companyImageApiUrl: string;
    headerImage: string;
    headerImageApiUrl: string;
    photoApiUrl: string;
    changingPhotoType: string;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                private translate: TranslatePipe,
                public permissionService: PermissionService,
                private notification: NotificationService) {
        this.careerPortalSettings = new CareerPortalSettingsModel();
        this.careerPortalSettings.headerColor = this.headerColor;
    }

    colorName(colorCod) {
        let colorName = this.headerColorText;
        for (let i = 0; i < (this.colorList).length; i++) {
            if (this.colorList[i].colorCode === colorCod) {
                colorName = this.colorList[i].colorName;
            }
        }
        this.headerColorText = this.translate.transform(colorName, []);
    }

    ngOnInit(): void {
        this.companyImageApiUrl = server.storageCompany + '/' + this.authService.companyId + '/logo';
        this.headerImageApiUrl = server.storageCompany + '/' + this.authService.companyId + '/header-image';
        this.getCareerPortalSettings();
        this.getCompanyLogo();
        this.getCompanyHeaderImage();
    }

    getHeaderColorText(e) {
        this.headerColorText = e.target.text;
        this.careerPortalSettings.headerColor = e.target.title;
        this.headerColorStyle = '3px solid ' + this.careerPortalSettings.headerColor;
    }

    getCareerPortalSettings() {
        const companyId = this.authService.companyId;
        this.authService.ServerGet(server.companies + '/' + companyId + '/career-portal-settings')
            .subscribe((res: any) => {
                this.careerPortalSettings = res;
                this.clearSocialMediaLinksOnInit();
                this.colorName(this.careerPortalSettings.headerColor);
                this.headerColorStyle = '3px solid ' + this.careerPortalSettings.headerColor;
                this.storedSettings = {...this.careerPortalSettings};
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    saveCareerPortalSettings() {
        const companyId = this.authService.companyId;
        this.authService.ServerPut(server.companies + '/' + companyId + '/career-portal-settings', this.careerPortalSettings)
            .subscribe(res => {
                this.storedSettings = {};
                this.storedSettings = {...this.careerPortalSettings};
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#recruitmentPreferencesModal').modal('hide');
                this.spinner.hide();
            });
    }

    cancelCareerPortalSettings() {
        this.careerPortalSettings = {...this.storedSettings};
    }

    parseSocialMediaUsername(url: string): string {
        const splitData = url.split('/');
        return splitData[splitData.length - 1];
    }

    getSocialMediaUsername(reference: string) {
        switch (reference) {
            case 'twitter':
                this.careerPortalSettings.twitterLink = this.parseSocialMediaUsername(this.careerPortalSettings.twitterLink);
                break;
            case 'facebook':
                this.careerPortalSettings.facebookLink = this.parseSocialMediaUsername(this.careerPortalSettings.facebookLink);
                break;
            case 'linkedin':
                this.careerPortalSettings.linkedinLink = this.parseSocialMediaUsername(this.careerPortalSettings.linkedinLink);
                break;
            case 'instagram':
                this.careerPortalSettings.instagramLink = this.parseSocialMediaUsername(this.careerPortalSettings.instagramLink);
                break;
            default:
                break;
        }
    }

    clearSocialMediaLinksOnInit() {
        this.careerPortalSettings.twitterLink = this.parseSocialMediaUsername(this.careerPortalSettings.twitterLink);
        this.careerPortalSettings.facebookLink = this.parseSocialMediaUsername(this.careerPortalSettings.facebookLink);
        this.careerPortalSettings.linkedinLink = this.parseSocialMediaUsername(this.careerPortalSettings.linkedinLink);
        this.careerPortalSettings.instagramLink = this.parseSocialMediaUsername(this.careerPortalSettings.instagramLink);
    }

    openChangePhoto(type: string) {
        this.changingPhotoType = type;
        switch (type) {
            case 'company':
                this.photoApiUrl = this.companyImageApiUrl;
                break;
            case 'header':
                this.photoApiUrl = this.headerImageApiUrl;
                break;
            default:
                break;
        }
        // @ts-ignore
        $('#photoImport').modal('show');
    }

    getCompanyPhoto() {
        switch (this.changingPhotoType) {
            case 'company':
                this.getCompanyLogo();
                break;
            case 'header':
                this.getCompanyHeaderImage();
                break;
            default:
                break;
        }
    }


    getCompanyLogo() {
        this.authService.ServerGet(this.companyImageApiUrl)
            .subscribe((res: { url: string }) => {
                if (res.url && res.url !== '') {
                    this.companyImage = res.url;
                }

            }, err => {
                /*if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }*/
            }, () => {
                this.spinner.hide();
            });
    }

    getCompanyHeaderImage() {
        this.authService.ServerGet(this.headerImageApiUrl)
            .subscribe((res: { url: string }) => {
                if (res.url && res.url !== '') {
                    this.headerImage = res.url;
                }

            }, err => {
                /*if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }*/
            }, () => {
                this.spinner.hide();
            });
    }
}
