<!-- Modal -->
<div aria-hidden="true" aria-labelledby="takeNoteModalCenterTitle" class="modal fade"
     id="takeNoteModal" 
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="takeNoteModalLongTitle">{{'takeNote' | translate}}</h5>
            </div>
            <div class="modal-body">          
                <div class="mb-3">
                    <div class="col-12">
                        <textarea [(ngModel)]="note.message" class="form-control border-radius-0"
                                  placeholder="{{'notesPlaceholder' | translate}}&nbsp;&nbsp;{{'here' | translate | lowercase}}"
                                  rows="9" appTitlecaseLower></textarea>
                    </div>  
                </div>           
            </div>            
            <div class="modal-footer">
                <span class="float-left col" style="color: #649a65;">
                    <label>
                        <input [(ngModel)]="note.privateNote" type="checkbox">
                        <span class="ml-2">
                            {{'privateNote' | translate}}
                        </span>
                    </label>
                </span>
                <button (click)="cancel()"
                        class="btn btn-warning border-radius-0"
                        data-dismiss="modal"
                        type="button">{{'cancel' | translate}}</button>
                <button (click)="save()"
                        class="btn btn-success border-radius-0"
                        type="button">{{'save' | translate}}</button>
            </div>
        </div>
    </div>
</div>




