<!--Add pool education Modal -->
<div aria-hidden="true" aria-labelledby="addPoolOperationTitle" class="modal fade" id="addPoolOperation"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">
                    {{ "addNewPool" | translate }}
                </h5>
            </div>
            <div class="modal-body" >
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="primaryEmail">{{"name" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [(ngModel)]="pool.name"
                                       class="form-control border-radius-0"
                                       id="primaryEmail"
                                       type="text" appTitleCaseDirective>
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="country">{{"category" | translate}}</label>
                                <app-searchable-dropdown    (selectResult)="selectCategory($event)"
                                                         [items]="categories"
                                                         id="category"></app-searchable-dropdown>
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="country">{{"country" | translate}}</label>
                                <app-searchable-dropdown   (selectResult)="selectCountry($event)"
                                                         [items]="countries"
                                                         id="country"></app-searchable-dropdown>
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="city">{{"city" | translate}}</label>
                                <app-searchable-dropdown (selectResult)="selectCity($event)"
                                                         [items]="cities"
                                                         id="city"></app-searchable-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="cancel()" class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="save()" class="btn btn-success border-radius-0" type="button">
                    {{ "save" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
