<div class="row step-row">
    <div class="col-12 border-radius-0 step-col mt-2">
        <span class="badge badge-light float-left rounded-circle step-badge">{{recruitmentPreference.position + 1}}</span>
        <span class="float-left ml-4 step-text">{{recruitmentPreference.name}}</span>
        <span class="float-right control-panel">
            <span><i class="fas fa-expand-arrows-alt"></i></span>
            <span (click)="edit()" *ngIf="permissionService.permissionsOnly(['UPDATE_PIPELINE_STEP'])" class="ml-4"><i
                    class="fas fa-pen"></i></span>
            <span (click)="delete()" *ngIf="permissionService.permissionsOnly(['DELETE_PIPELINE_STEP'])" class="ml-4"><i
                    class="fas fa-times"></i></span>
        </span>
    </div>
</div>
