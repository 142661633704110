<!--Edit candidate education Modal -->
<div aria-hidden="true" aria-labelledby="editWorkExperienceTitle" class="modal fade" id="editWorkExperience"
    role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">

        <div class="modal-content">
            <form #editCandidateWorkExperience="ngForm" class="needs-validation">
                <div class="modal-header gradient-bg">
                    <h5 class="modal-title" id="editUserLongTitle">
                        {{ "editWorkExperience" | translate }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3 validate-me">
                                    <label class="modal-input-label" for="editCompany">{{"company" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <input [(ngModel)]="workExperience.company" class="form-control border-radius-0"
                                        id="editCompany" type="text" name="editCompany" required appTitleCaseDirective>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3 validate-me">
                                    <label class="modal-input-label" for="editTitle">{{"title" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <input [(ngModel)]="workExperience.title" class="form-control border-radius-0"
                                        id="editTitle" type="text" name="editTitle" required appTitleCaseDirective>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3 validate-me">
                                    <label class="modal-input-label"
                                        for="positionType">{{'positionType' | translate}}<span
                                            class="text-danger">*</span></label>
                                    <select (change)="selectPositionType($event)" class="custom-select border-radius-0"
                                        [(ngModel)]="workExperience.employmentType" id="positionType"
                                        name="positionType" required>
                                        <option value="" selected>{{'positionType' | translate}}</option>
                                        <option *ngFor="let positionType of positionTypes"
                                            [selected]="workExperience.employmentType === positionType.value"
                                            [value]="positionType.value">{{positionType.name | translate}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="beginningDate">
                                        {{"beginningDate" | translate}}<span class="text-danger">*</span>
                                    </label>
                                    <div class="container-fluid">
                                        <div class="row validate-me" id="beginningDate">
                                            <input [(ngModel)]="workExperience.startMonth"
                                                class="form-control col border-radius-0" id="issueMonth" max="12"
                                                min="1" name="editIssueMonth" placeholder="{{'month' | translate}}..."
                                                type="number" required />&nbsp;
                                            <input [(ngModel)]="workExperience.startYear"
                                                class="form-control col border-radius-0 hideInputNumberArrow" id="editIssueYear"
                                                name="issueYear" min="1" placeholder="{{ 'year' | translate }}..."
                                                type="number" required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="endingDate">
                                        {{"endingDate" | translate}}
                                    </label>
                                    <div class="container-fluid">
                                        <div class="row" id="endingDate">
                                            <input [(ngModel)]="workExperience.endMonth"
                                                class="form-control col border-radius-0" id="expirationMonth" max="12"
                                                min="1" name="expirationMonth" placeholder="{{'month' | translate}}..."
                                                type="number" />&nbsp;
                                            <input [(ngModel)]="workExperience.endYear" min="1"
                                                class="form-control col border-radius-0 hideInputNumberArrow" id="expirationYear"
                                                name="expirationYear" placeholder="{{ 'year' | translate }}..."
                                                type="number" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="summary">
                                        {{"summary" | translate}}
                                    </label>
                                    <div class="container-fluid">
                                        <div class="row" id="summary">
                                            <textarea [(ngModel)]="workExperience.summary"
                                                class="form-control border-radius-0" rows="4" name="summary" appTitlecaseLower></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="closeEditWorkExperienceModal()" class="btn btn-warning border-radius-0"
                        data-dismiss="modal" type="button">
                        {{ "cancel" | translate }}
                    </button>
                    <button (click)="saveWorkExperience(editCandidateWorkExperience)"
                        class="btn btn-success border-radius-0" type="button">
                        {{ "save" | translate }}
                    </button>
                </div>
            </form>
        </div>

    </div>
</div>
