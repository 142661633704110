import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttachmentModel} from '../../attachment-import/attachment.model';

@Component({
    selector: 'app-attachment-list-item',
    templateUrl: './attachment-list-item.component.html',
    styleUrls: ['./attachment-list-item.component.scss']
})
export class AttachmentListItemComponent implements OnInit {

    @Input() attachment: AttachmentModel;
    @Output() emitOpenRemoveAttachment = new EventEmitter();

    constructor() {
        this.attachment = new AttachmentModel();
    }

    ngOnInit(): void {
    }

    openRemoveAttachment(attachment) {
        this.emitOpenRemoveAttachment.emit(attachment);
    }
}
