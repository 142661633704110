import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CountryModel} from '../../../../services/parameters/models/country.model';
import {CityModel} from '../../../../services/parameters/models/city.model';
import {AuthService} from '../../../../services/auth/auth.service';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';
import {PoolModel} from '../../pool.model';
import {CategoryModel} from '../../../../services/parameters/models/category.model';

@Component({
    selector: 'app-add-pool',
    templateUrl: './add-pool.component.html',
    styleUrls: ['./add-pool.component.scss']
})
export class AddPoolComponent implements OnInit {

    @Output() setPoolScreen = new EventEmitter();

    categories: CategoryModel[];
    pool: PoolModel;
    countries: CountryModel[];
    cities: CityModel[];
    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
        this.pool = new PoolModel();
    }

    ngOnInit(): void {
        this.getCountries();
        this.getCategories();
    }
    selectCategory(category): void {
        this.pool.category = new CountryModel();
        if (category.id === '') {
            this.pool.category.id = null;
            this.categories = [];
        } else {
            this.pool.category.id = category.id;
        }
    }

    selectCountry(country) {
        this.pool.country = new CountryModel();
        if (country.id === '') {
            this.pool.country.id = null;
            this.cities = [];
        } else {
            this.pool.country.id = country.id;
            this.getCountryCities(this.pool.country.id);
        }
    }

    selectCity(city) {
        this.pool.city = new CityModel();
        if (city.id === '') {
            this.pool.city.id = null;
        } else {
            this.pool.city.id = city.id;
        }
    }

    getCountries() {
        this.parameterService.countries.subscribe((res: CountryModel[]) => {
            this.countries = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCountryCities(countryId) {
        this.cities = [];
        this.parameterService.getCountryCities(countryId).subscribe((res: CityModel[]) => {
            this.cities = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCategories() {
        this.parameterService.poolCategories.subscribe((res: CategoryModel[]) => {
            this.categories = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    cancel() {
        // @ts-ignore
        $('#addPoolOperation').modal('hide');
    }

    save() {
        this.authService.ServerPost(server.pools, this.pool)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updatePools();
                this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['dashboard/pools']);
                });
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.cancel();
                this.spinner.hide();
            });
    }

    updatePools() {
        this.setPoolScreen.emit();
    }

}
