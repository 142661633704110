<div *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_EDUCATIONS','READ_CANDIDATE_EDUCATION'])"
     class="container-fluid mt-4">
    <div class="row">
        <div class="col-12 pl-5">
            <p><b>{{'education' | translate}}</b><i (click)="openAddEducation()"
                                                    *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE_EDUCATION'])"
                                                    [title]="'add' | translate"
                                                    class="fas fa-plus-circle ml-2 cursor-pointer text-info"></i></p>
            <div class="row">
                <div (click)="openEditEducation(education,$event)" *ngFor="let education of educations"
                     class="col-xl-4 col-lg-4 col-md-4 col-sm-12 edit-card">
                    <p>
                        <i *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_EDUCATION'])"
                           class="far fa-edit float-right text-success"></i>
                        <i *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_EDUCATION'])"
                           class="far fa-trash-alt float-right text-danger mr-2"
                           id="deleteEducationButton"></i>
                    </p>
                    <p><b>{{'university' | translate}}&nbsp;:&nbsp;</b>{{education?.university.value}}</p>
                    <p><b>{{'faculty' | translate}}&nbsp;:&nbsp;</b>{{education?.faculty.value}}</p>
                    <p><b>{{'department' | translate}}&nbsp;:&nbsp;</b>{{education?.department.value}}</p>
                    <p><b>{{'beginningDate' | translate}}&nbsp;:&nbsp;</b>{{education?.startYear}}</p>
                    <p><b>{{'endingDate' | translate}}&nbsp;:&nbsp;</b>{{education?.endYear}}</p>
                    <p><b>{{'gpa' | translate}}&nbsp;:&nbsp;</b>{{education?.grade}}</p>
                    <div class="dropdown-divider"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_LANGUAGES','READ_CANDIDATE_LANGUAGE'])"
     class="container-fluid">
    <div class="row mt-1">
        <div class="col-12">
            <div class="row">
                <p class="col" style="border-bottom: 3px solid #f6f6f6"></p>
            </div>
        </div>
    </div>
</div>
<div *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_LANGUAGES','READ_CANDIDATE_LANGUAGE'])"
     class="container-fluid mt-1">
    <div class="row">
        <div class="col-12 pl-5">
            <p><b>{{'languages' | translate}}</b><i (click)="openAddLanguage()"
                                                    *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE_LANGUAGE'])"
                                                    [title]="'add' | translate"
                                                    class="fas fa-plus-circle ml-2 cursor-pointer text-info"></i></p>
            <div class="row">
                <div (click)="openEditLanguage(lang,$event)" *ngFor="let lang of languages;"
                     class="col-xl-4 col-lg-4 col-md-4 col-sm-12 edit-card">
                    <p>
                        <i *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_LANGUAGE'])"
                           class="far fa-edit float-right text-success"></i>
                        <i *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_LANGUAGE'])"
                           class="far fa-trash-alt float-right text-danger mr-2"
                           id="deleteLanguageButton"></i>
                    </p>
                    <p><b>{{lang.language.value}}</b></p>
                    <p>{{'reading' | translate}}&nbsp;:&nbsp;{{lang.readingLevel}}</p>
                    <p>{{'writing' | translate}}&nbsp;:&nbsp;{{lang.writingLevel}}</p>
                    <p>{{'speaking' | translate}}&nbsp;:&nbsp;{{lang.speakingLevel}}</p>
                    <div class="dropdown-divider"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_CERTIFICATIONS','READ_CANDIDATE_CERTIFICATION'])"
     class="container-fluid">
    <div class="row mt-1">
        <div class="col-12">
            <div class="row">
                <p class="col" style="border-bottom: 3px solid #f6f6f6"></p>
            </div>
        </div>
    </div>
</div>
<div *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_CERTIFICATIONS','READ_CANDIDATE_CERTIFICATION'])"
     class="container-fluid mt-1">
    <div class="row">
        <div class="col-12 pl-5">
            <p><b>{{'certificates' | translate}}</b><i (click)="openAddCertificate()"
                                                       *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE_CERTIFICATION'])"
                                                       [title]="'add' | translate"
                                                       class="fas fa-plus-circle ml-2 cursor-pointer text-info"></i></p>
            <div class="row">
                <div (click)="openEditCertificate(certificate,$event)" *ngFor="let certificate of certifications;"
                     class="col-xl-4 col-lg-4 col-md-4 col-sm-12 edit-card">
                    <p>
                        <i *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_CERTIFICATION'])"
                           class="far fa-edit float-right text-success"></i>
                        <i *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_CERTIFICATION'])"
                           class="far fa-trash-alt float-right text-danger mr-2"
                           id="deleteCertificateButton"></i>
                    </p>
                    <p>{{'certificateTitle' | translate}}&nbsp;:&nbsp;{{certificate.name}}</p>
                    <p>{{'organisation' | translate}}&nbsp;:&nbsp;{{certificate.organisation}}</p>
                    <p>{{'issueDate'}}&nbsp;:&nbsp;{{certificate.issueMonth}}
                        <span *ngIf="certificate.issueMonth && certificate.issueYear">/</span>
                        {{certificate.issueYear}}</p>
                    <p>{{'expirationDate'}}&nbsp;:&nbsp;{{certificate.expirationMonth}}
                        <span *ngIf="certificate.expirationMonth && certificate.expirationYear">/</span>
                        {{certificate.expirationYear}}
                    </p>
                    <div class="dropdown-divider"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_TECHNICAL_SKILLS'])" class="container-fluid">
    <div class="row mt-1">
        <div class="col-12">
            <div class="row">
                <p class="col" style="border-bottom: 3px solid #f6f6f6"></p>
            </div>
        </div>
    </div>
</div>
<div *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_SOFT_SKILLS'])"
     class="container-fluid mt-1 mb-5">
    <div class="row">
        <div class="col-12 pl-5">
            <p><b>{{'softSkill' | translate}}</b><i
                    (click)="openSoftSkillOperation()"
                    *ngIf="permissionService.permissionsOnly(['UPDATE_ALL_CANDIDATE_SOFT_SKILLS'])"
                    [title]="'add' | translate"
                    class="far fa-edit ml-2 cursor-pointer text-info"></i>
            </p>
            <div class="row">
                <div class="col-12">
                    <span>{{parsedSoftSkill}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_TECHNICAL_SKILLS'])"
     class="container-fluid mt-1 mb-5">
    <div class="row">
        <div class="col-12 pl-5">
            <p><b>{{'technicalSkill' | translate}}</b><i
                    (click)="openTechnicalSkillOperation()"
                    *ngIf="permissionService.permissionsOnly(['UPDATE_ALL_CANDIDATE_TECHNICAL_SKILLS'])"
                    [title]="'add' | translate"
                    class="far fa-edit ml-2 cursor-pointer text-info"></i>
            </p>
            <div class="row">
                <div class="col-12">
                    <span>{{parsedTechnicalSkill}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<app-add-candidate-education (updateEducations)="getEducation()"
                             *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE_EDUCATION'])"
                             [candidateId]="candidateId"></app-add-candidate-education>
<app-edit-candidate-education (updateEducations)="getEducation()"
                              *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_EDUCATION'])"
                              [candidateId]="candidateId"
                              [education]="selectedEducation"></app-edit-candidate-education>
<app-delete-candidate-education (updateEducations)="getEducation()"
                                *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_EDUCATION'])"
                                [candidateId]="candidateId"
                                [education]="selectedEducation"></app-delete-candidate-education>
<app-add-candidate-language (updateLanguages)="getLanguages()"
                            *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE_LANGUAGE'])"
                            [candidateId]="candidateId"></app-add-candidate-language>
<app-edit-candidate-language *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_LANGUAGE'])"
                             [candidateId]="candidateId"
                             [selectedLanguage]="selectedLanguage"></app-edit-candidate-language>
<app-delete-candidate-language (updateLanguages)="getLanguages()"
                               *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_LANGUAGE'])"
                               [candidateId]="candidateId"
                               [selectedLanguage]="selectedLanguage"></app-delete-candidate-language>
<app-add-candidate-certificate (updateCertificates)="getCertifications()"
                               [candidateId]="candidateId"></app-add-candidate-certificate>
<app-edit-candidate-certificate *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_CERTIFICATION'])"
                                [candidateId]="candidateId"
                                [certificate]="selectedCertificate"></app-edit-candidate-certificate>
<app-delete-candidate-certificate (updateCertificates)="getCertifications()"
                                  *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_CERTIFICATION'])"
                                  [candidateId]="candidateId"
                                  [certificate]="selectedCertificate"></app-delete-candidate-certificate>
<app-technical-skill-operations (updateTechnicalSkills)="getTechnicalSkills()"
                                *ngIf="permissionService.permissionsOnly(['UPDATE_ALL_CANDIDATE_TECHNICAL_SKILLS'])"
                                [candidateId]="candidateId"
                                [technicalSkills]="parsedTechnicalSkill"></app-technical-skill-operations>
<app-soft-skill-operations (updateSoftSkills)="getSoftSkills()"
                           *ngIf="permissionService.permissionsOnly(['UPDATE_ALL_CANDIDATE_TECHNICAL_SKILLS'])"
                           [candidateId]="candidateId"
                           [softSkills]="parsedSoftSkill"></app-soft-skill-operations>
