import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../../services/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../../../services/notification/notification.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {server} from '../../../../services/server';

@Component({
    selector: 'app-add-new-item-with-search',
    templateUrl: './add-new-item-with-search.component.html',
    styleUrls: ['./add-new-item-with-search.component.scss']
})
export class AddNewItemWithSearchComponent implements OnInit {

    @Input() fieldName: string;
    @Output() emitNewItem = new EventEmitter();
    @Input() newItem: string;
    languageCode: string;

    constructor(private authService: AuthService,
                private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.languageCode = localStorage.getItem('lang');
    }

    ngOnInit(): void {
    }

    closeAddItemModal(): void {
        // @ts-ignore
        $('#addItem').modal('hide');
    }

    saveItem(): void {
        const requestUrl = this.setRequestUrl();

        const obj = {};
        obj[this.languageCode] = {
            language: this.languageCode,
            name: this.newItem
        };

        const obj2 = {
            localizations: obj
        };

        this.authService.ServerPost(requestUrl, obj2)
            .subscribe(res => {
                this.emitNewItem.emit(res);
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.closeAddItemModal();
                this.spinner.hide();
            });
    }

    setRequestUrl(): string {
        let requestUrl = '';
        switch (this.fieldName) {
            case 'university':
                requestUrl = server.universities;
                break;
            case 'faculty':
                requestUrl = server.faculties;
                break;
            case 'department':
                requestUrl = server.departments;
                break;
            case 'industry':
                requestUrl = server.industries;
                break;
        }
        return requestUrl;
    }

}
