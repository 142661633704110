import {Component, Input, OnInit} from '@angular/core';
import {GeneralModel} from '../../../../positions/position-operations/general/general.model';
import {Router} from '@angular/router';

@Component({
    selector: 'app-client-position-request-details-modal',
    templateUrl: './client-position-request-details-modal.component.html',
    styleUrls: ['./client-position-request-details-modal.component.scss']
})
export class ClientPositionRequestDetailsModalComponent implements OnInit {

    @Input() positionDetails: GeneralModel;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        this.positionDetails = new GeneralModel();
    }

    openCreatePosition() {
        // @ts-ignore
        $('#positionRequestDetailsModal').modal('hide');
        sessionStorage.setItem('selectedPosition', JSON.stringify(this.positionDetails));
        this.router.navigate(['/dashboard/positions', {operationType: 'clone'}]);
    }

}
