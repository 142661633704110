<div class="container-fluid"> 
    <div class="row">
        <div (click)="setTab('onListView')" [class]="!onListViewComponent ? ' gradient-bg':'top-line'"
             class="col menu-item">
            <a class="nav-link active">{{'onList' | translate}}</a>
        </div>
        <div (click)="setTab('calendarView')" [class]="!calendarViewComponent ? ' gradient-bg':'top-line'"
             class="col menu-item">
            <a class="nav-link active">{{'calendar' | translate}}</a>
        </div>
        <div (click)="setTab('interviewRequests')" *ngIf="permissionService.permissionsOnly(['READ_ALL_INTERVIEW_REQUESTS'])" [class]="!interviewRequestsViewComponent ? ' gradient-bg':'top-line'"
             class="col menu-item">
            <a class="nav-link active">{{'interviewRequests' | translate}}</a>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <app-calendar-view (getInterviews)="getInterviews($event)" *ngIf="calendarViewComponent"
                               [interviews]="interviews"></app-calendar-view>
            <app-onlist-view *ngIf="onListViewComponent"></app-onlist-view>
            <app-interview-requests *ngIf="interviewRequestsViewComponent" ></app-interview-requests>
        </div>
    </div>
</div>
