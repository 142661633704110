import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../../services/permission/permission.service';
import {NotificationService} from '../../../../../../services/notification/notification.service';
import {server} from '../../../../../../services/server';

@Component({
    selector: 'app-technical-skill-operations',
    templateUrl: './technical-skill-operations.component.html',
    styleUrls: ['./technical-skill-operations.component.scss']
})
export class TechnicalSkillOperationsComponent implements OnInit {

    @Output() updateTechnicalSkills = new EventEmitter();
    @Input() candidateId: string;
    @Input() technicalSkills: string[];
    skillsObj = [];

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
    }

    saveTechnicalSkills() {
        this.skillsObj = [];
        const object1 = Object.assign({}, this.technicalSkills);
        for (const [key, value] of Object.entries(object1)) {
            // @ts-ignore
            if (value.value) {
                // @ts-ignore
                this.skillsObj.push({name: value.value.trim()});
            } else {
                // @ts-ignore
                this.skillsObj.push({name: value.trim()});
            }
        }
        this.authService.ServerPut(server.candidates + '/' + this.candidateId + '/technical-skills', this.skillsObj)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updateTechnicalSkills.emit();
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.closeTechnicalSkillModal();
                this.spinner.hide();
            });
    }

    closeTechnicalSkillModal() {
        // @ts-ignore
        $('#candidateTechnicalSkillOperation').modal('hide');
    }

}
