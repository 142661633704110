<div class="dropdown drp">
    <button aria-expanded="false"
            aria-haspopup="true"
            class="btn dropdown-toggle col-12"
            data-toggle="dropdown"
            type="button">
    <span>
      {{dropdownName | translate}}
    </span>
    </button>
    <div aria-labelledby="multipleSelectDropdownMenuButton"
         class="dropdown-menu col-12 pb-0">
        <label *ngIf="isSearchable" class="col-12">
            <input #searchInput
                   (keyup)="search(searchInput.value)"
                   autocomplete="off"
                   autofocus
                   class="form-control border-radius-0"
                   placeholder="{{'search' | translate}}">
        </label>
        <div (click)="stopClose($event)" class="col-12 drp-menu p-0">
            <label *ngFor="let item of items" class="dropdown-item m-0 cursor-pointer">
                <input (click)="selectData($event,item)"
                       (select)="selectData($event,item)"
                       [checked]="existItemArray && existItemArray.indexOf(item.id) > -1"
                       type="checkbox">
                <span class="ml-2">{{item[translateObjectKey] | translate}}</span>
            </label>
        </div>
        <button (click)="emitData()"
                class="col-12 border-radius-0 btn btn-secondary mt-1">{{'apply' | translate}}</button>
    </div>
</div>
