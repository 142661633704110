<div class="card mt-3 pr-2" style="max-height: 360px; min-height: 360px; overflow-y: auto; border: none;">
            <p class="mb-0">{{position.name | uppercase}}&nbsp;{{'candidates' | translate | uppercase}}</p>
    <app-candidate-dashboard-view-list-item *ngFor="let candidate of candidates;"
                                            [candidate]="candidate"></app-candidate-dashboard-view-list-item>
    <app-empty-table-info *ngIf="candidates.length<=0"
                          tableInfoTranslateKey="candidateNotExistInfo"></app-empty-table-info>
    <div [hidden]="candidates.length <= maxItemCount" class="container-fluid mt-2">
        <div class="row">
            <div class="col-12">
                <p [routerLink]="['candidates']" class="text-center cursor-pointer text-info"
                   style="text-decoration: underline;">
                    <i>{{'seeAllCandidates' | translate}}</i>
                </p>
            </div>
        </div>
    </div>
</div>
