import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LanguageModel, LanguagesModel} from '../../../../../../services/parameters/models/education.model';
import {LanguageLevelList} from '../../../../../../shared/lists/language-level.list';
import {server} from '../../../../../../services/server';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../../services/permission/permission.service';
import {NotificationService} from '../../../../../../services/notification/notification.service';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-edit-candidate-language',
    templateUrl: './edit-candidate-language.component.html',
    styleUrls: ['./edit-candidate-language.component.scss']
})
export class EditCandidateLanguageComponent implements OnInit {
    isSaveClick = false;
    languages: LanguagesModel[];
    languageLevels = LanguageLevelList;
    @Input() selectedLanguage: LanguageModel;
    @Input() candidateId: string;
    @Output() updateLanguages = new EventEmitter();

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.selectedLanguage = new LanguageModel();
        this.getLanguages();
    }

    selectLanguage(e) {

        if (e.id === '') {
            this.selectedLanguage.language.id = null;
            this.selectedLanguage.language.value = null;

        } else {
            this.selectedLanguage.language.id = e.id;
            this.selectedLanguage.language.value = e.defaultLocalization.name;

        }
    }

    selectReadingLevel(e) {
        if (e.target.value === '') {
            this.selectedLanguage.readingLevel = null;
        } else {
            this.selectedLanguage.readingLevel = e.target.value;
        }
    }

    selectWritingLevel(e) {
        if (e.target.value === '') {
            this.selectedLanguage.writingLevel = null;
        } else {
            this.selectedLanguage.writingLevel = e.target.value;
        }
    }

    selectSpeakingLevel(e) {
        if (e.target.value === '') {
            this.selectedLanguage.speakingLevel = null;
        } else {
            this.selectedLanguage.speakingLevel = e.target.value;
        }
    }

    getLanguages() {
        this.authService.ServerGet(server.languages)
            .subscribe((res: { content: [] }) => {
                this.languages = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    saveCandidateLanguage(editCandidateLanguage: NgForm) {
        this.isSaveClick = true;
        if (!editCandidateLanguage.valid) {
            this.notification.warning(this.translate.transform('fillRequiredFields', []));
            return;
          }
        this.authService.ServerPut(server.candidates + '/' + this.candidateId + '/languages/' + this.selectedLanguage.id, this.selectedLanguage)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updateLanguages.emit();
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.closeEditLanguageModal();
                this.spinner.hide();
                this.isSaveClick = false;
            });
    }

    closeEditLanguageModal() {
        // @ts-ignore
        $('#editCandidateLanguage').modal('hide');
    }


}
