import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {HrManagerRegisterModel} from './hr-manager-register.model';
@Component({
  selector: 'app-hr-manager-register',
  templateUrl: './hr-manager-register.component.html',
  styleUrls: ['./hr-manager-register.component.scss']
})
export class HrManagerRegisterComponent implements OnInit { 
  @Output() goBackEvent = new EventEmitter<string>()
  @Output() saveInfoEvent = new EventEmitter<string>();
  @Input() activeSection: string
  @Input()  registerHrManager: HrManagerRegisterModel;
    typeOfComponent:string = 'hr-manager';
  constructor() { 
      
  }

  ngOnInit(): void {
  }

  goBack() {
    this.goBackEvent.emit(this.typeOfComponent)
  }
  saveInfo(): void {
    this.saveInfoEvent.emit(this.typeOfComponent);
}

}
