<div class="container-fluid mt-3 pl-0">
    <div class="row">
        <div class="col-auto mb-1">
            <button (click)="addClientUser()"
                    class="btn btn-blue border-radius-0 float-right exactalent-button width-100"
                    data-target="#addUserCenter"
                    data-toggle="modal">
                {{'addClientUser' | translate}}
            </button>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-bg">
                    <thead class="table-bg">
                    <tr>
                        <th scope="col">{{'name' | translate}}</th>
                        <th scope="col">{{'email' | translate}}</th>
                        <th scope="col">{{'role' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr (click)="openRowModal($event,clientUser)"
                        *ngFor="let clientUser of clientUsers | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count }"
                        class="custom-table-row">
                        <td>{{clientUser.fullName}}</td>
                        <td>{{clientUser.email}}</td>
                        <td>{{clientUser.role}}</td>
                    </tr>
                    <tr [hidden]="count>0">
                        <td colspan="3">
                            <app-empty-table-info
                                    tableInfoTranslateKey="clientUserNotExistInfo"></app-empty-table-info>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="width-100">
                        <td class="text-center" colspan="3">
                            <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
                                                 [autoHide]="count <= requestPayload.limit"
                                                 nextLabel="{{'next' | translate}}"
                                                 previousLabel="{{'previous' | translate}}"
                                                 responsive="true"></pagination-controls>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>

<div (clickOutside)="onClickedOutside($event)" class="operation-list"
     id="row-modal">
    <ul>
        <li (click)="openEditUser()" class="mt-2 operation-list-item">
            <span>{{'edit' | translate}}</span>
        </li>
        <li (click)="openDeleteUser()" class="mt-2 operation-list-item">
            <span>{{'delete' | translate}}</span>
        </li>
    </ul>
</div>


<app-add-client-user (emitUpdateTable)="getClientUsers(1)" [clientId]="clientId"></app-add-client-user>
<app-edit-client-user (emitUpdateTable)="getClientUsers(1)" [clientId]="clientId"
                      [clientUser]="selectedClientUser"></app-edit-client-user>
<app-delete-client-user (emitUpdateTable)="getClientUsers(1)" [clientId]="clientId"
                        [clientUser]="selectedClientUser"></app-delete-client-user>
