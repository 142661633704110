<div class="container-fluid">
    <div class="row table-bg" style="border-top: 12px #f6f6f6">
        <div (click)="openPipelineUserList(step,$event)" *ngFor="let step of pipelineSteps"
             class="cursor-pointer table-row col-12">
            <div>
                <span class="badge badge-light float-left rounded-circle step-badge p-1">{{step.candidateCount}}</span>
                <span class="float-left step-name">{{step.recruitmentStepName}}</span>
                <i class="fa fa-caret-down float-right"></i>
            </div>
            <div [id]="'stepId_' + step.recruitmentStepId" class="step-table-div">
                <app-pipeline-user-list (openPipelineCandidateStepChange)="openChangePipelineCandidateStep($event)"
                                        (openPipelineCandidateStepNote)="openStepNote($event)"
                                        (updateCandidates)="getStepUsers($event)"
                                        [candidates]="candidates"
                                        [count]="count"></app-pipeline-user-list>
            </div>
        </div>
    </div>
</div>
<app-pipeline-step-note [candidate]="selectedCandidate"></app-pipeline-step-note>
<app-pipeline-change-candidate-step (updateStepInfo)="getRecruitmentPreferences()"
                                    [candidate]="selectedCandidate"></app-pipeline-change-candidate-step>