<div class="row">
    <div class="col-auto mb-1">
        <app-pool-list-filter (applyFilterOperation)="applyFilter($event)"></app-pool-list-filter>
    </div>
    <div *ngIf="permissionService.permissionsOnly(['CREATE_POOL'])" class="col-auto mb-1">
        <button (click)="addPoolModal()"
                class="btn btn-blue border-radius-0 float-right exactalent-button width-100"
                data-target="#addUserCenter"
                data-toggle="modal">
            {{'addNewPool' | translate}}
        </button>
    </div>
</div>
<div class="row mt-2">
    <div class="col-12">
        <div class="table-responsive">
            <table class="table table-bg">
                <thead class="table-bg">
                <tr>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">{{'name' | translate}}</th>
                    <th scope="col">{{'country' | translate}}</th>
                    <th scope="col">{{'city' | translate}}</th>
                    <th scope="col">{{'category' | translate}}</th>
                    <th scope="col">{{'added' | translate}}</th>
                    <th scope="col">{{'lastActivity' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr (click)="openRowModal($event,pool)"
                    *ngFor="let pool of pools | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count }"
                    class="pool-row">
                    <th scope="row">
            <span (click)="openPoolStatusModal(pool)" class="btn-group mr-2"
                  role="group" style="cursor: pointer;">
                <p [class]="pool.status" class="pool-status-color-icon">
                </p>&nbsp;&nbsp;
                <p><i class="fa fa-caret-down"></i></p>
            </span>
                    </th>
                    <td>{{pool.name}}</td>
                    <td>{{pool.country?.value}}</td>
                    <td>{{pool.city?.value}}</td>
                    <td>{{pool.category?.value}}</td>
                    <td>
                        <app-user [userId]="pool.createdBy"></app-user>
                    </td>
                    <td>{{pool.updatedAt | date}}</td>
                </tr>
                <tr [hidden]="count>0">
                    <td colspan="7">
                        <app-empty-table-info tableInfoTranslateKey="poolNotExistInfo"></app-empty-table-info>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="width-100">
                    <td class="text-center" colspan="7">
                        <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
                                             [autoHide]="count <= requestPayload.limit"
                                             nextLabel="{{'next' | translate}}"
                                             previousLabel="{{'previous' | translate}}"
                                             responsive="true"></pagination-controls>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
<app-add-pool (setPoolScreen)="getPools('1')" *ngIf="permissionService.permissionsOnly(['CREATE_POOL'])"></app-add-pool>
<app-edit-pool (setPoolScreen)="getPools('1')" *ngIf="permissionService.permissionsOnly(['UPDATE_POOL'])"
               [pool]="selectedPool"></app-edit-pool>
<app-delete-pool (setPoolScreen)="getPools('1')" *ngIf="permissionService.permissionsOnly(['DELETE_POOL'])"
                 [pool]="selectedPool"></app-delete-pool>
<app-change-pool-status-modal *ngIf="permissionService.permissionsOnly(['UPDATE_POOL_STATUS'])"
                              [pool]="selectedPool"></app-change-pool-status-modal>

<div (clickOutside)="onClickedOutside($event)" *ngIf="permissionService.permissionsOnly(['READ_POOL','UPDATE_POOL','DELETE_POOL'])" class="operation-list"
     id="row-modal">
    <ul>
        <li (click)="openPoolDetails()" *ngIf="permissionService.permissionsOnly(['READ_POOL'])"
            class="operation-list-item">
            <span>{{'details' | translate}}</span>
        </li>
        <li (click)="openEditPool()" *ngIf="permissionService.permissionsOnly(['UPDATE_POOL'])"
            class="mt-2 operation-list-item">
            <span>{{'edit' | translate}}</span>
        </li>
        <li (click)="openDeletePool()" *ngIf="permissionService.permissionsOnly(['DELETE_POOL'])"
            class="mt-2 operation-list-item">
            <span>{{'delete' | translate}}</span>
        </li>
    </ul>
</div>
