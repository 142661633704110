<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-12 pl-5">
            <p>
                <b>{{'clientGroup' | translate}}&nbsp;:&nbsp;</b>{{(generalInfo.clientGroup !== null ? generalInfo.clientGroup : 'unspecified') | translate}}
            </p>
            <p>
                <b>{{'industry' | translate}}&nbsp;:&nbsp;</b>{{(generalInfo.industry !== null ? generalInfo.industry.value : 'unspecified') | translate}}
            </p>
            <p>
                <b>{{'service' | translate}}&nbsp;:&nbsp;</b>{{(generalInfo.service !== null ? generalInfo.service : 'unspecified') | translate}}
            </p>
            <p>
                <b>{{'accountManagers' | translate}}&nbsp;:&nbsp;</b>
                <span *ngFor="let accountManager of generalInfo.accountManagers; let i = index;">{{accountManager.name}}
                    <span *ngIf="generalInfo.accountManagers.length>1 && i < generalInfo.accountManagers.length - 1">,&nbsp;</span>
                </span>
            </p>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row mt-1">
        <div class="col-12">
            <div class="row">
                <p class="col" style="border-bottom: 3px solid #f6f6f6"></p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-12 mt-2 pl-5 ">
            <p (click)="openEditClient()"
               class="cursor-pointer"
               style="color: #353535; text-decoration: underline #353535">{{'editClientInfo' | translate}}</p>
        </div>
    </div>
</div>

<div *ngIf="permissionService.permissionsOnly(['READ_ALL_CLIENT_CONTACT_PERSONS','READ_CLIENT_CONTACT_PERSON'])"
     class="container-fluid mt-4">
    <div class="row">
        <div class="col-12 pl-5">
            <p><b>{{'contactPerson' | translate}}</b><i (click)="openAddContactPerson()"
                                                        *ngIf="permissionService.permissionsOnly(['CREATE_CONTACT_PERSON'])"
                                                        [title]="'add' | translate"
                                                        class="fas fa-plus-circle ml-2 cursor-pointer text-info"></i>
            </p>
            <div class="row">
                <div (click)="openEditContactPerson(contactPerson,$event)" *ngFor="let contactPerson of contactPersons"
                     class="col-xl-4 col-lg-4 col-md-4 col-sm-12 edit-card">
                    <p>
                        <i *ngIf="permissionService.permissionsOnly(['UPDATE_CONTACT_PERSON'])"
                           class="far fa-edit float-right text-success"></i>
                        <i *ngIf="permissionService.permissionsOnly(['DELETE_CONTACT_PERSON'])" class="far fa-trash-alt float-right text-danger mr-2"
                           id="deleteContactPersonButton"></i>
                    </p>
                    <p><b>{{'name' | translate}}&nbsp;:&nbsp;</b>{{contactPerson.firstName+' '+contactPerson.middleName+' '+contactPerson.lastName}}</p>
                    <p><b>{{'title' | translate}}&nbsp;:&nbsp;</b>{{contactPerson.title}}</p>
                    <p><b>{{'email' | translate}}&nbsp;:&nbsp;</b>{{contactPerson.email}}</p>
                    <p><b>{{'phone' | translate}}&nbsp;:&nbsp;</b>{{contactPerson.phone}}</p>
                    <p><b>{{'mobile' | translate}}&nbsp;:&nbsp;</b>{{contactPerson.mobile}}</p>
                    <div class="dropdown-divider"></div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-add-contact-person (updateContactPersons)="getContactPersons()"
                        *ngIf="permissionService.permissionsOnly(['CREATE_CONTACT_PERSON'])"
                        [clientId]="generalInfo.id"></app-add-contact-person>
<app-edit-contact-person (updateContactPersons)="getContactPersons()"
                         *ngIf="permissionService.permissionsOnly(['UPDATE_CONTACT_PERSON'])"
                         [clientId]="generalInfo.id" [contactPerson]="selectedContactPerson"></app-edit-contact-person>
<app-delete-contact-person (updateContactPersons)="getContactPersons()" *ngIf="permissionService.permissionsOnly(['DELETE_CONTACT_PERSON'])"
                           [clientId]="generalInfo.id"
                           [contactPerson]="selectedContactPerson"></app-delete-contact-person>
