import {Component} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {authPasswordFlowConfig} from './shared/config/oauth.config';
import {AuthService} from './services/auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'Exactalent';

    constructor(private authService: AuthService, private oauthService: OAuthService) {
        this.authService.setLanguage('');
        this.oauthService.configure(authPasswordFlowConfig);
    }
}
