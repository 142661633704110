import {CurrencyModel} from '../../services/parameters/models/currency.model';
import {CountryModel} from '../../services/parameters/models/country.model';
import {CityModel} from '../../services/parameters/models/city.model';
import {IndustryModel} from '../../services/parameters/models/industry.model';
import {NationalityModel} from '../../services/parameters/models/nationality.model';
import {StepModel} from './edit-candidate/candidate-profile-operations/candidate-change-step/step.model';

export class CandidateModel {
    id: string;
    status: string;
    source: string;
    primaryEmail: string;
    secondaryEmail: string;
    firstName: string;
    fullName: string;
    middleName: string;
    lastName: string;
    primaryPhone: string;
    secondaryPhone: string;
    dateOfBirth: string;
    country: CountryModel;
    city: CityModel;
    address: string;
    workingStatus: string;
    jobSearchStatus: string;
    jobSearchStatusUpdatedAt: string;
    minSalaryExpectation: number;
    maxSalaryExpectation: number;
    salaryExpectationUpdatedAt: string;
    currency: CurrencyModel;
    preferredCountry: CountryModel;
    preferredCity: CityModel;
    preferredIndustry: IndustryModel;
    preferredTitle: string;
    preferredJobType: string;
    outsourcePreference: boolean;
    gender: string;
    militaryService: string;
    dateOfPostponement: string;
    maritalStatus: string;
    nationality: NationalityModel;
    drivingLicence: boolean;
    smoking: boolean;
    disabilityOption: boolean;
    facebookAccount: string;
    twitterAccount: string;
    instagramAccount: string;
    linkedinAccount: string;
    youtubeAccount: string;
    behanceAccount: string;
    stackoverflowAccount: string;
    githubAccount: string;
    updatedAt: string;
    createdBy: string;
    totalExperience: {
        years: number;
        months: number;
    };
    pipelineSteps: StepModel;
    clientHrPermitted: boolean;
    clientTechnicalPermitted: boolean;
    hideSensitiveDataForClient: boolean;


    constructor() {
        this.country = new CountryModel();
        this.preferredCountry = new CountryModel();
        this.city = new CityModel();
        this.preferredCity = new CityModel();
        this.preferredIndustry = new IndustryModel();
        this.nationality = new NationalityModel();
        this.currency = new CurrencyModel();
        this.pipelineSteps = new StepModel();
        this.workingStatus = '';
        this.jobSearchStatus = '';
        this.preferredJobType = '';
        this.militaryService = '';
        this.minSalaryExpectation = 0;
        this.maxSalaryExpectation = 0;
        this.clientTechnicalPermitted = false;
        this.clientHrPermitted = false;
        this.hideSensitiveDataForClient = false;
    }
}

export class CandidateFilterModel {
    id: number;
    name: string;
    country: string;
    city: string;
    step: string;
    source: string;
    users: string;
    status: string;
    lastActivity: string;

    constructor() {
        this.country = '';
        this.city = '';
        this.name = '';
        this.step = '';
        this.source = '';
        this.users = '';
        this.status = '';
        this.lastActivity = '';
    }
}

