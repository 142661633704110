import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../../../services/auth/auth.service';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';
import {CandidateModel} from '../../candidate.model';
import {StatusList} from '../../../../shared/lists/status.list';

@Component({
    selector: 'app-candidate-status-modal',
    templateUrl: './candidate-status-modal.component.html',
    styleUrls: ['./candidate-status-modal.component.scss']
})
export class CandidateStatusModalComponent implements OnInit {

    @Input() candidate: CandidateModel;

    candidateStatusList = StatusList;
    tempCandidateStatus: string;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.candidate = new CandidateModel();
    }

    selectCandidateStatus(e) {
        this.tempCandidateStatus = e.target.value;
    }

    updateStatus() {

        const obj = {
            status: this.tempCandidateStatus
        };
        this.authService.ServerPut(server.candidates + '/' + this.candidate.id + '/status', obj)
            .subscribe(res => {
                this.candidate.status = this.tempCandidateStatus;
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#candidateStatusModal').modal('hide');
                this.spinner.hide();
            });
    }

}
