import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PositionFilterModel} from '../../position.model';
import {CountryModel} from '../../../../services/parameters/models/country.model';
import {ClientModel} from '../../../clients/client.model';
import {UserModel} from '../../../user-management/user.model';
import {CityModel} from '../../../../services/parameters/models/city.model';
import {PositionStatusList} from '../../../../shared/lists/position-status.list';
import {JobTypeList} from '../../../../shared/lists/job-type.list';
import {PermissionService} from '../../../../services/permission/permission.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../../../services/notification/notification.service';

@Component({
    selector: 'app-position-list-filter',
    templateUrl: './position-list-filter.component.html',
    styleUrls: ['./position-list-filter.component.scss']
})
export class PositionListFilterComponent implements OnInit {

    countries: CountryModel[];
    cities: CityModel[] = [];
    clients: ClientModel[] = [];
    companyUsers: UserModel[] = [];

    positionStatusList = PositionStatusList;
    positionTypes = JobTypeList;
    filterState: boolean;

    positionFilter: PositionFilterModel;
    filterData: PositionFilterModel;
    @Output() applyFilterOperation = new EventEmitter();

    constructor(public permissionService: PermissionService, private authService: AuthService,
                private spinner: NgxSpinnerService, private parameterService: ParameterService, private route: ActivatedRoute,
                private notification: NotificationService) {
        this.positionFilter = new PositionFilterModel();
    }

    ngOnInit(): void {
        this.getClients();
        this.getCompanyUsers();
    }

    getCountries() {
        this.parameterService.countries.subscribe((res: CountryModel[]) => {
            this.countries = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getClients() {
        this.parameterService.getAllClients().subscribe((res: ClientModel[]) => {
            this.clients = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCompanyUsers() {
        this.parameterService.companyUsers.subscribe((res: UserModel[]) => {
            this.companyUsers = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCountryCities(countryId) {
        this.cities = [];
        this.parameterService.getCountryCities(countryId).subscribe((res: CityModel[]) => {
            this.cities = res;
        }, err => {
            if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    selectStatus(selectedStatusList) {
        this.positionFilter.status = '';
        for (const selectedStatus of selectedStatusList) {
            if (this.positionFilter.status === '') {
                this.positionFilter.status = (selectedStatus).value;
            } else {
                this.positionFilter.status = this.positionFilter.status + ',' + selectedStatus.value;
            }
        }
    }

    selectCountry(e) {
        if (e.target.value !== 'all') {
            this.positionFilter.country.id = e.target.value;
            this.getCountryCities(this.positionFilter.country.id);
        } else {
            this.positionFilter.country.id = '';
        }
    }

    selectCity(selectedCityList) {
        this.positionFilter.city.id = '';
        for (const selectedCity of selectedCityList) {
            if (this.positionFilter.city.id === '') {
                this.positionFilter.city.id = selectedCity.id;
            } else {
                this.positionFilter.city.id = this.positionFilter.city.id + ',' + selectedCity.id;
            }
        }
    }

    selectCompanyUser(selectedUserList) {
        this.positionFilter.users = '';
        for (const selectedUser of selectedUserList) {
            if (this.positionFilter.users === '') {
                this.positionFilter.users = selectedUser.id;
            } else {
                this.positionFilter.users = this.positionFilter.users + ',' + selectedUser.id;
            }
        }
    }

    selectPositionType(selectedPositionTypeList) {
        this.positionFilter.positionType = '';
        for (const selectedPositionType of selectedPositionTypeList) {
            if (this.positionFilter.positionType === '') {
                this.positionFilter.positionType = (selectedPositionType).value;
            } else {
                this.positionFilter.positionType = this.positionFilter.positionType + ',' + selectedPositionType.value;
            }
        }
    }

    selectClient(selectedClientList) {
        this.positionFilter.client = '';
        for (const selectedClient of selectedClientList) {
            if (this.positionFilter.client === '') {
                this.positionFilter.client = selectedClient.id;
            } else {
                this.positionFilter.client = this.positionFilter.client + ',' + selectedClient.id;
            }
        }
    }

    closeFilterPopup(e) {
        if (e.target.id !== 'filterButton') {
            this.filterState = false;
        }
    }


    applyFilter() {
        if (JSON.stringify(this.filterData) === JSON.stringify(this.positionFilter)) {
            return false;
        }
        this.filterData = new PositionFilterModel();
        this.filterData = {...this.positionFilter};
        this.filterState = false;
        this.applyFilterOperation.emit(this.filterData);
    }

}
