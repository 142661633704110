<!--Delete candidate education Modal -->
<div aria-hidden="true" aria-labelledby="deleteCandidateEducationTitle" class="modal fade" id="deleteCandidateEducation"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title" id="deleteUserLongTitle">
                    {{ "deleteCandidateEducation" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="university">{{"university" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="education.university.value" class="form-control border-radius-0" disabled id="university"
                                       type="text">
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="faculty">{{"faculty" | translate}}<span class="text-danger">*</span></label>
                                <input [value]="education.faculty.value" class="form-control border-radius-0" disabled id="faculty"
                                       type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="department">{{"department" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="education.department.value" class="form-control border-radius-0" disabled id="department"
                                       type="text">
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="educationDegree">{{"educationDegree" | translate}}<span class="text-danger">*</span></label>
                                <input [value]="education.degree" class="form-control border-radius-0" disabled id="educationDegree"
                                       type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="beginningEndingDate">
                                    {{"beginningEndingDate" | translate}}
                                </label>
                                <div class="container-fluid">
                                    <div class="row" id="beginningEndingDate">
                                        <input [value]="education.startYear" class="form-control col border-radius-0"
                                               disabled id="startYear"
                                               name="startYear" placeholder="{{'startYear' | translate}}..."
                                               type="number"/>&nbsp;-&nbsp;
                                        <input [value]="education.endYear" class="form-control col border-radius-0"
                                               disabled id="endYear"
                                               name="endYear" placeholder="{{ 'endYear' | translate }}..."
                                               type="number"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="gpa">{{"gpa" | translate}}</label>
                                <input [value]="education.grade" class="form-control border-radius-0" disabled id="gpa" max="100"
                                       min="0" type="number">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closeDeleteCandidateEducationModal()" class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="deleteCandidateEducation()" class="btn btn-danger border-radius-0" type="button">
                    {{ "delete" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
