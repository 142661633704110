import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {TranslatePipe} from '../../pipes/translate/translate.pipe';
import {Items} from '../../shared/appItems';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../notification/notification.service';
import {server} from '../server';
import {ServerErrorList} from '../../shared/lists/serverErrorList';
import { onErrorResumeNextStatic } from 'rxjs/internal/operators/onErrorResumeNext';
import { fontFamily } from 'html2canvas/dist/types/css/property-descriptors/font-family';

@Injectable()
export class AuthService {

    connErrorImage = Items.connError;
    TOKEN_KEY = 'access_token';
    lang = localStorage.getItem('lang');
    headerWithoutToken = new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Cache-Control', ' no-cache')
        .set('Content-Type', 'application/json')
        .set('Accept-Language', this.lang);

    constructor(private http: HttpClient,
                private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private notification: NotificationService,
                private router: Router) {
    }

    get companyId(): string {
        return localStorage.getItem('companyId');
    }

    get myUserId(): string {
        return localStorage.getItem('userId');
    }

    setHeadersWithToken() {
        const token = localStorage.getItem(this.TOKEN_KEY);
        let headersWithToken: HttpHeaders;
        headersWithToken = new HttpHeaders()
            .set('Accept', 'application/json')
            .set('Cache-Control', ' no-cache')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', this.lang)
            .set('Authorization', 'Bearer ' + token);
        return headersWithToken;
    }

    ServerGet(address: string) {
        this.spinner.show();
        const headers = this.setHeadersWithToken();
        const options = {headers};
        return this.http.get(address, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }));
    }

    ServerPost(address: string, data: any) {
        this.spinner.show();
        const headers = this.setHeadersWithToken();
        const options = {headers};
        return this.http.post(address, data, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }));
    }

    ServerPut(address: string, data: any) {
        this.spinner.show();
        const headers = this.setHeadersWithToken();
        const options = {headers};
        return this.http.put(address, data, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }));
    }

    ServerDelete(address: string) {
        this.spinner.show();
        const headers = this.setHeadersWithToken();
        const options = {headers};
        return this.http.delete(address, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }));
    }

    ServerGetWithoutLogin(address: string) {
        this.spinner.show();
        const headers = this.headerWithoutToken;
        const options = {headers};
        return this.http.get(address, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }));
    }

    ServerPostWithoutLogin(address: string, data: any) {
        this.spinner.show();
        const headers = this.headerWithoutToken;
        const options = {headers};
        return this.http.post(address, data, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }));
    }

    ClearSession() {
        this.spinner.show();
        const tempLang = localStorage.getItem('lang');
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem('lang', tempLang);
        this.router.navigate(['/']).then(r => {
            this.spinner.hide();
        });
    }

    getUserPermissionsFromEndpoint() {
        this.ServerGet(server.me + '/permissions ')
            .subscribe((res: any[]) => {
                const perm = res;
                perm.push(JSON.parse(window.atob((this.getToken()).split('.')[1])).authorities[0]);

                localStorage.setItem('permissions', JSON.stringify(res));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                window.location.reload();
            });
    }

    getSessionLanguage() {
        if (!localStorage.getItem('lang')) {
            return JSON.parse(window.atob((this.getToken()).split('.')[1])).preferred_language;
        }
        return localStorage.getItem('lang');
    }

    setLanguage(lang) {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'en');
            window.location.reload();
        } else {
            if (lang.trim() !== '') {
                localStorage.setItem('lang', lang);
                window.location.reload();
            } else if (this.getToken()) {
                const sessionLanguage = this.getSessionLanguage();
                localStorage.setItem('lang', sessionLanguage);
            }
        }
    }

    getToken(): string {
        return localStorage.getItem(this.TOKEN_KEY);
    }

    ServerLogout() {
        this.ClearSession();
    }

    ServerError(err: any) {
        this.spinner.hide();
        const error = err.error;
        let throwErrorCode = 'GENERAL_ERROR';

        if (err.status === 401) {
           this.notification.error(this.translate.transform('loginErrorInfo', []));
           this.ServerLogout();
        }

        if (error.code && ServerErrorList[error.code]){
            throwErrorCode = this.checkSubErrors(error);
            if (throwErrorCode) {
                error.message = throwErrorCode;
                return throwError(err.error);
            }

            throwErrorCode = this.translate.transform(ServerErrorList[error.code], []);
            error.message = throwErrorCode;
            return throwError(err.error);
            }

        error.message = throwError;
        return throwError(err.error);

        }


        checkSubErrors(error){
            let message: string;
            if (error.subErrors ){
                const subCodes =  error.subErrors.map(item => item.code);
                const subCodesCleanDublicate = Array.from(new Set(subCodes));
                subCodesCleanDublicate.forEach((item: any) => {
                  if (ServerErrorList[item]) {
                    if (!message){
                        message = this.translate.transform(ServerErrorList[item], []);
                    }else{
                        message += ', ' + this.translate.transform(ServerErrorList[item], []);
                    }
                  }
                });
                return message;
              }else{return null; }

        }


}
