import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Items} from '../../../shared/appItems';
import {ActivatedRoute, Router} from '@angular/router';
import {server} from '../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';
import {CandidateModel} from '../candidate.model';
import {TagModel} from '../../../services/parameters/models/tag.model';
import {UtilitiesService} from 'src/app/services/utilities/utilities.service';
import {ConfirmData} from '../../../shared/modal/confirm-modal/confirm-model';
import {TooltipModule} from 'ng2-tooltip-directive';

@Component({
    selector: 'app-edit-candidate',
    templateUrl: './edit-candidate.component.html',
    styleUrls: ['./edit-candidate.component.scss']
})
export class EditCandidateComponent implements OnInit {
    facebookUrl:string;
    twitterUrl:string;
    instagramUrl:string;
    linkedinUrl:string;
    youtubeUrl:string;
    behanceUrl:string;
    stackoverflowUrl:string;
    githubUrl:string;

    tempUserImg: string;
    generalComponentState: boolean;
    educationComponentState: boolean;
    experienceComponentState: boolean;
    attachmentsComponentState: boolean;
    notesComponentState: boolean;
    calendarComponentState: boolean;
    historyComponentState: boolean;
    recommendationComponentState: boolean;
    id: string;
    candidate: CandidateModel;
    tags: TagModel[];
    parsedTags: string[];
    tagList: string;
    tagObj = [];
    attachmentUrlCv: string;
    viewCv: boolean;
    confirmData: ConfirmData;
    photoApiUrl: string;
    @ViewChild('candidateCvModal', {read: ElementRef})
    candidateCvModal: ElementRef;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private spinner: NgxSpinnerService,
                private authService: AuthService,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService,
                private utilitiesService: UtilitiesService) {
        this.setTab('general');
        this.tempUserImg = Items.tempUserImg;
        this.candidate = new CandidateModel();
        this.facebookUrl="";
        this.twitterUrl="";
        this.instagramUrl="";
        this.linkedinUrl="";
        this.youtubeUrl="";
        this.behanceUrl="";
        this.stackoverflowUrl="";
        this.githubUrl="";
    }

    ngOnInit(): void {
        // @ts-ignore
        if (this.route.params.value.id && this.route.params.value.id !== '') {
            // @ts-ignore
            this.id = this.route.params.value.id;
            this.getCandidate();
            this.getCandidateTags();
        } else {
            this.router.navigate(['/dashboard/candidate']);
        }

        this.photoApiUrl = server.storageCandidate + '/' + this.id + '/profile-photo';
        this.attachmentUrlCv = server.storageCandidate + '/' + this.id + '/cv';

        this.getUserProfileImage();

    }

    checklink(link:string,site:string){
        let prefixs: string[] = ['http://' + site , 'http://www.' + site , 'https://' + site , 'https://www.' + site , site , 'www.' + site];
        let flag:boolean = false;
        prefixs.forEach(x=>{
            if(link.startsWith(x)){
                flag=true;
            }
        });
        if(flag==false){
                switch (site) {
                    case "facebook":
                    case "instagram":
                    case "twitter":
                    case "github":
                        return 'https://' + site + ".com/"+link;
                        break;
                    case "linkedin":
                        return 'https://' + site + ".com/in/"+link;
                        break;
                    case "stackoverflow":
                        return 'https://' + site + ".com/users/"+link;
                        break;
                    case "youtube":
                        return  'https://' + site + ".com/c/"+link;
                        break;
                    case "behance":
                        return  'https://' + site + ".net/"+link;
                        break;
                    default:
                        break;
                }
            
        }
        else{            
            return link;
        }
    }
    getCandidate() {
        this.authService.ServerGet(server.candidates + '/' + this.id + '/profile')
            .subscribe((res: CandidateModel) => {
                this.candidate = res;
                this.candidate.id = this.id;

              
              this.facebookUrl=this.checklink(this.candidate.facebookAccount,"facebook");
              this.twitterUrl=this.checklink(this.candidate.twitterAccount,"twitter");
              this.instagramUrl=this.checklink(this.candidate.instagramAccount,"instagram");
              this.linkedinUrl=this.checklink(this.candidate.linkedinAccount,"linkedin");
              this.youtubeUrl=this.checklink(this.candidate.youtubeAccount,"youtube");
              this.behanceUrl=this.checklink(this.candidate.behanceAccount,"behance");
              this.stackoverflowUrl=this.checklink(this.candidate.stackoverflowAccount,"stackoverflow");
              this.githubUrl=this.checklink(this.candidate.githubAccount,"github");


            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    getUserProfileImage() {
        this.authService.ServerGet(this.photoApiUrl)
            .subscribe((res: { url: string }) => {
                if (res.url && res.url !== '') {
                    this.tempUserImg = res.url;
                }

            }, err => {
                /*if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }*/
            }, () => {
                this.spinner.hide();
            });
    }

    getAttacments() {

    }

    getAttacmentById(attachment_id) {

    }

    setTab(component) {
        this.setToFalseAllComponentState();
        switch (component) {
            case 'general':
                this.generalComponentState = true;
                break;
            case 'education':
                this.educationComponentState = true;
                break;
            case 'experience':
                this.experienceComponentState = true;
                break;
            case 'attachments':
                this.attachmentsComponentState = true;
                break;
            case 'notes':
                this.notesComponentState = true;
                break;
            case 'calendar':
                this.calendarComponentState = true;
                break;
            case 'history':
                this.historyComponentState = true;
                break;
            case 'recommendation':
                this.recommendationComponentState = true;
                break;
            default:
                this.generalComponentState = true;
                break;
        }
    }

    setToFalseAllComponentState() {
        this.generalComponentState = false;
        this.educationComponentState = false;
        this.experienceComponentState = false;
        this.attachmentsComponentState = false;
        this.notesComponentState = false;
        this.calendarComponentState = false;
        this.historyComponentState = false;
        this.recommendationComponentState = false;
    }

    openUpdateStatusModal() {
        // @ts-ignore
        $('#statusModal').modal('show');
    }

    openUpdateStepModal() {
        // @ts-ignore
        $('#stepModal').modal('show');
    }

    openUpdatePositionModal() {
        // @ts-ignore
        $('#candidatePositionModal').modal('show');
    }

    openUpdatePoolModal() {
        // @ts-ignore
        $('#candidatePoolModal').modal('show');
    }

    openEditProfile() {
        // @ts-ignore
        $('#editCandidateProfile').modal('show');
    }

    openDownloadCvModal() {
        // @ts-ignore
        $('#candidateCvModal').modal('show');
    }

    getCandidateTags() {
        this.tagList = '';
        this.tagObj = [];
        this.authService.ServerGet(server.candidates + '/' + this.id + '/tags')
            .subscribe((res: any) => {
                this.tags = res;
                if (this.tags.length > 0) {
                    for (let i = 0; i < this.tags.length; i++) {
                        this.tagObj.push({
                            display: this.tags[i].name.trim(),
                            value: this.tags[i].id
                        });
                        if (i === 0) {
                            this.tagList = this.tags[i].name;
                        } else {
                            this.tagList = this.tagList + ', ' + this.tags[i].name;
                        }
                    }
                }

            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    addTag() {
        // @ts-ignore
        $('#candidateTagOperation').modal('show');
    }

    updateStepInfo() {
        // @ts-ignore
        $('#addInterviewModal').modal('show');
    }

    openCvModal() {
        // @ts-ignore
        $('#viewCv').modal('show');
    }

    openChangePhoto() {
        // @ts-ignore
        $('#photoImport').modal('show');
    }

    openDeleteCandidate() {
        const title = 'deleteCandidate';
        const messageText = this.translate.transform('removeCandidateConfirmation', []);
        const candidateName = this.candidate.fullName;
        const message = `<i class="fas fa-exclamation-triangle pr-1"></i>
                            <strong>${candidateName}</strong>, ${messageText}`;
        this.confirmData = {
            title,
            message
        };
        // @ts-ignore
        $('#confirmModal').modal('show');
    }

    openImportAttachment() {
        // @ts-ignore
        $('#importAttachment').modal('show');
    }

    deleteCandidate() {
        this.authService.ServerDelete(server.candidates + '/' + this.candidate.id)
            .subscribe(res => {
                this.notification.success(this.translate.transform('deleteSuccessful', []));
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {

                // @ts-ignore
                $('#confirmModal').modal('hide');
                this.spinner.hide();
                this.router.navigate(['/dashboard/candidates']);
            });
    }

    downloadPDF(print= 'dpwnloadAsPdf') {
        const candidateCvModal = document.getElementById('candidateCvModal');
        const content = document.getElementById('contentCvModal');
        const sidebarCvModal = document.getElementById('sidebarCvModal');
        const menuCvModal = document.getElementById('menuCvModal');


        candidateCvModal.style.display = 'block';
        content.style.width = '790px';
        content.style.fontSize = '14px';
        sidebarCvModal.style.cssText = 'padding-left: 0px;padding-right: 0px; word-break: break-all; width: 170px;';
        sidebarCvModal.classList.remove('col-md-12', 'col-lg-3');
        sidebarCvModal.classList.add('col-4');
        menuCvModal.classList.remove('col-md-12', 'col-lg-9');
        menuCvModal.classList.add('col-8');


        // download pdf doc a4 size
        this.utilitiesService.getPdfDocument(content, this.candidate.fullName, print);

        candidateCvModal.style.display = 'none';
        content.style.width = '';
        content.style.fontSize = '14px';
        sidebarCvModal.style.cssText = 'padding-left: 0px;padding-right: 0px; word-break: break-all; width: 170px;';
        sidebarCvModal.classList.add('col-md-12', 'col-lg-3');
        sidebarCvModal.classList.remove('col-4');
        menuCvModal.classList.add('col-md-12', 'col-lg-9');
        menuCvModal.classList.remove('col-8');
    }
}
