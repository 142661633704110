import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CalendarEvent} from 'angular-calendar';
import {Router} from '@angular/router';
import {Items} from '../../../../shared/appItems';

@Component({
    selector: 'app-calendar-interview-detail',
    templateUrl: './calendar-interview-detail.component.html',
    styleUrls: ['./calendar-interview-detail.component.scss']
})
export class CalendarInterviewDetailComponent implements OnInit {

    @Input() interview: CalendarEvent;
    @Output() openRemoveInterview = new EventEmitter();
    @Output() openUpdateInterview = new EventEmitter();
    tempUserImg: string;

    constructor(private router: Router) {
        this.tempUserImg = Items.tempUserImg;
    }

    ngOnInit(): void {
    }

    openCandidateDetails() {
        // @ts-ignore
        $('#calendarInterviewDetailsModal').modal('hide');
        this.router.navigate(['/dashboard/edit-candidate', {id: this.interview.meta.candidate.id}]);
    }

    openDeleteInterview() {
        // @ts-ignore
        $('#calendarInterviewDetailsModal').modal('hide');
        this.openRemoveInterview.emit(this.interview.id);
    }

    openEditInterview() {
        // @ts-ignore
        $('#calendarInterviewDetailsModal').modal('hide');
        this.openUpdateInterview.emit(this.interview);
    }

}
