<div class="container-fluid">
    <div class="row">
        <div (click)="setTab('candidate')" *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATE_POOLS'])"
             [class]="!candidateComponentState ? ' gradient-bg':'top-line'"
             class="col menu-item">
            <a class="nav-link active">{{'candidate' | translate}}</a>
        </div>
        <div (click)="setTab('history')" *ngIf="permissionService.permissionsOnly(['READ_POOL_HISTORY'])"
             [class]="!historyComponentState ? ' gradient-bg':'top-line'"
             class="col menu-item">
            <a class="nav-link active">{{'history' | translate}}</a>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row mt-4">
        <div class="col-12">
            <label class="col-auto">
            <span (click)="openPoolStatusModal()" class="btn-group mr-2"
                  role="group" style="cursor: pointer; vertical-align: unset !important;"
                  title="{{pool.status | translate}}">
                <p [class]="pool.status" class="pool-status-color-icon">
                </p>&nbsp;&nbsp;
                <p><i class="fa fa-caret-down"></i></p>
            </span> &nbsp;&nbsp;
                <span class="pool-name">
                <b>{{pool.name | uppercase}}</b>
            </span>
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-pool-candidate
                    *ngIf="candidateComponentState && permissionService.permissionsOnly(['READ_ALL_CANDIDATE_POOLS'])"
                    [poolId]="id"></app-pool-candidate>
            <app-pool-history
                    *ngIf="historyComponentState && permissionService.permissionsOnly(['READ_POOL_HISTORY'])"
                    [poolId]="id"></app-pool-history>
        </div>
    </div>
</div>
<app-change-pool-status-modal [pool]="pool"></app-change-pool-status-modal>
