import {Component, Input, OnInit} from '@angular/core';
import {ClientModel} from '../../client.model';
import {ContactPersonModel} from '../../contact-person.model';
import {server} from '../../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';

@Component({
    selector: 'app-client-general',
    templateUrl: './client-general.component.html',
    styleUrls: ['./client-general.component.scss']
})
export class ClientGeneralComponent implements OnInit {

    @Input() generalInfo: ClientModel;
    contactPersons: ContactPersonModel[];
    selectedContactPerson: ContactPersonModel;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.getContactPersons();
    }

    getContactPersons() {
        this.authService.ServerGet(server.clients + '/' + this.generalInfo.id + '/contact-persons')
            .subscribe((res: any) => {
                this.contactPersons = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    openAddContactPerson() {
        // @ts-ignore
        $('#addContactPerson').modal('show');
    }
    openEditClient() {
        // @ts-ignore
        $('#editClient').modal('show');
    }

    openEditContactPerson(contactPerson, event) {
        this.selectedContactPerson = contactPerson;
        if (event.target.id === 'deleteContactPersonButton') {
            // @ts-ignore
            $('#deleteContactPerson').modal('show');
        } else {
            // @ts-ignore
            $('#editContactPerson').modal('show');
        }
    }

}
