import {Component, Input, OnInit} from '@angular/core';
import {StatusList} from '../../../../shared/lists/status.list';
import {AuthService} from '../../../../services/auth/auth.service';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';
import {ClientModel} from '../../client.model';

@Component({
    selector: 'app-change-client-status-modal',
    templateUrl: './change-client-status-modal.component.html',
    styleUrls: ['./change-client-status-modal.component.scss']
})
export class ChangeClientStatusModalComponent implements OnInit {

    @Input() client: ClientModel;

    statusList = StatusList;
    tempStatus: string;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.client = new ClientModel();
    }

    selectStatus(e) {
        this.tempStatus = e.target.value;
    }

    updateStatus() {

        const obj = {
            status: this.tempStatus
        };
        this.authService.ServerPut(server.clients + '/' + this.client.id + '/status', obj)
            .subscribe(res => {
                this.client.status = this.tempStatus;
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.closeUpdateStatusModal();
                this.spinner.hide();
            });
    }

    closeUpdateStatusModal() {
        // @ts-ignore
        $('#statusModal').modal('hide');
    }

}
