import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RecruitmentPreferencesModel} from '../recruitment-preferences.model';
import {PermissionService} from '../../../services/permission/permission.service';

@Component({
    selector: 'app-step-panel',
    templateUrl: './step-panel.component.html',
    styleUrls: ['./step-panel.component.scss']
})
export class StepPanelComponent implements OnInit {
    @Input() recruitmentPreference: RecruitmentPreferencesModel;
    @Output() deleteStep = new EventEmitter();
    @Output() editStep = new EventEmitter();

    constructor(public permissionService: PermissionService) {
    }

    ngOnInit(): void {
    }

    edit() {
        this.editStep.emit(this.recruitmentPreference);
    }

    delete() {
        this.deleteStep.emit(this.recruitmentPreference);
    }

}
