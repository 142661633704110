import { CompanyRegisterModel } from 'src/app/components/register/company-register/company-register-model';
import { HrManagerRegisterModel } from 'src/app/components/register/hr-manager-register/hr-manager-register.model';


export class RegisterModel {

    newCompany: CompanyRegisterModel;
    initialUser: HrManagerRegisterModel;

    constructor() {
    }
}