<div class="container-fluid">
    <div class="row">
        <div class="gradient-bg menu-item col-12">
            <a class="nav-link active">{{'newCandidate' | translate}}</a>
        </div>
    </div>
</div>
<div *ngIf="!addManuallyState && !importCandidateState" class="container-fluid mt-5">
    <div class="row">
        <div class="col-12 text-center">
            <button (click)="importCandidateModal()"
                class="btn exactalent-button border-radius-0 btn-blue mb-1 col-xl-auto col-lg-auto col-md-auto col-sm-12">{{'importFromCv' | translate}}</button>
            <button (click)="openAddManually()"
                class="btn exactalent-button border-radius-0 btn-blue ml-xl-1 ml-lg-1 ml-md-1 ml-sm-0 mb-1 col-xl-auto col-lg-auto col-md-auto col-sm-12">{{'addManually' | translate}}</button>
            <button (click)="goCandidates()"
                class="btn exactalent-button border-radius-0 btn-warning text-white ml-xl-1 ml-lg-1 ml-md-1 ml-sm-0 mb-1 col-xl-auto col-lg-auto col-md-auto col-sm-12">{{'cancel' | translate}}</button>
        </div>
    </div>
</div>

<div [hidden]="!addManuallyState" class="container mt-5">
    <form #formAddCandidate="ngForm" class="needs-validation">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="mb-3 validate-me">
                                            <label class="modal-input-label" for="firstName">
                                                {{"firstName" | translate}}<span class="text-danger">*</span>
                                            </label>
                                            <input [(ngModel)]="candidate.firstName"
                                                class="form-control border-radius-0" id="firstName" name="firstName"
                                                #name type="text" required appTitleCaseDirective>
                                        </div>
                                        <div class="mb-3">
                                            <label class="modal-input-label" for="middleName">
                                                {{"middleName" | translate}}
                                            </label>
                                            <input [(ngModel)]="candidate.middleName"
                                                class="form-control border-radius-0" id="middleName" name="middleName"
                                                #middleName type="text" appTitleCaseDirective>
                                        </div>
                                        <div class="mb-3 validate-me">
                                            <label class="modal-input-label" for="lastName">
                                                {{"lastName" | translate}}<span class="text-danger">*</span>
                                            </label>
                                            <input [(ngModel)]="candidate.lastName" class="form-control border-radius-0"
                                                id="lastName" name="lastName" #lastName type="text" required appTitleCaseDirective>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="mb-3 validate-me">
                                            <label class="modal-input-label"
                                                for="primaryPhone">{{"primaryPhone" | translate}}<span
                                                    class="text-danger">*</span></label>
                                            <input [(ngModel)]="candidate.primaryPhone"
                                                class="form-control border-radius-0" id="primaryPhone"
                                                name="primaryPhone" #primaryPhone type="text" required>
                                        </div>
                                        <div class="mb-3">
                                            <label class="modal-input-label"
                                                for="secondaryPhone">{{"secondaryPhone" | translate}}</label>
                                            <input [(ngModel)]="candidate.secondaryPhone"
                                                class="form-control border-radius-0" id="secondaryPhone"
                                                name="secondaryPhone" #secondaryPhone type="text">
                                        </div>
                                        <div class="mb-3 validate-me">
                                            <label class="modal-input-label"
                                                for="primaryEmail">{{"primaryEmail" | translate}}<span
                                                    class="text-danger">*</span></label>
                                            <input [(ngModel)]="candidate.primaryEmail"
                                                class="form-control border-radius-0" id="primaryEmail"
                                                name="primaryEmail" #primaryEmail type="email" email required>
                                        </div>
                                        <div class="mb-3">
                                            <label class="modal-input-label"
                                                for="secondaryEmail">{{"secondaryEmail" | translate}}</label>
                                            <input (keyup)="secondaryEmailValidaiton()" [(ngModel)]="candidate.secondaryEmail"
                                                class="form-control border-radius-0" id="secondaryEmail"
                                                name="secondaryEmail" #secondaryEmail="ngModel" type="email" email>
                                            <div *ngIf="secondaryEmail.invalid && (secondaryEmail.dirty || secondaryEmail.touched)"
                                                class="alert alert-danger">
                                                {{ 'emailValidation' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="row">
                            <!-- Social Media Begin -->
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="githubAccount">{{'githubAccount' | translate}}</label>

                                    <div class="input-group margin-bottom-sm mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text login-input-icon-password">
                                                <i class="fab fa-github"></i>
                                            </span>
                                        </div>
                                        <input [(ngModel)]="candidate.githubAccount"
                                            class="form-control border-radius-0" id="githubAccount" name="githubAccount"
                                            #githubAccount type="text">
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="twitterAccount">{{'twitterAccount' | translate}}</label>
                                    <div class="input-group margin-bottom-sm mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text login-input-icon-password">
                                                <i class="fab fa-twitter"></i>
                                            </span>
                                        </div>
                                        <input [(ngModel)]="candidate.twitterAccount"
                                            class="form-control border-radius-0" id="twitterAccount"
                                            name="twitterAccount" #twitterAccount type="text">
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="facebookAccount">{{'facebookAccount' | translate}}</label>
                                    <div class="input-group margin-bottom-sm mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text login-input-icon-password">
                                                <i class="fab fa-facebook"></i>
                                            </span>
                                        </div>
                                        <input [(ngModel)]="candidate.facebookAccount"
                                            class="form-control border-radius-0" id="facebookAccount"
                                            name="facebookAccount" #facebookAccount type="text">
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="twitterAccount">{{'linkedinAccount' | translate}}</label>
                                    <div class="input-group margin-bottom-sm mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text login-input-icon-password">
                                                <i class="fab fa-linkedin"></i>
                                            </span>
                                        </div>
                                        <input [(ngModel)]="candidate.linkedinAccount"
                                            class="form-control border-radius-0" id="linkedinAccount"
                                            name="linkedinAccount" #linkedinAccount type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="twitterAccount">{{'instagramAccount' | translate}}</label>
                                    <div class="input-group margin-bottom-sm mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text login-input-icon-password">
                                                <i class="fab fa-instagram"></i>
                                            </span>
                                        </div>
                                        <input [(ngModel)]="candidate.instagramAccount"
                                            class="form-control border-radius-0" id="instagramAccount"
                                            name="instagramAccount" #instagramAccount type="text">
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="youtubeAccount">{{'youtubeAccount' | translate}}</label>
                                    <div class="input-group margin-bottom-sm mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text login-input-icon-password">
                                                <i class="fab fa-youtube"></i>
                                            </span>
                                        </div>
                                        <input [(ngModel)]="candidate.youtubeAccount"
                                            class="form-control border-radius-0" id="youtubeAccount"
                                            name="youtubeAccount" #youtubeAccount type="text">
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="behanceAccount">{{'behanceAccount' | translate}}</label>
                                    <div class="input-group margin-bottom-sm mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text login-input-icon-password">
                                                <i class="fab fa-behance"></i>
                                            </span>
                                        </div>
                                        <input [(ngModel)]="candidate.behanceAccount"
                                            class="form-control border-radius-0" id="behanceAccount"
                                            name="behanceAccount" #behanceAccount type="text">
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="stackoverflowAccount">{{'stackoverflowAccount' | translate}}</label>
                                    <div class="input-group margin-bottom-sm mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text login-input-icon-password">
                                                <i class="fab fa-stack-overflow"></i>
                                            </span>
                                        </div>
                                        <input [(ngModel)]="candidate.stackoverflowAccount"
                                            class="form-control border-radius-0" id="stackoverflowAccount"
                                            name="stackoverflowAccount" #stackoverflowAccount type="text">
                                    </div>
                                </div>
                            </div>
                            <!-- Social Media End -->
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 p-0">
                        <div class="modal-footer">
                            <button (click)="addManuallyState = false"
                                class="btn btn-warning border-radius-0 col-xl-auto col-lg-auto col-md-auto col-sm-12"
                                data-dismiss="modal" type="button">{{'cancel' | translate}}</button>
                            <button (click)="save(formAddCandidate)"
                                class="btn btn-success border-radius-0 col-xl-auto col-lg-auto col-md-auto col-sm-12"
                                type="button">{{'save' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<app-import-candidate (emitCancelImportCandidate)="importCandidateState = false;" [hidden]="!importCandidateState">
</app-import-candidate>