import {Component, OnDestroy, OnInit} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {authPasswordFlowConfig} from '../../config/oauth.config';

@Component({
    selector: 'app-dashboard-layout',
    templateUrl: './dashboard-layout.component.html',
    styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {

    public sidebarIsOpen = true;
    public sidebarOpen = true;
    refreshToken;

    constructor(private oauthService: OAuthService) {
    }

    get calculatedRefreshTokenTime() {
        // tslint:disable-next-line:radix
        const expiresAt = parseInt(localStorage.getItem('expires_at'));
        // tslint:disable-next-line:radix
        const accessTokenStoredAt = parseInt(localStorage.getItem('access_token_stored_at'));
        const diff = expiresAt - accessTokenStoredAt;
        const totalTime = diff * authPasswordFlowConfig.timeoutFactor;
        // tslint:disable-next-line:radix
        return parseInt(totalTime.toFixed());
    }

    public _toggleSidebar() {
        this.sidebarIsOpen = !this.sidebarIsOpen;
        localStorage.setItem('sidebarIsOpen', String(this.sidebarIsOpen));
    }

    ngOnInit(): void {
        this.setSidebarState();
        this.refreshToken = setInterval(() => {
            this.oauthService.refreshToken();
        }, this.calculatedRefreshTokenTime);
    }

    ngOnDestroy() {
        if (this.refreshToken) {
            clearInterval(this.refreshToken);
        }
    }

    setSidebarState() {
        const isOpened = localStorage.getItem('sidebarIsOpen');
        const isTrueSet = (isOpened === 'true');
        if (isOpened && [true, false].includes(isTrueSet)) {
            this.sidebarIsOpen = isTrueSet;
        } else {
            this.sidebarIsOpen = true;
        }
    }
}
