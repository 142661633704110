import {Component, Input, OnInit} from '@angular/core';
import {server} from '../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';
import {NoteModel} from '../../candidates/edit-candidate/notes/note.model';

@Component({
  selector: 'app-take-note-modal',
  templateUrl: './take-note-modal.component.html',
  styleUrls: ['./take-note-modal.component.scss']
})
export class TakeNoteModalComponent implements OnInit {
 
  @Input() candidateId: string;
  notes: NoteModel[];
  note: NoteModel;
  inputAreaState: boolean;
  sessionUserId;

  constructor(  private spinner: NgxSpinnerService,
                private authService: AuthService,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) { 
                  this.inputAreaState = false;
                  this.note = new NoteModel();         
  }

  get userId() {
    const parsedToken = JSON.parse(window.atob((this.authService.getToken()).split('.')[1]));
    return parsedToken.sub;
}

  ngOnInit() {
  }

  getNotes(): void {
    this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/notes')
        .subscribe((res: any) => {
            this.notes = res.content;
        }, err => {
            if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
}

addNote(): void {
  this.note = new NoteModel();
  this.inputAreaState = true;
}

cancel(): void {
  this.inputAreaState = false;
}

save(): void {
  if (this.note.id) {
      this.updateNote();
  } else {
      this.saveNote();
  }
}

saveNote(): void {
  this.authService.ServerPost(server.candidates + '/' + this.candidateId + '/notes', this.note)
      .subscribe(res => {
          this.notification.success(this.translate.transform('saveSuccessful', []));
          this.getNotes();
      }, err => {
          if (err.status >= 400 || err.status < 500) {
              this.notification.warning(err.message);
          }
          this.spinner.hide();
      }, () => {
          this.cancel();
          this.spinner.hide();
      });
}

updateNote(): void {
  this.authService.ServerPut(server.candidates + '/' + this.candidateId + '/notes/' + this.note.id, this.note)
      .subscribe(res => {
          this.notification.success(this.translate.transform('saveSuccessful', []));
          this.getNotes();
      }, err => {
          if (err.status >= 400 || err.status < 500) {
              this.notification.warning(err.message);
          }
          this.spinner.hide();
      }, () => {
          this.cancel();
          this.spinner.hide();
      });
}

editNote(note: NoteModel): void {
  this.note = note;
  this.inputAreaState = true;
}

}
