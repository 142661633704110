export class ContactPersonModel {
    id: string;
    fullName: string;
    firstName: string;
    middleName: string;
    lastName: string;
    title: string;
    email: string;
    phone: string;
    mobile: string;
    position: number;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;

    constructor() {
    }
}
