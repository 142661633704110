import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CandidateModel} from '../../../candidates/candidate.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';
import {GeneralModel} from '../../../positions/position-operations/general/general.model';

@Component({
    selector: 'app-candidate-dashboard-view-list',
    templateUrl: './candidate-dashboard-view-list.component.html',
    styleUrls: ['./candidate-dashboard-view-list.component.scss']
})
export class CandidateDashboardViewListComponent implements OnInit, OnChanges {

    candidates: CandidateModel[] = [];
    selectedCandidateId: string;
    count: number;
    maxItemCount: number;
    @Input() position: GeneralModel;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.maxItemCount = 7;
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.getCandidates();
    }


    getCandidates() {
        this.authService.ServerGet(server.candidates +
            `?size=${this.maxItemCount}&filter=status!:DELETED&filter=pipelineSteps.positionId::` + this.position.id)
            .subscribe((res: any) => {
                this.candidates = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

}
