<div *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE_RECOMMENDATION'])" class="row">
    <div class="col-auto">
        <button (click)="openAddRecommendation()"
                class="btn btn-blue border-radius-0 float-right exactalent-button width-100"
                data-target="#addUserCenter"
                data-toggle="modal">
            {{'addNewRecommendation' | translate}}
        </button>
    </div>
</div>
<div class="row mt-2">
    <div class="col-12">
        <div class="table-responsive">
            <table class="table table-bg">
                <thead class="table-bg">
                <tr>
                    <th scope="col">{{'name' | translate}}</th>
                    <th scope="col">{{'title' | translate}}</th>
                    <th scope="col">{{'email' | translate}}</th>
                    <th scope="col">{{'phone' | translate}}</th>
                    <th scope="col">{{'content' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr (click)="openRowModal($event,recommendation)"
                    *ngFor="let recommendation of recommendations | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count }"
                    class="recommendation-row">
                    <td>{{recommendation.name}}</td>
                    <td>{{recommendation.title}}</td>
                    <td>{{recommendation.email}}</td>
                    <td>{{recommendation.phone}}</td>
                    <td>{{recommendation.content}}</td>
                </tr>
                <tr [hidden]="count>0">
                    <td colspan="5">
                        <app-empty-table-info tableInfoTranslateKey="recommendationNotExistInfo"></app-empty-table-info>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="width-100">
                    <td class="text-center" colspan="5">
                        <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
                                             [autoHide]="count <= requestPayload.limit"
                                             nextLabel="{{'next' | translate}}"
                                             previousLabel="{{'previous' | translate}}"
                                             responsive="true"></pagination-controls>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
<app-add-candidate-recommendation (setRecommendationScreen)="setRecommendationScreens()"
                                  *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE_RECOMMENDATION'])"
                                  [candidateId]="candidateId"></app-add-candidate-recommendation>
<app-edit-candidate-recommendation (setRecommendationScreen)="setRecommendationScreens()"
                                   *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_RECOMMENDATION'])"
                                   [candidateId]="candidateId"
                                   [recommendation]="selectedRecommendation"></app-edit-candidate-recommendation>
<app-delete-candidate-recommendation (setRecommendationScreen)="setRecommendationScreens()"
                                     *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_RECOMMENDATION'])"
                                     [candidateId]="candidateId"
                                     [recommendation]="selectedRecommendation"></app-delete-candidate-recommendation>

<div (clickOutside)="onClickedOutside($event)"
     *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_RECOMMENDATION','UPDATE_CANDIDATE_RECOMMENDATION'])"
     class="operation-list"
     id="row-modal">
    <ul>
        <li (click)="openEditRecommendation()"
            *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_RECOMMENDATION'])" class="operation-list-item">
            <span>{{'edit' | translate}}</span>
        </li>
        <li (click)="openDeleteRecommendation()"
            *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_RECOMMENDATION'])"
            class="mt-2 operation-list-item">
            <span>{{'delete' | translate}}</span>
        </li>
    </ul>
</div>
