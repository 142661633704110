import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CalendarEvent} from 'angular-calendar';

@Component({
    selector: 'app-calendar-interview-modal',
    templateUrl: './calendar-interview-modal.component.html',
    styleUrls: ['./calendar-interview-modal.component.scss']
})
export class CalendarInterviewModalComponent implements OnInit, OnChanges {

    @Input() interviews: CalendarEvent[];
    @Output() openRemoveInterview = new EventEmitter();
    @Output() openEditInterview = new EventEmitter();
    @Input() interview: CalendarEvent;
    @Input() isMonthView: boolean;

    constructor() {
        this.isMonthView = true;
    }
    ngOnInit(): void {
    }
    ngOnChanges(changes: SimpleChanges) {
       /*if (this.interviews.length) {
            this.isMonthView = true;
        } else {
            this.isMonthView = false;
            // @ts-ignore
            this.interview = this.interviews;
        }*/
    }

    collapse(interviewItemId) {
        // @ts-ignore
        $('#' + interviewItemId).collapse('toggle');
    }

    openDeleteInterview(interviewId) {
        this.openRemoveInterview.emit(interviewId);
    }

    openUpdateInterview(interview) {
        this.openEditInterview.emit(interview);
    }

}
