<!--User Modal -->
<div aria-hidden="true" aria-labelledby="editGeneralInfoTitle" class="modal fade" id="editGeneralInfo" role="dialog"
    tabindex="-1">
    <form #editGenralInfo="ngForm">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header gradient-bg">
                    <h5 class="modal-title" id="addUserLongTitle">
                        {{ "editGeneralInfo" | translate }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="workingStatus">{{"workingStatus" | translate}}</label>
                                    <select (change)="selectWorkingStatus($event)" class="form-control border-radius-0"
                                        id="workingStatus">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let workingStatus of workingStatusList"
                                            [selected]="workingStatus.value === tempGeneralInfo.workingStatus"
                                            [value]="workingStatus.value">{{workingStatus.name | translate}}</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="jobSearching">{{"jobSearching" | translate}}</label>
                                    <select (change)="selectJobSearchStatus($event)"
                                        class="form-control border-radius-0" id="jobSearching">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let jobSearchStatus of jobSearchStatusList"
                                            [selected]="jobSearchStatus.value === tempGeneralInfo.jobSearchStatus"
                                            [value]="jobSearchStatus.value">{{jobSearchStatus.name | translate}}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label" for="salaryExpectation">
                                        {{"salaryExpectation" | translate}}
                                    </label>
                                    <div class="container-fluid">
                                        <div class="row" id="salaryExpectation">
                                            <input [(ngModel)]="tempGeneralInfo.minSalaryExpectation" min="1"
                                                class="form-control col border-radius-0" id="minSalaryExpectation"
                                                name="minSalaryExpectation" placeholder="{{'min' | translate}}..."
                                                type="number" />&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label" for="currency">{{"currency" | translate}}</label>
                                    <app-searchable-dropdown (selectResult)="selectCurrencies($event)"
                                        [items]="currencies" [selectedItem]="tempGeneralInfo.currency?.value"
                                        id="currency"></app-searchable-dropdown> 
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="maritalStatus">{{"maritalStatus" | translate}}</label>
                                    <select (change)="selectMaritalStatus($event)" class="form-control border-radius-0"
                                        id="maritalStatus">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let maritalStatus of maritalStatusList"
                                            [selected]="maritalStatus.value === tempGeneralInfo.maritalStatus"
                                            [value]="maritalStatus.value">{{maritalStatus.name | translate}}</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label" for="gender">{{"gender" | translate}}</label>
                                    <select (change)="selectGender($event)" class="form-control border-radius-0"
                                        id="gender">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let gender of genderList"
                                            [selected]="gender.value === tempGeneralInfo.gender" [value]="gender.value">
                                            {{gender.name | translate}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="preferredWorkingCountry">{{ "preferredWorkingCountry" | translate }}</label>
                                    <app-searchable-dropdown (selectResult)="selectCountry($event);cityChild.selectedItem=null;cityChild.isFiltired=false" [items]="countries"
                                        [selectedItem]="tempGeneralInfo.preferredCountry?.value"
                                        id="preferredWorkingCountry"></app-searchable-dropdown>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="preferredWorkingCity">{{"preferredWorkingCity" | translate}}</label>
                                    <app-searchable-dropdown #cityChild (selectResult)="selectCity($event)" [items]="cities"
                                        [selectedItem]="tempGeneralInfo.preferredCity?.value" id="preferredWorkingCity">
                                    </app-searchable-dropdown>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="preferredIndustry">{{"preferredIndustry" | translate}}</label>
                                    <app-searchable-dropdown-with-add (selectResult)="selectIndustry($event)"
                                        [items]="industries" [selectedItem]="tempGeneralInfo.preferredIndustry?.value"
                                        fieldName="industry" id="preferredIndustry"></app-searchable-dropdown-with-add>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="preferredTitle">{{"preferredTitle" | translate}}</label>
                                    <input [(ngModel)]="tempGeneralInfo.preferredTitle"
                                        [placeholder]="'preferredTitle'| translate" class="form-control border-radius-0"
                                        id="preferredTitle" name="preferredTitle" type="text" appTitleCaseDirective />
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="preferredJobType">{{"preferredJobType" | translate}}</label>
                                    <select (change)="selectJobTYpe($event)" class="form-control border-radius-0"
                                        id="preferredJobType">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let jobType of jobTypes"
                                            [selected]="jobType.value === tempGeneralInfo.preferredJobType"
                                            [value]="jobType.value">{{jobType.name | translate}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="outsourcePreference">{{"outsourcePreference" | translate}}</label>
                                    <select (change)="selectOutsourcePreference($event)"
                                        class="form-control border-radius-0" id="outsourcePreference">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option [selected]="tempGeneralInfo.outsourcePreference === true"
                                            [value]="true">{{ "yes" | translate }}</option>
                                        <option [selected]="tempGeneralInfo.outsourcePreference === false"
                                            [value]="false">{{ "no" | translate }}</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="militaryService">{{"militaryService" | translate}}</label>
                                    <select (change)="selectMilitaryService($event)"
                                        class="form-control border-radius-0" id="militaryService">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let militaryServiceStatus of militaryServiceStatusList"
                                            [selected]="militaryServiceStatus.value === tempGeneralInfo.militaryService"
                                            [value]="militaryServiceStatus.value">
                                            {{militaryServiceStatus.name | translate}}</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="nationality">{{"nationality" | translate}}</label>
                                    <app-searchable-dropdown (selectResult)="selectNationality($event)"
                                        [items]="countries" [selectedItem]="tempGeneralInfo.nationality?.value"
                                        id="nationality"></app-searchable-dropdown>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="drivingLicence">{{"drivingLicence" | translate}}</label>
                                    <select (change)="selectDrivingLicence($event)" class="form-control border-radius-0"
                                        id="drivingLicence">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option [selected]="tempGeneralInfo.drivingLicence === true" [value]="true">
                                            {{ "yes" | translate }}</option>
                                        <option [selected]="tempGeneralInfo.drivingLicence === false" [value]="false">
                                            {{ "no" | translate }}</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label" for="smoking">{{"smoking" | translate}}</label>
                                    <select (change)="selectSmoking($event)" class="form-control border-radius-0"
                                        id="smoking">
                                        <option selected value="">{{"unspecified" | translate}}</option>
                                        <option [selected]="tempGeneralInfo.smoking === true" [value]="true">
                                            {{ "yes" | translate }}</option>
                                        <option [selected]="tempGeneralInfo.smoking === false" [value]="false">
                                            {{ "no" | translate }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="closeEditGeneralInfoModal()" class="btn btn-warning border-radius-0"
                        data-dismiss="modal" type="button">
                        {{ "cancel" | translate }}
                    </button>
                    <button (click)="saveGeneralInfo()" class="btn btn-success border-radius-0" type="button">
                        {{ "save" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
