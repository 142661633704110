import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {PermissionService} from '../../permission/permission.service';

@Injectable({
    providedIn: 'root'
})
export class ClientGuard implements CanActivate {
    constructor(private router: Router, private permissionService: PermissionService) {
    }

    canActivate(): boolean {
        const perm = this.permissionService.permissionsOnly(['READ_ALL_CLIENTS', 'READ_CLIENT']);
        if (perm) {
            return true;
        } else {
            this.router.navigate(['/dashboard']);
            return false;
        }
    }
}
