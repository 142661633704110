import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-export-modal-candidate',
  templateUrl: './export-modal-candidate.component.html',
  styleUrls: ['./export-modal-candidate.component.scss']
})
export class ExportModalCandidateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
