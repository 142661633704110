<div class="container-fluid">
    <div class="row">
        <div (click)="setTab('userManagement')"
             [ngClass]="{'gradient-bg':!userManagementComponentState,
             'top-line':userManagementComponentState,
             'col-xl-6 col-lg-6 col-md-6 col-sm-12':!permissionService.permissionsOnly(['READ_CAREER_PORTAL_SETTINGS'])}"
             class="col-xl-4 col-lg-4 col-md-4 col-sm-12 menu-item">
            <a class="nav-link active">{{'userManagement' | translate}}</a>
        </div>
        <div (click)="setTab('recruitmentPreferences')"
             [ngClass]="{'gradient-bg':!recruitmentPreferencesComponentState,
             'top-line':recruitmentPreferencesComponentState,
             'col-xl-6 col-lg-6 col-md-6 col-sm-12':!permissionService.permissionsOnly(['READ_CAREER_PORTAL_SETTINGS'])}"
             class="col-xl-4 col-lg-4 col-md-4 col-sm-12 menu-item">
            <a class="nav-link">{{'recruitmentPreferences' | translate}}</a>
        </div>
        <div (click)="setTab('careerPortalSettings')"
             *ngIf="permissionService.permissionsOnly(['READ_CAREER_PORTAL_SETTINGS'])"
             [ngClass]="{'gradient-bg':!careerPortalSettingsComponentState,
             'top-line':careerPortalSettingsComponentState}"
             class="col-xl-4 col-lg-4 col-md-4 col-sm-12 menu-item">
            <a class="nav-link">{{'careerPortalSettings' | translate}}</a>
        </div>
    </div>
</div>
<div class="container-fluid">
    <app-user-management *ngIf="userManagementComponentState"></app-user-management>
    <app-recruitment-preferences *ngIf="recruitmentPreferencesComponentState"></app-recruitment-preferences>
    <app-career-portal-settings *ngIf="careerPortalSettingsComponentState"></app-career-portal-settings>
</div>
