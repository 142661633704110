<div class="table-responsive">
    <table class="table table-bg">
        <thead class="table-bg">
        <tr> 
            <th scope="col">&nbsp;</th>
            <th scope="col">{{'client' | translate}}</th>
            <th scope="col">{{'positionName' | translate}}</th>
            <th scope="col">{{'name' | translate}}</th>
            <th scope="col">{{'email' | translate}}</th>
            <th scope="col">{{'added' | translate}}</th>
            
        </tr> 
        </thead>
        <tbody>
        <tr (click)="openRowModal($event,interviewRequest)"
            *ngFor="let interviewRequest of interviewRequests | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count } "
            class="candidate-row cursor-pointer">
            <th scope="row">
                <span (click)="openInterviewRequestStatusModal(interviewRequest)" class="btn-group mr-2"
                      role="group" style="cursor: pointer;"
                      title="{{interviewRequest.status | translate}}">
                    <p [class]="interviewRequest.status" class="interviewRequest-status-color-icon">
                    
                </span>
                </th>
            <td>{{interviewRequest?.client?.name}}</td>
            <td>{{interviewRequest?.position?.name}}</td>
            <td>{{interviewRequest?.candidate?.name}}</td>
            <td>{{interviewRequest?.candidate?.email}}</td>
            <td>{{interviewRequest?.createdAt | date}}</td>
             
        </tr>
        <tr [hidden]="count>0">
            <td colspan="11">
                <app-empty-table-info tableInfoTranslateKey="interviewRequestNotExistInfo"></app-empty-table-info>
            </td>
        </tr>
      
        </tbody>
        <tfoot>
            <tr class="width-100">
                <td class="text-center" colspan="7">
                    <pagination-controls (pageChange)="currentPage = $event;getInterviewRequests(currentPage)"
                                         [autoHide]="count <= requestPayload.limit"
                                         nextLabel="{{'next' | translate}}"
                                         previousLabel="{{'previous' | translate}}"
                                         responsive="true"></pagination-controls>
                </td>
            </tr>
        </tfoot> 
    </table>
</div>

<app-confirm-modal (operationInit)="changeInterviewRequestStatus('DECLINED')" [confirmData]="confirmData"></app-confirm-modal>
<app-create-interview-from-request (operationInit)="changeInterviewRequestStatus('ACCEPTED')" [selectedInterviewRequest]="selectedInterviewRequest" ></app-create-interview-from-request>

<div (clickOutside)="onClickedOutside($event)" *ngIf="permissionService.permissionsOnly(['UPDATE_INTERVIEW_REQUEST_STATUS'])" class="operation-list" id="row-modal">
    <ul>
        <li (click)="openCreateInterview()"  class="operation-list-item"> 
            <span>{{'createInterview' | translate}}</span>
        </li>
        <li (click)="openConfirmInterviewRequest()" class="operation-list-item">
            <span>{{'rejectInterviewRequest' | translate}}</span>
        </li>
        
    </ul>
</div>