<!-- Modal -->
<div aria-hidden="true" aria-labelledby="statusModalCenterTitle" class="modal fade"
     id="statusModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="statusModalLongTitle">{{'updateStatus' | translate}}</h5>
            </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label class="modal-input-label" for="status">{{'status' | translate}}</label>
                        <select (change)="selectStatus($event)" class="custom-select border-radius-0"
                                id="status">
                            <option *ngFor="let status of statusList"
                                    [selected]="status.value === candidate.status"
                                    [value]="status.value">{{status.name | translate}}</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="closeUpdateStatusModal()" class="btn btn-warning border-radius-0"
                            data-dismiss="modal"
                            type="button">{{'cancel' | translate}}</button>
                    <button *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_STATUS'])" (click)="updateStatus()" class="btn btn-success border-radius-0"
                            type="button">{{'save' | translate}}</button>
                </div>
        </div>
    </div>
</div>
