import {Component, Input, OnInit} from '@angular/core';
import {server} from '../../../../services/server';
import {InterviewModel} from '../../../../services/parameters/models/interview.model';
import {AuthService} from '../../../../services/auth/auth.service';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../services/notification/notification.service';

@Component({
    selector: 'app-position-calendar',
    templateUrl: './position-calendar.component.html',
    styleUrls: ['./position-calendar.component.scss']
})
export class PositionCalendarComponent implements OnInit {

    @Input() positionId: string;
    interviews: InterviewModel[];
    isCalendarView: boolean;
    isOnListView: boolean;
    dropdownViewTitle: string;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                private notification: NotificationService) {
        this.changeView('onList');
    }

    ngOnInit(): void {
    }

    getInterviews(monthlyFilter) {
        this.authService.ServerGet(server.interviews + monthlyFilter + '&filter=positionId::' + this.positionId)
            .subscribe((res: any) => {
                this.interviews = res.content;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    changeView(view) {
        switch (view) {
            case 'onList':
                this.isOnListView = true;
                this.isCalendarView = false;
                break;
            case 'onCalendar':
                this.isOnListView = false;
                this.isCalendarView = true;
                break;
            default:
                this.changeView('onList');
                break;
        }

        this.dropdownViewTitle = view;
    }

}
