import {Component, OnInit} from '@angular/core';
import {RequestPayload} from '../../../shared/requestPayload';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../services/permission/permission.service';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';
import {server} from '../../../services/server';
import {PoolFilterModel, PoolModel} from '../pool.model';

@Component({
    selector: 'app-pool-list',
    templateUrl: './pool-list.component.html',
    styleUrls: ['./pool-list.component.scss']
})
export class PoolListComponent implements OnInit {

    count: number;
    activePage: any;
    currentPage: number;
    requestPayload: RequestPayload;
    selectedPool: PoolModel;
    pools: PoolModel[] = [];
    tagList = ['TR', 'tr', 'TD', 'td'];
    openedElementId: string;
    positionFilter: PoolFilterModel;
    filterData: PoolFilterModel;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.requestPayload = new RequestPayload();
    }

    ngOnInit() {
        this.filterData = new PoolFilterModel();
        this.count = 0;
        this.pools = [];
        this.getPools(1);
    }

    addPoolModal() {
        // @ts-ignore
        $('#addPoolOperation').modal('show');
    }

    openPoolStatusModal(pool) {
        this.selectedPool = pool;
        // @ts-ignore
        $('#statusModal').modal('show');
    }

    getPools(pageNo) {
        this.authService.ServerGet(server.pools + this.requestPayload.payloadURL(pageNo) + this.requestFilter())
            .subscribe((res: any) => {
                this.pools = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    pagination(pageNo) {
        this.getPools(pageNo);
    }

    onClickedOutside(event) {
        if (this.tagList.indexOf(event.target.tagName) <= -1) {
            this.closeRowModal();
        }
    }

    openRowModal(event, pool) {
        if (this.tagList.indexOf(event.target.tagName) > -1) {
            if (this.openedElementId && document.getElementById(this.openedElementId)) {
                document.getElementById('row-modal').style.display = 'none';
            }
            this.selectedPool = pool;
            this.openedElementId = this.selectedPool.id;
            document.getElementById('row-modal').style.left = event.clientX - 5 + 'px';
            document.getElementById('row-modal').style.top = event.clientY - 10 + 'px';
            document.getElementById('row-modal').style.display = 'block';
        } else {
            this.closeRowModal();
        }
    }

    closeRowModal() {
        if (this.openedElementId) {
            document.getElementById('row-modal').style.display = 'none';
        }
    }

    openEditPool() {
        this.closeRowModal();
        // @ts-ignore
        $('#editPool').modal('show');
    }

    openPoolDetails() {
        this.router.navigate(['/dashboard/pool-details', {id: this.selectedPool.id}]);
    }

    openDeletePool() {
        this.closeRowModal();
        // @ts-ignore
        $('#deletePool').modal('show');
    }

    applyFilter(filter) {
        this.positionFilter = filter;
        if (JSON.stringify(this.filterData) === JSON.stringify(this.positionFilter)) {
            return false;
        }
        this.filterData = new PoolFilterModel();
        this.filterData = {...this.positionFilter};
        this.getPools(1);
    }

    requestFilter() {
        return '&filter=countryId**' + this.filterData.country +
            '&filter=cityId**' + this.filterData.city +
            '&filter=createdBy**' + this.filterData.createdBy +
            '&filter=status**' + this.filterData.status +
            '&filter=categoryId**' + this.filterData.category;
    }
}
