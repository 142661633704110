<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-bg">
                    <thead class="table-bg">
                    <tr>
                        <th scope="col">{{'date' | translate}}</th>
                        <th scope="col">{{'user' | translate}}</th>
                        <th scope="col">{{'status' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let log of historyLogs | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count }"
                        class="history-row">
                        <td>{{log.createdAt | date}}</td>
                        <td>
                            <app-user [userId]="log.createdBy"></app-user>
                        </td>
                        <td>{{log.status}}</td>
                    </tr>
                    <tr [hidden]="count>0">
                        <td colspan="3">
                            <app-empty-table-info tableInfoTranslateKey="historyNotExistInfo"></app-empty-table-info>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="width-100">
                        <td class="text-center" colspan="3">
                            <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
                                                 [autoHide]="count <= requestPayload.limit"
                                                 nextLabel="{{'next' | translate}}"
                                                 previousLabel="{{'previous' | translate}}"
                                                 responsive="true"></pagination-controls>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
