<div class="container-fluid">
    <form #formPosition="ngForm" class="needs-validation">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <p class="col-auto mb-2 light-black">
                        <b>{{operationType | translate | uppercase}}&nbsp;{{'position' | translate | uppercase}}</b>
                    </p>
                    <p class="col" style="border-bottom: 3px solid #f6f6f6"></p>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <p class="light-black"><b>{{'forCandidate' | translate}}</b></p>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="mb-3 validate-me">
                    <label class="modal-input-label" for="positionName">{{'positionName' | translate}}<span
                            class="text-danger">*</span></label>
                    <input #positionName="ngModel" [(ngModel)]="addPositionModel.name"
                        [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                        class="form-control border-radius-0" id="positionName" maxlength="64" name="positionName"
                        placeholder="{{'positionName' | translate}}" required="required" type="text" appTitleCaseDirective>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="country">{{'country' | translate}}<span
                            class="text-danger">*</span></label>
                    <div>
                        <app-searchable-dropdown (selectResult)="selectCountry($event);cityChild.selectedItem=null;cityChild.isFiltired=false"
                            [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                            [items]="countries" [selectedItem]="addPositionModel.country?.value" id="country"
                            [isSaveClick]="isSaveClick" isRequired="true" nameInput="country">
                        </app-searchable-dropdown>

                    </div>

                </div>
                <div class="mb-3 ">
                    <label class="modal-input-label" for="city">{{'city' | translate}}<span
                            class="text-danger">*</span></label>
                    <div>

                        <app-searchable-dropdown #cityChild (selectResult)="selectCity($event)"
                            [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                            [items]="cities" [selectedItem]="addPositionModel.city?.value" id="city"
                            [isSaveClick]="isSaveClick" isRequired="true" nameInput="city"
                            >
                        </app-searchable-dropdown>

                    </div>
                </div>
                <div class="mb-3 validate-me">
                    <label class="modal-input-label" for="jobType">{{'positionType' | translate}}<span
                            class="text-danger">*</span></label>
                    <select #jobType="ngModel" (change)="selectPositionType($event)"
                        [(ngModel)]="addPositionModel.jobType"
                        [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                        class="custom-select border-radius-0" id="jobType" name="jobType" required >
                        <option selected value="">{{'positionType' | translate}}</option>
                        <option *ngFor="let positionType of positionTypes"
                            [selected]="addPositionModel.jobType === positionType.value" [value]="positionType.value">
                            {{positionType.name | translate  }}</option>
                    </select>
                </div>
                <div class="mb-3 validate-me">
                    <label class="modal-input-label" for="jobTitle">{{'jobTitle' | translate}}<span
                            class="text-danger">*</span></label>
                    <input [(ngModel)]="addPositionModel.jobTitle"
                        [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                        class="form-control border-radius-0" id="jobTitle" maxlength="128" name="jobTitle"
                        placeholder="{{'jobTitle' | translate}}" required="required" type="text" appTitleCaseDirective>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="educationDegree">{{'educationDegree' | translate}}</label>
                    <select (change)="selectEducationDegree($event)"
                        [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                        class="custom-select border-radius-0" id="educationDegree" name="educationDegree">
                        <option selected>{{'educationDegree' | translate}}</option>
                        <option *ngFor="let educationDegree of educationDegrees"
                            [selected]="addPositionModel.educationDegree === educationDegree.value"
                            [value]="educationDegree.value">{{educationDegree.name | translate}}</option>
                    </select>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="mb-3">
                    <label class="modal-input-label" for="openingDate">{{'positionOpeningDate' | translate}}<span
                            class="text-danger">*</span></label>
                    <div class="input-group validate-me">
                        <input #openingDate [(ngModel)]="addPositionModel.openingDate"
                            [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                            class="form-control border-radius-0 datepicker" data-provide="datepicker" id="openingDate"
                            name="openingDate" placeholder="yyyy-mm-dd" required>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <i class="far fa-calendar"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label"
                        for="closingDateForJobPost">{{'closingDateForJobPost' | translate}}</label>
                    <div class="input-group">
                        <input #closingDate [(ngModel)]="addPositionModel.closingDate"
                            [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                            class="form-control border-radius-0 datepicker" data-provide="datepicker"
                            id="closingDateForJobPost" name="closingDateForJobPost" placeholder="yyyy-mm-dd">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <i class="far fa-calendar"></i>
                            </div>
                        </div>
                    </div>
                </div>               
                <div class="mb-3">
                    <label class="modal-input-label" for="client">{{'client' | translate}}</label>
                    <div class="input-group">
                        <select (change)="selectClient($event)"
                            [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                            class="custom-select border-radius-0" id="client" name="client">
                            <option selected>{{'client' | translate}}</option>
                            <option *ngFor="let client of clients"
                                [selected]="addPositionModel.client?.id === client.id" [value]="client.id">
                                {{client.name}}</option>
                        </select>
                        <div class="input-group-append" title="{{'showOnThePost' | translate}}">
                            <div class="input-group-text">
                                <input [(ngModel)]="addPositionModel.showClientOnJobPost"
                                    [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                                    aria-label="Radio button for following text input" name="clientCheckbox"
                                    type="checkbox">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="maximumSalary">{{'maximumSalary' | translate}}</label>
                    <div class="input-group">
                        <input [(ngModel)]="addPositionModel.maxSalary"
                            [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                            class="form-control border-radius-0" id="maximumSalary" min="1" name="maximumSalary"
                            placeholder="{{'maximumSalary' | translate}}" type="number">
                        <div class="input-group-append" title="{{'showOnThePost' | translate}}">
                            <div class="input-group-text">
                                <input [(ngModel)]="addPositionModel.showMaxSalaryOnJobPost"
                                    aria-label="Radio button for following text input" name="maximumSalaryCheckbox"
                                    type="checkbox">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="positionName">{{'numberOfHiring' | translate}}</label>
                    <div class="input-group">
                        <input [(ngModel)]="addPositionModel.numberOfHiring"
                            [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                            class="form-control border-radius-0" id="numberOfHiring" min="1" name="numberOfHiring"
                            placeholder="{{'numberOfHiring' | translate}}" type="number">
                        <div class="input-group-append" title="{{'showOnThePost' | translate}}">
                            <div class="input-group-text">
                                <input [(ngModel)]="addPositionModel.showNumberOfHiringOnJobPost"
                                    [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                                    aria-label="Radio button for following text input" name="numberOfHiringCheckbox"
                                    type="checkbox">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="disabilityOption">{{'disabilityOption' | translate}}<span
                            class="text-danger"></span></label>
                    <select [(ngModel)]="addPositionModel.disabilityOption"
                        [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                        class="custom-select border-radius-0" id="disabilityOption" name="disabilityOption">
                        <option [selected]="addPositionModel.disabilityOption === true" [value]="true">
                            {{'yes' | translate}}
                        </option>
                        <option [selected]="!addPositionModel.disabilityOption === false" [value]="false">
                            {{'no' | translate}}</option>
                    </select>
                </div>
            </div>   
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="mb-3 validate-me">
                    <label  class="modal-input-label" for="jobDescription" >{{'jobDescription' | translate   }}<span
                            class="text-danger">*</span></label>
                    <textarea [(ngModel)]="addPositionModel.jobDescription"
                        [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                        class="form-control border-radius-0 resize-none" id="jobDescription" maxlength="5000" name="jobDescription"
                        placeholder="{{'jobDescription' | translate}}" required rows="7"></textarea>
                </div>
                <div class="mb-3 validate-me">
                    <label class="modal-input-label mt-2" for="requirements">{{'requirements' | translate}}<span
                            class="text-danger">*</span></label>
                    <textarea [(ngModel)]="addPositionModel.requirements"
                        [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                        class="form-control border-radius-0 resize-none" id="requirements" maxlength="5000" name="requirements"
                        placeholder="{{'requirements' | translate}}" required rows="7"></textarea>
                </div>
            </div>  
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <div class="row">
                    <p class="col" style="border-bottom: 3px solid #f6f6f6"></p>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <p class="light-black"><b>{{'forRecruiter' | translate}}</b></p>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="mb-3">
                    <label class="modal-input-label" for="currency">{{'currency' | translate}}</label>
                    <app-searchable-dropdown (selectResult)="selectCurrency($event)"
                        [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                        [items]="currencies" [selectedItem]="addPositionModel.currency?.value" id="currency">
                    </app-searchable-dropdown>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="positionFee">{{'positionFee' | translate}}</label>
                    <input [(ngModel)]="addPositionModel.positionFee"
                        [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                        class="form-control border-radius-0" id="positionFee" min="1" name="positionFee"
                        placeholder="{{'positionFee' | translate}}" type="number">
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="jobPostDuration">{{'jobPostDuration' | translate}}</label>
                    <input [(ngModel)]="addPositionModel.jobPostDuration"
                        [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                        class="form-control border-radius-0" id="jobPostDuration" min="1" name="jobPostDuration"
                        placeholder="{{'jobPostDuration' | translate}}" type="number">
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="mb-3">
                    <label class="modal-input-label" for="commissionFee">{{'commissionFee' | translate}}</label>
                    <input [(ngModel)]="addPositionModel.commissionFee"
                        [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                        class="form-control border-radius-0" id="commissionFee" min="1" name="commissionFee"
                        placeholder="{{'commissionFee' | translate}}" type="number">
                </div>
                <div class="mb-3">
                    <label class="modal-input-label"
                        for="estimatedInvoiceAmount">{{'estimatedInvoiceAmount' | translate}}</label>
                    <input [(ngModel)]="addPositionModel.estimatedInvoiceAmount"
                        [disabled]="!permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])"
                        class="form-control border-radius-0" id="estimatedInvoiceAmount" name="estimatedInvoiceAmount"
                        placeholder="{{'estimatedInvoiceAmount' | translate}}" type="text">
                </div>
            </div>
            <!--<div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
               
            </div> -->
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <div class="row">
                    <p class="col" style="border-bottom: 3px solid #f6f6f6"></p>
                </div>
            </div>
        </div>
        <!-- <div class="row mt-3">
             <div class="col-12">
                 <p class="light-black"><b>{{'attachmentsFromClient' | translate}}</b></p>
             </div>
         </div>
         <div class="row mt-3">
             <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                 <div class="row">
                     <div class="col-6 mb-3">
                         <p class="attachments-dir width-100">
                             <i class="fas fa-file-alt"></i>
                             <span class="ml-2">Mustafa_ER.pdf</span>
                             <span class="float-right attachments-op">
                                 <span>1.5 MB</span>
                                 <i class="fas fa-download ml-2"></i>
                             </span>
                         </p>
                     </div>
                 </div>
             </div>
             <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                 <button class="btn border-radius-0 form-control upload-button">{{'upload' | translate}}</button>
             </div>
         </div>-->
        <div *ngIf="permissionService.permissionsOnly(['CREATE_POSITION','UPDATE_POSITION'])" class="row mt-3">
            <div class="col-12 p-0">
                <div class="modal-footer">
                    <button (click)="cancel()"
                        class="btn btn-warning border-radius-0 col-xl-auto col-lg-auto col-md-auto col-sm-12"
                        data-dismiss="modal" type="button">{{'cancel' | translate}}</button>
                    <button (click)="positionOperation(formPosition,openingDate.value,closingDate.value)"
                        class="btn btn-success border-radius-0 col-xl-auto col-lg-auto col-md-auto col-sm-12"
                        type="button">{{'save' | translate}}</button>
                </div>
            </div>
        </div>
        <div class="width-100 mb-5"></div>
    </form>
</div>
