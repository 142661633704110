import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClientModel} from '../../client.model';
import {AuthService} from '../../../../services/auth/auth.service';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';

@Component({
    selector: 'app-delete-client',
    templateUrl: './delete-client.component.html',
    styleUrls: ['./delete-client.component.scss']
})
export class DeleteClientComponent implements OnInit {

    @Output() setClientScreen = new EventEmitter();
    @Input() client: ClientModel;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.client = new ClientModel();
    }

    cancel() {
        // @ts-ignore
        $('#deleteClient').modal('hide');
    }

    deleteClient() {
        this.authService.ServerDelete(server.clients + '/' + this.client.id)
            .subscribe(res => {
                this.notification.success(this.translate.transform('deleteSuccessful', []));
                this.updateClients();
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.cancel();
                this.spinner.hide();
            });
    }

    updateClients() {
        this.setClientScreen.emit();
    }

}
