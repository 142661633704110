<!--Edit recommendation education Modal -->
<div aria-hidden="true" aria-labelledby="editRecommendationOperationTitle" class="modal fade" id="editRecommendation"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">
                    {{ "editRecommendation" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="name">{{"name" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [(ngModel)]="recommendation.name"
                                       class="form-control border-radius-0"
                                       id="name"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="title">{{"title" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [(ngModel)]="recommendation.title"
                                       class="form-control border-radius-0"
                                       id="title"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="email">{{'email' | translate}}<span
                                        class="text-danger">*</span></label>

                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text login-input-icon-password">
                                            <i class="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input [(ngModel)]="recommendation.email"
                                           class="form-control border-radius-0"
                                           id="email"
                                           name="email"
                                           placeholder="{{'emailPlaceholder' | translate}}" type="email">
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="phone">{{'phone' | translate}}<span
                                        class="text-danger">*</span></label>

                                <div class="input-group">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text login-input-icon-password">
                                        <i class="fas fa-phone"></i>
                                    </span>
                                    </div>
                                    <input [(ngModel)]="recommendation.phone"
                                           class="form-control border-radius-0"
                                           id="phone"
                                           name="phone"
                                           placeholder="{{'phonePlaceholder' | translate}}" type="text">
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="content">{{"content" | translate}}<span
                                        class="text-danger">*</span></label>
                                <textarea [(ngModel)]="recommendation.content"
                                          class="form-control border-radius-0"
                                          id="content"
                                          name="content"
                                          required="required"
                                          rows="4" appTitlecaseLower></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="cancel()" class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="save()" class="btn btn-success border-radius-0" type="button">
                    {{ "save" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
