<!--Attachment Import Modal -->
<div aria-hidden="true" aria-labelledby="importAttachment" class="modal fade" id="importAttachment"
     role="dialog"
     tabindex="-1">  
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">
                    {{ "importAttachment" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div style="margin-top: 15px; padding: 0 12px 12px;">
                                    <div class="input-group mb-3">
                                        <input aria-describedby="basic-addon2" class="form-control"
                                               readonly required
                                               type="text" value="{{selectedFileName}}">
                                        <input #fileUpload [accept]="acceptableFileTypes" id="fileUpload"
                                               name="fileUpload"
                                               style="display: none;" type="file">
                                        <div class="input-group-append">
                                            <button (click)="onClick()"
                                                    class="form-control"
                                                    id="basic-addon2"
                                                    style="border-bottom-left-radius: 0; border-top-left-radius: 0; cursor: pointer;">{{'chooseFile'| translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="cancelImportCandidate()" class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="saveAttachment()" [disabled]="!selectedFileName"
                        class="btn btn-success border-radius-0" type="button">
                    {{ "save" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

