import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserModel} from '../../user-management/user.model';
import {PermissionService} from '../../../services/permission/permission.service';
import {AuthService} from '../../../services/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ParameterService} from '../../../services/parameters/parameter.service';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../../services/notification/notification.service';
import {ReportFilterModel} from './report-filter.model';
import {server} from '../../../services/server';
import {PositionModel} from '../../positions/position.model';
import {ClientModel} from '../../clients/client.model';
import {CandidateModel} from '../../candidates/candidate.model';

@Component({
    selector: 'app-report-filter',
    templateUrl: './report-filter.component.html',
    styleUrls: ['./report-filter.component.scss']
})
export class ReportFilterComponent implements OnInit {
    companyUsers: UserModel[] = [];
    positions: PositionModel[] = [];
    clients: ClientModel[] = [];
    candidates: CandidateModel[] = [];

    filterState: boolean;

    reportFilter: ReportFilterModel;
    filterData: ReportFilterModel;
    @Output() applyFilterOperation = new EventEmitter();

    constructor(public permissionService: PermissionService, private authService: AuthService,
                private spinner: NgxSpinnerService, private parameterService: ParameterService, private route: ActivatedRoute,
                private notification: NotificationService) {
        this.reportFilter = new ReportFilterModel();
    }

    ngOnInit(): void {
        this.getCompanyUsers();
        this.getPositions();
        this.getCandidates();
        this.getClients();
    }

    getCompanyUsers() {
        this.parameterService.companyUsers.subscribe((res: UserModel[]) => {
            this.companyUsers = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getPositions() {
        this.authService.ServerGet(server.positions)
            .subscribe((res: { content: PositionModel[] }) => {
                this.positions = res.content;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    getClients() {
        this.parameterService.getAllClients().subscribe((res: ClientModel[]) => {
            this.clients = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCandidates() {
        this.authService.ServerGet(server.candidates)
            .subscribe((res: { content: CandidateModel[] }) => {
                this.candidates = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    selectCompanyUser(selectedUserList) {
        this.reportFilter.user = '';
        for (const selectedUser of selectedUserList) {
            if (this.reportFilter.user === '') {
                this.reportFilter.user = selectedUser.id;
            } else {
                this.reportFilter.user = this.reportFilter.user + ',' + selectedUser.id;
            }
        }
    }

    selectPosition(selectedPositionList) {
        this.reportFilter.position = '';
        for (const selectedPosition of selectedPositionList) {
            if (this.reportFilter.position === '') {
                this.reportFilter.position = selectedPosition.id;
            } else {
                this.reportFilter.position = this.reportFilter.position + ',' + selectedPosition.id;
            }
        }
    }

    selectClient(selectedClientList) {
        this.reportFilter.client = '';
        for (const selectedClient of selectedClientList) {
            if (this.reportFilter.client === '') {
                this.reportFilter.client = selectedClient.id;
            } else {
                this.reportFilter.client = this.reportFilter.client + ',' + selectedClient.id;
            }
        }
    }

    selectCandidate(selectedCandidateList) {
        this.reportFilter.candidate = '';
        for (const selectedPipelineStep of selectedCandidateList) {
            if (this.reportFilter.candidate === '') {
                this.reportFilter.candidate = selectedPipelineStep.id;
            } else {
                this.reportFilter.candidate = this.reportFilter.candidate + ',' + selectedPipelineStep.id;
            }
        }
    }

    closeFilterPopup(e) {
        if (e.target.id !== 'filterButton') {
            this.filterState = false;
        }
    }


    applyFilter() {
        if (JSON.stringify(this.filterData) === JSON.stringify(this.reportFilter)) {
            return false;
        }
        this.filterData = new ReportFilterModel();
        this.filterData = {...this.reportFilter};
        this.filterState = false;
        this.applyFilterOperation.emit(this.filterData);
    }

}
