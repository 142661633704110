<!--User Modal -->
<div aria-hidden="true" aria-labelledby="addStepNoteTitle" class="modal fade" id="pipelineStepNoteModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title" id="addUserLongTitle">{{'addStepNote' | translate}}</h5>
            </div>
            <div class="modal-body">
                <div class="mb-2">
                    <label class="modal-input-label">{{'candidateName' | translate}}&nbsp;:&nbsp;&nbsp;{{candidate.name}}</label>
                </div>
                <div class="mb-2">
                    <label class="modal-input-label">{{'position' | translate}}&nbsp;:&nbsp;&nbsp;{{candidate.positionName}}</label>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label">{{'step' | translate}}&nbsp;:&nbsp;&nbsp;{{candidate.recruitmentStepName}}</label>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="positionStepNote">{{'note' | translate}}</label>
                    <textarea [(ngModel)]="candidate.note" class="form-control border-radius-0"
                              id="positionStepNote"
                              name="positionStepNote"
                              rows="4" appTitlecaseLower></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">{{'cancel' | translate}}</button>
                <button (click)="addCandidateStepNote()" class="btn btn-success border-radius-0"
                        type="button">{{'save' | translate}}</button>
            </div>
        </div>
    </div>
</div>
