import {Component, Input, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';
import {CandidateModel} from '../../candidate.model';

@Component({
    selector: 'app-candidate-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.scss']
})
export class CandidateGeneralComponent implements OnInit {

    @Input() candidateId: string;
    generalInfo: CandidateModel;
    editGeneralInfoState: boolean;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.generalInfo = new CandidateModel();
        this.editGeneralInfoState = false;
    }

    ngOnInit(): void {
        this.getGeneralInfo();
    }

    getGeneralInfo() {
        this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/general-info')
            .subscribe((res: CandidateModel) => {
                this.generalInfo = res;
                this.editGeneralInfoState = true;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    editGeneralInfoModal() {
        // @ts-ignore
        $('#editGeneralInfo').modal('show');
    }

    updateContent() {
        this.getGeneralInfo();
    }

}
