<div class="row mt-4">
    <div class="col-auto">
        <app-calendar-filter (applyFilterOperation)="applyFilter($event)"></app-calendar-filter>
    </div>
</div>
<div class="container-fluid">
    <div class="row mt-3" style="background-color: #f6f6f6;">
        <div class="col-12 pb-3">
            <div class="row text-center pt-2 pb-1">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-xl-left text-lg-left text-md-left text-sm-center">
                    <h3 class="color-orange">{{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}</h3>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center mb-1">
                    <div class="btn-group">
                        <div (click)="setView(CalendarView.Month)"
                             [class.active]="view === CalendarView.Month"
                             class="btn bg-orange color-white border-radius-0"
                        >
                            {{'month' | translate}}
                        </div>
                        <div (click)="setView(CalendarView.Week)"
                             [class.active]="view === CalendarView.Week"
                             class="btn bg-orange color-white border-radius-0 ml-2"
                        >
                            {{'week' | translate}}
                        </div>
                        <div (click)="setView(CalendarView.Day)"
                             [class.active]="view === CalendarView.Day"
                             class="btn bg-orange color-white border-radius-0 ml-2"
                        >
                            {{'day' | translate}}
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-xl-right text-lg-right text-md-right text-sm-center">
                    <div class="btn-group">
                        <div (click)="changeDate()"
                             [(viewDate)]="viewDate" [view]="view"

                             class="btn bg-orange color-white border-radius-0"
                             mwlCalendarPreviousView
                        >
                            <i class="fas fa-chevron-left"></i>
                        </div>
                        <div (click)="changeDate()" [(viewDate)]="viewDate"

                             class="btn bg-orange color-white border-radius-0 ml-2"
                             mwlCalendarToday
                        >
                            {{'today' | translate}}
                        </div>
                        <div (click)="changeDate()"
                             [(viewDate)]="viewDate"
                             [view]="view"
                             class="btn bg-orange color-white border-radius-0 ml-2"
                             mwlCalendarNextView
                        >
                            <i class="fas fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngSwitch]="view">
                <mwl-calendar-month-view
                        (dayClicked)="dateClicked($event.day.events)"
                        *ngSwitchCase="CalendarView.Month"
                        [activeDayIsOpen]="activeDayIsOpen"
                        [events]="events"
                        [locale]="locale"
                        [refresh]="refresh"
                        [viewDate]="viewDate"
                        weekStartsOn="1"
                >
                </mwl-calendar-month-view>
                <mwl-calendar-week-view
                        (eventClicked)="dateClicked($event.event)"
                        *ngSwitchCase="CalendarView.Week"
                        [events]="events"
                        [refresh]="refresh"
                        [viewDate]="viewDate"
                        weekStartsOn="1"
                >
                </mwl-calendar-week-view>
                <mwl-calendar-day-view
                        (eventClicked)="dateClicked($event.event)"
                        *ngSwitchCase="CalendarView.Day"
                        [events]="events"
                        [refresh]="refresh"
                        [viewDate]="viewDate"
                >
                </mwl-calendar-day-view>
            </div>
        </div>
    </div>
</div>

<app-calendar-interview-modal (openEditInterview)="openEditInterview($event)"
                              (openRemoveInterview)="openDeleteInterview($event)"
                              [interviews]="selectedDay"></app-calendar-interview-modal>

<app-delete-interview-modal (interviewUpdate)="updateInterviews()"
                            *ngIf="permissionService.permissionsOnly(['DELETE_INTERVIEW'])"
                            [interviewId]="selectedInterviewId"></app-delete-interview-modal>
<app-edit-interview-modal (interviewUpdate)="updateInterviews()"
                          *ngIf="permissionService.permissionsOnly(['UPDATE_INTERVIEW'])"
                          [interviewInfo]="selectedInterview"></app-edit-interview-modal>
