<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div style="margin-top: 15px; padding: 0 12px 12px;">
                    <div *ngFor="let file of files" class="mb-2">
                        <div class="progress">
                            <div [style.width]="file.value + '%'" aria-valuemax="100" aria-valuemin="0"
                                 class="progress-bar" role="progressbar"></div>
                        </div>
                        <span id="file-label"></span>
                    </div>
                    <div class="input-group mb-3">
                        <input aria-describedby="basic-addon2" class="form-control"
                               readonly required
                               type="text" value="{{selectedFileName}}">
                        <input #fileUpload accept="application/pdf" id="fileUpload" name="fileUpload"
                               style="display: none;" type="file">
                        <div class="input-group-append">
                            <button (click)="onClick()"
                                    class="form-control"
                                    id="basic-addon2"
                                    style="border-bottom-left-radius: 0; border-top-left-radius: 0; cursor: pointer;">{{'chooseFile'| translate}}
                            </button>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-6">
                                <button (click)="importCandidate()" class="btn btn-success form-control"
                                        id="f-upload"
                                        [disabled]="!selectedFileName"
                                        name="upload"
                                        style="height: 40px;text-shadow: none; margin-bottom: 0; border-radius: 3px;"
                                        type="submit">{{'save' | translate}}
                                </button>
                            </div>
                            <div class="col-6">
                                <button (click)="cancelImportCandidate()" class="btn btn-warning form-control"
                                        id="cancel-upload" name="upload"
                                        style="height: 40px;text-shadow: none; margin-bottom: 0; border-radius: 3px;"
                                        type="submit">{{'cancel' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
