import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CandidateModel} from '../../../candidate.model';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../services/permission/permission.service';
import {NotificationService} from '../../../../../services/notification/notification.service';
import {SourceList} from '../../../../../shared/lists/source.list';
import {CountryModel} from '../../../../../services/parameters/models/country.model';
import {CityModel} from '../../../../../services/parameters/models/city.model';
import {server} from '../../../../../services/server';
import {Items} from '../../../../../shared/appItems';
import { NgForm } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
    selector: 'app-candidate-edit-profile',
    templateUrl: './candidate-edit-profile.component.html',
    styleUrls: ['./candidate-edit-profile.component.scss']
})
export class CandidateEditProfileComponent implements OnInit, OnChanges {

    @Input() candidate: CandidateModel;
    @Output() updateProfileInfo = new EventEmitter();
    tempCandidate: CandidateModel;
    sourceList = SourceList;
    countries: CountryModel[];
    cities: CityModel[];
    tempUserImg: string;
    isSaveClick = false;
    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService,
                private utilitiesService: UtilitiesService) {
        this.candidate = new CandidateModel();
        this.tempUserImg = Items.tempUserImg;
    }

    ngOnInit(): void {
        // @ts-ignore
        $('.datepicker').datepicker({
            todayHighlight: true,
            autoclose: true,
            format: 'yyyy-mm-dd',
            language: this.authService.getSessionLanguage(),
        });
        this.getCountries();


    }

    numericOnly(event): boolean {    
        let patt = /^([0-9])$/;
        let result = patt.test(event.key);
        return result;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.tempCandidate = new CandidateModel();
        this.tempCandidate = {...this.candidate};

        if (!this.candidate.country){
            this.tempCandidate.country = new CountryModel();
            this.tempCandidate.city = new CityModel();

        }
        if (this.tempCandidate.country && this.tempCandidate.country.id) {
            this.getCountryCities(this.tempCandidate.country.id);
        }
    }

    selectSource(e) {
        this.candidate.source = e.target.value;
    }

    selectCountry(country) {
        this.candidate.country = new CountryModel();
        if (country.id === '') {
            this.candidate.country.id = null;
            this.tempCandidate.country.id = null;
            this.cities = [];
        } else {
            this.candidate.country.id = country.id;
            this.tempCandidate.country.id = country.id;
            this.getCountryCities(this.candidate.country.id);
        }
    }

    selectCity(city) {
        this.candidate.city = new CityModel();
        if (city.id === '') {
            this.candidate.city.id = null;
            this.tempCandidate.city.id = null;
        } else {
            this.candidate.city.id = city.id;
            this.tempCandidate.city.id = city.id;

        }
    }


    getCountries() {
        this.parameterService.countries.subscribe((res: CountryModel[]) => {
            this.countries = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCountryCities(countryId) {
        this.cities = [];
        this.parameterService.getCountryCities(countryId).subscribe((res: CityModel[]) => {
            this.cities = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    saveCandidateProfileInfo(formEditCandidate: NgForm, dateOfBirth) {
        this.candidate.dateOfBirth = dateOfBirth;
        formEditCandidate.controls.dateOfBirth.setValue(dateOfBirth);
        this.isSaveClick = true;

        this.candidate.country = this.tempCandidate.country;
        this.candidate.city = this.tempCandidate.city;


        if (!formEditCandidate.valid ) {
            this.utilitiesService.validationClassState('validate-meEdit', 'add');
            this.notification.warning(this.translate.transform('fillRequiredFields', []));
            if (!formEditCandidate.controls.secondaryEmail.valid){
                const secondaryEmail = document.getElementById('secondaryEmail');
                secondaryEmail.classList.add('is-invalid');
            }
            return;
          }




        this.authService.ServerPut(server.candidates + '/' + this.candidate.id + '/profile', this.candidate)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.updateProfileInfo.emit();
                this.closeCandidateProfileInfoModal();
                this.spinner.hide();
            });
    }

    closeCandidateProfileInfoModal() {
        // @ts-ignore
        $('#editCandidateProfile').modal('hide');
    }

    secondaryEmailValidaiton(){
        const secondaryEmail = document.getElementById('secondaryEmail');
        secondaryEmail.classList.remove('is-invalid');
    }



}
