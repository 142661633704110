import {Component, OnInit} from '@angular/core';
import {InterviewModel} from '../../services/parameters/models/interview.model';
import {server} from '../../services/server';
import {AuthService} from '../../services/auth/auth.service';
import {ParameterService} from '../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {NotificationService} from '../../services/notification/notification.service';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
    interviews: InterviewModel[];
    reportListViewComponent: boolean;
    // reportTimeFillComponent: boolean;
    reportPositionComponent: boolean;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                private notification: NotificationService) {
        this.setTab('Events');
    }


    ngOnInit(): void {
    }

    setTab(component): void {
        this.reportListViewComponent = false;
        this.reportPositionComponent = false;
        // this.reportTimeFillComponent = false;

        switch (component) {
            case 'onEvents':
                this.reportListViewComponent = true;
                break;
            case 'onPosition':
                this.reportPositionComponent = true;
                break;
           /* case 'onTimeFill':
                this.reportTimeFillComponent = true;
                break;*/

            default:
                this.reportListViewComponent = true;
                break;
        }

    }

    getInterviews(monthlyFilter): void {
        this.authService.ServerGet(server.interviews + monthlyFilter)
            .subscribe((res: any) => {
                this.interviews = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

}
