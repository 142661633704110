<!-- Modal -->
<div aria-hidden="true" aria-labelledby="positionRequestDetailsModalTitle" class="modal fade"
     id="positionRequestDetailsModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="positionRequestDetailsModalLongTitle">{{'positionRequestDetail' | translate}}</h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid"> 
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <p><b>{{"positionName" | translate}}:&nbsp;</b>{{positionDetails.name}}</p>
                            </div>
                            <div class="mb-3">
                                <p><b>{{"jobTitle" | translate}}:&nbsp;</b>{{positionDetails.jobTitle}}</p>
                            </div>
                            <div class="mb-3">
                                <p><b>{{"jobType" | translate}}:&nbsp;</b>{{positionDetails.jobType | translate}}</p>
                            </div>
                            <div class="mb-3">
                                <p><b>{{"requirements" | translate}}
                                    :&nbsp;</b>{{positionDetails.requirements}}
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <p><b>{{"country" | translate}}:&nbsp;</b>{{positionDetails.country.value}}</p>
                            </div>
                            <div class="mb-3">
                                <p><b>{{"city" | translate}}:&nbsp;</b>{{positionDetails.city.value}}</p>
                            </div>
                            <div class="mb-3">
                                <p><b>{{"user" | translate}}:&nbsp;</b>
                                    <app-user *ngIf="positionDetails.createdBy"
                                              [userId]="positionDetails.createdBy"></app-user>
                                </p>
                            </div>
                            <div class="mb-3">
                                <p><b>{{"requestDate" | translate}}:&nbsp;</b>{{positionDetails.createdAt | date}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning border-radius-0"
                        data-dismiss="modal"
                        type="button">{{'cancel' | translate}}</button>
                <button (click)="openCreatePosition()" class="btn btn-success border-radius-0"
                        type="button">{{'createPosition' | translate}}</button>
            </div>
        </div>
    </div>
</div>
