<div class="container-fluid">
    <div class="row">
        <div class="col-12 menu-item gradient-bg">
            <a class="nav-link active">{{'clients' | translate}}</a>
        </div>
    </div>
</div>
<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-12">
            <app-client-list></app-client-list>
        </div>
    </div>
</div>