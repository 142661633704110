<div class="dropdown">
    <button (click)="filterState = !filterState"
            class="btn btn-secondary dropdown-toggle border-radius-0 form-control"
            id="filterButton"
            type="button">
        <span class="fas fa-filter float-left mt-1 mr-2"></span>{{'filter' | translate}}
    </button>
    <div (clickOutside)="closeFilterPopup($event)" [style.display]="filterState ? 'block':'none'"
         aria-labelledby="dropdownMenuButton" class="color-dropdown border-radius-0 mt-1 position-absolute"
         style="position: fixed; z-index: 5000; width: 100% !important;">
        <div>
            <a aria-controls="position"
               aria-expanded="false" class="dropdown-item color-dropdown-item text-center"
               data-toggle="collapse" href="#position"
               role="button">
                {{'position' | translate}}<span *ngIf="this.reportFilter.position !==''" class="text-danger">*</span>
            </a>
            <div class="collapse" id="position">
                <app-multiselect-dropdown (selectResult)="selectPosition($event)"
                                          [isSearchable]="true"
                                          [items]="positions"
                                          dropdownName="position"
                                          translateObjectKey="name">
                </app-multiselect-dropdown>
            </div>
        </div>

        <div>
            <a aria-controls="user"
               aria-expanded="false" class="dropdown-item color-dropdown-item text-center"
               data-toggle="collapse" href="#companyUser"
               role="button">
                {{'companyUser' | translate}}<span *ngIf="this.reportFilter.user !==''" class="text-danger">*</span>
            </a>
            <div class="collapse" id="companyUser">
                <app-multiselect-dropdown (selectResult)="selectCompanyUser($event)"
                                          [isSearchable]="true"
                                          [items]="companyUsers"
                                          dropdownName="companyUser"
                                          translateObjectKey="fullName">
                </app-multiselect-dropdown>
            </div>
        </div>
        <div>
            <a aria-controls="positionType"
               aria-expanded="false" class="dropdown-item color-dropdown-item text-center"
               data-toggle="collapse" href="#client"
               role="button">
                {{'client' | translate}}<span *ngIf="this.reportFilter.client !==''" class="text-danger">*</span>
            </a>
            <div class="collapse" id="client">
                <app-multiselect-dropdown (selectResult)="selectClient($event)"
                                          [isSearchable]="true"
                                          [items]="clients"
                                          dropdownName="client"
                                          translateObjectKey="name">
                </app-multiselect-dropdown>
            </div>
        </div>
        <div>
            <a aria-controls="candidate"
               aria-expanded="false" class="dropdown-item color-dropdown-item text-center"
               data-toggle="collapse" href="#candidate"
               role="button">
                {{'candidate' | translate}}<span *ngIf="this.reportFilter.candidate !==''" class="text-danger">*</span>
            </a>
            <div class="collapse" id="candidate">
                <app-multiselect-dropdown (selectResult)="selectCandidate($event)"
                                          [isSearchable]="true"
                                          [items]="candidates"
                                          dropdownName="candidate"
                                          translateObjectKey="fullName">
                </app-multiselect-dropdown>
            </div>
        </div>
        <div>
            <button (click)="applyFilter()"
                    class="exactalent-button border-radius-0 apply-filter">{{'applyFilters' | translate}}</button>
        </div>
    </div>
</div>
