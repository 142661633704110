export const LANG_DE_NAME = 'de';

export const LANG_DE_TRANS = {
    appName: 'App Name',
    welcome: 'Willkommen',
    user: 'Benutzer',
    signIn: 'Sign In',
    signUp: 'Anmelden',
    logOut: 'Ausloggen',
    loginErrorInfo: 'Benutzername oder Passwort ist falsch. Bitte überprüfen Sie Ihre Anmeldeinformationen.',
    loginErrorGeneralInfo: 'Ein allgemeines Problem ist aufgetreten. Bitte versuchen Sie es später noch einmal.',
    updateSuccessful: 'Aktualisierung ist erfolgreich',
    createSuccessful: 'Erfolgreich erstellt',
    deleteSuccessful: 'erfolgreich gelöscht',
    passwordRequestSuccessful: 'Passwortanfrage wurde erfolgreich gesendet',
    saveSuccessful: 'Erfolgreich gespeichert',
    previous: 'Bisherige',
    next: 'Nächster',

    dashboard: 'Home',
    home: 'Home',
    calendar: 'Kalender',
    positions: 'Positionen',
    reports: 'Berichte',
    candidates: 'Kandidaten',
    pools: 'Pools',
    clients: 'Klientel',
    profile: 'Profil',
    userSettings: 'Benutzereinstellungen',
    companySettings: 'Firmeneinstellungen',
    welcomeTo: 'Willkommen zu',

    emailAddress: 'E-Mail-Addresse',
    password: 'Passwort',
    passwordAgain: 'Passwort erneut',
    forgotMyPassword: 'Ich habe mein Passwort vergessen!',
    forgotYourPassword: 'Haben Sie Ihr Passwort vergessen?',
    forgotPasswordTitle: 'Bitte geben Sie Ihre E-Mail-Adresse für Ihr Konto ein und folgen Sie dem Link, der per E-Mail gesendet wird, um Ihr Passwort zurückzusetzen.',
    resetPasswordTitle: 'Sie wurden als Benutzer zum Team des Unternehmens hinzugefügt. Nachdem Sie unten Ihr Passwort festgelegt haben, können Sie sich in Ihrem Konto anmelden.',
    goBack: 'Zurück',
    send: 'Senden',
    save: 'Speichern',

    userManagement: 'Benutzerverwaltung',
    recruitmentPreferences: 'Rekrutierungspräferenzen',
    careerPortalSettings: 'Karriereportal-Einstellungen',

    fullName: 'Vollständiger Name',
    name: 'Name',
    middleName: 'Zweiter Vorname',
    surname: 'Nachname',
    username: 'Nutzername',

    add: 'Hinzufügen',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    cancel: 'Stornieren',
    clone: 'Klon',
    email: 'Email',


    addUser: 'Benutzer hinzufügen',
    userType: 'Benutzertyp',
    oldPassword: 'Altes Passwort',
    newPassword: 'Neues Passwort',
    newPasswordAgain: 'Neues Passwort wieder',

    stepName: 'Schrittname',
    addNewStep: 'Neuen Schritt hinzufügen',

    companyLogo: 'Firmenlogo',
    headerImage: 'Header Image',
    header: 'Header',
    title: 'Titel',
    subtitle: 'Untertitel',
    description: 'Beschreibung',
    headerColor: 'Header Color',

    // social

    websiteLink: 'Website Link',
    websiteLinkPlaceholder: 'https://example.com',
    twitterAccount: 'Twitter Account',
    twitterAccountPlaceholder: 'Twitter Account Name',
    facebookAccount: 'Facebook Account',
    facebookAccountPlaceholder: 'Facebook Account Name',
    linkedinAccount: 'Linkedin Account',
    linkedinAccountPlaceholder: 'Linkedin Account Name',
    instagramAccount: 'Instagram Account',
    instagramAccountPlaceholder: 'Instagram Account Name',
    githubAccount: 'Github Account',
    githubAccountPlaceholder: 'Github Account Name',
    youtubeAccount: 'Youtube Account',
    youtubeAccountPlaceholder: 'Youtube Account Name',
    behanceAccount: 'Behance Account',
    behanceAccountPlaceholder: 'Behance Account Name',
    stackoverflowAccount: 'Stackoverflow Account',
    stackoverflowAccountPlaceholder: 'Stackoverflow Account Name',

    // color

    black: 'Schwarz',
    white: 'Weiss',
    green: 'Grün',
    blue: 'Blau',
    yellow: 'Gelb',
    gray: 'Grau',
    red: 'Rot',
    orange: 'Orange',
    language: 'Sprache',
    languages: 'Sprachen',

    //
    changeProfilePhoto: 'Profilfoto ändern',
    deleteProfilePhoto: 'Profilfoto löschen',
    confirmdeleteProfilePhoto: 'Das Profilfoto wird entfernt, möchten Sie fortfahren',

    active: 'Aktiv',
    passive: 'Passiv',
    yes: 'Ja',
    true: 'Ja',
    no: 'Nein',
    false: 'Nein',

    status: 'Status',
    resetPassword: 'Passwort zurücksetzen',

    // position table
    country: 'Land',
    city: 'Stadt',
    positionType: 'Positionstyp',
    opening: 'Eröffnung',
    created: 'Erstellt',
    users: 'Benutzer',
    myPositions: 'Meine Positionen',
    allPositions: 'Alle Positionen',
    addNewPosition: 'Neue Position hinzufügen',
    general: 'Allgemeines',
    hiringTeam: 'Einstellungsteam',
    forCandidate: 'Für Kandidaten',
    positionName: 'Positionsname',
    jobTitle: 'Job Title',
    jobType: 'Jobtyp',
    educationDegree: 'Bildungsgrad',
    closingDateForJobPost: 'Bewerbungsschluss',
    positionOpeningDate: 'Positionseröffnungsdatum',
    client: 'Klient',
    maximumSalary: 'Maximales Gehalt',
    numberOfHiring: 'Anzahl der Personen',
    disabilityOption: 'Option für Behinderte',
    jobDescription: 'Arbeitsbeschreibung',
    requirements: 'Bedarf',
    showOnThePost: 'Auf dem Post anzeigen',
    forRecruiter: 'Für Personalvermittler',
    service: 'Service',
    positionFee: 'Positionsgebühr',
    currency: 'Währung',
    commissionFee: 'Provisionsgebühr',
    estimatedInvoiceAmount: 'Geschätzter Rechnungsbetrag',
    jobPostDuration: 'Dauer der Stellenausschreibung',
    attachmentsFromClient: 'Anhänge vom Client',
    upload: 'Hochladen',
    addToPosition: 'Zur Position hinzufügen',
    addToPositionPlaceholder: 'Vor- und Nachname, E-Mail',
    removeTeamMember: 'Teammitglied entfernen',
    remove: 'Entfernen',
    editSuccessful: 'Position wurde erfolgreich gespeichert',

    // education degree
    highSchool: 'Weiterführende Schule',
    graduate: 'Absolvent',
    postGraduate: 'Postgraduierter',
    doctorate: 'Doktorat',

    // job title
    permanent: 'Permanent',
    temporary: 'Vorübergehend',
    fullTime: 'Vollzeit',
    partTime: 'Teilzeit',
    remote: 'Remote',

    // position status list
    draft: 'Entwurf',
    closed: 'Geschlossen',
    cancelled: 'Storniert',
    paused: 'Pausiert',
    archived: 'Archiviert',

    filter: 'Filter',

    showPositionDetails: 'Positionsdetails',
    editPosition: 'Position bearbeiten',
    clonePosition: 'Klonposition',
    sharePosition: 'Position teilen',
    viewOnPortal: 'Auf Portal anzeigen',


    positionStatus: 'Positionsstatus',
    pipeline: 'Pipeline',
    candidate: 'Kandidat',
    calender: 'Kalender',
    history: 'Geschichte',

    moreTitle: 'und mehr',
    person: 'Person',

    changePassword: 'Passwort ändern',
    currentPassword: 'Aktuelles Passwort',

    resetPasswordMessage: 'Wir haben Ihnen eine E-Mail gesendet, bitte überprüfen Sie Ihren Posteingang!',
    resetUrlNotValid: 'URL zurücksetzen ist ungültig',
    resetPasswordSuccess: 'Das Zurücksetzen des Passworts war erfolgreich',
    applyFilters: 'Filter anwenden',
    removeFilters: 'Filter entfernen',
    all: 'Alles',

    phone: 'Phone',
    position: 'Position',
    step: 'Schritt',
    source: 'Quelle',
    added: 'Hinzugefügt',
    lastActivity: 'Letzte Aktivität',
    addNewCandidate: 'Neuen Kandidaten hinzufügen',
    newCandidate: 'Neuer Kandidat',
    importFromCv: 'Aus Lebenslauf importieren',
    addManually: 'Manuell hinzufügen',
    pool: 'Pool',
    goCandidates: 'Gehe zu Kandidaten',
    viewCv: 'Lebenslauf anzeigen',
    nocvuploaded: 'Es wurde kein Lebenslauf hochgeladen',
    downloadCv: 'Lebenslauf herunterladen',
    viewCandidateCard: 'Kandidatenkarte anzeigen',
    printCandidateCard: 'Kandidatenkarte drucken',
    downloadPdf: 'Als PDF herunterladen',
    candidateCv: 'Lebenslauf des Kandidaten',
    candidateCard: 'Kandidatenkarte',
    address: 'Adresse',
    dateOfBirth: 'Geburtsdatum',
    editProfileInfo: 'Profilinformationen bearbeiten',

    workingStatus: 'Arbeitsstatus',
    jobSearch: 'Jobsuche',
    minSalaryExpectation: 'Min. Gehaltsvorstellung',
    maxSalaryExpectation: 'Max. Gehaltsvorstellung',
    salaryExpectationUpdateDate: 'Aktualisierungsdatum der Gehaltserwartung',
    preferredWorkingLocation: 'Bevorzugter Arbeitsort',
    preferredIndustry: 'Bevorzugte Industrie',
    preferredTitle: 'Bevorzugter Titel',
    preferredJobType: 'Bevorzugter Jobtyp',
    outsourcePreferences: 'Einstellungen Auslagern',
    gender: 'Geschlecht',
    militaryService: 'Militärdienst',
    dateOfPostponement: 'Datum der Verschiebung',
    nationality: 'Nationalität',
    drivingLicence: 'Führerschein',
    smoking: 'Rauchen',

    education: 'Bildung',
    experience: 'Erfahrung',
    attachments: 'Anhänge',
    notes: 'Notizen',

    university: 'Universität',
    faculty: 'Fakultät',
    department: 'Abteilung',
    beginningDate: 'Anfangsdatum',
    endingDate: 'Enddatum',
    gpa: 'GPA',
    reading: 'Lesen',
    writing: 'Schreiben',
    speaking: 'Sprechen',
    certificates: 'Zertifikate',
    certificateTitle: 'Zertifikatstitel',
    organisation: 'Organisation',
    year: 'Jahr',
    technicalSkill: 'Technische Fähigkeit',

    workExperience: 'Berufserfahrung',
    total: 'Gesamt',
    company: 'Unternehmen',
    years: 'Jahre',
    experienceSummary: 'Erfahrungsübersicht',
    projects: 'Projekte',
    projectSummary: 'Projektzusammenfassung',


    notesPlaceholder: 'Fügen Sie Ihre persönlichen Notizen hinzu über',
    here: 'Hier',
    addNote: 'Notiz Hinzufügen',
    attachFile: 'Datei Anhängen',
    noteInfo: 'Sie haben noch keine Notizen hinzugefügt',
    privateNote: 'Private Notiz',
    privateNoteInfo: 'Diese Notiz ist privat',
    deleteNote: 'Notiz löschen',
    note: 'Note',
    editGeneralInfo: 'Allgemeine Informationen Bearbeiten',
    jobSearching: 'Jobsuche',
    salaryExpectation: 'Gehaltserwartung',
    maritalStatus: 'Familienstand',
    preferredWorkingCountry: 'Bevorzugtes Arbeitsland',
    preferredWorkingCity: 'Bevorzugte Arbeitsstadt',
    outsourcePreference: 'Präferenz Auslagern',
    min: 'Min',
    max: 'Max',
    currentlyWorking: 'Derzeit arbeitet',
    currentlyNotWorking: 'Derzeit arbeitet nicht',
    industry: 'Industrie',
    completed: 'Abgeschlossen',
    postponed: 'Verschoben',
    excused: 'Entschuldigt',
    single: 'Singel',
    married: 'Verheiratet',

    unspecified: 'Nicht spezifiziert',
    male: 'Männlich',
    female: 'Weiblich',


    addCandidateEducation: 'Ausbildung des Kandidaten Hinzufügen',
    editCandidateEducation: 'Ausbildung des Kandidaten Bearbeiten',
    deleteCandidateEducation: 'Ausbildung des Kandidaten Löschen',
    beginningEndingDate: 'Anfang * / Enddatum ',
    startYear: 'Startjahr',
    endYear: 'Endesjahr',
    native: 'Native',
    advanced: 'Advanced',
    upperIntermediate: 'Upper Intermediate',
    intermediate: 'Intermediate',
    elementary: 'Elementary',
    beginner: 'Beginner',
    readingLevel: 'Lesen',
    writingLevel: 'Schreiben',
    speakingLevel: 'Sprechen',
    addCandidateLanguage: 'Sprache des Kandidaten Hinzufügen',
    editCandidateLanguage: 'Sprache des Kandidaten Bearbeiten',
    deleteCandidateLanguage: 'Sprache des Kandidaten Löschen',
    addCandidateCertificate: 'Kandidatenzertifikat Hinzufügen',
    editCandidateCertificate: 'Kandidatenzertifikat Bearbeiten',
    deleteCandidateCertificate: 'Kandidatenzertifikat Löschen',
    certificateName: 'Zertifikatsname',
    candidateTechnicalSkillOperation: 'Technische Fähigkeiten des Kandidaten',
    candidateSoftSkillOperation: 'Soft Skill-Operation des Kandidaten',
    technicalSkills: 'Technische Fähigkeiten',
    addWorkExperience: 'Berufserfahrung Hinzufügen',
    editWorkExperience: 'Arbeitserfahrung Bearbeiten',
    deleteWorkExperience: 'Arbeitserfahrung Löschen',
    month: 'Monat',
    summary: 'Zusammenfassung',
    employmentType: 'Beschäftigungstyp',
    addProject: 'Projekt Hinzufügen',
    editProject: 'Projekt Bearbeiten',
    deleteProject: 'Projekt Löschen',
    updateStatus: 'Status Aktualisieren',
    editCandidateProfile: 'Kandidatenprofil Bearbeiten',
    jobSearchingStatusUpdateDate: 'Aktualisierungsdatum für den Status der Jobsuche',
    tags: 'Tags',
    primaryPhone: 'Primäres Telefon',
    secondaryPhone: 'Sekundäres Telefon',
    primaryEmail: 'Primäre E-Mail',
    secondaryEmail: 'Sekundäre E-Mail',
    showCandidateDetails: 'Kandidatendetails Anzeigen',
    addTag: 'Tag Hinzufügen',
    candidateTagOperation: 'Tags Bearbeiten',


    category: 'Kategorie',
    addNewPool: 'Neuen Pool Hinzufügen',
    organisationName: 'Organisationsname',
    organisationWebsiteLink: 'Organisationswebsite',
    organisationPhone: 'Organisationstelefon',
    contactPerson: 'Kontaktperson',
    contactPersonTitle: 'Titel der Kontaktperson',
    contactEmail: 'Kontakt E-Mail',
    contactPhone: 'Kontakttelefon',
    clientGroup: 'Client Group',
    addNewClient: 'Neuen Client Hinzufügen',

    poolDetails: 'Pool Details',
    addToPool: 'Zum Pool Hinzufügen',
    candidatePools: 'Kandidatenpools',
    editPool: 'Pool Bearbeiten',
    deletePool: 'Pool Löschen',
    date: 'Datum',
    details: 'Details',
    addedDate: 'Hinzugefügtes Datum',
    lastActivityDate: 'Datum der letzten Aktivität',
    candidateStatus: 'Kandidatenstatus',
    deleteCandidate: 'Kandidat Löschen',
    removeFromPosition: 'Aus Position Entfernen',
    removeFromPool: 'Aus Pool Entfernen',
    removeFromPoolConfirmation: 'Dieser Kandidat wird aus dem Pool entfernt. Möchten Sie fortfahren?',
    removeFromPositionConfirmation: 'Dieser Kandidat wird von der Position entfernt. Möchten Sie fortfahren?',
    removeCandidateConfirmation: 'Dieser Kandidat wird entfernt, möchten Sie fortfahren?',
    candidateName: 'Kandidatenname',
    addStepNote: 'Schrittnotiz Hinzufügen',
    startTime: 'Startzeit',
    endTime: 'Endzeit',
    location: 'Standort',
    week: 'Woche',
    day: 'Tag',
    today: 'Heute',
    interviewDetails: 'Details zum Interview',
    viewCandidate: 'Kandidat Anzeigen',
    editEvent: 'Ereignis Bearbeiten',
    deleteEvent: 'Ereignis Löschen',
    attendee: 'Teilnehmer',
    deleteInterview: 'Interview Löschen',
    deleteInterviewConfirmation: 'Dieses Interview wird gelöscht, möchten Sie fortfahren?',
    selectInterviewers: 'Interviewer Auswählen',
    interviewRequests: 'Interviewanfragen',
    interviewRequestStatus: 'Status der Interviewanfrage',
    createInterview: 'Interview Erstellen',
    rejectInterviewRequest: 'Interviewanfrage Ablehnen',
    confirmInterviewRequest: 'Die Interviewanfrage wird abgelehnt. Möchten Sie fortfahren?',
    addtoPositionInfo: 'zur Position hinzugefügt',
    createInterviewInfo: 'Interview erstellt',
    warnInterviewRequestOperation: 'Sie können den Status der bereits vorhandenen Anfrage nicht ändern',
    accepted: 'akzeptiert',
    declined: 'abgelehnt',
    stepSetted: 'step is set',
    onList: 'On List',
    time: 'Zeit',
    with: 'Mit',
    onCalendar: 'Im Kalender',
    firstName: 'Vorname',
    lastName: 'Nachname',
    addNewContactPerson: 'Neue Kontaktperson Hinzufügen',
    editContactPerson: 'Kontaktperson Bearbeiten',
    deleteContactPerson: 'Kontaktperson Löschen',
    showClientDetails: 'Client-Details Anzeigen',
    editClientInfo: 'Client Info Bearbeiten',
    editClient: 'Client Bearbeiten',
    deleteClient: 'Client Löschen',
    accountManagers: 'Account Managers',
    selectAccountManager: 'Account Manager Auswählen',
    successfulLastStep: 'Erfolgreicher letzter Schritt',
    hideOnMobile: 'Auf Handy Verstecken',
    recommendation: 'Empfehlung',
    content: 'Inhalt',
    addNewRecommendation: 'Neue Empfehlung Hinzufügen',
    editRecommendation: 'Empfehlung Bearbeiten',
    deleteRecommendation: 'Empfehlung Löschen',
    upcomingEvents: 'Bevorstehende Veranstaltungen',
    scheduleInterview: 'Interview Planen',
    interviewers: 'Interviewers',
    interview: 'Interview',
    addCandidate: 'Kandidat Hinzufügen',
    hiringProcess: 'Einstellungsprozess',
    hiringTeamMember: 'Teammitglied Einstellen',
    seeAllPositions: 'Alle Positionen Anzeigen',
    seeAllCandidates: 'Alle Kandidaten Anzeigen',
    apply: 'Bewerben',
    events: 'Ereignisse',
    timeToFill: 'Time To Fill',
    changeStep: 'Schritt ändern',
    softSkill: 'Soft Skill',
    pipelineSteps: 'Pipeline-Schritte',
    positionCandidates: 'Position Kandidaten',
    clientHrPermitted: 'Client Hr Erlaubt',
    clientTechnicalPermitted: 'Client Technisch Erlaubt',
    hideSensitiveDataForClient: 'Vertrauliche Daten für Client ausblenden',
    search: 'Suche',
    searchNoResult: 'Keine Ergebnisse für ',
    companyUser: 'Firmenbenutzer',


    // Empty Table Info Begin
    interviewNotExistInfo: 'Noch keine Interviews',
    poolNotExistInfo: 'Noch keine Pools',
    clientNotExistInfo: 'Noch keine Clients',
    candidateNotExistInfo: 'Noch keine Kandidaten',
    historyNotExistInfo: 'Noch keine Geschichte',
    positionNotExistInfo: 'Noch keine Positionen',
    recommendationNotExistInfo: 'Noch keine Empfehlungen',
    userNotExistInfo: 'Noch keine Benutzer',
    timeToFillDataNotExist: 'Zeit zum Füllen von Daten existiert noch nicht',
    interviewRequestNotExistInfo: 'Noch keine Interviewanfragen',
    createInterviewRequestNotExistInfo: 'Noch keine Interviewanfragen',
    positionRequestNotExistInfo: 'Noch keine Positionsanfragen',
    clientUserNotExistInfo: 'Noch keine Clientbenutzer',
    messageNotExistInfo: 'Noch keine Nachrichten',
    // Empty Table Info End

    // Client Group List Items Begin
    public: 'Public',
    enterprise: 'Enterprise',
    foundation: 'Foundation',
    sme: 'SME',
    educational_institution: 'Bildungseinrichtung',
    // Client Group List Items End

    // Source List Items Begin
    manual: 'Manual',
    import: 'Import',
    companyPortal: 'Firmenportal',
    mobile: 'Mobil',
    indeed: 'Indeed',
    linkedin: 'Linkedin',
    kariyerNet: 'Kariyer Net',
    // Source List Items End

    expirationDate: 'Ablaufdatum',
    issueDate: 'Ausgabedatum',
    issuedAt: 'Ausgestellt um',
    contact: 'Kontakt',
    twitter: 'Twitter',
    github: 'Github',
    generalInfo: 'Allgemeine Informationen',
    chooseFile: 'Datei wählen',
    changePhoto: 'Foto ändern',
    choosePhoto: 'Wähle ein Foto aus',
    softSkills: 'Soft Skills',
    requestDate: 'Anforderungsdatum',
    openPositionRequestDetail: 'Open Position Request Detail',
    positionRequestDetail: 'Positionsanforderungsdetail',
    positionRequest: 'Positionsanfrage',
    createPosition: 'Position Erstellen',
    role: 'Role',

    // warn users
    fillRequiredFields: 'Bitte füllen Sie alle erforderlichen Felder aus',
    leastThreeCharacter: 'Mindestens 3 Zeichen',
    leastThreeCharacterWarning: 'Die Anzahl der Zeichen muss mindestens 3 betragen',
    emailValidation: 'Bitte überprüfen Sie das E-Mail-Format',

    // client users
    addClientUser: 'Client-Benutzer Hinzufügen',
    editClientUser: 'Client-Benutzer Bearbeiten',
    deleteClientUser: 'Client-Benutzer Löschen',
    CLIENT_TECHNICAL: 'CLIENT TECHNICAL',
    CLIENT_HR: 'CLIENT HR',

    importAttachment: 'Datei importieren',

    // candidate detail bottom icon tooltips
    candidateDetailBottomPdfTooltip: 'Lebenslauf Herunterladen',
    candidateDetailBottomUploadTooltip: 'Lebenslauf Hochladen',
    candidateDetailBottomPrintCv: 'Lebenslauf Drucken',
    candidateDetailBottomDeleteTooltip: 'Kandidat Löschen',
    candidateDetailBottomMailTooltip: 'Mail an Kandidaten Senden',

    // messages
    messages: 'Mitteilungen',

    // server error list
    INTERNAL_SERVER_ERROR: 'Ein Fehler ist aufgetreten. Wir arbeiten daran, das Problem zu beheben. Bitte versuchen Sie es später noch einmal.',
    FEIGN_SERVER_ERROR: 'Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.', // Servisler arası iletişimde sunucu bazlı hata
    FEIGN_CLIENT_ERROR: 'Ein Clientfehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.', // Servisler arası isteklerde 4xx hatası
    // UAA
    INVALID_GRANT: 'Benutzername oder Passwort sind ungültig. Bitte überprüfen Sie Ihre Anmeldeinformationen.', // Kullanıcı adı veya parola hatalı
    INVALID_BEARER_TOKEN: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.', // Geçersiz token
    INVALID_JWT: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.', // Geçersiz JWT
    ACCESS_DENIED: 'Sie haben keinen Zugriff, um diesen Vorgang auszuführen.', // Kullanıcının bu kaynağa erişim yetkisi yok
    USER_NOT_FOUND: 'Der Benutzer existiert nicht.', // Kullanıcı bulunamadı
    USER_HAS_NO_PASSWORD: 'Sie haben Ihr Passwort noch nicht definiert. Bitte folgen Sie den Anweisungen in der E-Mail, die wir Ihnen gesendet haben.',
    WRONG_PASSWORD: 'Das Passwort ist nicht korrekt. Bitte überprüfen Sie es und versuchen Sie es erneut.', // Kullanıcı parola değiştirmede mevcut parolasını yanlış girmiş

    // Resource
    RESOURCE_NOT_FOUND: 'Die Ressource ist nicht verfügbar', // Ulaşılmak istenen obje bulunamadı
    RESOURCE_DELETED: 'Die Ressource wurde gelöscht', // Ulaşılmak istenen obje DELETED statüsünde
    RESOURCE_ALREADY_EXISTS: 'Die Ressource ist bereits vorhanden', // Kullanılan business id (email, mobile, vs.) ile halihazırda obje mevcut

    // JPA
    DATA_INTEGRITY_VIOLATION: 'Ein Fehler ist aufgetreten. Wir arbeiten daran, das Problem zu beheben. Bitte versuchen Sie es später noch einmal.', // Veritabanında UQ, FK, Constraint Violation hatası

    // Request validation
    INVALID_REQUEST_FORMAT: 'Ein Fehler ist aufgetreten. Wir arbeiten daran, das Problem zu beheben. Bitte versuchen Sie es später noch einmal.',
    VALIDATION_ERROR: 'Einige der Parameter sind ungültig.',
    // General

    INVALID_VALUE: 'Der Wert ist ungültig.', // Geçersiz değer

    //mahir
    // code=VALIDATION_ERROR olduğunda subErrors[n].code değerleri:
    INVALID_FIELD: 'Das Feld ist ungültig',
    MUST_BE_NULL: 'Der Wert muss null sein',
    MUST_NOT_BE_NULL: 'Bitte füllen Sie alle erforderlichen Felder aus',
    MUST_BE_TRUE: 'Der Wert muss wahr sein',
    MUST_BE_FALSE: 'Der Wert muss falsch sein',
    MIN_VALUE_EXCEEDED: 'Der Wert liegt unter dem erwarteten Mindestwert',
    MAX_VALUE_EXCEEDED: 'Der Wert liegt über dem erwarteten Maximalwert',
    DECIMAL_MIN_VALUE_EXCEEDED: 'Der Wert liegt unter dem erwarteten Mindestwert',
    DECIMAL_MAX_VALUE_EXCEEDED: 'Der Wert liegt über dem erwarteten Maximalwert',
    MUST_BE_NEGATIVE: 'Der Wert muss negativ sein',
    MUST_BE_NEGATIVE_OR_ZERO: 'Der Wert muss entweder negativ oder null sein',
    MUST_BE_POSITIVE: 'Der Wert muss positiv sein',
    MUST_BE_POSITIVE_OR_ZERO: 'Der Wert muss entweder positiv oder null sein',
    INVALID_SIZE: 'Die definierte Größe ist ungültig',
    INVALID_DIGITS: 'Die definierten Ziffern sind ungültig',
    MUST_BE_IN_THE_PAST: 'Der Wert muss nach dem Datum liegen',
    MUST_BE_IN_THE_PAST_OR_PRESENT: 'Der Wert muss entweder das vergangene oder das zukünftige Datum sein',
    MUST_BE_IN_THE_FUTURE: 'Der Wert muss das zukünftige Datum sein',
    MUST_BE_IN_THE_FUTURE_OR_PRESENT: 'Der Wert muss entweder aktuell oder zukünftig sein',
    MUST_MATCH_THE_REGEX: 'Das Format des Wertes ist nicht korrekt',
    MUST_NOT_BE_EMPTY: 'Das Feld darf nicht leer sein',
    MUST_NOT_BE_BLANK: 'Das Feld darf nicht leer sein',
    INVALID_EMAIL: 'Die E-Mail ist ungültig',

    // email
    shareEmailSubject: 'Eine Position, an der Sie interessiert sein könnten',
    GENERAL_ERROR: 'Ein Fehler ist aufgetreten. Wir arbeiten daran, das Problem zu beheben. Bitte versuchen Sie es später noch einmal',

    // Resul

    currentlyWorkings : 'Derzeit Arbeiten',
    addToPools: 'Zum Pool Hinzufügens',
    addToPositions: 'Zur Position hinzufügens',
    warningNotes: 'WarnungHinweise',
    deleteProfilePhotos: 'Profilfoto löschens',
    ClearSearchResults: 'Suchergebnisse löschen ',
    Search: 'Suche',


    //bugünEklenenler-Şubat
    takeNote: 'Mache eine Notiz',
    viewHistory: 'Siehe Verlauf',
    candidateHistory: 'Kandidaten Geschichte',
    companyInfo: 'Firmeninfo',
    subscriptionInfo: 'Abonnement Informationen',
    currentPackageInfo: 'Aktuelle Paket informationen',
    subscriptionDate: 'Abonnement Datum',
    cancelSubscription: 'Abonnement kündigen',
    confirmCancelSubscription: 'Ihr Abonnement wird gekündigt. Möchten Sie fortfahren?',
    close: 'Schließen',
    fromStep: 'Von Schritt',
    toStep: 'Zu Schritt',
    passwordsCannotBeSame: 'Das aktuelle und das neue Passwort können nicht identisch sein.',

    //yeni
    deleteAttachment: 'Anhang löschen',
    deleteAttachmentConfirmation: 'Dieser Anhang wird gelöscht. Möchten Sie fortfahren?',

};
