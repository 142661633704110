<div class="form-group mt-4">
    <p class="forgot-body">{{'resetPasswordTitle' | translate}} </p>
</div>
<div class="input-group margin-bottom-sm mt-2">
    <div class="input-group-prepend">
        <span class="input-group-text login-input-icon-password">
            <i class="fa fa-lock"></i>
        </span>
    </div>
    <input [(ngModel)]="passwordResetUser.newPassword" autocomplete="off" class="form-control login-input"
           name="password"
           placeholder="{{'password' | translate}}"
           type="password">
</div>
<div class="input-group margin-bottom-sm mt-2">
    <div class="input-group-prepend">
        <span class="input-group-text login-input-icon-password">
            <i class="fa fa-lock"></i>
        </span>
    </div>
    <input [(ngModel)]="passwordResetUser.reNewPassword" autocomplete="off" class="form-control login-input"
           name="rePassword"
           placeholder="{{'passwordAgain' | translate}}" type="password">
</div>
<div class="input-group margin-bottom-sm login-button-div mt-2">
    <input (click)="passwordReset()" [disabled]="passwordResetUser.newPassword !== passwordResetUser.reNewPassword"
           class="btn login-btn" type="submit" value="{{'save'| translate}}">
</div>
<div class="input-group mt-4">
    <a [routerLink]="['/']"
       class="forgot-password">{{'goBack' | translate}}</a>
</div>
