import { TitleCasePipe } from '@angular/common';
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTitleCaseDirective]'
})
export class TitlecaseDirective {

  constructor(private elementRef: ElementRef, public titleCase: TitleCasePipe) { }
      @HostListener('keyup') titleCaseOnKeyup(){
      const value = this.elementRef.nativeElement.value;
      const titleCaseStr = this.titleCase.transform(value);
      this.elementRef.nativeElement.value = titleCaseStr;
  }


}
