import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslatePipe } from 'src/app/pipes/translate/translate.pipe';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { InterviewRequestFilterModel } from './interview-requests-filter/interview-request-filter-model';

@Component({
  selector: 'app-interview-requests',
  templateUrl: './interview-requests.component.html',
  styleUrls: ['./interview-requests.component.scss']
})
export class InterviewRequestsComponent implements OnInit {

    filterData = '';

    constructor(private spinner: NgxSpinnerService,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {

       }

  ngOnInit(): void {

  }


  applyFilter(filterData: InterviewRequestFilterModel){
   this.requestFilter(filterData);

  }

  requestFilter(filterData?: InterviewRequestFilterModel) {
    this.filterData = '&filter=positionId**' + filterData.position +
        '&filter=clientId**' + filterData.client +
        '&filter=candidateId**' + filterData.candidate +
        '&filter=status**' + filterData.status;
}

}
