<!--Add candidate education Modal -->
<div aria-hidden="true" aria-labelledby="addCandidateCertificateTitle" class="modal fade" id="addCandidateCertificate"
    role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">

        <div class="modal-content">
            <form #addCandidateCertificateForm="ngForm" class="needs-validation">
                <div class="modal-header gradient-bg">
                    <h5 class="modal-title" id="addUserLongTitle">
                        {{ "addCandidateCertificate" | translate }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3 validate-me">
                                    <label class="modal-input-label"
                                        for="certificateName">{{"certificateName" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <input [(ngModel)]="certificate.name" class="form-control border-radius-0"
                                        id="certificateName" name="certificateName" type="text" required appTitleCaseDirective>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                        for="organisation">{{"organisation" | translate}}</label>
                                    <input [(ngModel)]="certificate.organisation" class="form-control border-radius-0"
                                        id="organisation" name="organisation" type="text" appTitleCaseDirective>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="issueDate">
                                        {{"issueDate" | translate}}
                                    </label>
                                    <div class="container-fluid">
                                        <div class="row" id="issueDate">
                                            <input [(ngModel)]="certificate.issueMonth"
                                                class="form-control col border-radius-0" id="issueMonth" max="12"
                                                min="1" name="issueMonth" placeholder="{{'month' | translate}}..."
                                                type="number" />&nbsp;
                                            <input [(ngModel)]="certificate.issueYear" min="1"
                                                class="form-control col border-radius-0 hideInputNumberArrow" id="issueYear" name="issueYear"
                                                placeholder="{{ 'year' | translate }}..." type="number" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="expirationDate">
                                        {{"expirationDate" | translate}}
                                    </label>
                                    <div class="container-fluid">
                                        <div class="row" id="expirationDate">
                                            <input [(ngModel)]="certificate.expirationMonth"
                                                class="form-control col border-radius-0" id="expirationMonth" max="12"
                                                min="1" name="expirationMonth" placeholder="{{'month' | translate}}..."
                                                type="number" />&nbsp;
                                            <input [(ngModel)]="certificate.expirationYear" min="1"
                                                class="form-control col border-radius-0 hideInputNumberArrow" id="expirationYear"
                                                name="expirationYear" placeholder="{{ 'year' | translate }}..."
                                                type="number" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="closeAddCertificatesModal()" class="btn btn-warning border-radius-0"
                        data-dismiss="modal" type="button">
                        {{ "cancel" | translate }}
                    </button>
                    <button (click)="saveCandidateCertificate(addCandidateCertificateForm)"
                        class="btn btn-success border-radius-0" type="button">
                        {{ "save" | translate }}
                    </button>
                </div>
            </form>
        </div>

    </div>
</div>