export class UserModel {

    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    fullName: string;
    email: string;
    status: string;
    role: string;
    companyId: string;
    preferredLanguage: string;
    clientId: string;

    constructor() {
        this.fullName = '';
        this.firstName = '';
        this.lastName = '';
        this.role = 'HR_MANAGER';
        this.status = 'ACTIVE';
    }
}
