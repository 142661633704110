<div class="container-fluid">
    <div class="row">
        <div class="col-12 menu-item gradient-bg">
            <a class="nav-link active">{{'profile' | translate}}</a>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div (click)="openChangePhoto()" class='user-img-div mx-auto mt-5 text-center'
                 style="max-width: 200px; cursor: pointer;">
                <img [src]="tempUserImg" class="rounded-circle img-thumbnail img-fluid"
                     style="width: 200px; height: 200px;">
                <div class="mt-3 mx-auto">
                    <p class="logo-title">
                        <i class="fas fa-plus-circle"></i>&nbsp;{{'changeProfilePhoto' | translate}}
                    </p>
                </div> 
            </div> 
            <div (click)="openConfirmInterviewRequest()" style="text-align:center;" class="mt-4 mx-auto">
                <p class="logo-titleDelete" >
                    {{'deleteProfilePhotos' | translate}}
                </p>
            </div> 
        </div>
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
            <div class="mb-3 mt-5">
                <label class="modal-input-label" for="name">{{'fullName' | translate}}</label>
                <input [value]="user.name"
                       class="form-control border-radius-0"
                       disabled
                       id="name"
                       name="name" required="required" type="text">
            </div>
            <div class="mb-3">
                <label class="modal-input-label" for="email">{{'email' | translate}}</label>
                <input [value]="user.email"
                       class="form-control border-radius-0"
                       disabled
                       id="email"
                       name="email" required="required" type="email">
            </div>
            <div class="mb-3">
                <label class="modal-input-label" for="languages">{{'language' | translate}}</label>
                <select (change)="getLanguage($event)" class="custom-select border-radius-0" id="languages"
                        name="languages">
                    <option *ngFor="let lang of languages" [selected]="lang.value === user.language"
                            [value]="lang.value">{{lang.display}}</option>
                </select>
            </div>
            <div class="row mt-3">
                <div class="col-12 p-0">
                    <div class="modal-footer">
                        <button (click)="openResetPasswordModal()"
                                class="btn btn-info border-radius-0 col-xl-auto col-lg-auto col-md-auto col-sm-12"
                                type="button">{{'changePassword' | translate}}</button>
                        <button (click)="saveUser()"
                                class="btn btn-success border-radius-0 col-xl-auto col-lg-auto col-md-auto col-sm-12"
                                type="button">{{'save' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-password-reset-modal></app-password-reset-modal>
<app-photo-import (emitSuccessStatus)="getUserProfileImage()" [photoApiUrl]="photoApiUrl" [tempUserImg]="tempUserImg"></app-photo-import>
<app-confirm-modal (operationInit)="deleteUserProfilImage()" [confirmData]="confirmData"></app-confirm-modal>
