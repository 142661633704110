import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CandidateModel } from 'src/app/components/candidates/candidate.model';
import { ClientModel } from 'src/app/components/clients/client.model';
import { PositionFilterModel, PositionModel } from 'src/app/components/positions/position.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ParameterService } from 'src/app/services/parameters/parameter.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { server } from 'src/app/services/server';
import {InterviewRequestStatus} from '../../../../shared/lists/interview-request-status-list';
import {InterviewRequestFilterModel} from './interview-request-filter-model';

@Component({
  selector: 'app-interview-requests-filter',
  templateUrl: './interview-requests-filter.component.html',
  styleUrls: ['./interview-requests-filter.component.scss']
})
export class InterviewRequestsFilterComponent implements OnInit {

  filterState: boolean;
  clients: ClientModel[] = [];
  positions: PositionModel[] = [];
  interviewRequestFilter: InterviewRequestFilterModel;
  filterData: InterviewRequestFilterModel;
  candidates: CandidateModel[] = [];
  statuies = InterviewRequestStatus;

  @Output() applyFilterOperation = new EventEmitter();

  constructor(public permissionService: PermissionService, private authService: AuthService,
              private spinner: NgxSpinnerService, private parameterService: ParameterService,
              private notification: NotificationService) {
      this.interviewRequestFilter = new InterviewRequestFilterModel();

    }

  ngOnInit(): void {
    this.getClients();
    this.getPositions();
    this.getCandidates();
  }

  getClients() {
    this.parameterService.getAllClients().subscribe((res: ClientModel[]) => {
        this.clients = res;
    }, err => {
        if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
            this.notification.error(err.message);
            this.spinner.hide();
        }
    }, () => {
        this.spinner.hide();
    });
}
getPositions() {
  this.authService.ServerGet(server.positions)
      .subscribe((res: { content: PositionModel[] }) => {
          this.positions = res.content;
      }, err => {
          if (err.status >= 400 || err.status < 500) {
              this.notification.error(err.message);
              this.spinner.hide();
          }
      }, () => {
          this.spinner.hide();
      });
}

getCandidates() {
  this.authService.ServerGet(server.candidates)
      .subscribe((res: { content: CandidateModel[] }) => {
          this.candidates = res.content;
      }, err => {
          if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
              this.notification.error(err.message);
              this.spinner.hide();
          }
      }, () => {
          this.spinner.hide();
      });
}

selectClient(selectedClientList) {
  this.interviewRequestFilter.client = '';

  for (const selectedClient of selectedClientList) {
      if (this.interviewRequestFilter.client === '') {
          this.interviewRequestFilter.client = selectedClient.id;
      } else {
          this.interviewRequestFilter.client = this.interviewRequestFilter.client + ',' + selectedClient.id;
      }
  }
}

selectPosition(selectedPositionList){
  this.interviewRequestFilter.position = '';
  for (const selectedPosition of selectedPositionList) {
      if (this.interviewRequestFilter.position === '') {
          this.interviewRequestFilter.position = selectedPosition.id;
      } else {
          this.interviewRequestFilter.position = this.interviewRequestFilter.position + ',' + selectedPosition.id;
      }
  }
}

selectStatus(selectedStatuies){

  this.interviewRequestFilter.status = '';
  for (const selectedStatus of selectedStatuies) {
      if (this.interviewRequestFilter.status === '') {
          this.interviewRequestFilter.status = selectedStatus.value;
      } else {
          this.interviewRequestFilter.status = this.interviewRequestFilter.status + ',' + selectedStatus.value;
      }
  }

}

selectCandidate(selectedCandidateList) {
  this.interviewRequestFilter.candidate = '';
  for (const candidate of selectedCandidateList) {
      if (this.interviewRequestFilter.candidate === '') {
          this.interviewRequestFilter.candidate = candidate.id;
      } else {
          this.interviewRequestFilter.candidate = this.interviewRequestFilter.candidate + ',' + candidate.id;
      }
  }
}

applyFilter() {

  if (JSON.stringify(this.filterData) === JSON.stringify(this.interviewRequestFilter)) {
      return false;
  }

  this.filterData = new InterviewRequestFilterModel();
  this.filterData = {...this.interviewRequestFilter};
  this.filterState = false;
  this.applyFilterOperation.emit(this.filterData);
}

  closeFilterPopup(e) {
    if (e.target.id !== 'filterButton') {
        this.filterState = false;
    }
}

}
