export class ReportFilter {

    client: string;
    user: string;
    status: string;
    openingDate:string;
    closingDate:string;

    constructor() {

        this.client = '';
        this.user = '';
        this.status = '';
        this.openingDate = '';
        this.closingDate = '';
    }
}

