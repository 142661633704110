import {Component, Input, OnInit} from '@angular/core';
import {server} from '../../../../services/server';
import {AuthService} from '../../../../services/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';

@Component({
    selector: 'app-hiring-team-users',
    templateUrl: './hiring-team-users.component.html',
    styleUrls: ['./hiring-team-users.component.scss']
})
export class HiringTeamUsersComponent implements OnInit {

    @Input() positionId: string;
    positionMembers: any;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                private translate: TranslatePipe,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.getPositionUsers();
    }

    getPositionUsers() {
        this.positionMembers = [];
        this.authService.ServerGet(server.positions + '/' + this.positionId + '/hiring-team-members')
            .subscribe((res: { content: [] }) => {
                if (res.content) {
                    this.positionMembers = res.content;
                }
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });


    }




}
