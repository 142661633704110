<!-- Modal -->
<div aria-hidden="true" aria-labelledby="stepModalCenterTitle" class="modal fade"
     id="changePipelineStepModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="stepModalLongTitle">{{'changeStep' | translate}}</h5>
            </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label class="modal-input-label" for="step">{{'step' | translate}}</label>
                        <select (change)="selectStep($event)" class="custom-select border-radius-0"
                                id="step">
                            <option selected value="">{{'unspecified' | translate}}</option>
                            <option *ngFor="let step of steps"
                                    [selected]="candidate?.recruitmentStepId === step.recruitmentStepId"
                                    [value]="step.recruitmentStepId">{{step.recruitmentStepName | translate}}</option>
                        </select>
                    </div><!--
                    <div class="mb-1">
                        <label class="ex-check" style="color: #649a65;">
                            <input [(ngModel)]="candidate.clientHrPermitted" type="checkbox" name="clientHrPermitted">
                            <span class="ml-2">
                                {{'clientHrPermitted' | translate}}
                            </span>
                        </label>
                    </div>
                    <div class="mb-1">
                        <label class="ex-check" style="color: #649a65;">
                            <input [(ngModel)]="candidate.clientTechnicalPermitted" type="checkbox" name="clientTechnicalPermitted">
                            <span class="ml-2">
                                {{'clientTechnicalPermitted' | translate}}
                            </span>
                        </label>
                    </div>-->
                </div>
                <div class="modal-footer">
                    <button (click)="closeChangeStepModal()" class="btn btn-warning border-radius-0"
                            data-dismiss="modal"
                            type="button">{{'cancel' | translate}}</button>
                    <button (click)="updateStep()" class="btn btn-success border-radius-0"
                            type="button">{{'save' | translate}}</button>
                </div>
        </div>
    </div>
</div>
