<!-- Modal -->
<div aria-hidden="true" aria-labelledby="poolModalCenterTitle" class="modal fade"
     id="candidatePoolModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="container-fluid">
                <div class="row">
                    <div (click)="setTab('candidatePools')" [ngClass]="{'gradient-bg':!detailsTab,
                         'top-line':detailsTab}"
                         class="menu-item col">
                        <a class="nav-link active">{{'candidatePools' | translate}}</a>
                    </div>
                    <div (click)="setTab('addToPool')" *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE_TO_POOL'])"
                         [ngClass]="{'gradient-bg':!addTab,
                         'top-line':addTab}"
                         class="menu-item col">
                        <a class="nav-link">{{'addToPool' | translate}}</a>
                    </div>
                </div>
            </div>
                <div class="modal-body">

                    <div *ngIf="detailsTab" class="container-fluid">
                        <div *ngIf="candidatePools && candidatePools.length>0" class="row">
                            <div (click)="openRemovePoolModal(candidatePool)"
                                 *ngFor="let candidatePool of candidatePools"
                                 class="col-auto mr-2 mt-2 cursor-pointer"
                                 style="padding:7px 14px; border: 1px solid #efefef; background-color: #efefef; border-radius: 21px; color: #444;">
                                <span>{{candidatePool.name}}</span><span class="ml-2"><i
                                    *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_TO_POOL'])"
                                    class="fas fa-times"></i></span>
                            </div>
                        </div>
                        <div *ngIf="candidatePools && candidatePools.length<=0" class="row">
                            Any pool assigned yet!
                        </div>
                    </div>
                    <div *ngIf="addTab && permissionService.permissionsOnly(['CREATE_CANDIDATE_TO_POOL'])"
                         class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="poolDrp">{{'pool' | translate}}</label>
                                    <select (change)="selectPool($event)" class="custom-select border-radius-0"
                                            id="poolDrp">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let pool of pools"
                                                [value]="pool.id">{{pool.name | translate}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="closeChangePoolModal()" class="btn btn-warning border-radius-0"
                            data-dismiss="modal"
                            type="button">{{'cancel' | translate}}</button>
                    <button (click)="addPool()" *ngIf="addTab" class="btn btn-success border-radius-0"
                            type="button">{{'save' | translate}}</button>
                </div>
        </div>
    </div>
</div>
<app-remove-candidate-pool *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_TO_POOL'])" (updateCandidatePools)="getCandidatePools()"
                           [candidateId]="candidateId" [pool]="selectedPool"></app-remove-candidate-pool>
