import {Component, OnInit} from '@angular/core';
import {PermissionService} from '../../services/permission/permission.service';

@Component({
    selector: 'app-company-settings',
    templateUrl: './company-settings.component.html',
    styleUrls: ['./company-settings.component.scss']
})
export class CompanySettingsComponent implements OnInit {

    userManagementComponentState: boolean;
    recruitmentPreferencesComponentState: boolean;
    careerPortalSettingsComponentState: boolean;

    constructor(public permissionService: PermissionService) {
        this.setTab('userManagement');
    }

    ngOnInit(): void {
    }

    setTab(component) {
        switch (component) {
            case 'userManagement':
                this.userManagementComponentState = true;
                this.recruitmentPreferencesComponentState = false;
                this.careerPortalSettingsComponentState = false;
                break;
            case 'recruitmentPreferences':
                this.userManagementComponentState = false;
                this.recruitmentPreferencesComponentState = true;
                this.careerPortalSettingsComponentState = false;
                break;
            case 'careerPortalSettings':
                this.userManagementComponentState = false;
                this.recruitmentPreferencesComponentState = false;
                this.careerPortalSettingsComponentState = true;
                break;
            default:
                this.userManagementComponentState = true;
                break;
        }

    }
}
