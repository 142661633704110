import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../services/notification/notification.service';
import {PipelineStepModel} from '../../../../../services/parameters/models/pipeline-step.model';
import {server} from '../../../../../services/server';
import {StepModel} from './step.model';
import {PermissionService} from '../../../../../services/permission/permission.service';

@Component({
    selector: 'app-candidate-change-step',
    templateUrl: './candidate-change-step.component.html',
    styleUrls: ['./candidate-change-step.component.scss']
})
export class CandidateChangeStepComponent implements OnInit {

    @Input() pipelineStep: PipelineStepModel;
    @Input() candidateId: string;
    @Output() updateStepInfo = new EventEmitter();
    positions: PipelineStepModel[];
    requestObject: any;
    steps: StepModel[];
    tempStep: string;
    tempPosition: PipelineStepModel;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.tempPosition = new PipelineStepModel();
        this.requestObject = {};
        this.getCandidatePositions();
    }

    selectStep(e) {
        this.tempStep = e.target.value;
    }

    selectPosition(e) {
        if (e.target.value !== '') {
            this.tempPosition = JSON.parse(e.target.value);
            this.tempStep = this.tempPosition.recruitmentStepId;
            if (!this.steps || this.steps.length <= 0) {
                this.getSteps();
            }
        } else {
            this.tempPosition = new PipelineStepModel();
            this.steps = [];
            this.tempStep = '';
        }
    }

    updateStep() {
        this.requestObject = {
            recruitmentStepId: this.tempStep,
            candidateId: this.candidateId,
            positionId: this.tempPosition.positionId,
            clientHrPermitted: this.tempPosition.clientHrPermitted,
            clientTechnicalPermitted: this.tempPosition.clientTechnicalPermitted,
            hideSensitiveDataForClient: this.tempPosition.hideSensitiveDataForClient
        };


        this.authService.ServerPut(server.pipelineStepCandidates, this.requestObject)
            .subscribe(res => {
                this.updateStepInfo.emit();
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.getCandidatePositions();
                this.closeChangeStepModal();
                this.openScheduleInterviewModal();
                this.spinner.hide();
            });
    }

    getSteps() {
        this.authService.ServerGet(server.pipelineSteps + '?positionId=')
            .subscribe((res: any) => {
                this.steps = res;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    getCandidatePositions() {
        this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/pipeline-steps?onlyCurrentSteps=true')
            .subscribe((res: any) => {
                this.positions = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    closeChangeStepModal() {
        // @ts-ignore
        $('#stepModal').modal('hide');
    }

    openScheduleInterviewModal() {
        // @ts-ignore
        $('#addInterviewModal').modal('show');
    }

}
