import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CountryModel} from '../../../../services/parameters/models/country.model';
import {CityModel} from '../../../../services/parameters/models/city.model';
import {UserModel} from '../../../user-management/user.model';
import {CandidateStatusList} from '../../../../shared/lists/candidate-status.list';
import {JobTypeList} from '../../../../shared/lists/job-type.list';
import {PermissionService} from '../../../../services/permission/permission.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../../../services/notification/notification.service';
import {CandidateFilterModel} from '../../candidate.model';
import {SourceList} from '../../../../shared/lists/source.list';
import {server} from '../../../../services/server';
import {RecruitmentPreferencesModel} from '../../../recruitment-preferences/recruitment-preferences.model';

@Component({
    selector: 'app-candidate-list-filter',
    templateUrl: './candidate-list-filter.component.html',
    styleUrls: ['./candidate-list-filter.component.scss']
})
export class CandidateListFilterComponent implements OnInit {

    countries: CountryModel[];
    cities: CityModel[] = [];
    sources = SourceList;
    companyUsers: UserModel[] = [];
    pipelineSteps: RecruitmentPreferencesModel[] = [];

    candidateStatusList = CandidateStatusList;
    filterState: boolean;

    positionFilter: CandidateFilterModel;
    filterData: CandidateFilterModel;
    @Output() applyFilterOperation = new EventEmitter();

    constructor(public permissionService: PermissionService, private authService: AuthService,
                private spinner: NgxSpinnerService, private parameterService: ParameterService, private route: ActivatedRoute,
                private notification: NotificationService) {
        this.positionFilter = new CandidateFilterModel();
    }

    ngOnInit(): void {
        this.getCompanyUsers();
        this.getRecruitmentPreferences();
    }

    getCountries() {
        this.parameterService.countries.subscribe((res: CountryModel[]) => {
            this.countries = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCompanyUsers() {
        this.parameterService.companyUsers.subscribe((res: UserModel[]) => {
            this.companyUsers = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCountryCities(countryId) {
        this.cities = [];
        this.parameterService.getCountryCities(countryId).subscribe((res: CityModel[]) => {
            this.cities = res;
        }, err => {
            if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getRecruitmentPreferences() {
        const companyId = this.authService.companyId;
        this.authService.ServerGet(server.companies + '/' + companyId + '/recruitment-steps')
            .subscribe((res: { content: [] }) => {
                this.pipelineSteps = res.content;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    selectStatus(selectedStatusList) {
        this.positionFilter.status = '';
        for (const selectedStatus of selectedStatusList) {
            if (this.positionFilter.status === '') {
                this.positionFilter.status = (selectedStatus).value;
            } else {
                this.positionFilter.status = this.positionFilter.status + ',' + selectedStatus.value;
            }
        }
    }

    selectCountry(e) {
        if (e.target.value !== 'all') {
            this.positionFilter.country = e.target.value;
            this.getCountryCities(this.positionFilter.country);
        } else {
            this.positionFilter.country = '';
        }
    }

    selectCity(selectedCityList) {
        this.positionFilter.city = '';
        for (const selectedCity of selectedCityList) {
            if (this.positionFilter.city === '') {
                this.positionFilter.city = selectedCity.id;
            } else {
                this.positionFilter.city = this.positionFilter.city + ',' + selectedCity.id;
            }
        }
    }

    selectCompanyUser(selectedUserList) {
        this.positionFilter.users = '';
        for (const selectedUser of selectedUserList) {
            if (this.positionFilter.users === '') {
                this.positionFilter.users = selectedUser.id;
            } else {
                this.positionFilter.users = this.positionFilter.users + ',' + selectedUser.id;
            }
        }
    }

    selectStep(selectedStepList) {
        this.positionFilter.step = '';
        for (const selectedStep of selectedStepList) {
            if (this.positionFilter.step === '') {
                this.positionFilter.step = selectedStep.id;
            } else {
                this.positionFilter.step = this.positionFilter.step + ',' + selectedStep.id;
            }
        }
    }

    selectSource(selectedSourceList) {
        this.positionFilter.source = '';

        for (const selectedSource of selectedSourceList) {
            if (this.positionFilter.source === '') {
                this.positionFilter.source = selectedSource.value;
            } else {
                this.positionFilter.source = this.positionFilter.source + ',' + selectedSource.value;
            }
        }

    }

    closeFilterPopup(e) {
        if (e.target.id !== 'filterButton') {
            this.filterState = false;
        }
    }


    applyFilter() {
        if (JSON.stringify(this.filterData) === JSON.stringify(this.positionFilter)) {
            return false;
        }
        this.filterData = new CandidateFilterModel();
        this.filterData = {...this.positionFilter};
        this.filterState = false;
        this.applyFilterOperation.emit(this.filterData);
    }

}
