import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {RequestPayload} from '../../../shared/requestPayload';
import {server} from '../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';
import {GeneralModel} from '../position-operations/general/general.model';
import {PermissionService} from '../../../services/permission/permission.service';
import {PositionFilterModel} from '../position.model';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-position-list',
    templateUrl: './position-list.component.html',
    styleUrls: ['./position-list.component.scss']
})
export class PositionListComponent implements OnChanges {

    positions: GeneralModel[];
    @Input() filterData: PositionFilterModel;
    count: number;
    activePage: any;
    currentPage: number;
    requestPayload: RequestPayload;
    openedElementId: string;
    @Output() positionDetail = new EventEmitter();
    @Input() tabState: string;
    position: GeneralModel;
    operationType: string;
    selectedPosition: GeneralModel;


    tagList = ['TR', 'tr', 'TD', 'td'];

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.requestPayload = new RequestPayload();
        this.selectedPosition = new GeneralModel();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.positions = [];
        this.count = 0;
        this.checkTab();
    }

    checkTab() {
        switch (this.tabState) {
            case 'allPositions':
                this.currentPage = 1;
                this.getPositions(this.currentPage);
                break;
            case 'myPositions':
                this.currentPage = 1;
                this.getMyPositions(this.currentPage);
                break;
            default:
                break;
        }
    }

    requestFilter() {
        return '&filter=countryId**' + this.filterData.country.id +
            '&filter=cityId**' + this.filterData.city.id +
            '&filter=jobType**' + this.filterData.positionType +
            '&filter=status**' + this.filterData.status +
            '&filter=hiringTeam.userId**' + this.filterData.users +
            '&filter=clientId**' + this.filterData.client;
    }

    getPositions(pageNo) {
        this.authService.ServerGet(server.positions + this.requestPayload.payloadURL(pageNo) + this.requestFilter())
            .subscribe((res: any) => {
                this.positions = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    getMyPositions(pageNo) {
        this.authService.ServerGet(server.positions + '/my' + this.requestPayload.payloadURL(pageNo) + this.requestFilter())
            .subscribe((res: any) => {
                this.positions = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    pagination(pageNo) {
        switch (this.tabState) {
            case 'allPositions':
                this.getPositions(pageNo);
                break;
            case 'myPositions':
                this.getMyPositions(pageNo);
                break;
            default:
                break;
        }
    }

    onClickedOutside(event) {
        if (this.tagList.indexOf(event.target.tagName) <= -1) {
            this.closeRowModal();
        }
    }

    openRowModal(event, client) {
        if (this.tagList.indexOf(event.target.tagName) > -1) {
            if (this.openedElementId && document.getElementById(this.openedElementId)) {
                document.getElementById('row-modal').style.display = 'none';
            }
            this.selectedPosition = client;
            this.openedElementId = this.selectedPosition.id;
            document.getElementById('row-modal').style.left = event.layerX + 'px';
            document.getElementById('row-modal').style.top = event.layerY - 10 + 'px';
            document.getElementById('row-modal').style.display = 'block';
        } else {
            this.closeRowModal();
        }
    }

    closeRowModal() {
        if (this.openedElementId) {
            document.getElementById('row-modal').style.display = 'none';
        }
    }

    openEditPosition() {
        this.operationType = 'edit';
        this.getPosition(this.selectedPosition.id);
    }

    openClonePosition() {
        this.operationType = 'clone';
        this.getPosition(this.selectedPosition.id);
    }

    openDeletePosition() {
        this.closeRowModal();
        // @ts-ignore
        $('#deletePosition').modal('show');
    }

    openPositionDetails() {
        this.router.navigate(['/dashboard/position-details', {id: this.selectedPosition.id}]);
    }

    openPositionStatusModal(position) {
        this.selectedPosition = position;
        // @ts-ignore
        $('#positionStatusModal').modal('show');
    }
    openPositionAddHiringTeamModal(position) {
        this.selectedPosition = position;
        // @ts-ignore
        $('#addToPositionHiringTeamMemberModal').modal('show');
    }

    getPosition(positionId) {
        this.authService.ServerGet(server.positions + '/' + positionId)
            .subscribe((res: GeneralModel) => {
                this.position = res;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
                this.positionDetail.emit({position: this.position, operationType: this.operationType});
            });
    }

    deletePosition() {
        this.authService.ServerDelete(server.positions + '/' + this.selectedPosition.id)
            .subscribe(res => {
                this.notification.success(this.translate.transform('deleteSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#deletePosition').modal('hide');
                this.spinner.hide();
                this.pagination(1);
            });
    }

    viewOnPortal() {
        window.open(environment.careerPortalUrl + '?id=' + this.selectedPosition.id, '_blank');
    }

    shareWithEmail() {
        window.open('mailto:?subject=' + this.translate.transform('shareEmailSubject', []) + '&body=' + environment.careerPortalUrl + '?id=' + this.selectedPosition.id, '_blank');
    }

}
