import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RequestPayload} from '../../../shared/requestPayload';
import {CandidateModel} from '../candidate.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../services/permission/permission.service';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';
import {PositionFilterModel} from '../../positions/position.model';

@Component({
    selector: 'app-candidate-list',
    templateUrl: './candidate-list.component.html',
    styleUrls: ['./candidate-list.component.scss']
})
export class CandidateListComponent implements OnInit {

    @Input() referencePage = 'candidate';
    @Input() count: number;
    @Input() referenceId: string | number;
    @Input() candidateId;
    activePage: any;
    currentPage: number;
    requestPayload: RequestPayload;
    selectedCandidate: CandidateModel;
    @Input() candidates: CandidateModel[];
    tagList = ['TR', 'tr', 'TD', 'td'];
    openedElementId: string;
    @Output() updateCandidates = new EventEmitter();
    @Output() removeFromPoolOperation = new EventEmitter();
    @Output() removeFromPositionOperation = new EventEmitter();
    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService) {
        this.requestPayload = new RequestPayload();
    }

    ngOnInit() {
    }

    openCandidateStatusModal(candidate) {
        this.selectedCandidate = candidate;
        // @ts-ignore
        $('#candidateStatusModal').modal('show');
    }

    pagination(pageNo) {
        this.updateCandidates.emit(pageNo);
    }

    onClickedOutside(event) {
        if (this.tagList.indexOf(event.target.tagName) <= -1) {
            this.closeRowModal();
        }
    }

    openRowModal(event, pool) {
        if (this.tagList.indexOf(event.target.tagName) > -1) {
            if (this.openedElementId && document.getElementById(this.openedElementId)) {
                document.getElementById('row-modal').style.display = 'none';
            }
            this.selectedCandidate = pool;
            this.openedElementId = this.selectedCandidate.id;
            document.getElementById('row-modal').style.left = event.layerX + 'px';
            document.getElementById('row-modal').style.top = event.layerY - 5 + 'px';
            document.getElementById('row-modal').style.display = 'block';
        } else {
            this.closeRowModal();
        }
    }

    closeRowModal() {
        if (this.openedElementId) {
            document.getElementById('row-modal').style.display = 'none';
        }
    }

    openCandidateDetails() {
        this.router.navigate(['/dashboard/edit-candidate', {id: this.selectedCandidate.id}]);
    }

    openDeleteCandidate() {
        // @ts-ignore
        $('#deleteCandidate').modal('show');
        this.closeRowModal();
    }
    updateStepInfo() {
        // @ts-ignore
        $('#addInterviewModal').modal('show');
        this.closeRowModal();
    }

    takeNote() {
        // @ts-ignore
        $('#takeNoteModal').modal('show');
        this.closeRowModal();
    }

    openUpdateStepModal() {
        // @ts-ignore
        $('#stepModal').modal('show');
        this.closeRowModal();
    }
    removeFromPool() {
        this.removeFromPoolOperation.emit(this.selectedCandidate);
        this.closeRowModal();
    }

    removeFromPosition() {
        this.removeFromPositionOperation.emit(this.selectedCandidate);
    }
    openPoolDetail() {
        // @ts-ignore
        $('#candidatePoolModal').modal('show');
        this.closeRowModal();
    }
    openAddPositions() {
        // @ts-ignore
         $('#stepModal').modal('show');
         this.closeRowModal();
    }
}
