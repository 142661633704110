
<div class="dropdown ">
    <button (click)="filterState = !filterState" class="btn btn-secondary dropdown-toggle border-radius-0 "
        id="filterButton" type="button">
        <span class="fas fa-filter float-left mt-1 mr-2"></span>{{'filter' | translate}}
    </button>
    <div (clickOutside)="closeFilterPopup($event)" [style.display]="filterState ? 'block':'none'"
        aria-labelledby="dropdownMenuButton" class="color-dropdown border-radius-0 mt-1"
        style="position: fixed; z-index: 5000; width: auto !important;">
        <div>
            <a aria-controls="positionType"
               aria-expanded="false" class="dropdown-item color-dropdown-item"
               data-toggle="collapse" href="#client"
               role="button">
                {{'client' | translate}}<span *ngIf="this.interviewRequestFilter?.client !==''" class="text-danger">*</span>
            </a>
            <div class="collapse" id="client">
                <app-multiselect-dropdown (selectResult)="selectClient($event)"
                                          [isSearchable]="true"
                                          [items]="clients"
                                          dropdownName="client"
                                          translateObjectKey="name">
                </app-multiselect-dropdown>
            </div>
        </div>
        <div>
            <a aria-controls="position" 
               aria-expanded="false" class="dropdown-item color-dropdown-item"
               data-toggle="collapse" href="#position"
               role="button">
                {{'position' | translate}}<span *ngIf="this.interviewRequestFilter?.position !==''" class="text-danger">*</span>
            </a>
            <div class="collapse" id="position">
                <app-multiselect-dropdown (selectResult)="selectPosition($event)"
                                          [isSearchable]="true"
                                          [items]="positions"
                                          dropdownName="position"
                                          translateObjectKey="name">
                </app-multiselect-dropdown>
            </div>
        </div>
        <div>
            <a aria-controls="candidate"
               aria-expanded="false" class="dropdown-item color-dropdown-item"
               data-toggle="collapse" href="#candidate"
               role="button">
                {{'candidate' | translate}}<span *ngIf="this.interviewRequestFilter?.candidate !==''"
                                                 class="text-danger">*</span>
            </a>
            <div class="collapse" id="candidate">
                <app-multiselect-dropdown (selectResult)="selectCandidate($event)"
                                          [isSearchable]="true"
                                          [items]="candidates"
                                          dropdownName="candidate"
                                          translateObjectKey="fullName">
                </app-multiselect-dropdown>
            </div>
        </div>
        <div>
            <a aria-controls="status"
               aria-expanded="false" class="dropdown-item color-dropdown-item"
               data-toggle="collapse" href="#status"
               role="button">
                {{'status' | translate}}<span *ngIf="this.interviewRequestFilter?.status !==''"
                                                 class="text-danger">*</span>
            </a>
            <div class="collapse" id="status">
                <app-multiselect-dropdown (selectResult)="selectStatus($event)"
                                          [isSearchable]="true"
                                          [items]="statuies"
                                          dropdownName="status"
                                          translateObjectKey="name">
                </app-multiselect-dropdown>
            </div>
        </div>
        <div>
            <button (click)="applyFilter()"
                    class="exactalent-button border-radius-0 apply-filter">{{'applyFilters' | translate}}</button>
        </div>
    </div>
</div>