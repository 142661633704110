import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CountryModel} from '../../../../services/parameters/models/country.model';
import {CityModel} from '../../../../services/parameters/models/city.model';
import {StatusList} from '../../../../shared/lists/status.list';
import {PermissionService} from '../../../../services/permission/permission.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../../../services/notification/notification.service';
import {PoolFilterModel} from '../../pool.model';
import {CategoryModel} from '../../../../services/parameters/models/category.model';
import {UserModel} from '../../../user-management/user.model';

@Component({
    selector: 'app-pool-list-filter',
    templateUrl: './pool-list-filter.component.html',
    styleUrls: ['./pool-list-filter.component.scss']
})
export class PoolListFilterComponent implements OnInit {

    countries: CountryModel[];
    cities: CityModel[] = [];
    categories: CategoryModel[] = [];
    companyUsers: UserModel[] = [];

    statusList = StatusList;
    filterState: boolean;

    positionFilter: PoolFilterModel;
    filterData: PoolFilterModel;
    @Output() applyFilterOperation = new EventEmitter();

    constructor(public permissionService: PermissionService, private authService: AuthService,
                private spinner: NgxSpinnerService, private parameterService: ParameterService, private route: ActivatedRoute,
                private notification: NotificationService) {
        this.positionFilter = new PoolFilterModel();
    }

    ngOnInit(): void {
        this.getCountries();
        this.getCategories();
        this.getCompanyUsers();
    }

    getCountries() {
        this.parameterService.countries.subscribe((res: CountryModel[]) => {
            this.countries = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCountryCities(countryId) {
        this.cities = [];
        this.parameterService.getCountryCities(countryId).subscribe((res: CityModel[]) => {
            this.cities = res;
        }, err => {
            if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCategories() {
        this.parameterService.poolCategories.subscribe((res: CategoryModel[]) => {
            this.categories = res;
        }, err => {
            if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCompanyUsers() {
        this.parameterService.companyUsers.subscribe((res: UserModel[]) => {
            this.companyUsers = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    selectStatus(selectedStatusList) {
        this.positionFilter.status = '';
        for (const selectedStatus of selectedStatusList) {
            if (this.positionFilter.status === '') {
                this.positionFilter.status = (selectedStatus).value;
            } else {
                this.positionFilter.status = this.positionFilter.status + ',' + selectedStatus.value;
            }
        }
    }

    selectCountry(e) {
        if (e.target.value !== 'all') {
            this.positionFilter.country = e.target.value;
            this.getCountryCities(this.positionFilter.country);
        } else {
            this.positionFilter.country = '';
        }
    }

    selectCity(selectedCityList) {
        this.positionFilter.city = '';
        for (const selectedCity of selectedCityList) {
            if (this.positionFilter.city === '') {
                this.positionFilter.city = selectedCity.id;
            } else {
                this.positionFilter.city = this.positionFilter.city + ',' + selectedCity.id;
            }
        }
    }

    selectCategory(selectedCategoryList) {
        this.positionFilter.category = '';
        for (const selectedCategory of selectedCategoryList) {
            if (this.positionFilter.category === '') {
                this.positionFilter.category = selectedCategory.id;
            } else {
                this.positionFilter.category = this.positionFilter.category + ',' + selectedCategory.id;
            }
        }
    }

    selectCompanyUser(selectedUserList) {
        this.positionFilter.createdBy = '';
        for (const selectedUser of selectedUserList) {
            if (this.positionFilter.createdBy === '') {
                this.positionFilter.createdBy = selectedUser.id;
            } else {
                this.positionFilter.createdBy = this.positionFilter.createdBy + ',' + selectedUser.id;
            }
        }
    }

    closeFilterPopup(e) {
        if (e.target.id !== 'filterButton') {
            this.filterState = false;
        }
    }


    applyFilter() {
        if (JSON.stringify(this.filterData) === JSON.stringify(this.positionFilter)) {
            return false;
        }
        this.filterData = new PoolFilterModel();
        this.filterData = {...this.positionFilter};
        this.filterState = false;
        this.applyFilterOperation.emit(this.filterData);
    }

}
