export class PasswordModel {

    currentPassword: string;
    newPassword: string;
    newPasswordAgain: string;

    constructor() {
        this.currentPassword = '';
        this.newPassword = '';
        this.newPasswordAgain = '';
    }
}

export class ProfileModel {

    id: string;
    name: string;
    email: string;
    language: string;

    constructor() {
    }
}
