<!--Add candidate education Modal -->
<div aria-hidden="true" aria-labelledby="editCandidateProfileTitle" class="modal fade mt-5 mb-5"
    id="editCandidateProfile" role="dialog" tabindex="-1">
    <form #formEditCandidate="ngForm" class="needs-validation">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header gradient-bg">
                    <h5 class="modal-title" id="addUserLongTitle">
                        {{ "editCandidateProfile" | translate }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <div class='user-img-div mt-1 text-left' style="max-width: 140px; cursor: pointer;">
                                        <img [src]="tempUserImg"
                                            class="rounded-circle img-thumbnail img-fluid user-img">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div class="mb-3 validate-meEdit">
                                            <label class="modal-input-label" for="name">
                                                {{"fullName" | translate}}<span class="text-danger">*</span>
                                            </label>
                                            <div class="container-fluid">
                                                <div class="row" id="name">
                                                    <input [(ngModel)]="candidate.firstName"
                                                        class="form-control col border-radius-0" id="firstName"
                                                        name="firstName" #name placeholder="{{'firstName' | translate}}"
                                                        type="text" required appTitleCaseDirective />&nbsp;
                                                    <input [(ngModel)]="candidate.lastName"
                                                        class="form-control col border-radius-0" id="lastName"
                                                        name="lastName" #lastName
                                                        placeholder="{{ 'lastName' | translate }}" type="text"
                                                        required appTitleCaseDirective />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3 validate-meEdit">
                                            <label class="modal-input-label"
                                                for="primaryPhone">{{"primaryPhone" | translate}}<span
                                                    class="text-danger">*</span></label>
                                            <input [(ngModel)]="candidate.primaryPhone"
                                                class="form-control border-radius-0" id="primaryPhone"
                                                name="primaryPhone" #primaryPhone type="text" required>
                                        </div>
                                        <div class="mb-3">
                                            <label class="modal-input-label"
                                                for="secondaryPhone">{{"secondaryPhone" | translate}}</label>
                                            <input [(ngModel)]="candidate.secondaryPhone"
                                                class="form-control border-radius-0" id="secondaryPhone"
                                                name="secondaryPhone" #secondaryPhone type="text"
                                                maxlength="11"
                                                (keypress)="numericOnly($event)">
                                        </div>
                                        <div class="mb-3 validate-meEdit">
                                            <label class="modal-input-label"
                                                for="primaryEmail">{{"primaryEmail" | translate}}<span
                                                    class="text-danger">*</span></label>
                                            <input [(ngModel)]="candidate.primaryEmail"
                                                class="form-control border-radius-0" id="primaryEmail"
                                                name="primaryEmail" #primaryEmail type="email" email required>
                                        </div>
                                        <div class="mb-3">
                                            <label class="modal-input-label"
                                                for="secondaryEmail">{{"secondaryEmail" | translate}}</label>
                                            <input (keyup)="secondaryEmailValidaiton()"
                                                [(ngModel)]="candidate.secondaryEmail"
                                                class="form-control border-radius-0" id="secondaryEmail"
                                                name="secondaryEmail" #secondaryEmail="ngModel" type="email" email>
                                            <div *ngIf="secondaryEmail.invalid && (secondaryEmail.dirty || secondaryEmail.touched)"
                                                class="alert alert-danger">
                                                {{ 'emailValidation' | translate}}
                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div class="mb-3 validate-meEdit">
                                            <label class="modal-input-label"
                                                for="dateOfBirth">{{"dateOfBirth" | translate}}<span
                                                    class="text-danger">*</span></label>
                                            <input #dateOfBirth [(ngModel)]="candidate.dateOfBirth" name="dateOfBirth"
                                                class="form-control border-radius-0 datepicker"
                                                data-provide="datepicker" id="dateOfBirth" placeholder="yyyy-mm-dd"
                                                required>
                                        </div>
                                        <div class="mb-3">
                                            <label class="modal-input-label"
                                                for="source">{{"source" | translate}}</label>
                                            <select (change)="selectSource($event)" [(ngModel)]="candidate.source"
                                                class="custom-select border-radius-0" id="source" name="source" #source>
                                                <option *ngFor="let source of sourceList" [value]="source.value">
                                                    {{source.name | translate}}</option>
                                            </select>
                                        </div>
                                        <div class="mb-3 validate-meEdit">
                                            <label class="modal-input-label"
                                                for="editCountry">{{"country" | translate}}<span
                                                    class="text-danger">*</span></label>
                                            <div>
                                                <app-searchable-dropdown (selectResult)="selectCountry($event);cityChild.selectedItem=null;cityChild.isFiltired=false"
                                                    [items]="countries" [selectedItem]="tempCandidate.country?.value"
                                                    id="editCountry"
                                                    [isSaveClick]="isSaveClick" 
                                                    isRequired="true"
                                                    nameInput="country"
                                                    ></app-searchable-dropdown>
                                            </div>

                                        </div>

                                        <div class="mb-3 ">
                                            <label class="modal-input-label" for="editCity">{{"city" | translate}}<span
                                                    class="text-danger">*</span></label>
                                            <div>
                                                <app-searchable-dropdown #cityChild (selectResult)="selectCity($event)"
                                                    [items]="cities" [selectedItem]="tempCandidate.city?.value"
                                                    id="editCity"
                                                    [isSaveClick]="isSaveClick" 
                                                    isRequired="true"
                                                    nameInput="city"
                                                    ></app-searchable-dropdown>

                                            </div>
 
                                        </div>
                                        <div class="mb-3 validate-meEdit">
                                            <label class="modal-input-label"
                                                for="address">{{"address" | translate}}<span
                                                    class="text-danger">*</span></label>
                                            <input [(ngModel)]="candidate.address" class="form-control border-radius-0"
                                                id="address" name="address" #address type="text" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="row">
                                    <!-- Social Media Begin -->
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div class="mb-3">
                                            <label class="modal-input-label"
                                                for="githubAccount">{{'githubAccount' | translate}}</label>

                                            <div class="input-group margin-bottom-sm mt-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text login-input-icon-password">
                                                        <i class="fab fa-github"></i>
                                                    </span>
                                                </div>
                                                <input [(ngModel)]="candidate.githubAccount"
                                                    class="form-control border-radius-0" id="githubAccount"
                                                    name="githubAccount" #githubAccount type="text">
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label class="modal-input-label"
                                                for="twitterAccount">{{'twitterAccount' | translate}}</label>
                                            <div class="input-group margin-bottom-sm mt-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text login-input-icon-password">
                                                        <i class="fab fa-twitter"></i>
                                                    </span>
                                                </div>
                                                <input [(ngModel)]="candidate.twitterAccount"
                                                    class="form-control border-radius-0" id="twitterAccount"
                                                    name="twitterAccount" #twitterAccount type="text">
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label class="modal-input-label"
                                                for="facebookAccount">{{'facebookAccount' | translate}}</label>
                                            <div class="input-group margin-bottom-sm mt-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text login-input-icon-password">
                                                        <i class="fab fa-facebook"></i>
                                                    </span>
                                                </div>
                                                <input [(ngModel)]="candidate.facebookAccount"
                                                    class="form-control border-radius-0" id="facebookAccount"
                                                    name="facebookAccount" #facebookAccount type="text">
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label class="modal-input-label"
                                                for="twitterAccount">{{'linkedinAccount' | translate}}</label>
                                            <div class="input-group margin-bottom-sm mt-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text login-input-icon-password">
                                                        <i class="fab fa-linkedin"></i>
                                                    </span>
                                                </div>
                                                <input [(ngModel)]="candidate.linkedinAccount"
                                                    class="form-control border-radius-0" id="linkedinAccount"
                                                    name="linkedinAccount" #linkedinAccount type="text">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div class="mb-3">
                                            <label class="modal-input-label"
                                                for="twitterAccount">{{'instagramAccount' | translate}}</label>
                                            <div class="input-group margin-bottom-sm mt-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text login-input-icon-password">
                                                        <i class="fab fa-instagram"></i>
                                                    </span>
                                                </div>
                                                <input [(ngModel)]="candidate.instagramAccount"
                                                    class="form-control border-radius-0" id="instagramAccount"
                                                    name="instagramAccount" #instagramAccount type="text">
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label class="modal-input-label"
                                                for="youtubeAccount">{{'youtubeAccount' | translate}}</label>
                                            <div class="input-group margin-bottom-sm mt-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text login-input-icon-password">
                                                        <i class="fab fa-youtube"></i>
                                                    </span>
                                                </div>
                                                <input [(ngModel)]="candidate.youtubeAccount"
                                                    class="form-control border-radius-0" id="youtubeAccount"
                                                    name="youtubeAccount" #youtubeAccount type="text">
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label class="modal-input-label"
                                                for="behanceAccount">{{'behanceAccount' | translate}}</label>
                                            <div class="input-group margin-bottom-sm mt-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text login-input-icon-password">
                                                        <i class="fab fa-behance"></i>
                                                    </span>
                                                </div>
                                                <input [(ngModel)]="candidate.behanceAccount"
                                                    class="form-control border-radius-0" id="behanceAccount"
                                                    name="behanceAccount" #behanceAccount type="text">
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label class="modal-input-label"
                                                for="stackoverflowAccount">{{'stackoverflowAccount' | translate}}</label>
                                            <div class="input-group margin-bottom-sm mt-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text login-input-icon-password">
                                                        <i class="fab fa-stack-overflow"></i>
                                                    </span>
                                                </div>
                                                <input [(ngModel)]="candidate.stackoverflowAccount"
                                                    class="form-control border-radius-0" id="stackoverflowAccount"
                                                    name="stackoverflowAccount" #stackoverflowAccount type="text">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Social Media End -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-warning border-radius-0" data-dismiss="modal" type="button">
                            {{ "cancel" | translate }}
                        </button>
                        <button (click)="saveCandidateProfileInfo(formEditCandidate,dateOfBirth.value)"
                            class="btn btn-success border-radius-0" type="button">
                            {{ "save" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
