import {Component, OnInit} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {LoginModel} from './login.model';
import {AuthService} from '../../services/auth/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../services/notification/notification.service';
import {TranslatePipe} from '../../pipes/translate/translate.pipe';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginUser: LoginModel;
    loginError = false;
    generalError = false;

    constructor(private oauthService: OAuthService,
                private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.loginUser = new LoginModel();
    }

    get token(): string {
        return this.oauthService.getAccessToken();
    }

    checkInputsFilled(): boolean {
        return !((this.loginUser.email).trim() === '' || (this.loginUser.password).trim() === '');
    }

    setCompanyId() {
        const companyId = JSON.parse(window.atob((this.token).split('.')[1])).ats_company_id;
        localStorage.setItem('companyId', companyId);
    }

    setMyUserId() {
        const userId = JSON.parse(window.atob((this.token).split('.')[1])).sub;
        localStorage.setItem('userId', userId);
    }

    ngOnInit(): void {
    }

    login() {
        this.spinner.show();
        this.loginError = false;
        this.generalError = false;
        if (!this.checkInputsFilled()) {
            this.notification.error(this.translate.transform('loginErrorInfo', []));
            this.spinner.hide();
            return;
        }
        localStorage.removeItem(this.authService.TOKEN_KEY);
        this.oauthService.fetchTokenUsingPasswordFlow(
            this.loginUser.email.trim(),
            this.loginUser.password
        )
            .then(() => {
                if (this.token) {
                    this.setCompanyId();
                    this.setMyUserId();
                    this.authService.setLanguage('');
                    this.authService.getUserPermissionsFromEndpoint();
                } else {
                    this.authService.ServerError('');
                }
                this.spinner.hide();
            })
            .catch(err => {

                if (err.error.code === 'INVALID_GRANT') {
                    this.loginError = true;
                 }else{
                     this.generalError = true;
                 }
                this.authService.ServerError(err);
                this.spinner.hide();
            });
    }
}
