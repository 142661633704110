import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ClientModel} from '../../client.model';
import {CountryModel} from '../../../../services/parameters/models/country.model';
import {CityModel} from '../../../../services/parameters/models/city.model';
import {IndustryModel} from '../../../../services/parameters/models/industry.model';
import {ServiceList} from '../../../../shared/lists/service.list';
import {AuthService} from '../../../../services/auth/auth.service';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';
import {ClientGroupList} from '../../../../shared/lists/client-group.list';
import { NgForm } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
    selector: 'app-edit-client',
    templateUrl: './edit-client.component.html',
    styleUrls: ['./edit-client.component.scss']
})
export class EditClientComponent implements OnInit, OnChanges {

    @Output() setClientScreen = new EventEmitter();

    categories: any;
    @Input() client: ClientModel;
    tempClient: ClientModel;
    clients: any;
    countries: CountryModel[];
    cities: CityModel[];
    industries: IndustryModel[];
    serviceList = ServiceList;
    accountManagers: any[] = [];
    clientGroupList = ClientGroupList;
    isSaveClick = false;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService,
                private utilitiesService: UtilitiesService) {
        this.client = new ClientModel();
    }

    ngOnInit(): void {
        this.getCountries();
        this.getIndustries();
        this.getAccountManagers();

    }

    ngOnChanges(changes: SimpleChanges) {
        this.tempClient = new ClientModel();
        this.tempClient = {...this.client};

        if (this.tempClient.country.id) {
            this.getCountryCities(this.tempClient.country.id);
        }
    }

    selectAccountManager(users) {
        users.length < 1 ? this.tempClient.accountManagers = null : this.tempClient.accountManagers = users;
    }

    getAccountManagers() {
        const companyId = this.authService.companyId;
        this.authService.ServerGet(server.users + '/' + '?filter=companyId::' +companyId)
            .subscribe((res: { content: [] }) => {
                this.accountManagers = res.content;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    selectClientGroup(e): void {
        this.tempClient.clientGroup = e.target.value;
    }

    selectIndustry(industry): void {
        if (!industry) { this.tempClient.industry.id = null; }
        this.tempClient.industry.id = industry.id;
    }

    selectService(e): void {
        this.tempClient.service = e.target.value;
    }

    selectCountry(country) {
        this.tempClient.country = new CountryModel();
        if (country.id === '') {
            this.tempClient.country.id = null;
            this.cities = [];
        } else {
            this.tempClient.country.id = country.id;
            this.tempClient.country.value = country.defaultLocalization.name;
            this.getCountryCities(this.tempClient.country.id);
        }
    }

    selectCity(city) {
        this.tempClient.city = new CityModel();
        if (city.id === '') {
            this.tempClient.city.id = null;
        } else {
            this.tempClient.city.id = city.id;
            this.tempClient.city.value = city.defaultLocalization.name;
        }
    }

    getCountries() {
        this.parameterService.countries.subscribe((res: CountryModel[]) => {
            this.countries = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCountryCities(countryId) {
        this.cities = [];
        this.parameterService.getCountryCities(countryId).subscribe((res: CityModel[]) => {
            this.cities = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getIndustries() {
        this.parameterService.industries.subscribe((res: IndustryModel[]) => {
            this.industries = res;
        }, err => {
            if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    numericOnly(event): boolean {    
        let patt = /^([0-9])$/;
        let result = patt.test(event.key);
        return result;
    }

    cancel() {
        this.isSaveClick = false;
        this.utilitiesService.validationClassState('validate-meEdit', 'remove');
        // @ts-ignore
        $('#editClient').modal('hide');
    }

    save(formClient: NgForm) {

        this.isSaveClick = true;
        if (!formClient.valid || !this.checkValidationComponentField()) {
            this.utilitiesService.validationClassState('validate-meEdit', 'add');
            this.notification.warning(this.translate.transform('fillRequiredFields', []));
            return;
          }


        this.authService.ServerPut(server.clients + '/' + this.tempClient.id, this.tempClient)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updateClients();
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.cancel();
                this.spinner.hide();
            });
    }

    updateClients() {
        this.setClientScreen.emit();
    }
    checkValidationComponentField(){
        if ( this.tempClient.accountManagers){
            return true;
        }
        return false;
    }

}
