import {CurrencyModel} from '../../../../services/parameters/models/currency.model';
import {CountryModel} from '../../../../services/parameters/models/country.model';
import {CityModel} from '../../../../services/parameters/models/city.model';
import {ClientModel} from '../../../clients/client.model';

export class GeneralModel {
    id: string;
    name: string;
    country: CountryModel;
    city: CityModel;
    jobType: string;
    jobTitle: string;
    educationDegree: string;
    closingDate: string;
    openingDate: string;
    client: ClientModel;
    showClientOnJobPost: boolean;
    maxSalary: number;
    showMaxSalaryOnJobPost: boolean;
    numberOfHiring: number;
    showNumberOfHiringOnJobPost: boolean;
    disabilityOption: boolean;
    jobDescription: string;
    requirements: string;
    positionFee: number;
    currency: CurrencyModel;
    commissionFee: number;
    estimatedInvoiceAmount: number;
    jobPostDuration: number;
    status: string;
    createdAt: string;
    createdBy: string;
    softSkills: string;
    technicalSkills: string;

    constructor() {
        this.status = 'DRAFT';
        this.showClientOnJobPost = false;
        this.showMaxSalaryOnJobPost = false;
        this.showNumberOfHiringOnJobPost = false;
        this.disabilityOption = false;
        this.country = new CountryModel();
        this.city = new CityModel();
        this.currency = new CurrencyModel();
        this.client = new ClientModel();
    }
}
