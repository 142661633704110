<div aria-hidden="true" aria-labelledby="resetPasswordModalTitle" class="modal fade" id="resetPasswordModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title" id="profileModalLongTitle">{{'changePassword' | translate}}</h5>
            </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label class="modal-input-label" for="oldPassword">{{'currentPassword' | translate}}</label>
                        <input [(ngModel)]="profile.currentPassword" class="form-control border-radius-0" [value]="passOne"
                               id="oldPassword"
                               name="oldPassword"
                               required="required" type="password">
                    </div>
                    <div class="mb-3">
                        <label class="modal-input-label" for="newPassword">{{'newPassword' | translate}}</label>
                        <input [(ngModel)]="profile.newPassword" class="form-control border-radius-0" [value]="passTwo"
                               id="newPassword"
                               name="newPassword"
                               required="required" type="password">
                    </div>
                    <div class="mb-3">
                        <label class="modal-input-label"
                               for="newPasswordAgain">{{'newPasswordAgain' | translate}}</label>
                        <input [(ngModel)]="profile.newPasswordAgain" class="form-control border-radius-0" [value]="passThree"
                               id="newPasswordAgain"
                               name="newPasswordAgain"
                               required="required" type="password">
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-warning border-radius-0" data-dismiss="modal"
                            type="button">{{'cancel' | translate}}</button>
                    <button (click)="resetPassword()"
                            [disabled]="(profile.newPassword !== profile.newPasswordAgain) || profile.currentPassword === '' || profile.newPassword === '' || profile.newPasswordAgain === ''"
                            class="btn btn-success border-radius-0"
                            type="button">{{'save' | translate}}</button>
                </div>
        </div>
    </div>
</div>
