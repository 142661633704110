import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PositionFilterModel} from '../../../positions/position.model';
import {RequestPayload} from '../../../../shared/requestPayload';
import {InterviewModel} from '../../../../services/parameters/models/interview.model';
import {PermissionService} from '../../../../services/permission/permission.service';

@Component({
    selector: 'app-report-event-list',
    templateUrl: './report-event-list.component.html',
    styleUrls: ['./report-event-list.component.scss']
})

export class ReportEventListComponent implements OnInit {

    @Input() count: number;
    activePage: any;
    currentPage: number;
    requestPayload: RequestPayload;
    @Input() interviews: InterviewModel[];
    @Output() updateCandidates = new EventEmitter();

    constructor(public permissionService: PermissionService) {
        this.requestPayload = new RequestPayload();
    }

    ngOnInit(): void {
    }

    pagination(pageNo) {
        this.updateCandidates.emit(pageNo);
    }

}
