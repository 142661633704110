<div class="container-fluid">
    <div class="row mb-2">
        <div class="col-12">
            <button (click)="addTeamMemberModalOpen()"
                    *ngIf="permissionService.permissionsOnly(['CREATE_HIRING_TEAM_MEMBER'])"
                    class="btn btn-blue exactalent-button border-radius-0 float-right">{{'addToPosition' | translate}}</button>
        </div>
    </div>
    <div *ngFor="let hiringTeamMember of hiringTeamMembers" class="row mt-1">
        <div class="col-12">
            <div class="row step-row">
                <div class="col-12 border-radius-0 step-col mt-2">
                    <div class="row">
                        <span class="float-left ml-4 step-text col">{{hiringTeamMember.fullName}}</span>
                        <span class="ml-4 step-text col">{{hiringTeamMember.email}}</span>
                        <span class="float-right control-panel col">
                            <span (click)="removeMemberModalOpen(hiringTeamMember)"
                                  *ngIf="permissionService.permissionsOnly(['DELETE_HIRING_TEAM_MEMBER'])"
                                  class="ml-4 float-right">
                                <i class="fas fa-times"></i> 
                            </span>
                        </span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<app-add-to-position (updateTable)="getHiringTeamMembers()"
                     *ngIf="permissionService.permissionsOnly(['CREATE_HIRING_TEAM_MEMBER'])"
                     [positionId]="positionId"></app-add-to-position>
<app-remove-team-member (runOperation)="getHiringTeamMembers()" [positionId]="positionId" [selectedMember]="selectedMember"></app-remove-team-member>
