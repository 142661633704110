import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RequestPayload} from '../../../../../shared/requestPayload';
import {PermissionService} from '../../../../../services/permission/permission.service';
import {PositionFilterModel} from '../../../position.model';
import {Router} from '@angular/router';
import {PipelineStepModel} from '../../../../../services/parameters/models/pipeline-step.model';

@Component({
    selector: 'app-pipeline-user-list',
    templateUrl: './pipeline-user-list.component.html',
    styleUrls: ['./pipeline-user-list.component.scss']
})
export class PipelineUserListComponent implements OnInit {

    @Input() count: number;
    activePage: any;
    @Input() filterData: PositionFilterModel;
    currentPage: number;
    requestPayload: RequestPayload;
    @Input() candidates: PipelineStepModel[];
    selectedCandidate: PipelineStepModel;
    @Output() updateCandidates = new EventEmitter();
    @Output() openPipelineCandidateStepNote = new EventEmitter();
    @Output() openPipelineCandidateStepChange = new EventEmitter();

    constructor(public permissionService: PermissionService,
                private router: Router) {
        this.requestPayload = new RequestPayload();
    }

    ngOnInit(): void {
    }

    pagination(pageNo) {
        this.updateCandidates.emit(pageNo);
    }

    openCandidateDetails(candidate) {
        this.router.navigate(['/dashboard/edit-candidate', {id: candidate.candidateId}]);
    }

    openStepNote(candidate) {
        this.openPipelineCandidateStepNote.emit(candidate);
    }

    openChangeCandidateStep(candidate) {
        this.openPipelineCandidateStepChange.emit(candidate);
    }

}
