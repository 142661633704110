<div class="table-responsive">
    <table class="table table-bg">
        <thead class="table-bg">
        <tr>
            <th scope="col">&nbsp;</th>
            <th scope="col">{{'name' | translate}}</th>
            <th scope="col">{{'country' | translate}}</th>
            <th scope="col">{{'city' | translate}}</th>
            <th scope="col">{{'email' | translate}}</th>
            <th scope="col">{{'phone' | translate}}</th>
            <th *ngIf="referencePage !== 'position'" scope="col">{{'position' | translate}}</th>
            <th scope="col">{{'step' | translate}}</th>
            <th scope="col">{{'source' | translate}}</th>
            <th scope="col">{{'added' | translate}}</th>
            <th scope="col">{{'lastActivity' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr (click)="openRowModal($event,candidate)"
            *ngFor="let candidate of candidates | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count }"
            class="candidate-row">
            <th scope="row">
            <span (click)="openCandidateStatusModal(candidate)" class="btn-group mr-2"
                  role="group" style="cursor: pointer;"
                  title="{{candidate.status | translate}}">
                <p [class]="candidate.status" class="candidate-status-color-icon">
                </p>&nbsp;&nbsp;
                <p><i class="fa fa-caret-down"></i></p>
            </span>
            </th>
            <td>{{candidate.fullName}}</td>
            <td>{{candidate.country?.value}}</td>
            <td>{{candidate.city?.value}}</td>
            <td>{{candidate.primaryEmail}}</td>
            <td>{{candidate.primaryPhone}}</td>
            <td *ngIf="referencePage!=='position'">{{candidate?.pipelineSteps[0]?.positionName}}</td>
            <td>
                <span *ngIf="referencePage!=='position'">{{candidate?.pipelineSteps[0]?.recruitmentStepName}}</span>
                <span *ngIf="candidate.pipelineSteps && referencePage==='position'">
                <span *ngFor="let step of candidate.pipelineSteps">
                    <span *ngIf="step.positionId === referenceId">{{step.recruitmentStepName}}</span>
                </span>
            </span>
            </td>
            <td>{{candidate.source}}</td>
            <td>
                <span *ngIf="candidate.createdBy === null">{{candidate.fullName}}</span>
                <app-user *ngIf="candidate.createdBy !== null" [userId]="candidate.createdBy"></app-user>
            </td>
            <td>{{candidate.updatedAt | date}}</td>
        </tr>
        <tr [hidden]="count>0">
            <td colspan="11">
                <app-empty-table-info tableInfoTranslateKey="candidateNotExistInfo"></app-empty-table-info>
            </td>
        </tr>
        </tbody>
        <tfoot>
        <tr class="width-100">
            <td class="text-center" colspan="7">
                <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
                                     [autoHide]="count <= requestPayload.limit"
                                     nextLabel="{{'next' | translate}}"
                                     previousLabel="{{'previous' | translate}}"
                                     responsive="true"></pagination-controls>
            </td>
        </tr>
        </tfoot>
    </table>
</div>
<app-delete-candidate (updateCandidate)="pagination(1)"
                      *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE'])"
                      [candidate]="selectedCandidate" [fromPage]=""></app-delete-candidate>
<app-candidate-status-modal *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_STATUS'])"
                            [candidate]="selectedCandidate"></app-candidate-status-modal>
<app-candidate-change-pool *ngIf="selectedCandidate" [candidateId]="selectedCandidate.id">
</app-candidate-change-pool>
<app-candidate-change-step (updateStepInfo)="updateStepInfo()" [candidateId]="selectedCandidate.id" *ngIf="selectedCandidate" ></app-candidate-change-step>
<app-take-note-modal></app-take-note-modal>
<div (clickOutside)="onClickedOutside($event)" class="operation-list" id="row-modal">
    <ul>
        <li (click)="openCandidateDetails()" class="operation-list-item">
            <span>{{'showCandidateDetails' | translate}}</span>
        </li>
        <li (click)="openPoolDetail()"
            class="mt-2 operation-list-item">
            <span>{{'addToPools' | translate}}</span>
        </li>
        <li (click)="openAddPositions()"
            class="mt-2 operation-list-item">
            <span>{{'addToPositions' | translate}}</span>
        </li>
        <li (click)="openUpdateStepModal()"
            class="mt-2 operation-list-item">
            <span>{{'changeStep' | translate}}</span>
        </li>
        <li (click)="updateStepInfo()" 
            class="mt-2 operation-list-item">
            <span>{{'createInterview' | translate}}</span>
        </li>
        <li (click)="takeNote()" 
            class="mt-2 operation-list-item">
            <span>{{'takeNote' | translate}}</span>
        </li>
        <li (click)="openDeleteCandidate()"
            *ngIf="referencePage === 'candidate' && permissionService.permissionsOnly(['DELETE_CANDIDATE'])"
            class="mt-2 operation-list-item">
            <span>{{'deleteCandidate' | translate}}</span>
        </li>   
        <li (click)="removeFromPool()"
            *ngIf="referencePage === 'pool' && permissionService.permissionsOnly(['DELETE_CANDIDATE_TO_POOL'])"
            class="mt-2 operation-list-item">
            <span>{{'removeFromPool' | translate}}</span>
        </li>
        <li (click)="removeFromPosition()" *ngIf="referencePage === 'position'"
            class="mt-2 operation-list-item">
            <span>{{'removeFromPosition' | translate}}</span>
        </li>  
    </ul>
</div>


