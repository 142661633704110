import {Component, Input, OnInit} from '@angular/core';
import {StatusList} from '../../../../../shared/lists/status.list';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../services/notification/notification.service';
import {server} from '../../../../../services/server';
import {CandidateModel} from '../../../candidate.model';
import {PermissionService} from '../../../../../services/permission/permission.service';

@Component({
    selector: 'app-candidate-update-status',
    templateUrl: './candidate-update-status.component.html',
    styleUrls: ['./candidate-update-status.component.scss']
})
export class CandidateUpdateStatusComponent implements OnInit {

    @Input() candidate: CandidateModel;

    statusList = StatusList;
    tempStatus: string;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
        this.candidate = new CandidateModel();
    }

    ngOnInit(): void {
    }

    selectStatus(e) {
        this.tempStatus = e.target.value;
    }

    updateStatus() {

        const obj = {
            status: this.tempStatus
        };
        this.authService.ServerPut(server.candidates + '/' + this.candidate.id + '/status', obj)
            .subscribe(res => {
                this.candidate.status = this.tempStatus;
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.closeUpdateStatusModal();
                this.spinner.hide();
            });
    }

    closeUpdateStatusModal() {
        // @ts-ignore
        $('#statusModal').modal('hide');
    }

}
