<!--Add pool education Modal -->
<div aria-hidden="true" aria-labelledby="addInterviewModal" class="modal fade"
     id="addInterviewModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">
                    {{ "scheduleInterview" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="date">{{'date' | translate}}<span
                                        class="text-danger">*</span></label>
                                <input #date
                                       class="form-control border-radius-0 datepicker"
                                       data-provide="datepicker"
                                       id="date"
                                       placeholder="yyyy-mm-dd" type="text">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="startTime">{{'startTime' | translate}}<span
                                        class="text-danger">*</span></label>
                                <input #startTime
                                       class="form-control border-radius-0"
                                       id="startTime" type="time">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="endTime">{{'endTime' | translate}}<span
                                        class="text-danger">*</span></label>
                                <input #endTime
                                       class="form-control border-radius-0"
                                       id="endTime" type="time">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="location">{{'location' | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [(ngModel)]="scheduleInterviewData.location"
                                       class="form-control border-radius-0"
                                       id="location" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="interviewers">{{'interviewers' | translate}}<span
                                        class="text-danger">*</span></label>
                                <app-multiselect-dropdown [isSearchable]="true" id="interviewers"
                                                          (selectResult)="selectInterviewers($event)"
                                                          [items]="interviewers"
                                                          [dropdownName]="'selectInterviewers'"
                                                          [translateObjectKey]="'fullName'"></app-multiselect-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label class="modal-input-label" for="description">{{'description' | translate}}</label>
                            <textarea class="form-control border-radius-0" id="description"
                                      [(ngModel)]="scheduleInterviewData.description"
                                      placeholder="{{'description' | translate}}"
                                      rows="4" appTitlecaseLower></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="scheduleInterview(date.value, startTime.value, endTime.value)"
                        class="btn btn-success border-radius-0" type="button">
                    {{ "save" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
