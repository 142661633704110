import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {server} from '../../../../../services/server';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../services/notification/notification.service';
import {UserModel} from '../../../../user-management/user.model';

@Component({
    selector: 'app-add-to-position',
    templateUrl: './add-to-position.component.html',
    styleUrls: ['./add-to-position.component.scss']
})
export class AddToPositionComponent implements OnInit {

    companyId: string;
    @Input() positionId: string;
    users: UserModel[];
    minSearchValueCount: number;
    selectedUser: UserModel;
    selectedUserFullName: string;
    @Output() updateTable = new EventEmitter();

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                private notification: NotificationService) {
        this.selectedUser = new UserModel();

    }

    ngOnInit(): void {
        this.minSearchValueCount = 3;
        this.companyId = this.authService.companyId;
        // @ts-ignore
        $('#addToPositionHiringTeamMemberModal').on('hidden.bs.modal', () => {
            // @ts-ignore
            $('#names').val('');
            // @ts-ignore
            this.users = [];
        });

    }

    searchUsers(data) {
        this.users = [];
        this.selectedUser = new UserModel();
        if (data.length < this.minSearchValueCount) {
            return false;
        }
        const companyId = this.authService.companyId;
        this.authService.ServerGet(server.users + '/' + '?filter=companyId::' + companyId + '&filter=name~~' + data)
            .subscribe((res: { content: [] }) => {
                this.users = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    selectUser(user) {
        this.selectedUser = user;
        this.selectedUserFullName = user.fullName;
    }

    addToPosition() {
        const obj = {
            userId: this.selectedUser.id
        };
        this.authService.ServerPost(server.positions + '/' + this.positionId + '/hiring-team-members', obj)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    console.log(err.code);
                    if (err.code === 'INVALID_VALUE') {

                        const errorMessage = this.translate.transform('theUserIsAlreadyInTheHiringTeam');
                        this.notification.error(errorMessage);
                    } else {
                        this.notification.error(err.message);
                    }
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#addToPositionHiringTeamMemberModal').modal('hide');
                this.updateTable.emit();
                this.spinner.hide();
            });
        this.selectedUserFullName = '';
        this.users = [];
        this.selectedUser = new UserModel();
    }

}
