<app-upcoming-event-list-item (openOperationDialog)="operationDialogController($event)"
                              *ngFor="let interview of interviews | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count }"
                              [interview]="interview">

</app-upcoming-event-list-item>
<div class="container-fluid mt-3">
    <div class="row">
        <div class="col-12 text-center">
            <pagination-controls (pageChange)="currentPage = $event;getInterviews(currentPage)" nextLabel=''
                                 previousLabel=''
                                 [autoHide]="count <= requestPayload.limit"
                                 responsive="true"></pagination-controls>
        </div>
    </div>
</div>

<app-edit-interview-modal *ngIf="permissionService.permissionsOnly(['UPDATE_INTERVIEW'])" (interviewUpdate)="getInterviews(1)" [interviewInfo]="event"></app-edit-interview-modal>
<app-delete-interview-modal *ngIf="permissionService.permissionsOnly(['DELETE_INTERVIEW'])" (interviewUpdate)="getInterviews(1)" [interviewId]="event?.id"></app-delete-interview-modal>
<app-calendar-interview-modal (openEditInterview)="openEditInterview()"
(openRemoveInterview)="openDeleteInterview()"
[interview]="event"
[isMonthView]="false"> 
</app-calendar-interview-modal>