<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-12 pl-5">
            <p>
                <b>{{'workingStatus' | translate}}&nbsp;:&nbsp;</b>{{(generalInfo.workingStatus !== null ? generalInfo.workingStatus : 'unspecified') | translate}}
            </p>
            <p>
                <b>{{'jobSearch' | translate}}&nbsp;:&nbsp;</b>{{(generalInfo.jobSearchStatus !== null ? generalInfo.jobSearchStatus : 'unspecified') | translate}}
            </p>
            <p>
                <b>{{'jobSearchingStatusUpdateDate' | translate}}&nbsp;:&nbsp;</b>{{generalInfo.jobSearchStatusUpdatedAt | date}}
            </p>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row mt-1">
        <div class="col-12">
            <div class="row">
                <p class="col" style="border-bottom: 3px solid #f6f6f6"></p>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid mt-1">
    <div class="row">
        <div class="col-12 pl-5">
            <p>
                <b>{{'minSalaryExpectation' | translate}}&nbsp;:&nbsp;</b>{{(generalInfo.minSalaryExpectation !== null ? generalInfo.minSalaryExpectation : 'unspecified') | translate}}
            </p>
            <p>
                <b>{{'salaryExpectationUpdateDate' | translate}}&nbsp;:&nbsp;</b>{{generalInfo.salaryExpectationUpdatedAt | date}}
            </p>
            <p><b>{{'preferredWorkingLocation' | translate}}&nbsp;:&nbsp;</b>
                {{(generalInfo.preferredCountry !== null ? generalInfo.preferredCountry?.value : 'unspecified') | translate}}
                <span *ngIf="generalInfo.preferredCity !== null">,</span>
                &nbsp;{{generalInfo.preferredCity?.value}}</p>
            <p>
                <b>{{'preferredIndustry' | translate}}&nbsp;:&nbsp;</b>{{(generalInfo.preferredIndustry !== null ? generalInfo.preferredIndustry?.value : 'unspecified') | translate}}
            </p>
            <p>
                <b>{{'preferredTitle' | translate}}&nbsp;:&nbsp;</b>{{(generalInfo.preferredTitle !== '' ? generalInfo.preferredTitle : 'unspecified') | translate}}
            </p>
            <p>
                <b>{{'preferredJobType' | translate}}&nbsp;:&nbsp;</b>{{(generalInfo.preferredJobType !== null ? generalInfo.preferredJobType : 'unspecified') | translate}}
            </p>
            <p>
                <b>{{'outsourcePreferences' | translate}}&nbsp;:&nbsp;</b>
                {{ (generalInfo.outsourcePreference === true ? 'yes' : (generalInfo.outsourcePreference !== null ? 'no' : 'unspecified')) | translate}}
            </p>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row mt-1">
        <div class="col-12">
            <div class="row">
                <p class="col" style="border-bottom: 3px solid #f6f6f6"></p>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid mt-1">
    <div class="row">
        <div class="col-12 pl-5">
            <p>
                <b>{{'gender' | translate}}&nbsp;:&nbsp;</b>{{(generalInfo.gender !== null ? generalInfo.gender : 'unspecified') | translate}}
            </p>
            <p>
                <b>{{'militaryService' | translate}}&nbsp;:&nbsp;</b>{{(generalInfo.militaryService !== null ? generalInfo.militaryService : 'unspecified') | translate}}
            </p>
            <p><b>{{'dateOfPostponement' | translate}}&nbsp;:&nbsp;</b>{{generalInfo.dateOfPostponement | date}}</p>
            <p>
                <b>{{'nationality' | translate}}&nbsp;:&nbsp;</b>{{(generalInfo.nationality !== null ? generalInfo.nationality?.value : 'unspecified') | translate}}
            </p>
            <p>
                <b>{{'drivingLicence' | translate}}&nbsp;:&nbsp;</b>
                {{ (generalInfo.drivingLicence === true ? 'yes' : (generalInfo.drivingLicence !== null ? 'no' : 'unspecified')) | translate}}
            </p>
            <p>
                <b>{{'smoking' | translate}}&nbsp;:&nbsp;</b>
                {{(generalInfo.smoking === true ? 'yes' : (generalInfo.smoking !== null ? 'no' : 'unspecified')) | translate}}
            </p>
        </div>
    </div>
</div>
<div *ngIf="permissionService.permissionsOnly(['UPDATE_CANDIDATE_GENERAL_INFO'])" class="container-fluid mt-2">
    <div class="row">
        <div class="col-12 pl-5">
            <p (click)="editGeneralInfoModal()" class="edit-link cursor-pointer">{{'editGeneralInfo' | translate}}</p>
        </div>
    </div>
</div>
<app-edit-general-info-modal (updatedContent)="updateContent()"
                             *ngIf="editGeneralInfoState && permissionService.permissionsOnly(['UPDATE_CANDIDATE_GENERAL_INFO'])"
                             [candidateId]="candidateId"
                             [generalInfo]="generalInfo"></app-edit-general-info-modal>
