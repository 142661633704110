<div class="row">
    <div class="col-auto">
        <app-report-filter (applyFilterOperation)="applyFilter($event)"></app-report-filter>
    </div>
</div>
<div class="row mt-3">
    <div class="col-12">
        <div class="table-responsive">
            <table class="table table-bg">
                <thead class="table-bg">
                <tr>
                    <th scope="col">{{'Position' | translate}}</th>
                    <th scope="col">{{'Client' | translate}}</th>
                    <th scope="col">{{'User' | translate}}</th>
                    <th scope="col">{{'Added' | translate}}</th>
                    <th scope="col">{{'Hired' | translate}}</th>
                    <th scope="col">{{'Time To Fill' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of timeToFillData | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count }"
                    class="pool-row">
                    <td>{{data.position?.name}}</td>
                    <td>{{data.client?.name}}</td>
                    <td>{{data.candidate?.name}}</td>
                    <td>
                        <app-user [userId]="data.createdBy"></app-user>
                    </td>
                    <td>
                        <app-user [userId]="data.hired"></app-user>
                    </td>
                    <td>{{data.timeToFill}}</td>
                </tr>
                <tr [hidden]="count>0">
                    <td colspan="6">
                        <app-empty-table-info tableInfoTranslateKey="timeToFillDataNotExist"></app-empty-table-info>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="width-100">
                    <td class="text-center" colspan="6">
                        <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
                                             [autoHide]="count <= requestPayload.limit"
                                             nextLabel="{{'next' | translate}}"
                                             previousLabel="{{'previous' | translate}}"
                                             responsive="true"></pagination-controls>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
