import {Component, OnInit} from '@angular/core';
import {RequestPayload} from '../../../shared/requestPayload';
import {ClientFilterModel, ClientModel} from '../client.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../services/permission/permission.service';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';
import {server} from '../../../services/server';

@Component({
    selector: 'app-client-list',
    templateUrl: './client-list.component.html',
    styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {

    count: number;
    activePage: any;
    currentPage: number;
    requestPayload: RequestPayload;
    selectedClient: ClientModel;
    selectedClientId: string;
    clients: ClientModel[];
    tagList = ['TR', 'tr', 'TD', 'td'];
    openedElementId: string;
    positionFilter: ClientFilterModel;
    filterData: ClientFilterModel;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.requestPayload = new RequestPayload();
        this.selectedClient = new ClientModel();
    }

    ngOnInit() {
        this.filterData = new ClientFilterModel();
        this.count = 0;
        this.clients = [];
        this.getClients(1);
    }

    openClientStatusModal(client) {
        this.selectedClient = client;
        // @ts-ignore
        $('#statusModal').modal('show');
    }

    getClients(pageNo) {
        this.authService.ServerGet(server.clients + this.requestPayload.payloadURL(pageNo) + this.requestFilter())
            .subscribe((res: any) => {
                this.clients = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    pagination(pageNo) {
        this.getClients(pageNo);
    }

    onClickedOutside(event) {
        if (this.tagList.indexOf(event.target.tagName) <= -1) {
            this.closeRowModal();
        }
    }

    openRowModal(event, client) {
        if (this.tagList.indexOf(event.target.tagName) > -1) {
            if (this.openedElementId && document.getElementById(this.openedElementId)) {
                document.getElementById('row-modal').style.display = 'none';
            }
            this.selectedClient = client;
            this.openedElementId = this.selectedClient.id;
            document.getElementById('row-modal').style.left = event.layerX + 'px';
            document.getElementById('row-modal').style.top = event.layerY + 40 + 'px';
            document.getElementById('row-modal').style.display = 'block';
        } else {
            this.closeRowModal();
        }
    }

    closeRowModal() {
        if (this.openedElementId) {
            document.getElementById('row-modal').style.display = 'none';
        }
    }

    openClientDetails() {
        this.router.navigate(['/dashboard/client-details', {id: this.selectedClient.id}]);
    }

    openAddClient() {
        // @ts-ignore
        $('#addClient').modal('show');
    }

    setClientScreens(clientId) {
        this.openAddContactPerson(clientId);
        this.getClients(1);
    }

    openEditClient() {
        this.closeRowModal();
        // @ts-ignore
        $('#editClient').modal('show');
    }

    openDeleteClient() {
        this.closeRowModal();
        // @ts-ignore
        $('#deleteClient').modal('show');
    }

    openAddContactPerson(clientId) {
        this.selectedClientId = clientId;
        // @ts-ignore
        $('#addContactPerson').modal('show');
    }

    applyFilter(filter) {
        this.positionFilter = filter;
        if (JSON.stringify(this.filterData) === JSON.stringify(this.positionFilter)) {
            return false;
        }
        this.filterData = new ClientFilterModel();
        this.filterData = {...this.positionFilter};
        this.getClients(1);
    }

    requestFilter() {
        return '&filter=countryId**' + this.filterData.country +
            '&filter=cityId**' + this.filterData.city +
            '&filter=industryId**' + this.filterData.industry +
            '&filter=status**' + this.filterData.status +
            '&filter=service**' + this.filterData.service +
            '&filter=clientGroup**' + this.filterData.clientGroup;
    }
}
