<div class="input-group margin-bottom-sm">
    <div class="input-group-prepend">
        <span class="input-group-text login-input-icon-email">
            <i class="fas fa-envelope"></i>
        </span>
    </div>
    <input [(ngModel)]="loginUser.email" autocomplete="off" class="form-control login-input"
           name="email"
           placeholder="{{'emailAddress' | translate}}" type="text">
</div>
<div class="input-group margin-bottom-sm mt-2">
    <div class="input-group-prepend">
        <span class="input-group-text login-input-icon-password">
            <i class="fa fa-lock"></i>
        </span>
    </div>
    
    <input
            [(ngModel)]="loginUser.password" autocomplete="off" class="form-control login-input"
            name="password"
            placeholder="{{'password' | translate}}" type="password">
</div>
<div *ngIf="loginError"
    class="alert alert-danger mt-2">
    <div>{{'loginErrorInfo' | translate}}</div>
</div>
<div *ngIf="generalError"
class="alert alert-danger mt-2" >{{'loginErrorGeneralInfo' | translate }}</div> 
<div class="input-group margin-bottom-sm login-button-div mt-2">
    <input (click)="login()"
           [disabled]="!loginUser.email || loginUser.email == '' || !loginUser.password || loginUser.password ==''"
           class="btn login-btn"
           type="submit" value="{{'signIn'| translate}}">
</div>
<div class="input-group mt-4">
    <a [routerLink]="['password-request']"
       class="forgot-password">{{'forgotMyPassword' | translate}}</a>
</div>
<div class="input-group mt-4">
    <a routerLink="/register"
       class="create-new-account">Create a New Account</a>
</div>
