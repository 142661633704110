<!-- Modal -->
<div aria-hidden="true" aria-labelledby="deleteAttachmentModalTitle" class="modal fade"
     id="deleteAttachmentModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="deleteAttachmentModalLongTitle">{{'deleteAttachment' | translate}}</h5>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <label>{{'deleteAttachmentConfirmation' | translate}}</label>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="cancelDeleteAttachment()" class="btn btn-warning border-radius-0"
                        data-dismiss="modal"
                        type="button">{{'cancel' | translate}}</button>
                <button (click)="deleteAttachment()" class="btn btn-danger border-radius-0"
                        type="button">{{'delete' | translate}}</button>
            </div>
        </div>
    </div>
</div>
