import {DefaultNameModel} from './default-name.model';

export class InterviewModel {
    id?: string | number;
    position: DefaultNameModel;
    recruitmentStep: DefaultNameModel;
    candidate: DefaultNameModel;
    date: string;
    startTime: string;
    endTime: string;
    location: string;
    description: string;
    occurred: boolean;
    deleted: boolean;
    attendees: [DefaultNameModel];
    client: DefaultNameModel;

    constructor() {
        this.position = new DefaultNameModel();
        this.recruitmentStep = new DefaultNameModel();
        this.candidate = new DefaultNameModel();
        this.client = new DefaultNameModel();
        this.attendees = [new DefaultNameModel()];
    }
}

export class CalendarFilterModel {
    position: string;
    recruitmentStep: string;
    candidate: string;
    occurred: boolean;
    deleted: boolean;
    attendees: string;
    location: string;
    date: string;
    company: string;

    constructor() {
        this.position = '';
        this.recruitmentStep = '';
        this.candidate = '';
        this.occurred = false;
        this.deleted = false;
        this.attendees = '';
        this.location = '';
        this.date = '';
        this.company = '';
    }
}

