import {Component, OnInit} from '@angular/core';
import {PasswordRequestModel} from './password-request.model';
import {server} from '../../services/server';
import {AuthService} from '../../services/auth/auth.service';
import {TranslatePipe} from '../../pipes/translate/translate.pipe';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../services/notification/notification.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-password-request',
    templateUrl: './password-request.component.html',
    styleUrls: ['./password-request.component.scss']
})
export class PasswordRequestComponent implements OnInit {

    passwordRequestUser: PasswordRequestModel;

    constructor(private authService: AuthService,
                private translate: TranslatePipe,
                private router: Router,
                private spinner: NgxSpinnerService,
                private notification: NotificationService) {
        this.passwordRequestUser = new PasswordRequestModel();
    }

    ngOnInit(): void {
    }

    passwordRequest() {
        this.authService.ServerPostWithoutLogin(server.passwordRequest, this.passwordRequestUser)
            .subscribe(res => {
                this.notification.success(this.translate.transform('resetPasswordMessage', []));
                this.router.navigate(['/']);
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.spinner.hide();
            });
    }

}
