import {Component, Input, OnInit} from '@angular/core';
import {RequestPayload} from '../../../../shared/requestPayload';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';

@Component({
    selector: 'app-client-position-requests',
    templateUrl: './client-position-requests.component.html',
    styleUrls: ['./client-position-requests.component.scss']
})
export class ClientPositionRequestsComponent implements OnInit {

    @Input() clientId: string;
    count: number;
    activePage: any;
    currentPage: number;
    requestPayload: RequestPayload;
    selectedPositionRequest;
    positionRequests = [];
    tagList = ['TR', 'tr', 'TD', 'td'];
    openedElementId: string;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.requestPayload = new RequestPayload();
    }

    ngOnInit() {
        this.count = 0;
        this.positionRequests = [];
        this.getPositionRequests(1);
    }

    getPositionRequests(pageNo) {
        this.authService.ServerGet(server.positionRequests + this.requestPayload.payloadURLWithOutDelete(pageNo) + '&filter=clientId::' + this.clientId)
            .subscribe((res: any) => {
                this.positionRequests = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    pagination(pageNo) {
        this.getPositionRequests(pageNo);
    }

    onClickedOutside(event) {
        if (this.tagList.indexOf(event.target.tagName) <= -1) {
            this.closeRowModal();
        }
    }

    openRowModal(event, positionRequest) {
        if (this.tagList.indexOf(event.target.tagName) > -1) {
            if (this.openedElementId && document.getElementById(this.openedElementId)) {
                document.getElementById('row-modal').style.display = 'none';
            }
            this.selectedPositionRequest = positionRequest;
            this.openedElementId = this.selectedPositionRequest.id;
            document.getElementById('row-modal').style.left = event.layerX + 'px';
            document.getElementById('row-modal').style.top = event.layerY + 'px';
            document.getElementById('row-modal').style.display = 'block';
        } else {
            this.closeRowModal();
        }
    }

    closeRowModal() {
        if (this.openedElementId) {
            document.getElementById('row-modal').style.display = 'none';
        }
    }

    openPositionRequestDetails() {
        this.router.navigate(['/dashboard/positionRequest-details', {id: this.selectedPositionRequest.id}]);
    }

    openPositionDetails() {
        // @ts-ignore
        $('#positionRequestDetailsModal').modal('show');
        this.closeRowModal();
    }
}
