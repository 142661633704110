import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-upcoming-events',
    templateUrl: './upcoming-events.component.html',
    styleUrls: ['./upcoming-events.component.scss']
})
export class UpcomingEventsComponent implements OnInit {

    count: number;

    constructor() {
    }

    ngOnInit(): void {
    }

    setEventCount(count) {
        this.count = count;
    }

}
