<div class="container-fluid">
    <div class="row">
        <div (click)="setTab('general')"
             [ngClass]="{'gradient-bg':!generalComponentState,
             'top-line':(generalComponentState && operationType!='add' ),
             'col-12 menu-item gradient-bg':(operationType=='add'),
             'col-xl-6 col-lg-6 col-md-6 col-sm-12':(operationType ==='edit'),
             'col-12':!permissionService.permissionsOnly(['READ_ALL_HIRING_TEAM_MEMBERS']) || (operationType !=='edit')}"
             class="text-center">
            <label class="nav-link general-tab-color">{{'general' | translate}}</label>
        </div>
        <div (click)="setTab('hiringTeam')"
             *ngIf="operationType ==='edit' || !permissionService.permissionsOnly(['READ_ALL_HIRING_TEAM_MEMBERS'])"
             [class]="!hiringTeamComponentState ? ' gradient-bg':'top-line'"
             class="col-xl-6 col-lg-6 col-md-6 col-sm-12 menu-item">
            <a class="nav-link">{{'hiringTeam' | translate}}</a>
        </div>
    </div>
</div>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-12">
            <app-general (cancelPositionOperation)="cancelPositionOperation()" *ngIf="generalComponentState"
                         [addPositionModel]="positionDetail"
                         [operationType]="operationType"></app-general>
            <app-hiring-team *ngIf="hiringTeamComponentState && (operationType !=='add')"
                             [positionId]="positionDetail.id"></app-hiring-team>
        </div>
    </div>
</div>
