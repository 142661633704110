<div class="row">
    <div class="col-auto mb-1">
        <app-client-list-filter (applyFilterOperation)="applyFilter($event)"></app-client-list-filter>
    </div>
    <div *ngIf="permissionService.permissionsOnly(['CREATE_CLIENT'])" class="col-auto mb-1">
        <button (click)="openAddClient()"
                class="btn btn-blue border-radius-0 float-right exactalent-button width-100"
                data-target="#addUserCenter"
                data-toggle="modal">
            {{'addNewClient' | translate}}
        </button>
    </div>
</div>
<div class="row mt-2">
    <div class="col-12">
        <div class="table-responsive">
            <table class="table table-bg">
                <thead class="table-bg">
                <tr>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">{{'name' | translate}}</th>
                    <th scope="col">{{'country' | translate}}</th>
                    <th scope="col">{{'city' | translate}}</th>
                    <th scope="col">{{'address' | translate}}</th>
                    <th scope="col">{{'industry' | translate}}</th>
                    <th scope="col">{{'website' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr (click)="openRowModal($event,client)"
                    *ngFor="let client of clients | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count }"
                    class="client-row">
                    <th scope="row">
            <span (click)="openClientStatusModal(client)" class="btn-group mr-2"
                  role="group" style="cursor: pointer;">
                <p [class]="client.status" class="client-status-color-icon">
                </p>&nbsp;&nbsp;
                <p><i class="fa fa-caret-down"></i></p>
            </span>
                    </th>
                    <td>{{client.name}}</td>
                    <td>{{client.country?.value}}</td>
                    <td>{{client.city?.value}}</td>
                    <td>{{client.address}}</td>
                    <td>{{client.industry?.value}}</td>
                    <td>{{client.website}}</td>
                </tr>
                <tr [hidden]="count>0">
                    <td colspan="7">
                        <app-empty-table-info tableInfoTranslateKey="clientNotExistInfo"></app-empty-table-info>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="width-100">
                    <td class="text-center" colspan="6">
                        <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
                                             [autoHide]="count <= requestPayload.limit"
                                             nextLabel="{{'next' | translate}}"
                                             previousLabel="{{'previous' | translate}}"
                                             responsive="true"></pagination-controls>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
<app-add-client (setClientScreen)="setClientScreens($event)"></app-add-client>
<app-edit-client (setClientScreen)="getClients(1)" *ngIf="permissionService.permissionsOnly(['UPDATE_CLIENT'])"
                 [client]="selectedClient"></app-edit-client>
<app-delete-client (setClientScreen)="getClients(1)" *ngIf="permissionService.permissionsOnly(['DELETE_CLIENT'])"
                   [client]="selectedClient"></app-delete-client>
<app-change-client-status-modal *ngIf="permissionService.permissionsOnly(['UPDATE_CLIENT_STATUS'])"
                                [client]="selectedClient"></app-change-client-status-modal>
<app-add-contact-person *ngIf="permissionService.permissionsOnly(['CREATE_CONTACT_PERSON'])" [clientId]="selectedClientId"></app-add-contact-person>

<div (clickOutside)="onClickedOutside($event)" class="operation-list" id="row-modal">
    <ul>
        <li (click)="openClientDetails()"
            *ngIf="permissionService.permissionsOnly(['READ_CLIENT'])" class="operation-list-item">
            <span>{{'showClientDetails' | translate}}</span>
        </li>
        <li (click)="openEditClient()"
            *ngIf="permissionService.permissionsOnly(['UPDATE_CLIENT'])" class="mt-2 operation-list-item">
            <span>{{'edit' | translate}}</span>
        </li>
        <li (click)="openDeleteClient()"
            *ngIf="permissionService.permissionsOnly(['DELETE_CLIENT'])" class="mt-2 operation-list-item">
            <span>{{'delete' | translate}}</span>
        </li>
    </ul>
</div>
