<div class="container-fluid" style="height: 100%;">
    <div class="row">
        <div class="col-12 menu-item" style="background-color: #0d6b7b;">
            <a class="nav-link" style="color: white;padding: 11px 0;">
                {{'upcomingEvents' | translate}}&nbsp;({{count}})
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-upcoming-event-list (setEventCount)="setEventCount($event)"></app-upcoming-event-list>
        </div>
    </div>
</div>