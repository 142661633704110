import {Component, OnInit} from '@angular/core';
import {PasswordModel} from '../profile.model';
import {server} from '../../../services/server';
import {AuthService} from '../../../services/auth/auth.service';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../../services/notification/notification.service';

@Component({
    selector: 'app-password-reset-modal',
    templateUrl: './password-reset-modal.component.html',
    styleUrls: ['./password-reset-modal.component.scss']
})
export class PasswordResetModalComponent implements OnInit {

    profile: PasswordModel;
    passOne: string;
    passTwo: string;
    passThree: string;

    constructor(private authService: AuthService,
                private translate: TranslatePipe,
                private spinner: NgxSpinnerService,
                private notification: NotificationService) {
        this.profile = new PasswordModel();
    }

    ngOnInit(): void {
    }

    resetPassword() {
        if (this.profile.currentPassword === this.profile.newPassword) {
            this.notification.warning(this.translate.transform('passwordsCannotBeSame', []));
            return 0;
        }
        const obj = {
            currentPassword: this.profile.currentPassword,
            newPassword: this.profile.newPassword
        };
        this.authService.ServerPut(server.me + '/password', obj)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.passOne = ' ';
                this.passTwo = ' ';
                this.passThree = ' ';
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.spinner.hide();
                // @ts-ignore
                $('#resetPasswordModal').modal('hide');
            });
    }

}
