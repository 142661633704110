<!--Edit candidate education Modal -->
<div aria-hidden="true" aria-labelledby="editCandidateEducationTitle" class="modal fade" id="editCandidateEducation"
    role="dialog" tabindex="-1">

    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">

        <div class="modal-content">
            <form #editCandidateEducationForm="ngForm" class="needs-validation">
                <div class="modal-header gradient-bg">
                    <h5 class="modal-title" id="editUserLongTitle">
                        {{ "editCandidateEducation" | translate }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="university">{{"university" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <app-searchable-dropdown-with-add (selectResult)="selectUniversity($event)"
                                        [items]="universities" [selectedItem]="education.university?.value"
                                        fieldName="university" id="university" [isSaveClick]="isSaveClick"
                                        isRequired="true" nameInput="universityF"></app-searchable-dropdown-with-add>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="faculty">{{"faculty" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <app-searchable-dropdown-with-add (selectResult)="selectFaculty($event)"
                                        [items]="faculties" [selectedItem]="education.faculty?.value"
                                        fieldName="faculty" id="faculty" [isSaveClick]="isSaveClick" isRequired="true"
                                        nameInput="facultyF"></app-searchable-dropdown-with-add>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="department">{{"department" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <app-searchable-dropdown-with-add (selectResult)="selectDepartment($event)"
                                        [items]="departments" [selectedItem]="education.department?.value"
                                        fieldName="department" id="department" [isSaveClick]="isSaveClick"
                                        isRequired="true" nameInput="departmentF"></app-searchable-dropdown-with-add>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="mb-3 validate-me">
                                    <label class="modal-input-label"
                                        for="educationDegree">{{"educationDegree" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <select (change)="selectEducationDegree($event)" [(ngModel)]="education.degree"
                                        class="form-control border-radius-0" id="educationDegree" name="educationDegree"
                                        required>
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let educationDegree of educationDegreeList"
                                            [selected]="educationDegree.value === education.degree"
                                            [value]="educationDegree.value">{{educationDegree.name | translate}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="beginningEndingDate">
                                        {{"beginningEndingDate" | translate}}
                                    </label>
                                    <div class="container-fluid">
                                        <div class="row" id="beginningEndingDate">
                                            <div class=validate-me>
                                                <input [(ngModel)]="education.startYear"
                                                    class="form-control col border-radius-0 hideInputNumberArrow" id="startYear"
                                                    name="startYear" placeholder="{{'startYear' | translate}}..."
                                                    type="number" required />
                                            </div>
                                            &nbsp;-&nbsp;
                                            <input [(ngModel)]="education.endYear"
                                                class="form-control col border-radius-0 hideInputNumberArrow" id="endYear" name="endYear"
                                                placeholder="{{ 'endYear' | translate }}..." type="number" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="gpa">{{"gpa" | translate}}</label>
                                    <input [(ngModel)]="education.grade" class="form-control border-radius-0" name="gpa"
                                        id="gpa" max="100" min="0" type="number">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="closeEditCandidateEducationModal()" class="btn btn-warning border-radius-0"
                        data-dismiss="modal" type="button">
                        {{ "cancel" | translate }}
                    </button>
                    <button (click)="saveCandidateEducation(editCandidateEducationForm)"
                        class="btn btn-success border-radius-0" type="button">
                        {{ "save" | translate }}
                    </button>
                </div>
            </form>
        </div>

    </div>

</div>