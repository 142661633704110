<div class="container-fluid main-header">
    <a [routerLink]="['dashboard']" class="nav-link navbar-link d-xl-block d-lg-block d-md-block d-sm-none d-none">
        <img [src]="appLogo" height="45">
    </a>
    &nbsp;&nbsp;
    <span (click)="toggleSidebar()" class="sidebar-toggle">
        <i class="fas fa-bars" style="margin-top: 14px;"></i>
    </span>
    <span class="dropdown float-right" style="margin-top: -10px;">
        <a aria-expanded="false" aria-haspopup="true" class="nav-link float-left"
           data-toggle="dropdown"
           href="#" id="navbarDropdownMenuLink"> 
            <div class='user-img-div'>
                <img style="padding:1px!important;" [src]="tempUserImg" class="rounded-circle img-thumbnail img-fluid user-img">
            </div>
            <div class="user-info">
                <p class="user-name d-xl-block d-lg-block d-md-block d-sm-none d-none">
                    <b>{{userFullName}}</b>
                    &nbsp;&nbsp;
                    <span class="dropdown-toggle"></span>
                </p>
                <p *ngIf="company" class="user-title d-xl-block d-lg-block d-md-block d-sm-none d-none">{{company?.name}}</p>
            </div>
        </a>
        <div aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-right drp-top-menu">
            <ul class="list-group header-drp-menu mt-1">
                <li [routerLink]="['profile']" class="list-group-item">
                            <span class="header-drp-menu-content-text">{{ 'profile' | translate }}<i
                                    class="fas fa-angle-right float-right ml-3"></i></span>
                </li>
                <li *ngIf="permissionService.permissionsOnly(['READ_ALL_USERS','READ_USER','READ_COMPANY','READ_ALL_COMPANIES','READ_ALL_PIPELINE_STEPS'])"
                    [routerLink]="['company-settings']"
                    class="list-group-item">
                            <span class="header-drp-menu-content-text">{{ 'companySettings' | translate }}<i
                                    class="fas fa-angle-right float-right ml-3"></i></span>
                </li>
                <li (click)="logout()" class="list-group-item">
                            <span class="header-drp-menu-content-text">{{ 'logOut' | translate }}<i
                                    class="fas fa-angle-right float-right ml-3"></i></span>
                </li>
            </ul>
        </div>
    </span>
    <span class="float-right" style="padding: 9px 5px 12px 5px; font-size: 23px;">
        <i (click)="openMessageModal()" class="fas fa-envelope cursor-pointer"></i>
    </span>
    <span class="float-right" style="padding: 9px 5px 12px 5px; font-size: 23px;">
        <i (click)="openSearchModal()" class="fas fa-search fa-rotate-90 cursor-pointer"></i>
    </span>
   
</div>
<app-search></app-search>
<app-messages></app-messages>
