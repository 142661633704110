import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { TitleCasePipe } from '@angular/common';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslatePipe} from './pipes/translate/translate.pipe';
import {LoginLayoutComponent} from './shared/layouts/login-layout/login-layout.component';
import {DashboardLayoutComponent} from './shared/layouts/dashboard-layout/dashboard-layout.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {SidebarModule} from 'ng-sidebar';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {LoginComponent} from './components/login/login.component';
import {AuthService} from './services/auth/auth.service';
import {TranslateService, TRANSLATION_PROVIDERS} from './services/translate';
import {NotificationService} from './services/notification/notification.service';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderComponent} from './components/header/header.component';
import {CalendarComponent} from './components/calendar/calendar.component';
import {ReportsComponent} from './components/reports/reports.component';
import {PositionsComponent} from './components/positions/positions.component';
import {CandidatesComponent} from './components/candidates/candidates.component';
import {PoolsComponent} from './components/pools/pools.component';
import {ClientsComponent} from './components/clients/clients.component';
import {ProfileComponent} from './components/profile/profile.component';
import {CompanySettingsComponent} from './components/company-settings/company-settings.component';
import {PasswordRequestComponent} from './components/password-request/password-request.component';
import {PasswordResetComponent} from './components/password-reset/password-reset.component';
import {OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {AuthGuard} from './services/auth/guards/auth.guard';
import {LoginGuard} from './services/auth/guards/login.guard';
import {RecruitmentPreferencesComponent} from './components/recruitment-preferences/recruitment-preferences.component';
import {CareerPortalSettingsComponent} from './components/career-portal-settings/career-portal-settings.component';
import {UserManagementComponent} from './components/user-management/user-management.component';
import {StepPanelComponent} from './components/recruitment-preferences/step-panel/step-panel.component';
import {RecruitmentPreferenceModalComponent} from './components/recruitment-preferences/recruitment-preference-modal/recruitment-preference-modal.component';
import {NgxSortableModule} from 'ngx-sortable';
import {PasswordResetModalComponent} from './components/profile/password-reset-modal/password-reset-modal.component';
import {UserManagementModalComponent} from './components/user-management/user-management-modal/user-management-modal.component';
import {PositionListComponent} from './components/positions/position-list/position-list.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {PositionOperationsComponent} from './components/positions/position-operations/position-operations.component';
import {GeneralComponent} from './components/positions/position-operations/general/general.component';
import {HiringTeamComponent} from './components/positions/position-operations/hiring-team/hiring-team.component';
import {AddToPositionComponent} from './components/positions/position-operations/hiring-team/add-to-position/add-to-position.component';
import {RemoveTeamMemberComponent} from './components/positions/position-operations/hiring-team/remove-team-member/remove-team-member.component';
import {ParameterService} from './services/parameters/parameter.service';
import {ClickOutsideModule} from 'ng-click-outside';
import {DeletePositionModalComponent} from './components/positions/position-list/delete-position-modal/delete-position-modal.component';
import {PositionStatusModalComponent} from './components/positions/position-list/position-status-modal/position-status-modal.component';
import {PositionDetailsComponent} from './components/positions/position-details/position-details.component';
import {PipelineComponent} from './components/positions/position-details/pipeline/pipeline.component';
import {HiringTeamUsersComponent} from './components/positions/position-list/hiring-team-users/hiring-team-users.component';
import {PermissionService} from './services/permission/permission.service';
import {CandidateListComponent} from './components/candidates/candidate-list/candidate-list.component';
import {AddCandidateComponent} from './components/candidates/add-candidate/add-candidate.component';
import {UserComponent} from './components/user-management/user/user.component';
import {EditCandidateComponent} from './components/candidates/edit-candidate/edit-candidate.component';
import {EducationComponent} from './components/candidates/edit-candidate/education/education.component';
import {ExperienceComponent} from './components/candidates/edit-candidate/experience/experience.component';
import {AttachmentsComponent} from './components/candidates/edit-candidate/attachments/attachments.component';
import {NotesComponent} from './components/candidates/edit-candidate/notes/notes.component';
import {CandidateGeneralComponent} from './components/candidates/edit-candidate/general/general.component';
import {CandidateCalendarComponent} from './components/candidates/edit-candidate/calendar/calendar.component';
import {MultiselectDropdownComponent} from './shared/components/multiselect-dropdown/multiselect-dropdown.component';
import {MultiselectDropdownMComponent} from './shared/components/multiselect-dropdown-m/multiselect-dropdown-m.component';
import {DeleteNoteModalComponent} from './components/candidates/edit-candidate/notes/delete-note-modal/delete-note-modal.component';
import {EditGeneralInfoModalComponent} from './components/candidates/edit-candidate/general/edit-general-info-modal/edit-general-info-modal.component';
import {EditCandidateEducationComponent} from './components/candidates/edit-candidate/education/candidate-education-operations/edit-candidate-education/edit-candidate-education.component';
import {AddCandidateEducationComponent} from './components/candidates/edit-candidate/education/candidate-education-operations/add-candidate-education/add-candidate-education.component';
import {DeleteCandidateEducationComponent} from './components/candidates/edit-candidate/education/candidate-education-operations/delete-candidate-education/delete-candidate-education.component';
import {AddCandidateLanguageComponent} from './components/candidates/edit-candidate/education/candidate-language-operations/add-candidate-language/add-candidate-language.component';
import {EditCandidateLanguageComponent} from './components/candidates/edit-candidate/education/candidate-language-operations/edit-candidate-language/edit-candidate-language.component';
import {DeleteCandidateLanguageComponent} from './components/candidates/edit-candidate/education/candidate-language-operations/delete-candidate-language/delete-candidate-language.component';
import {AddCandidateCertificateComponent} from './components/candidates/edit-candidate/education/candidate-certificate-operations/add-candidate-certificate/add-candidate-certificate.component';
import {EditCandidateCertificateComponent} from './components/candidates/edit-candidate/education/candidate-certificate-operations/edit-candidate-certificate/edit-candidate-certificate.component';
import {DeleteCandidateCertificateComponent} from './components/candidates/edit-candidate/education/candidate-certificate-operations/delete-candidate-certificate/delete-candidate-certificate.component';
import {TagInputComponent, TagInputModule} from 'ngx-chips';
import {TechnicalSkillOperationsComponent} from './components/candidates/edit-candidate/education/candidate-technical-skill-operations/technical-skill-operations/technical-skill-operations.component';
import {AddWorkExperienceComponent} from './components/candidates/edit-candidate/experience/work-experience-operations/add-work-experience/add-work-experience.component';
import {EditWorkExperienceComponent} from './components/candidates/edit-candidate/experience/work-experience-operations/edit-work-experience/edit-work-experience.component';
import {DeleteWorkExperienceComponent} from './components/candidates/edit-candidate/experience/work-experience-operations/delete-work-experience/delete-work-experience.component';
import {AddCandidateProjectComponent} from './components/candidates/edit-candidate/experience/candidate-project-operations/add-candidate-project/add-candidate-project.component';
import {EditCandidateProjectComponent} from './components/candidates/edit-candidate/experience/candidate-project-operations/edit-candidate-project/edit-candidate-project.component';
import {DeleteCandidateProjectComponent} from './components/candidates/edit-candidate/experience/candidate-project-operations/delete-candidate-project/delete-candidate-project.component';
import {CandidateUpdateStatusComponent} from './components/candidates/edit-candidate/candidate-profile-operations/candidate-update-status/candidate-update-status.component';
import {CandidateChangeStepComponent} from './components/candidates/edit-candidate/candidate-profile-operations/candidate-change-step/candidate-change-step.component';
import {CandidateEditProfileComponent} from './components/candidates/edit-candidate/candidate-profile-operations/candidate-edit-profile/candidate-edit-profile.component';
import {ViewNoteModalComponent} from './components/candidates/edit-candidate/notes/view-note-modal/view-note-modal.component';
import {DeleteCandidateComponent} from './components/candidates/delete-candidate/delete-candidate.component';
import {SoftSkillOperationsComponent} from './components/candidates/edit-candidate/education/candidate-soft-skill-operations/soft-skill-operations/soft-skill-operations.component';
import {CandidateUpdateTagsComponent} from './components/candidates/edit-candidate/candidate-profile-operations/candidate-update-tags/candidate-update-tags.component';
import {PoolListComponent} from './components/pools/pool-list/pool-list.component';
import {AddPoolComponent} from './components/pools/pool-operations/add-pool/add-pool.component';
import {EditPoolComponent} from './components/pools/pool-operations/edit-pool/edit-pool.component';
import {DeletePoolComponent} from './components/pools/pool-operations/delete-pool/delete-pool.component';
import {ClientListComponent} from './components/clients/client-list/client-list.component';
import {AddClientComponent} from './components/clients/client-operations/add-client/add-client.component';
import {EditClientComponent} from './components/clients/client-operations/edit-client/edit-client.component';
import {DeleteClientComponent} from './components/clients/client-operations/delete-client/delete-client.component';
import {CandidateChangePositionComponent} from './components/candidates/edit-candidate/candidate-profile-operations/candidate-change-position/candidate-change-position.component';
import {CandidateChangePoolComponent} from './components/candidates/edit-candidate/candidate-profile-operations/candidate-change-pool/candidate-change-pool.component';
import {ChangePoolStatusModalComponent} from './components/pools/pool-operations/change-pool-status-modal/change-pool-status-modal.component';
import {ChangeClientStatusModalComponent} from './components/clients/client-operations/change-client-status-modal/change-client-status-modal.component';
import {RemoveCandidatePoolComponent} from './components/candidates/edit-candidate/candidate-profile-operations/candidate-change-pool/remove-candidate-pool/remove-candidate-pool.component';
import {PositionCandidateComponent} from './components/positions/position-details/position-candidate/position-candidate.component';
import {PoolDetailsComponent} from './components/pools/pool-details/pool-details.component';
import {PoolCandidateComponent} from './components/pools/pool-details/pool-candidate/pool-candidate.component';
import {HistoryListComponent} from './shared/components/history-list/history-list.component';
import {CandidateHistoryComponent} from './components/candidates/edit-candidate/candidate-history/candidate-history.component';
import {PoolHistoryComponent} from './components/pools/pool-details/pool-history/pool-history.component';
import {PipelineUserListComponent} from './components/positions/position-details/pipeline/pipeline-user-list/pipeline-user-list.component';
import {PositionHistoryComponent} from './components/positions/position-details/position-history/position-history.component';
import {CandidateStatusModalComponent} from './components/candidates/candidate-list/candidate-status-modal/candidate-status-modal.component';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {RemoveFromPoolModalComponent} from './components/pools/pool-details/pool-candidate/remove-from-pool-modal/remove-from-pool-modal.component';
import {AddToPoolCandidateModalComponent} from './components/pools/pool-details/pool-candidate/add-to-pool-candidate-modal/add-to-pool-candidate-modal.component';
import {AddToPositionCandidateModalComponent} from './components/positions/position-details/position-candidate/add-to-position-candidate-modal/add-to-position-candidate-modal.component';
import {RemoveFromPositionModalComponent} from './components/positions/position-details/position-candidate/remove-from-position-modal/remove-from-position-modal.component';
import {RemoveCandidatePositionComponent} from './components/candidates/edit-candidate/candidate-profile-operations/candidate-change-position/remove-candidate-position/remove-candidate-position.component';
import {PipelineStepNoteComponent} from './components/positions/position-details/pipeline/pipeline-step-note/pipeline-step-note.component';
import {PipelineChangeCandidateStepComponent} from './components/positions/position-details/pipeline/pipeline-change-candidate-step/pipeline-change-candidate-step.component';
import {CalendarInterviewModalComponent} from './components/calendar/calendar-interview-modal/calendar-interview-modal.component';
import {AddInterviewModalComponent} from './components/calendar/add-interview-modal/add-interview-modal.component';

import {registerLocaleData} from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import localeDe from '@angular/common/locales/de';
import {CalendarInterviewDetailComponent} from './components/calendar/calendar-interview-modal/calendar-interview-detail/calendar-interview-detail.component';
import {DeleteInterviewModalComponent} from './components/calendar/delete-interview-modal/delete-interview-modal.component';
import {EditInterviewModalComponent} from './components/calendar/edit-interview-modal/edit-interview-modal.component';
import {CalendarViewComponent} from './components/calendar/calendar-view/calendar-view.component';
import {PositionCalendarComponent} from './components/positions/position-details/position-calendar/position-calendar.component';
import {OnlistViewComponent} from './components/calendar/onlist-view/onlist-view.component';
import {CalendarInterviewUserListComponent} from './components/calendar/onlist-view/calendar-interview-user-list/calendar-interview-user-list.component';
import {AddContactPersonComponent} from './components/clients/contact-person-operations/add-contact-person/add-contact-person.component';
import {EditContactPersonComponent} from './components/clients/contact-person-operations/edit-contact-person/edit-contact-person.component';
import {DeleteContactPersonComponent} from './components/clients/contact-person-operations/delete-contact-person/delete-contact-person.component';
import {ClientDetailsComponent} from './components/clients/client-details/client-details.component';
import {ClientGeneralComponent} from './components/clients/client-details/client-general/client-general.component';
import {ClientAttachmentsComponent} from './components/clients/client-details/client-attachments/client-attachments.component';
import {ClientNotesComponent} from './components/clients/client-details/client-notes/client-notes.component';
import {EmptyTableInfoComponent} from './shared/components/empty-table-info/empty-table-info.component';
import {RecommendationComponent} from './components/candidates/edit-candidate/recommendation/recommendation.component';
import {AddCandidateRecommendationComponent} from './components/candidates/edit-candidate/recommendation/candidate-recommendation-operations/add-candidate-recommendation/add-candidate-recommendation.component';
import {EditCandidateRecommendationComponent} from './components/candidates/edit-candidate/recommendation/candidate-recommendation-operations/edit-candidate-recommendation/edit-candidate-recommendation.component';
import {DeleteCandidateRecommendationComponent} from './components/candidates/edit-candidate/recommendation/candidate-recommendation-operations/delete-candidate-recommendation/delete-candidate-recommendation.component';
import {RecommendationListComponent} from './components/candidates/edit-candidate/recommendation/recommendation-list/recommendation-list.component';
import {UpcomingEventsComponent} from './components/dashboard/upcoming-events/upcoming-events.component';
import {UpcomingEventListComponent} from './components/dashboard/upcoming-events/upcoming-event-list/upcoming-event-list.component';
import {UpcomingEventListItemComponent} from './components/dashboard/upcoming-events/upcoming-event-list-item/upcoming-event-list-item.component';
import {PositionsDashboardViewComponent} from './components/dashboard/positions-dashboard-view/positions-dashboard-view.component';
import {CandidatesDashboardViewComponent} from './components/dashboard/candidates-dashboard-view/candidates-dashboard-view.component';
import {PositionsDashboardViewListComponent} from './components/dashboard/positions-dashboard-view/positions-dashboard-view-list/positions-dashboard-view-list.component';
import {PositionsDashboardViewListItemComponent} from './components/dashboard/positions-dashboard-view/positions-dashboard-view-list-item/positions-dashboard-view-list-item.component';
import {HiringTeamMemberIconContainerComponent} from './components/dashboard/positions-dashboard-view/hiring-team-member-icon-container/hiring-team-member-icon-container.component';
import {CandidateDashboardViewListComponent} from './components/dashboard/candidates-dashboard-view/candidate-dashboard-view-list/candidate-dashboard-view-list.component';
import {CandidateDashboardViewListItemComponent} from './components/dashboard/candidates-dashboard-view/candidate-dashboard-view-list-item/candidate-dashboard-view-list-item.component';
import {PositionListFilterComponent} from './components/positions/position-list/position-list-filter/position-list-filter.component';
import {CandidateListFilterComponent} from './components/candidates/candidate-list/candidate-list-filter/candidate-list-filter.component';
import {ClientListFilterComponent} from './components/clients/client-list/client-list-filter/client-list-filter.component';
import {PoolListFilterComponent} from './components/pools/pool-list/pool-list-filter/pool-list-filter.component';
import {CalendarFilterComponent} from './components/calendar/calendar-filter/calendar-filter.component';
import {ReportPositionComponent} from './components/reports/report-position/report-position.component';
import {ReportPositionListComponent} from './components/reports/report-position/report-position-list/report-position-list.component';
import {ReportEventComponent} from './components/reports/report-event/report-event.component';
import {ReportEventListComponent} from './components/reports/report-event/report-event-list/report-event-list.component';
import {ReportTimeFillListComponent} from './components/reports/report-timefill/report-time-fill-list/report-time-fill-list.component';
import {ReportTimeFillComponent} from './components/reports/report-timefill/report-time-fill.component';
import {ReportFilterComponent} from './components/reports/report-filter/report-filter.component';
import {SearchComponent} from './components/header/search/search.component';
import {CandidateCvComponent} from './components/candidates/candidate-cv/candidate-cv.component';
import { ImportCandidateComponent } from './components/candidates/import-candidate/import-candidate.component';
import { PhotoImportComponent } from './shared/components/photo-import/photo-import.component';
import { AttachmentImportComponent } from './shared/components/attachments/attachment-import/attachment-import.component';
import { AttachmentListComponent } from './shared/components/attachments/attachment-list/attachment-list.component';
import { AttachmentDeleteModalComponent } from './shared/components/attachments/attachment-delete-modal/attachment-delete-modal.component';
import { AttachmentListItemComponent } from './shared/components/attachments/attachment-list/attachment-list-item/attachment-list-item.component';
import { ClientPositionRequestsComponent } from './components/clients/client-details/client-position-requests/client-position-requests.component';
import { ClientPositionRequestDetailsModalComponent } from './components/clients/client-details/client-position-requests/client-position-request-details-modal/client-position-request-details-modal.component';
import { ClientUsersComponent } from './components/clients/client-details/client-users/client-users.component';
import { AddClientUserComponent } from './components/clients/client-details/client-users/client-user-operations/add-client-user/add-client-user.component';
import { EditClientUserComponent } from './components/clients/client-details/client-users/client-user-operations/edit-client-user/edit-client-user.component';
import { DeleteClientUserComponent } from './components/clients/client-details/client-users/client-user-operations/delete-client-user/delete-client-user.component';
import { CandidateCvModalComponent } from './components/candidates/candidate-cv-modal/candidate-cv-modal.component';
import { CandidateViewAttachmentCvComponent } from './components/candidates/edit-candidate/candidate-view-attachment-cv/candidate-view-attachment-cv.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InterviewRequestsComponent } from './components/calendar/interview-requests/interview-requests.component';
import { InterviewRequestsListComponent } from './components/calendar/interview-requests/interview-requests-list/interview-requests-list.component';
import { InterviewRequestsFilterComponent } from './components/calendar/interview-requests/interview-requests-filter/interview-requests-filter.component';
import { InterviewRequestStatusComponent } from './components/calendar/interview-requests/interview-request-status/interview-request-status.component';
import { ConfirmModalComponent } from './shared/modal/confirm-modal/confirm-modal.component';
import { MessagesComponent } from './components/messages/messages.component';
import { MessageContentAreaComponent } from './components/messages/message-content-area/message-content-area.component';
import { MessageUsersAreaComponent } from './components/messages/message-users-area/message-users-area.component';
import { MessageRowComponent } from './components/messages/message-users-area/message-row/message-row.component';
import { CreateInterviewFromRequestComponent } from './components/calendar/interview-requests/interview-requests-list/create-interview-from-request/create-interview-from-request.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { SearchableDropdownComponent } from './shared/components/searchable-dropdown/searchable-dropdown.component';
import { SearchableDynamicDropdownComponent } from './shared/components/searchable-dynamic-dropdown/searchable-dynamic-dropdown.component';
import { SearchableDropdownWithAddComponent } from './shared/components/searchable-dropdown-with-add/searchable-dropdown-with-add.component';
import { AddNewItemWithSearchComponent } from './shared/components/searchable-dropdown-with-add/add-new-item-with-search/add-new-item-with-search.component';
import { TitlecaseDirective } from './directives/titlecase.directive';
import { TitlecaseLowerDirective } from './directives/UppLowerCase/titlecase-lower.directive';
import { ExportModalCandidateComponent } from './components/reports/report-event/export-modal-candidate/export-modal-candidate.component';
import { ExportModalPositionComponent } from './components/reports/report-position/export-modal-position/export-modal-position.component';
import {ReportPositionFilterComponent} from './components/reports/report-position/report-position-filter/report-position-filter.component';
import { RegisterComponent } from './components/register/register.component';
import { HrManagerRegisterComponent } from './components/register/hr-manager-register/hr-manager-register.component';
import { CompanyRegisterComponent } from './components/register/company-register/company-register.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from  '@angular/material/core';
import { MultiselectDropdownFilterComponent } from './shared/components/multiselect-dropdown-filter/multiselect-dropdown-filter.component';
import { TakeNoteModalComponent } from './components/candidates/take-note-modal/take-note-modal.component';



registerLocaleData(localeTr);
registerLocaleData(localeDe);

@NgModule({
    declarations: [
        AppComponent,
        TranslatePipe,
        LoginLayoutComponent,
        DashboardLayoutComponent,
        SidebarComponent,
        DashboardComponent,
        LoginComponent,
        HeaderComponent,
        CalendarComponent,
        ReportsComponent,
        PositionsComponent,
        CandidatesComponent,
        PoolsComponent,
        ClientsComponent,
        ProfileComponent,
        CompanySettingsComponent,
        PasswordRequestComponent,
        PasswordResetComponent,
        RecruitmentPreferencesComponent,
        CareerPortalSettingsComponent,
        UserManagementComponent,
        StepPanelComponent,
        RecruitmentPreferenceModalComponent,
        PasswordResetModalComponent,
        UserManagementModalComponent,
        PositionListComponent,
        PositionOperationsComponent,
        GeneralComponent,
        HiringTeamComponent,
        AddToPositionComponent,
        RemoveTeamMemberComponent,
        DeletePositionModalComponent,
        PositionStatusModalComponent,
        PositionDetailsComponent,
        PipelineComponent,
        HiringTeamUsersComponent,
        CandidateListComponent,
        AddCandidateComponent,
        UserComponent,
        EditCandidateComponent,
        EducationComponent,
        ExperienceComponent,
        AttachmentsComponent,
        NotesComponent,
        CandidateGeneralComponent,
        CandidateCalendarComponent,
        MultiselectDropdownComponent,
        MultiselectDropdownMComponent,
        DeleteNoteModalComponent,
        EditGeneralInfoModalComponent,
        EditCandidateEducationComponent,
        AddCandidateEducationComponent,
        DeleteCandidateEducationComponent,
        AddCandidateLanguageComponent,
        EditCandidateLanguageComponent,
        DeleteCandidateLanguageComponent,
        AddCandidateCertificateComponent,
        EditCandidateCertificateComponent,
        DeleteCandidateCertificateComponent,
        TechnicalSkillOperationsComponent,
        AddWorkExperienceComponent,
        EditWorkExperienceComponent,
        DeleteWorkExperienceComponent,
        AddCandidateProjectComponent,
        EditCandidateProjectComponent,
        DeleteCandidateProjectComponent,
        CandidateUpdateStatusComponent,
        CandidateChangeStepComponent,
        CandidateEditProfileComponent,
        ViewNoteModalComponent,
        DeleteCandidateComponent,
        SoftSkillOperationsComponent,
        CandidateUpdateTagsComponent,
        PoolListComponent,
        AddPoolComponent,
        EditPoolComponent,
        DeletePoolComponent,
        ClientListComponent,
        AddClientComponent,
        EditClientComponent,
        DeleteClientComponent,
        CandidateChangePositionComponent,
        CandidateChangePoolComponent,
        ChangePoolStatusModalComponent,
        ChangeClientStatusModalComponent,
        RemoveCandidatePoolComponent,
        PositionCandidateComponent,
        PoolDetailsComponent,
        PoolCandidateComponent,
        HistoryListComponent,
        CandidateHistoryComponent,
        PoolHistoryComponent,
        PipelineUserListComponent,
        PositionHistoryComponent,
        CandidateStatusModalComponent,
        RemoveFromPoolModalComponent,
        AddToPoolCandidateModalComponent,
        AddToPositionCandidateModalComponent,
        RemoveFromPositionModalComponent,
        RemoveCandidatePositionComponent,
        PipelineStepNoteComponent,
        PipelineChangeCandidateStepComponent,
        CalendarInterviewModalComponent,
        AddInterviewModalComponent,
        CalendarInterviewDetailComponent,
        DeleteInterviewModalComponent,
        EditInterviewModalComponent,
        CalendarViewComponent,
        PositionCalendarComponent,
        OnlistViewComponent,
        CalendarInterviewUserListComponent,
        AddContactPersonComponent,
        EditContactPersonComponent,
        DeleteContactPersonComponent,
        ClientDetailsComponent,
        ClientGeneralComponent,
        ClientAttachmentsComponent,
        ClientNotesComponent,
        EmptyTableInfoComponent,
        RecommendationComponent,
        AddCandidateRecommendationComponent,
        EditCandidateRecommendationComponent,
        DeleteCandidateRecommendationComponent,
        RecommendationListComponent,
        UpcomingEventsComponent,
        UpcomingEventListComponent,
        UpcomingEventListItemComponent,
        PositionsDashboardViewComponent,
        CandidatesDashboardViewComponent,
        PositionsDashboardViewListComponent,
        PositionsDashboardViewListItemComponent,
        HiringTeamMemberIconContainerComponent,
        CandidateDashboardViewListComponent,
        CandidateDashboardViewListItemComponent,
        PositionListFilterComponent,
        CandidateListFilterComponent,
        ClientListFilterComponent,
        PoolListFilterComponent,
        CalendarFilterComponent,
        ReportPositionComponent,
        ReportTimeFillComponent,
        ReportTimeFillListComponent,
        ReportEventListComponent,
        ReportPositionListComponent,
        ReportEventComponent,
        ReportFilterComponent,
        SearchComponent,
        CandidateCvComponent,
        ImportCandidateComponent,
        PhotoImportComponent,
        AttachmentImportComponent,
        AttachmentListComponent,
        AttachmentDeleteModalComponent,
        AttachmentListItemComponent,
        ClientPositionRequestsComponent,
        ClientPositionRequestDetailsModalComponent,
        ClientUsersComponent,
        AddClientUserComponent,
        EditClientUserComponent,
        DeleteClientUserComponent,
        CandidateCvModalComponent,
        CandidateViewAttachmentCvComponent,
        InterviewRequestsComponent,
        InterviewRequestsListComponent,
        InterviewRequestsFilterComponent,
        InterviewRequestStatusComponent,
        ConfirmModalComponent,
        MessagesComponent,
        MessageContentAreaComponent,
        MessageUsersAreaComponent,
        MessageRowComponent,
        CreateInterviewFromRequestComponent,
        SearchableDropdownComponent,
        SearchableDynamicDropdownComponent,
        SearchableDropdownWithAddComponent,
        AddNewItemWithSearchComponent,
        TitlecaseDirective,
        TitlecaseLowerDirective,
        ExportModalCandidateComponent,
        ExportModalPositionComponent,
        ReportPositionFilterComponent,
        RegisterComponent,
        HrManagerRegisterComponent,
        CompanyRegisterComponent,
        MultiselectDropdownFilterComponent,
        TakeNoteModalComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TooltipModule,
        BrowserAnimationsModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: ['http://api.exactalent-test.com:8080/uaa/api/v1'],
                sendAccessToken: true
            }
        }),
        SidebarModule.forRoot(),
        FormsModule,
        NgxSpinnerModule,
        NgxSortableModule,
        NgxPaginationModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ClickOutsideModule,
        TagInputModule,
        PdfViewerModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
    ],
    providers: [AuthService, ParameterService, NotificationService, TitleCasePipe, TranslatePipe, TranslateService, TRANSLATION_PROVIDERS,
        TagInputModule, TagInputComponent,
        PermissionService, AuthGuard, LoginGuard, {
            provide: OAuthStorage,
            useValue: localStorage
        }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
