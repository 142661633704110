import {Component, Input, OnInit} from '@angular/core';
import {PoolModel} from '../../../../pools/pool.model';
import {server} from '../../../../../services/server';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../services/notification/notification.service';
import {PermissionService} from '../../../../../services/permission/permission.service';

@Component({
    selector: 'app-candidate-change-pool',
    templateUrl: './candidate-change-pool.component.html',
    styleUrls: ['./candidate-change-pool.component.scss']
})
export class CandidateChangePoolComponent implements OnInit {

    @Input() candidateId: string;
    detailsTab: boolean;
    addTab: boolean;
    pool: PoolModel;
    pools: PoolModel[];
    candidatePools: PoolModel[];
    selectedPool: PoolModel;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
        this.detailsTab = false;
        this.addTab = false;
        this.pool = new PoolModel();
    }

    ngOnInit(): void {
        this.setTab('candidatePools');
    }

    selectPool(e) {
        this.pool.id = e.target.value;
    }

    setTab(component) {
        switch (component) {
            case 'candidatePools':
                this.detailsTab = true;
                this.addTab = false;
                this.getCandidatePools();
                break;
            case 'addToPool':
                this.detailsTab = false;
                this.addTab = true;
                this.getPools();
                break;
            default:
                this.detailsTab = true;
                break;
        }
    }

    getPools() {
        this.authService.ServerGet(server.pools)
            .subscribe((res: any) => {
                this.pools = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    closeChangePoolModal() {
        // @ts-ignore
        $('#candidatePoolModal').modal('hide');
    }

    getCandidatePools() {
        this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/pools')
            .subscribe((res: any) => {
                this.candidatePools = res;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    addPool() {
        const obj = {
            candidateId: this.candidateId
        };
        this.authService.ServerPost(server.pools + '/' + this.pool.id + '/members', obj)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.spinner.hide();
            });
    }

    openRemovePoolModal(pool) {
        this.selectedPool = pool;
        // @ts-ignore
        $('#deleteCandidatePool').modal('show');
    }

}
