<!--Delete candidate education Modal -->
<div aria-hidden="true" aria-labelledby="deleteProjectTitle" class="modal fade" id="deleteProject"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title" id="deleteUserLongTitle">
                    {{ "deleteProject" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteProjectName">{{"name" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="project.name" class="form-control border-radius-0" disabled
                                       id="deleteProjectName"
                                       type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="organisation">{{"organisation" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="project.organisation" class="form-control border-radius-0" disabled
                                       id="organisation"
                                       type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="beginningDate">
                                    {{"beginningDate" | translate}}<span
                                        class="text-danger">*</span>
                                </label>
                                <div class="container-fluid">
                                    <div class="row" id="beginningDate">
                                        <input [value]="project.startMonth" class="form-control col border-radius-0"
                                               disabled
                                               id="issueMonth" max="12"
                                               min="1"
                                               name="issueMonth" placeholder="{{'month' | translate}}..."
                                               type="number"/>&nbsp;
                                        <input [value]="project.startYear" class="form-control col border-radius-0"
                                               disabled
                                               id="issueYear" name="issueYear"
                                               placeholder="{{ 'year' | translate }}..."
                                               type="number"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="endingDate">
                                    {{"endingDate" | translate}}
                                </label>
                                <div class="container-fluid">
                                    <div class="row" id="endingDate">
                                        <input [value]="project.endMonth" class="form-control col border-radius-0"
                                               disabled
                                               id="expirationMonth" max="12"
                                               min="1" name="expirationMonth"
                                               placeholder="{{'month' | translate}}..."
                                               type="number"/>&nbsp;
                                        <input [value]="project.endYear" class="form-control col border-radius-0"
                                               disabled
                                               id="expirationYear" name="expirationYear"
                                               placeholder="{{ 'year' | translate }}..."
                                               type="number"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="summary">
                                    {{"summary" | translate}}
                                </label>
                                <div class="container-fluid">
                                    <div class="row" id="summary">
                                        <textarea [value]="project.summary" class="form-control border-radius-0"
                                                  disabled
                                                  rows="4" appTitlecaseLower></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closeDeleteProjectModal()" class="btn btn-warning border-radius-0"
                        data-dismiss="modal"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="saveProject()" class="btn btn-danger border-radius-0" type="button">
                    {{ "delete" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
