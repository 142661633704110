<div class="container-fluid">
    <div class="row">
        <div (click)="setTab('onEvents')" [class]="!reportListViewComponent? ' gradient-bg':'top-line'"
             class="col menu-item">
            <a class="nav-link active">{{'events' | translate}}</a>
        </div>
        <div (click)="setTab('onPosition')" [class]="!reportPositionComponent ? ' gradient-bg':'top-line'"
             class="col menu-item">
            <a class="nav-link active">{{'positionss' | translate}}</a>
        </div>

    </div>
</div>
<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-12">
            <app-report-event *ngIf="reportListViewComponent"></app-report-event>
            <app-report-position *ngIf="reportPositionComponent"></app-report-position>
        </div>
    </div>
</div>
