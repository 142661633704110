export class ExperienceModel {
    id: number;
    title: string;
    company: string;
    employmentType: string;
    startMonth: number;
    startYear: number;
    endMonth: number;
    endYear: number;
    summary: string;

    constructor() {
    }
}

export class ProjectsModel {

    id: number;
    name: string;
    organisation: string;
    startMonth: number;
    startYear: number;
    endMonth: number;
    endYear: number;
    summary: string;
    employment: EmploymentModel;

    constructor() {
        this.employment = new EmploymentModel();
    }
}

export class EmploymentModel {
    id: number;
    title: string;
    company: string;
    employmentType: string;
    startMonth: number;
    startYear: number;
    endMonth: number;
    endYear: number;
    summary: string;
    period: PeriodModel;

    constructor() {
        this.period = new PeriodModel();
    }
}

export class PeriodModel {

    years: number;
    months: number;
}
