<!--Delete candidate certificate Modal -->
<div aria-hidden="true" aria-labelledby="deleteCandidateCertificateTitle" class="modal fade"
     id="deleteCandidateCertificate"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title" id="deleteUserLongTitle">
                    {{ "deleteCandidateCertificate" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteOrganisation">{{"organisation" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="certificate.organisation" class="form-control border-radius-0" disabled
                                       id="deleteOrganisation"
                                       type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="deleteCertificateName">{{"certificateName" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="certificate.name" class="form-control border-radius-0" disabled
                                       id="deleteCertificateName"
                                       type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="issueDate">
                                    {{"issueDate" | translate}}
                                </label>
                                <div class="container-fluid">
                                    <div class="row" id="issueDate">
                                        <input [(ngModel)]="certificate.issueMonth"
                                               class="form-control col border-radius-0"
                                               disabled id="issueMonth" max="12"
                                               min="1"
                                               name="issueMonth" placeholder="{{'month' | translate}}..."
                                               type="number"/>&nbsp;
                                        <input [(ngModel)]="certificate.issueYear"
                                               class="form-control col border-radius-0"
                                               disabled
                                               id="issueYear" name="issueYear"
                                               placeholder="{{ 'year' | translate }}..."
                                               type="number"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label" for="expirationDate">
                                    {{"expirationDate" | translate}}
                                </label>
                                <div class="container-fluid">
                                    <div class="row" id="expirationDate">
                                        <input [value]="certificate.expirationMonth"
                                               class="form-control col border-radius-0"
                                               disabled
                                               id="expirationMonth" max="12"
                                               min="1" name="expirationMonth"
                                               placeholder="{{'month' | translate}}..."
                                               type="number"/>&nbsp;
                                        <input [value]="certificate.expirationYear"
                                               class="form-control col border-radius-0"
                                               disabled
                                               id="expirationYear" name="expirationYear"
                                               placeholder="{{ 'year' | translate }}..."
                                               type="number"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closeDeleteCertificatesModal()" class="btn btn-warning border-radius-0"
                        data-dismiss="modal"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="deleteCandidateCertificate()" class="btn btn-danger border-radius-0" type="button">
                    {{ "delete" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
