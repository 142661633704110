import { CountryModel } from 'src/app/services/parameters/models/country.model';
export class CompanyRegisterModel {
    name: string;
    country: CountryModel;
    websiteLink: string;
    taxOffice: string;
    taxNumber: string;
    address: string;
    phone: string;

    constructor() {
    }
}