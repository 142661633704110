import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {server} from '../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';
import {GeneralModel} from '../position-operations/general/general.model';

@Component({
    selector: 'app-position-details',
    templateUrl: './position-details.component.html',
    styleUrls: ['./position-details.component.scss']
})
export class PositionDetailsComponent implements OnInit {

    pipelineComponentState: boolean;
    candidateComponentState: boolean;
    calenderComponentState: boolean;
    reportsComponentState: boolean;
    historyComponentState: boolean;
    position: GeneralModel;
    id: string;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private spinner: NgxSpinnerService,
                private authService: AuthService,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.position = new GeneralModel();
    }

    ngOnInit(): void {
        // @ts-ignore
        if (this.route.params.value.id && this.route.params.value.id !== '') {
            // @ts-ignore
            this.id = this.route.params.value.id;
            this.getPosition();
            // @ts-ignore
            if (this.route.params.value.type && this.route.params.value.type === 'pipeline') {
                this.setTab('pipeline');
            } else {
                this.setTab('candidate');
            }
        } else {
            this.router.navigate(['/dashboard/positions']);
        }
    }

    setTab(component) {
        switch (component) {
            case 'candidate':
                this.candidateComponentState = true;
                this.pipelineComponentState = false;
                this.calenderComponentState = false;
                this.reportsComponentState = false;
                this.historyComponentState = false;
                break;
            case 'pipeline':
                this.candidateComponentState = false;
                this.pipelineComponentState = true;
                this.calenderComponentState = false;
                this.reportsComponentState = false;
                this.historyComponentState = false;
                break;
            case 'calender':
                this.candidateComponentState = false;
                this.pipelineComponentState = false;
                this.calenderComponentState = true;
                this.reportsComponentState = false;
                this.historyComponentState = false;
                break;
            case 'reports':
                this.candidateComponentState = false;
                this.pipelineComponentState = false;
                this.calenderComponentState = false;
                this.reportsComponentState = true;
                this.historyComponentState = false;
                break;
            case 'history':
                this.candidateComponentState = false;
                this.pipelineComponentState = false;
                this.calenderComponentState = false;
                this.reportsComponentState = false;
                this.historyComponentState = true;
                break;
            default:
                this.candidateComponentState = true;
                break;
        }

    }

    getPosition() {
        this.authService.ServerGet(server.positions + '/' + this.id)
            .subscribe((res: GeneralModel) => {
                this.position = res;
                this.position.id = this.id;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    openPositionStatusModal() {
        // @ts-ignore
        $('#positionStatusModal').modal('show');
    }

    openPositionClient(clientId) {
        this.router.navigate(['/dashboard/client-details', {id: clientId}]);
    }
}
