<!--User Modal -->
<div aria-hidden="true" aria-labelledby="userModalTitle" class="modal fade" id="userModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="addUserLongTitle">{{'addClientUser' | translate}}</h5>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <label class="modal-input-label" for="name">{{'name' | translate}}<span
                            class="text-danger">*</span></label>
                    <input [(ngModel)]="clientUser.firstName" [value]="valueOne"
                           class="form-control border-radius-0"
                           id="name"
                           name="name"
                           required="required" type="text" appTitleCaseDirective>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="middleName">{{'middleName' | translate}}</label>
                    <input [(ngModel)]="clientUser.middleName" [value]="valueTwo"
                           class="form-control border-radius-0"
                           id="middleName"
                           name="middleName" type="text" appTitleCaseDirective>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="surname">{{'surname' | translate}}<span
                            class="text-danger">*</span></label>
                    <input [(ngModel)]="clientUser.lastName" [value]="valueThree"
                           class="form-control border-radius-0"
                           id="surname"
                           name="surname"
                           required="required" type="text" appTitleCaseDirective>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="email">{{'email' | translate}}<span
                            class="text-danger">*</span></label>
                    <input [(ngModel)]="clientUser.email" [value]="valueFour"
                           class="form-control border-radius-0"
                           id="email"
                           name="email"
                           required="required" type="email">
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="userType">{{'userType' | translate}}<span
                            class="text-danger">*</span></label>
                    <div class="dropdown" id="userType">
                        <button aria-expanded="false" [value]="valueFive"
                                aria-haspopup="true"
                                class="btn dropdown-toggle border-radius-0 form-control dropdown-button"
                                data-toggle="dropdown"
                                type="button">
                            {{clientUser.role | translate}}
                        </button>
                        <div (click)="getSelectedRole($event)" aria-labelledby="dropdownMenuButton"
                             class="dropdown-menu width-100 color-dropdown border-radius-0">
                            <a *ngFor="let role of clientUserRoles" [id]="role.value"
                               class="dropdown-item color-dropdown-item">{{ role.name| translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">{{'cancel' | translate}}</button>
                <button (click)="addClientUser()" class="btn btn-success border-radius-0"
                        type="button">{{'add' | translate}}</button>
            </div>
        </div>
    </div>
</div>
