import {Component, OnInit} from '@angular/core';
import {GeneralModel} from './position-operations/general/general.model';
import {PermissionService} from '../../services/permission/permission.service';
import {PositionStatusList} from '../../shared/lists/position-status.list';
import {AuthService} from '../../services/auth/auth.service';
import {NotificationService} from '../../services/notification/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {CountryModel} from '../../services/parameters/models/country.model';
import {CityModel} from '../../services/parameters/models/city.model';
import {PositionFilterModel} from './position.model';
import {JobTypeList} from '../../shared/lists/job-type.list';
import {ParameterService} from '../../services/parameters/parameter.service';
import {ClientModel} from '../clients/client.model';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../user-management/user.model';

@Component({
    selector: 'app-positions',
    templateUrl: './positions.component.html',
    styleUrls: ['./positions.component.scss']
})
export class PositionsComponent implements OnInit {

    allPositionsComponentState: boolean;
    myPositionsComponentState: boolean;
    addPosition: boolean;
    positionDetail: GeneralModel;
    operationType: string;
    tabState: string;
    countries: CountryModel[];
    cities: CityModel[] = [];
    clients: ClientModel[] = [];
    companyUsers: UserModel[] = [];

    positionStatusList = PositionStatusList;
    positionTypes = JobTypeList;

    positionFilter: PositionFilterModel;
    filterData: PositionFilterModel;

    constructor(public permissionService: PermissionService, private authService: AuthService,
                private spinner: NgxSpinnerService, private parameterService: ParameterService, private route: ActivatedRoute,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.addPosition = false;
        this.positionDetail= new GeneralModel();
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (sessionStorage.getItem('selectedPosition') && this.route.params.value.operationType && (this.route.params.value.operationType === 'edit' || this.route.params.value.operationType === 'clone')) {
            const selectedPosition = JSON.parse(sessionStorage.getItem('selectedPosition'));
            // @ts-ignore
            const operationType = this.route.params.value.operationType;
            if (operationType === 'clone') {
                selectedPosition.status = null;
                selectedPosition.disabilityOption = false;
            }
            this.openPosition({position: selectedPosition, operationType});
        } else {
            if (this.permissionService.permissionsOnly(['READ_ALL_POSITIONS'])) {
                this.setTab('allPositions');
            } else {
                this.setTab('myPositions');
            }
        }
    }

    setTab(component) {
        this.filterData = new PositionFilterModel();
        switch (component) {
            case 'allPositions':
                this.allPositionsComponentState = true;
                this.myPositionsComponentState = false;
                this.tabState = 'allPositions';
                break;
            case 'myPositions':
                this.allPositionsComponentState = false;
                this.myPositionsComponentState = true;
                this.tabState = 'myPositions';
                break;
            default:
                this.allPositionsComponentState = true;
                break;
        }

    }

    addPositionModal() {
        this.addPosition = true;
        this.operationType = 'add';
    }

    openPosition(positionDetail) {
        this.positionDetail = positionDetail.position;
        this.operationType = positionDetail.operationType;
        this.addPosition = true;
    }

    cancelOperation() {
        this.addPosition = false;
        sessionStorage.removeItem('selectedPosition');
        if (this.permissionService.permissionsOnly(['READ_ALL_POSITIONS'])) {
            this.setTab('allPositions');
        } else {
            this.setTab('myPositions');
        }
    }


    applyFilter(filter) {
        this.positionFilter = filter;
        if (JSON.stringify(this.filterData) === JSON.stringify(this.positionFilter)) {
            return false;
        }
        this.filterData = new PositionFilterModel();
        this.filterData = {...this.positionFilter};
    }
}
