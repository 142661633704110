<div class="row mt-4">
    <div class="col-auto">
        <app-calendar-filter (applyFilterOperation)="applyFilter($event)"></app-calendar-filter>
    </div>
</div>
<div class="container-fluid mt-3">
    <div class="row table-bg" style="border-top: 12px #f6f6f6">
        <div (click)="openPipelineUserList(step,$event)" *ngFor="let step of pipelineSteps"
             class="cursor-pointer table-row col-12">
            <div>
                <span class="badge badge-light float-left rounded-circle step-badge p-1">{{step.interviewCount}}</span>
                <span class="float-left step-name">{{step.recruitmentStepName}}</span>
                <i class="fa fa-caret-down float-right"></i>
            </div>
            <div [id]="'stepId_' + step.recruitmentStepId" class="step-table-div">
                <app-calendar-interview-user-list (updateCandidates)="getStepUsers($event)"
                                                  [count]="count"
                                                  [interviews]="interviews"></app-calendar-interview-user-list>
            </div>
        </div>
    </div>
</div>
