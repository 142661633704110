import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EducationDegreeList} from '../../../../../../shared/lists/education-degree.list';
import {DepartmentModel, EducationModel, FacultyModel, UniversityModel} from '../../../../../../services/parameters/models/education.model';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../../services/permission/permission.service';
import {NotificationService} from '../../../../../../services/notification/notification.service';
import {server} from '../../../../../../services/server';
import { NgForm } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
    selector: 'app-edit-candidate-education',
    templateUrl: './edit-candidate-education.component.html',
    styleUrls: ['./edit-candidate-education.component.scss']
})
export class EditCandidateEducationComponent implements OnInit {

    educationDegreeList = EducationDegreeList;
    @Input() education: EducationModel;
    universities: UniversityModel[];
    faculties: FacultyModel[];
    departments: DepartmentModel[];
    @Input() candidateId: string;
    @Output() updateEducations = new EventEmitter();
    isSaveClick = false;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService,
                private utilitiesService: UtilitiesService) {
    }

    ngOnInit(): void {
        this.education = new EducationModel();
        this.getUniversities();
        this.getFaculties();
        this.getDepartments();
    }

    selectEducationDegree(e) {
        if (e.target.value === '') {
            this.education.degree = null;
        } else {
            this.education.degree = e.target.value;
        }
    }

    selectUniversity(university) {
        if (university.id === '') {
            this.education.university.id = null;
        } else {
            this.education.university.id = university.id;
        }
    }

    selectFaculty(faculty) {
        if (faculty.id === '') {
            this.education.faculty.id = null;
        } else {
            this.education.faculty.id = faculty.id;
        }
    }

    selectDepartment(department) {
        if (department.id === '') {
            this.education.department.id = null;
        } else {
            this.education.department.id = department.id;
        }
    }

    getUniversities() {
        this.parameterService.universities.subscribe((res: UniversityModel[]) => {
            this.universities = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getFaculties() {
        this.parameterService.faculties.subscribe((res: FacultyModel[]) => {
            this.faculties = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getDepartments() {
        this.parameterService.departments.subscribe((res: DepartmentModel[]) => {
            this.departments = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    saveCandidateEducation(editCandidateEducationForm: NgForm) {
        this.isSaveClick = true;
        if (!editCandidateEducationForm.valid) {
            this.utilitiesService.validationClassState('validate-me', 'add');
            this.notification.warning(this.translate.transform('fillRequiredFields', []));
            return;
          }
        this.authService.ServerPut(server.candidates + '/' + this.candidateId + '/educations/' + this.education.id, this.education)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updateEducations.emit();
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.closeEditCandidateEducationModal();
                this.spinner.hide();
                this.isSaveClick = false;
            });
    }

    closeEditCandidateEducationModal() {
        // @ts-ignore
        $('#editCandidateEducation').modal('hide');
    }
}
