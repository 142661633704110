import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-report-time-fill',
    templateUrl: './report-time-fill.component.html',
    styleUrls: ['./report-time-fill.component.scss']
})

export class ReportTimeFillComponent implements OnInit {


    constructor() {
    }

    ngOnInit() {
    }
}


