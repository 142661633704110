import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CountryModel} from '../../../../services/parameters/models/country.model';
import {CityModel} from '../../../../services/parameters/models/city.model';
import {AuthService} from '../../../../services/auth/auth.service';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';
import {ClientModel} from '../../client.model';
import {IndustryModel} from '../../../../services/parameters/models/industry.model';
import {ServiceList} from '../../../../shared/lists/service.list';
import {ClientGroupList} from '../../../../shared/lists/client-group.list';
import { ReturnStatement } from '@angular/compiler';
import { NgForm } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';

@Component({
    selector: 'app-add-client',
    templateUrl: './add-client.component.html',
    styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

    @Output() setClientScreen = new EventEmitter();


    categories: any;
    client: ClientModel;
    clients: any;
    countries: CountryModel[];
    cities: CityModel[];
    industries: IndustryModel[];
    serviceList = ServiceList;
    clientGroupList = ClientGroupList;
    accountManagers: any[] = [];
    clientId: string;
    isSaveClick = false;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService,
                private utilitiesService: UtilitiesService) {
        this.client = new ClientModel();
    }

    ngOnInit(): void {
        this.getCountries();
        this.getIndustries();
        this.getAccountManagers();
    }

    selectIndustry(industry): void {
        if (!industry) { this.client.industry.id = null; }
        this.client.industry.id = industry.id;
    }

    selectService(e): void {
        this.client.service = e.target.value;
    }

    selectClientGroup(e): void {
        this.client.clientGroup = e.target.value;
    }

    selectCountry(country) {
        this.client.country = new CountryModel();
        if (country.id === '') {
            this.client.country.id = null;
            this.cities = [];
        } else {
            this.client.country.id = country.id;
            this.getCountryCities(this.client.country.id);
        }
    }

    selectCity(city) {
        this.client.city = new CityModel();
        if (city.id === '') {
            this.client.city.id = null;
        } else {
            this.client.city.id = city.id;
        }
    }

    selectAccountManager(users) {
        users.length < 1 ? this.client.accountManagers = null : this.client.accountManagers = users;
    }

    getCountries() {
        this.parameterService.countries.subscribe((res: CountryModel[]) => {
            this.countries = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCountryCities(countryId) {
        this.cities = [];
        this.parameterService.getCountryCities(countryId).subscribe((res: CityModel[]) => {
            this.cities = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getIndustries() {
        this.parameterService.industries.subscribe((res: IndustryModel[]) => {
            this.industries = res;
        }, err => {
            if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getAccountManagers() {
        const companyId = this.authService.companyId;
        this.authService.ServerGet(server.users + '/' + '?filter=companyId::' +companyId)
            .subscribe((res: { content: [] }) => {
                this.accountManagers = res.content;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    numericOnly(event): boolean {    
        let patt = /^([0-9])$/;
        let result = patt.test(event.key);
        return result;
    }

    cancel() {
        this.isSaveClick = false;
        this.utilitiesService.validationClassState('validate-me', 'remove');
        // @ts-ignore
        $('#addClient').modal('hide');
    }

    save(formClient: NgForm) {
        console.log(formClient);
        this.isSaveClick = true;
        this.utilitiesService.validationClassState('validate-me', 'add');

        if (!formClient.valid || !this. checkValidationComponentField()) {

            this.notification.warning(this.translate.transform('fillRequiredFields', []));
            return;
          }

        console.log(this.client);
        this.authService.ServerPost(server.clients, this.client)
            .subscribe((res: any) => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updateClients(res.id);
                this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['dashboard/clients']);
                });
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.cancel();
                this.spinner.hide();
                this.utilitiesService.validationClassState('validate-me', 'remove');
            });
    }

    updateClients(clientId) {
        this.setClientScreen.emit(clientId);
    }

    checkValidationComponentField(){
        if ( this.client.accountManagers ){
            return true;
        }
        return false;
    }

}
