<div class="container-fluid">
    <div class="row">
        <div (click)="setTab('myPositions')" *ngIf="permissionService.permissionsOnly(['READ_MY_POSITIONS'])"
             [ngClass]="{'gradient-bg':!myPositionsComponentState,
                         'top-line':myPositionsComponentState,
                         'col-12':!permissionService.permissionsOnly(['READ_ALL_POSITIONS']),
                         'col-xl-6 col-lg-6 col-md-6 col-sm-12':permissionService.permissionsOnly(['READ_ALL_POSITIONS'])}"
             class="menu-item">
            <a class="nav-link">{{'myPositions' | translate}}</a>
        </div>
        <div (click)="setTab('allPositions')" *ngIf="permissionService.permissionsOnly(['READ_ALL_POSITIONS'])"
             [ngClass]="{'gradient-bg':!allPositionsComponentState,
                         'top-line':allPositionsComponentState,
                         'col-12':!permissionService.permissionsOnly(['READ_MY_POSITIONS']),
                         'col-xl-6 col-lg-6 col-md-6 col-sm-12 ':permissionService.permissionsOnly(['READ_MY_POSITIONS'])}"
             class="menu-item">
            <a class="nav-link active">{{'allPositions' | translate}}</a>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <app-positions-dashboard-view-list [tabState]="tabState" (getPositionCandidatesOperation)="emitPositionCandidateOperation($event)"></app-positions-dashboard-view-list>
        </div>
    </div>
</div>
