import {Component, Input, OnInit} from '@angular/core';
import {RequestPayload} from '../../../../shared/requestPayload';
import {HistoryModel} from '../../../../shared/components/history-list/history.model';
import {PermissionService} from '../../../../services/permission/permission.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';

@Component({
    selector: 'app-candidate-history',
    templateUrl: './candidate-history.component.html',
    styleUrls: ['./candidate-history.component.scss']
})
export class CandidateHistoryComponent implements OnInit {

    @Input() candidateId: string;
    requestPayload: RequestPayload;
    historyLogs: any;
    count: number;
    activePage: any;
    currentPage: number;

    constructor(public permissionService: PermissionService, private authService: AuthService, private spinner: NgxSpinnerService,
                private notification: NotificationService) {
        this.requestPayload = new RequestPayload();
    }

    ngOnInit(): void {
        this.getHistoryLogs(1);
    }

    pagination(pageNo) {
        this.getHistoryLogs(pageNo);
    }

    getHistoryLogs(pageNo) {
        this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/pipeline-steps' + this.requestPayload.payloadURL(pageNo) + '&onlyCurrentSteps=false')
            .subscribe((res: { content: HistoryModel[], totalElements: number }) => {
                this.historyLogs = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

}
