<div aria-hidden="true" aria-labelledby="candidateStatusModalCenterTitle" class="modal fade" id="confirmModal"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">{{confirmData?.title | translate}}</h5>
            </div>
            <div class="modal-body">
                <div class="mb-3 alert alert-warning" [innerHTML]='confirmData?.message'>
                    
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning border-radius-0" data-dismiss="modal"
                            type="button">{{'cancel' | translate}}</button>
                    <button (click)="operationClick()" class="btn btn-success border-radius-0"
                            type="button">{{'yes' | translate}}</button>
            </div>

        </div>


    </div>


</div>