import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardLayoutComponent} from './shared/layouts/dashboard-layout/dashboard-layout.component';
import {LoginLayoutComponent} from './shared/layouts/login-layout/login-layout.component';
import {LoginComponent} from './components/login/login.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {CalendarComponent} from './components/calendar/calendar.component';
import {ReportsComponent} from './components/reports/reports.component';
import {PositionsComponent} from './components/positions/positions.component';
import {CandidatesComponent} from './components/candidates/candidates.component';
import {PoolsComponent} from './components/pools/pools.component';
import {ClientsComponent} from './components/clients/clients.component';
import {ProfileComponent} from './components/profile/profile.component';
import {CompanySettingsComponent} from './components/company-settings/company-settings.component';
import {PasswordRequestComponent} from './components/password-request/password-request.component';
import {PasswordResetComponent} from './components/password-reset/password-reset.component';
import {AuthGuard} from './services/auth/guards/auth.guard';
import {LoginGuard} from './services/auth/guards/login.guard';
import {PositionDetailsComponent} from './components/positions/position-details/position-details.component';
import {PositionGuard} from './services/auth/guards/position.guard';
import {ClientGuard} from './services/auth/guards/client.guard';
import {EditCandidateComponent} from './components/candidates/edit-candidate/edit-candidate.component';
import {PoolDetailsComponent} from './components/pools/pool-details/pool-details.component';
import {ClientDetailsComponent} from './components/clients/client-details/client-details.component';
import {CalendarGuard} from './services/auth/guards/calendar.guard';
import {ReportGuard} from './services/auth/guards/report.guard';
import {CandidateGuard} from './services/auth/guards/candidate.guard';
import {PoolGuard} from './services/auth/guards/pool.guard';
import {MessagesComponent} from './components/messages/messages.component';
import { RegisterComponent } from './components/register/register.component';

const routes: Routes = [{
    path: '', component: LoginLayoutComponent, canActivate: [LoginGuard],
    children: [
        {
            path: '', component: LoginComponent
        },
        {
            path: 'login', component: LoginComponent
        },
        {
            path: 'password-request', component: PasswordRequestComponent
        },
        {
            path: 'password-reset', component: PasswordResetComponent
        },
        {
            path: 'register', component: RegisterComponent
        },
    ]
},
    {
        path: 'dashboard', component: DashboardLayoutComponent, canActivate: [AuthGuard],
        children: [
            {
                path: '', component: DashboardComponent
            },
            {
                path: 'calendar', component: CalendarComponent, canActivate: [CalendarGuard]
            },
            {
                path: 'reports', component: ReportsComponent, canActivate: [ReportGuard]
            },
            {
                path: 'positions', component: PositionsComponent, canActivate: [PositionGuard]
            },
            {
                path: 'candidates', component: CandidatesComponent, canActivate: [CandidateGuard]
            },
            {
                path: 'pools', component: PoolsComponent, canActivate: [PoolGuard]
            },
            {
                path: 'clients', component: ClientsComponent, canActivate: [ClientGuard]
            },
            {
                path: 'profile', component: ProfileComponent
            },
            {
                path: 'company-settings', component: CompanySettingsComponent
            },
            {
                path: 'position-details', component: PositionDetailsComponent, canActivate: [PositionGuard]
            },
            {
                path: 'edit-candidate', component: EditCandidateComponent, canActivate: [CandidateGuard]
            },
            {
                path: 'pool-details', component: PoolDetailsComponent, canActivate: [PoolGuard]
            },
            {
                path: 'client-details', component: ClientDetailsComponent, canActivate: [ClientGuard]
            },
            {
                path: 'messages', component: MessagesComponent
            },
        ]
    },
    {
        path: '**', redirectTo: '/'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
