<!--User Modal -->
<div aria-hidden="true" aria-labelledby="deletePositionTitle" class="modal fade" id="deletePosition"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title" id="addUserLongTitle">{{'deletePosition' | translate}}</h5>
            </div>
            <div class="modal-body">
                    <div class="mb-3">
                        <label class="modal-input-label" for="name">{{'name' | translate}}</label>
                        <input [(ngModel)]="position.name"
                               class="form-control border-radius-0"
                               disabled
                               id="name"
                               name="name"
                               required="required" type="text">
                    </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">{{'cancel' | translate}}</button>
                <button (click)="deleteOperation()" class="btn btn-danger border-radius-0"
                        type="button">{{'delete' | translate}}</button>
            </div>
        </div>
    </div>
</div>
