import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { StepModel } from 'src/app/components/candidates/edit-candidate/candidate-profile-operations/candidate-change-step/step.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { PipelineStepModel } from 'src/app/services/parameters/models/pipeline-step.model';
import { ParameterService } from 'src/app/services/parameters/parameter.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { server } from 'src/app/services/server';
import { TranslatePipe } from 'src/app/services/translate';

@Component({
  selector: 'app-create-interview-from-request',
  templateUrl: './create-interview-from-request.component.html',
  styleUrls: ['./create-interview-from-request.component.scss']
})
export class CreateInterviewFromRequestComponent implements OnInit , OnChanges{

  @Input()selectedInterviewRequest;
  @Output()operationInit = new EventEmitter();
  tempInterviewRequest: any;
  pipelineStep: PipelineStepModel;
  tempStep;
  steps: StepModel[];
  requestObject: any;


  constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
              private translate: TranslatePipe,
              private router: Router,
              public permissionService: PermissionService,
              private notification: NotificationService) { }

  ngOnInit(): void {
    this.pipelineStep = new PipelineStepModel();
    this.getSteps();
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.selectedInterviewRequest;
    this.tempInterviewRequest = currentItem.currentValue;
  }


  getSteps() {
    this.authService.ServerGet(server.pipelineSteps + '?positionId=')
        .subscribe((res: any) => {
            this.steps = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
}


  selectStep(e){
    this.tempStep = e.target.value;
    this.pipelineStep.recruitmentStepId = this.tempStep;
  }


  async createInterview(){
    this.requestObject = { };
    if (!this.pipelineStep.recruitmentStepId) {return; }

    this.requestObject = {
      candidateId: this.tempInterviewRequest.candidate.id,
      positionId: this.tempInterviewRequest.position.id,
      clientHrPermitted: this.pipelineStep.clientHrPermitted,
      clientTechnicalPermitted: this.pipelineStep.clientTechnicalPermitted,
      hideSensitiveDataForClient: this.pipelineStep.hideSensitiveDataForClient
  };

    try{
      await this.addtoPosition();
      const text = this.translate.transform('addtoPositionInfo', []);
      this.notification.success(this.tempInterviewRequest.candidate.name + ' ' + text );
      this.spinner.hide();
      this.operationInit.emit();
      this.requestObject.recruitmentStepId = this.pipelineStep.recruitmentStepId,
      await this.changeStep();
      this.notification.success(this.translate.transform('stepSetted', []));
    }catch (err){
      if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
          this.notification.warning(err.message);
         }
      this.spinner.hide();
    }
    this.getSteps();
    this.closeThisModal();
    this.openScheduleInterviewModal();
    this.spinner.hide();

  }

  addtoPosition(){
   return this.authService.ServerPost(server.pipelineStepCandidates, this.requestObject)
    .pipe(take(1)).toPromise();

  }

  changeStep(){
    return this.authService.ServerPut(server.pipelineStepCandidates, this.requestObject).pipe(take(1)).toPromise();
  }

  openScheduleInterviewModal() {
    // @ts-ignore
    $('#addInterviewModal').modal('show');
  }


  closeThisModal(){
     // @ts-ignore
     $('#createInterviewFromRequest').modal('hide');
  }

}
