export class ConversationModel {
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    id: string;
    status: string;
    position: {
        id: string;
        name: string;
    };
    participants: [
        {
            id: string;
            name: string;
            profilePhotoUrl: string;
        }
    ];
    unread: boolean;
    lastMessage: {
        createdAt: string;
        createdBy: string;
        content: string;
        id: number;
        updatedAt: string;
        updatedBy: string;
        deleted: boolean;
    };
}
