<!-- Modal -->
<div aria-hidden="true" aria-labelledby="poolModalCenterTitle" class="modal fade"
     id="addToPoolModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="statusModalLongTitle">{{'addToPool' | translate}}</h5>
            </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="poolDrp">{{'candidate' | translate}}</label>
                                    <select (change)="selectCandidate($event)" class="custom-select border-radius-0"
                                            id="poolDrp">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let candidate of candidates"
                                                [value]="candidate.id">{{candidate.fullName}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="closeChangePoolModal()" class="btn btn-warning border-radius-0"
                            data-dismiss="modal"
                            type="button">{{'cancel' | translate}}</button>
                    <button (click)="addPool()" class="btn btn-success border-radius-0"
                            type="button">{{'save' | translate}}</button>
                </div>
        </div>
    </div>
</div>
