import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {UploadService} from '../../../services/upload/upload.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {server} from '../../../services/server';

@Component({
    selector: 'app-import-candidate',
    templateUrl: './import-candidate.component.html',
    styleUrls: ['./import-candidate.component.scss']
})
export class ImportCandidateComponent implements OnInit {

    @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;
    files: any[] = [];
    selectedFileName: string;

    @Output() emitCancelImportCandidate = new EventEmitter();

    constructor(private uploadService: UploadService, private spinner: NgxSpinnerService) {
    }

    ngOnInit(): void {
    }

    uploadFile(file) {
        this.spinner.show();
        const formData = new FormData();
        formData.append('file', file.data);
        file.inProgress = true;
        const data = {
            file: formData,
            url: server.importCandidate
        };
        this.uploadService.importCv(data).pipe(
            map(event => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        file.progress = Math.round(event.loaded * 100 / event.total);
                        break;
                    case HttpEventType.Response:
                        return event;
                }
            }),
            catchError((error: HttpErrorResponse) => {
                file.inProgress = false;
                this.spinner.hide();
                return of(`${file.name} upload failed.`);
            })).subscribe((event: any) => {
            if (typeof (event) === 'object') {
                this.spinner.hide();
            }
        });
    }

    onClick() {
        const fileUpload = this.fileUpload.nativeElement;
        fileUpload.onchange = () => {
            const file = fileUpload.files[0];
            this.selectedFileName = file.name;
            this.files.push({data: file, inProgress: false, progress: 0});
        };
        fileUpload.click();
    }

    cancelImportCandidate() {
        this.files = [];
        this.selectedFileName = '';
        this.emitCancelImportCandidate.emit();
    }

    importCandidate() {
        this.fileUpload.nativeElement.value = '';
        this.uploadFile(this.files[0]);
    }

}
