import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserModel} from '../../user-management/user.model';
import {PermissionService} from '../../../services/permission/permission.service';
import {AuthService} from '../../../services/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ParameterService} from '../../../services/parameters/parameter.service';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../../services/notification/notification.service';
import {CalendarFilterModel} from '../../../services/parameters/models/interview.model';
import {server} from '../../../services/server';
import {PositionModel} from '../../positions/position.model';
import {RecruitmentPreferencesModel} from '../../recruitment-preferences/recruitment-preferences.model';
import {CandidateModel} from '../../candidates/candidate.model';
import {RequestPayload} from 'src/app/shared/requestPayload';

@Component({
    selector: 'app-calendar-filter',
    templateUrl: './calendar-filter.component.html',
    styleUrls: ['./calendar-filter.component.scss']
})
export class CalendarFilterComponent implements OnInit {

    companyUsers: UserModel[] = [];
    requestPayload: RequestPayload;
    positions: PositionModel[] = [];
    pipelineSteps: RecruitmentPreferencesModel[] = [];
    candidates: CandidateModel[] = [];
    filterState: boolean;
    apiUrlForGetCandidate;

    calendarFilter: CalendarFilterModel;
    filterData: CalendarFilterModel;
    @Output() applyFilterOperation = new EventEmitter();

    constructor(public permissionService: PermissionService, private authService: AuthService,
                private spinner: NgxSpinnerService, private parameterService: ParameterService, private route: ActivatedRoute,
                private notification: NotificationService) {
        this.calendarFilter = new CalendarFilterModel();
    }

    ngOnInit(): void {
        this.requestPayload = new RequestPayload();
        this.requestPayload.limit=10;
        this.apiUrlForGetCandidate = `${server.candidates}?page=0&size=50&filter=status!:DELETED&filter=name~~`;
    }

    closeFilterPopup(e) {
        if (e.target.id !== 'filterButton') {
            this.filterState = false;
        }
    }

    applyFilter() {
        if (JSON.stringify(this.filterData) === JSON.stringify(this.calendarFilter)) {
            return false;
        }
        this.filterData = new CalendarFilterModel();
        this.filterData = {...this.calendarFilter};
        this.filterState = false;
        this.applyFilterOperation.emit(this.filterData);
    }

    getCompanyUsers(searchKey: string) {
       
        this.authService.ServerGet(server.users + this.requestPayload.payloadURL(1) + '&filter=fullName~~' + searchKey)
        .subscribe((res: { content: [] }) => {
            this.companyUsers = res.content;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }



    getRecruitmentPreferences(searchKey: string) {
        const companyId = this.authService.companyId;
        this.authService.ServerGet(server.companies + '/' + companyId + '/recruitment-steps'+ this.requestPayload.payloadURL(1) + '&filter=name~~' + searchKey)
            .subscribe((res: { content: [] }) => {
                this.pipelineSteps = res.content;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    getPositions(searchKey: string) {
        this.authService.ServerGet(server.positions + this.requestPayload.payloadURL(1) + '&filter=name~~' + searchKey)
            .subscribe((res: { content: PositionModel[] }) => {
                this.positions = res.content;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }
    getCandidates(searchKey: string) {

        this.authService.ServerGet(server.candidates + this.requestPayload.payloadURL(1) + '&filter=freeText~~' + searchKey)
        .subscribe((res: any) => {
            this.candidates = res.content;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });



        // this.authService.ServerGet(server.candidates + '/?filter=status!:DELETED')
        //     .subscribe((res: { content: CandidateModel[] }) => {
        //         this.candidates = res.content;
        //     }, err => {
        //         if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
        //             this.notification.error(err.message);
        //             this.spinner.hide();
        //         }
        //     }, () => {
        //         this.spinner.hide();
        //     });
    }

    selectPosition(selectedPositionList) {
        this.calendarFilter.position = '';
        for (const selectedPosition of selectedPositionList) {
            if (this.calendarFilter.position === '') {
                this.calendarFilter.position = selectedPosition.id;
            } else {
                this.calendarFilter.position = this.calendarFilter.position + ',' + selectedPosition.id;
            }
        }
    }

    selectPipelineStep(selectedPipelineStepList) {
        this.calendarFilter.recruitmentStep = '';
        for (const selectedPipelineStep of selectedPipelineStepList) {
            if (this.calendarFilter.recruitmentStep === '') {
                this.calendarFilter.recruitmentStep = selectedPipelineStep.id;
            } else {
                this.calendarFilter.recruitmentStep = this.calendarFilter.recruitmentStep + ',' + selectedPipelineStep.id;
            }
        }
    }

    selectCandidate(selectedCandidateList) {
        // console.log(selectedCandidate);
        // this.calendarFilter.candidate = '';
        // if (selectedCandidate) { this.calendarFilter.candidate = selectedCandidate.id; }
        this.calendarFilter.candidate = '';
        for (const selectedPipelineStep of selectedCandidateList) {
            if (this.calendarFilter.candidate === '') {
                this.calendarFilter.candidate = selectedPipelineStep.id;
            } else {
                this.calendarFilter.candidate = this.calendarFilter.candidate + ',' + selectedPipelineStep.id;
            }
        }
    }

    selectCompanyUser(selectedUserList) {
        this.calendarFilter.attendees = '';
        for (const selectedUser of selectedUserList) {
            if (this.calendarFilter.attendees === '') {
                this.calendarFilter.attendees = selectedUser.id;
            } else {
                this.calendarFilter.attendees = this.calendarFilter.attendees + ',' + selectedUser.id;
            }
        }
    }
}
