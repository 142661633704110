<div class="dropdown"> 
    <button (click)="filterState = !filterState"
            class="btn btn-secondary dropdown-toggle border-radius-0 form-control"
            id="filterButton"
            type="button">
        <span class="fas fa-filter float-left mt-1 mr-2"></span>{{'filter' | translate}}
    </button>
    <div (clickOutside)="closeFilterPopup($event)" [style.display]="filterState ? 'block':'none'"
         aria-labelledby="dropdownMenuButton" class="color-dropdown border-radius-0 mt-1 col-8 col-sm-6 col-md-4 col-xl-2 p-0"
         style="position: fixed; z-index: 5000; ">
        <div>
            <a (click)="getPositions('')" aria-controls="position"
               aria-expanded="false" class="dropdown-item color-dropdown-item"
               data-toggle="collapse" href="#position"
               role="button">
                {{'position' | translate}}<span *ngIf="this.calendarFilter.position !==''" class="text-danger">*</span>
            </a>
            <div class="collapse" id="position">
                <app-multiselect-dropdown-filter (selectResult)="selectPosition($event)"
                (searchData)="getPositions($event)"
                                          [isSearchable]="true"
                                          [items]="positions"
                                          dropdownName="position"
                                          translateObjectKey="name">
                </app-multiselect-dropdown-filter>
            </div>
        </div>
        <div>
            <a (click)="getRecruitmentPreferences('')"
             aria-controls="pipelineStep"
               aria-expanded="false" class="dropdown-item color-dropdown-item"
               data-toggle="collapse" href="#pipelineStep"
               role="button">
                {{'pipelineSteps' | translate}}<span *ngIf="this.calendarFilter.recruitmentStep !==''"
                                                     class="text-danger">*</span>
            </a>
            <div class="collapse" id="pipelineStep">
                <app-multiselect-dropdown-filter (selectResult)="selectPipelineStep($event)"
                (searchData)="getRecruitmentPreferences($event)"
                                          [isSearchable]="true"
                                          [items]="pipelineSteps"
                                          dropdownName="pipelineSteps"
                                          translateObjectKey="name">
                </app-multiselect-dropdown-filter>
            </div>
        </div>
        <div>
            <a (click)="getCandidates('')"
             aria-controls="candidate"
               aria-expanded="false" class="dropdown-item color-dropdown-item"
               data-toggle="collapse" href="#candidate"
               role="button">
                {{'candidate' | translate}}<span *ngIf="this.calendarFilter.candidate !==''"
                                                 class="text-danger">*</span>
            </a>
            <div *ngIf="apiUrlForGetCandidate" class="collapse" id="candidate">
                <!-- <app-searchable-dynamic-dropdown
                (selectResult)="selectCandidate($event)"
                dropdownName="candidate"
                showElement = 'fullName'
                resGetElement = 'content'
                [apiUrlForGetData]="apiUrlForGetCandidate"
                ></app-searchable-dynamic-dropdown> -->
                <app-multiselect-dropdown-filter (selectResult)="selectCandidate($event)"
                                          (searchData)="getCandidates($event)"
                                          [isSearchable]="true"
                                          [items]="candidates"
                                          dropdownName="candidate"
                                          translateObjectKey="fullName">
                </app-multiselect-dropdown-filter>
            </div>
        </div>
        <div>
            <a (click)="getCompanyUsers('')"
             aria-controls="positionType"
               aria-expanded="false" class="dropdown-item color-dropdown-item"
               data-toggle="collapse" href="#attendees"
               role="button">
                {{'attendee' | translate}}<span *ngIf="this.calendarFilter.attendees !==''" class="text-danger">*</span>
            </a>
            <div class="collapse" id="attendees">
                <app-multiselect-dropdown-filter (selectResult)="selectCompanyUser($event)"
                (searchData)="getCompanyUsers($event)"
                                          [isSearchable]="true"
                                          [items]="companyUsers"
                                          dropdownName="attendee"
                                          translateObjectKey="fullName">
                </app-multiselect-dropdown-filter>
            </div>
        </div>
        <div>
            <button (click)="applyFilter()"
                    class="exactalent-button border-radius-0 apply-filter">{{'applyFilters' | translate}}</button>
        </div>
    </div>
</div>
