<div *ngIf="!addPosition" class="container-fluid">
    <div class="row">
        <div (click)="setTab('allPositions')" *ngIf="permissionService.permissionsOnly(['READ_ALL_POSITIONS'])"
             [ngClass]="{'gradient-bg':!allPositionsComponentState,
                         'top-line':allPositionsComponentState,
                         'col-12':!permissionService.permissionsOnly(['READ_MY_POSITIONS']),
                         'col-xl-6 col-lg-6 col-md-6 col-sm-12 ':permissionService.permissionsOnly(['READ_MY_POSITIONS'])}"
             class="menu-item">
            <a class="nav-link active">{{'allPositions' | translate}}</a>
        </div>
        <div (click)="setTab('myPositions')" *ngIf="permissionService.permissionsOnly(['READ_MY_POSITIONS'])"
             [ngClass]="{'gradient-bg':!myPositionsComponentState,
                         'top-line':myPositionsComponentState,
                         'col-12':!permissionService.permissionsOnly(['READ_ALL_POSITIONS']),
                         'col-xl-6 col-lg-6 col-md-6 col-sm-12 ':permissionService.permissionsOnly(['READ_ALL_POSITIONS'])}"
             class="menu-item">
            <a class="nav-link">{{'myPositions' | translate}}</a>
        </div>
    </div>
</div>
<div *ngIf="!addPosition" class="container-fluid mt-4">
    <div class="row">
        <div class="col-auto mb-1">
            <app-position-list-filter (applyFilterOperation)="applyFilter($event)"></app-position-list-filter>
        </div>
        <div *ngIf="permissionService.permissionsOnly(['CREATE_POSITION'])" class="col-auto mb-1">
            <button (click)="addPositionModal()"
                    class="btn btn-blue border-radius-0 float-right exactalent-button width-100"
                    data-target="#addUserCenter"
                    data-toggle="modal">
                {{'addNewPosition' | translate}}
            </button>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            <app-position-list (positionDetail)="openPosition($event)" [filterData]="filterData"
                               [tabState]="tabState"></app-position-list>
        </div>
    </div>
</div>
<app-position-operations (cancelOperation)="cancelOperation()" *ngIf="addPosition"
                         [operationType]="operationType"
                         [positionDetail]="positionDetail"></app-position-operations>

