import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { server } from 'src/app/services/server';

@Component({
  selector: 'app-searchable-dynamic-dropdown',
  templateUrl: './searchable-dynamic-dropdown.component.html',
  styleUrls: ['./searchable-dynamic-dropdown.component.scss']
})
export class SearchableDynamicDropdownComponent implements OnInit {

  @Input() disabled = false;
  @Input() dropdownName = 'unspecified';
  @Input() isSearchable = true;
  @Output() selectResult = new EventEmitter();
  loadItems$: Observable<any>;
  @Input() minLengthForSearch = 3;
  @Input() selectedItem: string = null;
  storedItems: any[] = [];
  noSearch = false;
  @Input() showElement: string ;
  @Input() resGetElement: string;
  @Input() apiUrlForGetData: string;
  @Input() isRequired = false;

  constructor(private authService: AuthService, private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {

  }
  search(searchKey: string){
    if (searchKey.length < 3) {
      this.noSearch = true;
      return; }
    this.noSearch = false;
    this.loadItems$ = this.loadItems(searchKey).pipe(tap(x => {console.log(x); }));
  }

  selectData(item): void {
    if (!item) { this.selectedItem = null;
                 this.selectResult.emit('');
                 return; }

    this.selectedItem = item[this.showElement];
    this.selectResult.emit(item);

}

loadItems(search = ''): Observable<any> {
  this.spinner.hide();
  return this.authService.ServerGet(`${server.candidates}?page=0&size=50&filter=status!:DELETED&filter=name~~${search}`)
  .pipe(map((res: any) => {
    this.spinner.hide();
    return res[this.resGetElement]; }));
}

}
