<div class="card mt-3" style="border-radius: 18px;" [ngClass]="interview.date == today && utilitiesService.compareHours(hour,interview.endTime)  ? 'gray': ''"  >
    <div class="card-header">
        <p class="text-muted p-0 m-0"><i style="color: #0e6c7b;">{{'interview' | translate}}</i></p>
    </div>
    <div (click)="openViewInterview(interview)"  class="card-body" style="background-color: #0d6b7b; color: white;">
        <p><i class="far fa-user-circle"></i>&nbsp;<span class="font-size-14">{{interview.candidate?.name}}</span></p>
        <p class="mb-0">
            <span>
                <i class="far fa-calendar-alt font-size-12"></i>&nbsp;
                <span class="font-size-12">{{interview.date | date:'dd-MM-yyy'}}</span>
            </span>
            <span class="float-right">
                <i  class="far fa-clock font-size-12"></i>&nbsp;
                <span class="font-size-12">{{interview.startTime | slice:0:5}}</span>
            </span>
        </p>
    </div>
    <div class="card-footer">
        <a (click)="openEditInterview(interview)" class="card-link cursor-pointer"><i
                class="fas fa-pencil-alt text-success"></i>&nbsp;<span
                class="text-success"
                style="text-decoration: underline">{{'edit' | translate}}</span></a>
        <a (click)="openDeleteInterview(interview)" class="card-link cursor-pointer"><i
                class="fas fa-pencil-alt text-danger"></i>&nbsp;<span
                class="text-danger"
                style="text-decoration: underline">{{'delete' | translate}}</span></a>
    </div>
</div>
