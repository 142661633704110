import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttachmentModel} from '../attachment-import/attachment.model';

@Component({
    selector: 'app-attachment-list',
    templateUrl: './attachment-list.component.html',
    styleUrls: ['./attachment-list.component.scss']
})
export class AttachmentListComponent implements OnInit {

    @Input() attachments: AttachmentModel[] = [];
    @Output() emitOpenRemoveAttachment = new EventEmitter();

    constructor() {

    }

    ngOnInit(): void {
    }

    openRemoveAttachment(attachment) {
        this.emitOpenRemoveAttachment.emit(attachment);
    }

}
