import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GeneralModel} from '../../../positions/position-operations/general/general.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';

@Component({
    selector: 'app-positions-dashboard-view-list-item',
    templateUrl: './positions-dashboard-view-list-item.component.html',
    styleUrls: ['./positions-dashboard-view-list-item.component.scss']
})
export class PositionsDashboardViewListItemComponent implements OnInit {

    @Input() position: GeneralModel;
    @Output() addCandidateOperation = new EventEmitter();
    @Output() addHiringTeamMemberOperation = new EventEmitter();

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService) {
        this.position = new GeneralModel();
    }

    ngOnInit(): void {
    }

    openHiringProcess(position) {
        this.router.navigate(['/dashboard/position-details', {id: position.id, type: 'pipeline'}]);
    }

    openPositionCandidates(position) {
        this.router.navigate(['/dashboard/position-details', {id: position.id, type: 'candidate'}]);
    }

    emitAddCandidate(position) {
        this.addCandidateOperation.emit(position);
    }

    emitAddHiringTeamMember(position) {
        this.addHiringTeamMemberOperation.emit(position);
    }

    openEditPosition(position) {
        sessionStorage.setItem('selectedPosition', JSON.stringify(position));
        this.router.navigate(['/dashboard/positions', {operationType: 'edit'}]);
    }

}
