import {Component, OnInit} from '@angular/core';
import {ProfileModel} from './profile.model';
import {Langs} from '../../shared/language/_languages';
import {AuthService} from '../../services/auth/auth.service';
import {TranslatePipe} from '../../pipes/translate/translate.pipe';
import {NotificationService} from '../../services/notification/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {server} from '../../services/server';
import {Items} from '../../shared/appItems';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    user: ProfileModel;
    languages = Langs;
    tempUserImg: string;
    confirmData: any = {};

    photoApiUrl: string;

    constructor(private authService: AuthService,
                private translate: TranslatePipe,
                private spinner: NgxSpinnerService,
                private notification: NotificationService) {
        this.tempUserImg = Items.tempUserImg;
        this.user = new ProfileModel();
        this.getUserInfo();
    }

    ngOnInit(): void {
    }

    getLanguage(e) {
        this.user.language = e.target.value;

    }

    getUserProfileImage() {
        this.authService.ServerGet(this.photoApiUrl)
            .subscribe((res: { url: string }) => {
                if (res.url && res.url !== '') {
                    this.tempUserImg = res.url;
                }

            }, err => {
                /*if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }*/
            }, () => {
                this.spinner.hide();
            });
    }

    deleteUserProfilImage(){
        this.authService.ServerDelete(this.photoApiUrl)
        .subscribe((res: { url: string }) => {

            this.tempUserImg = Items.tempUserImg;

        }, err => {
            /*if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }*/
        }, () => {
            this.spinner.hide();
             // @ts-ignore
            $('#confirmModal').modal('hide');
            window.location.reload();
        });
    }

    openConfirmInterviewRequest() {

        this.confirmData.title = 'deleteProfilePhoto';
        const confirmInterviewRequest = this.translate.transform('confirmdeleteProfilePhoto', []);
        this.confirmData.message = `<i class="fas fa-exclamation-triangle pr-1"></i> ${confirmInterviewRequest}`;

        // @ts-ignore
        $('#confirmModal').modal('show');
      }

    getUserInfo() {
        const parsedToken = JSON.parse(decodeURIComponent(escape(window.atob((this.authService.getToken()).split('.')[1]))));
        this.user.name = parsedToken.first_name + ' ' +
            (parsedToken.middle_name ? parsedToken.middle_name + ' ' : '') +
            parsedToken.last_name;
        this.user.email = parsedToken.user_name;
        this.user.id = parsedToken.sub;
        this.user.language = this.authService.getSessionLanguage();

        this.photoApiUrl = server.storageUser + '/' + this.user.id + '/profile-photo';

        this.getUserProfileImage();
    }



    saveUser() {
        const obj = {
            preferredLanguage: this.user.language
        };
        this.authService.ServerPut(server.me, obj)
            .subscribe(res => {
                this.authService.setLanguage(this.user.language);
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                window.location.reload();
                this.spinner.hide();
            });
    }

    openResetPasswordModal() {
        // @ts-ignore
        $('#resetPasswordModal').modal('show');
    }

    openChangePhoto() {
        // @ts-ignore
        $('#photoImport').modal('show');
    }

}
