<div class="dropdown drp">
    <button [disabled]="disabled" aria-expanded="false" aria-haspopup="true"
        class="btn dropdown-toggle col-12 text-center form-control" data-toggle="dropdown" type="button" [ngClass]="{'is-invalid':(!selectedItem && isRequired),
                    'is-valid':(selectedItem && isRequired)
                    }">
        <span *ngIf="(selectedItem !== '' && selectedItem !== null)">
            {{selectedItem}}
        </span>

        <span *ngIf="selectedItem === '' || selectedItem === null">
            {{dropdownName | translate}}
        </span>

    </button>
    <div aria-labelledby="multipleSelectDropdownMenuButton" class="dropdown-menu col-12 pb-0">
        <label *ngIf="isSearchable" class="col-12">
            <input #searchInput (keyup)="search(searchInput.value)" autocomplete="off" autofocus
                class="form-control border-radius-0" placeholder="{{'search' | translate}}">
        </label>

        <div class="col-12 drp-menu p-0">

            <label *ngIf="noSearch" class="alert alert-warning">{{'leastThreeCharacter' | translate}}</label>
            <label (click)="selectData(null)" class="dropdown-item m-0 cursor-pointer">
                <span class="ml-2">{{'unspecified' | translate}}</span>
            </label>

            <div *ngIf="!noSearch">
                <ng-container *ngIf="(loadItems$ | async) as items ">
                    <label (click)="selectData(item)" *ngFor="let item of items"
                        class="dropdown-item m-0 cursor-pointer">
                        <span class="ml-2">{{item.fullName}}</span>
                    </label>
                </ng-container>
            </div>


        </div>
    </div>
</div>