import {Component, Input, OnInit} from '@angular/core';
import {RequestPayload} from '../../../../../shared/requestPayload';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../../services/notification/notification.service';
import {server} from '../../../../../services/server';
import {RecommendationModel} from '../recommendation.model';

@Component({
    selector: 'app-recommendation-list',
    templateUrl: './recommendation-list.component.html',
    styleUrls: ['./recommendation-list.component.scss']
})
export class RecommendationListComponent implements OnInit {

    @Input() candidateId: string;
    count: number;
    activePage: any;
    currentPage: number;
    requestPayload: RequestPayload;
    selectedRecommendation: RecommendationModel;
    selectedRecommendationId: string;
    recommendations: RecommendationModel[];
    tagList = ['TR', 'tr', 'TD', 'td'];
    openedElementId: string;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.requestPayload = new RequestPayload();
        this.selectedRecommendation = new RecommendationModel();
    }

    ngOnInit() {
        this.count = 0;
        this.recommendations = [];
        this.getRecommendations(1);
    }

    getRecommendations(pageNo) {
        this.authService.ServerGet(server.candidates + '/' + this.candidateId + '/recommendations' + this.requestPayload.payloadURL(pageNo))
            .subscribe((res: any) => {
                this.recommendations = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    pagination(pageNo) {
        this.getRecommendations(pageNo);
    }

    onClickedOutside(event) {
        if (this.tagList.indexOf(event.target.tagName) <= -1) {
            this.closeRowModal();
        }
    }

    openRowModal(event, recommendation) {
        if (this.tagList.indexOf(event.target.tagName) > -1) {
            if (this.openedElementId && document.getElementById(this.openedElementId)) {
                document.getElementById('row-modal').style.display = 'none';
            }
            this.selectedRecommendation = recommendation;
            this.openedElementId = this.selectedRecommendation.id;
            document.getElementById('row-modal').style.left = event.layerX + 'px';
            document.getElementById('row-modal').style.top = event.layerY + 40 + 'px';
            document.getElementById('row-modal').style.display = 'block';
        } else {
            this.closeRowModal();
        }
    }

    closeRowModal() {
        if (this.openedElementId) {
            document.getElementById('row-modal').style.display = 'none';
        }
    }

    openAddRecommendation() {
        // @ts-ignore
        $('#addRecommendation').modal('show');
    }

    setRecommendationScreens() {
        this.getRecommendations(1);
    }

    openEditRecommendation() {
        this.closeRowModal();
        // @ts-ignore
        $('#editRecommendation').modal('show');
    }

    openDeleteRecommendation() {
        this.closeRowModal();
        // @ts-ignore
        $('#deleteRecommendation').modal('show');
    }
}
