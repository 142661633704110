<!--Add candidate education Modal -->
<div aria-hidden="true" aria-labelledby="candidateSoftSkillOperationTitle" class="modal fade"
     id="candidateSoftSkillOperation"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">
                    {{ "candidateSoftSkillOperation" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="softSkills">{{"softSkills" | translate}}<span
                                        class="text-danger" >*</span></label>
                                <tag-input [(ngModel)]="softSkills" id="softSkills" separatorKeys=","  ></tag-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closeSoftSkillModal()" class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="saveSoftSkills()" class="btn btn-success border-radius-0" type="button">
                    {{ "save" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
