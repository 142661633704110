<!-- Modal --> 
<div aria-hidden="true" aria-labelledby="searchModal" class="modal fade modal-backdrop" id="searchModal"
     role="dialog"
     style="z-index: 1000000000;" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document" >
        <div class="modal-content" style="background:none; border: none;">
            <div class="modal-header" style="border: none;"> 
                <div class="input-group mb-3 inputDiv">
                    <input (keyup.enter)="search()" [(ngModel)]="searchModel.value" aria-describedby="basic-addon2"
                           autofocus
                           id="search-input"
                           class="form-control inputSize" placeholder="{{'search' | translate}}" maxlength="128"
                           style="border-top-left-radius: 25px;border-bottom-left-radius: 25px; height: 30px;"
                           type="text" [ngClass]="{'is-invalid':(isLessThanMinimum && isSearchClick)}" autofocus>
                    <div class="input-group-append" style="height: 30px;">
                        <span (click)="search()" class="input-group-text cursor-pointer"
                              id="basic-addon2"
                              style="border-top-right-radius: 25px;border-bottom-right-radius: 25px; ">
                              <span class="mr-2" *ngIf="(isLessThanMinimum && isSearchClick)"  style="color:red">{{ 'leastThreeCharacter' | translate }}</span><i
                                class="fas fa-search"></i></span>
                    </div>
                    
                </div>
            </div>
            
            <div  class="modal-body">
                <ul *ngIf="searchDatas && searchDatas.length>=1" class="bg-white px-5 py-3 searchResult" style="overflow-y: auto;">
                    <li (click)="openSearchedElement(searchData)" *ngFor="let searchData of searchDatas"
                        class="mt-2 mb-2 search-item" style="text-decoration: underline;">
                        {{searchData.text}}
                    </li>
                </ul>
                <div *ngIf="(!searchDatas || searchDatas.length<=0) && (!isLessThanMinimum && isSearchClick)" >
                    <ul  class="bg-white px-5 py-3 searchResult" style="overflow-y: auto;font-style: italic;list-style: none;">
                        <li  class="mt-2 mb-2 search-item" style="text-decoration: none;">
                            {{'searchNoResult' | translate}}&nbsp;{{searchKey}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
