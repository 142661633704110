import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {server} from '../../../services/server';
import {AuthService} from '../../../services/auth/auth.service';
import {ParameterService} from '../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {NotificationService} from '../../../services/notification/notification.service';

@Component({
    selector: 'app-delete-interview-modal',
    templateUrl: './delete-interview-modal.component.html',
    styleUrls: ['./delete-interview-modal.component.scss']
})
export class DeleteInterviewModalComponent implements OnInit {

    @Input() interviewId: number;
    @Output() interviewUpdate = new EventEmitter();

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
    }

    deleteInterview() {
        this.authService.ServerDelete(server.interviews + '/' + this.interviewId)
            .subscribe(res => {
                this.notification.success(this.translate.transform('deleteSuccessful', []));
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.spinner.hide();
                this.closeRemoveInterviewModal();
                this.updateInterviews();
            });
    }

    cancelDeleteInterview() {
        this.closeRemoveInterviewModal();
        // @ts-ignore
        $('#calendarInterviewDetailsModal').modal('show');
    }

    closeRemoveInterviewModal() {
        // @ts-ignore
        $('#deleteInterviewModal').modal('hide');
    }

    updateInterviews() {
        this.interviewUpdate.emit();
    }

}
