<!-- Modal -->
<div aria-hidden="true" aria-labelledby="positionModalCenterTitle" class="modal fade"
     id="addToPositionModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="statusModalLongTitle">
                    <img src="assets/img/icons/users-filled.png" alt="" class="img-fluid mr-3">{{'addToPosition' | translate}}</h5>
            </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                           for="positionDrp">{{'candidate' | translate}}</label>
                                    <select (change)="selectCandidate($event)" class="custom-select border-radius-0"
                                            id="positionDrp">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let candidate of candidates"
                                                [value]="candidate.id">{{candidate.fullName}}</option>
                                    </select>
                                </div>
                                <div class="mb-1">
                                    <label class="ex-check" style="color: #649a65;">
                                        <input [(ngModel)]="selectedCandidate.clientHrPermitted" type="checkbox">
                                        <span class="ml-2 text-1">
                                            {{'clientHrPermitted' | translate}}
                                        </span>
                                    </label>
                                </div>
                                <div class="mb-1">
                                    <label class="ex-check" style="color: #649a65;">
                                        <input [(ngModel)]="selectedCandidate.clientTechnicalPermitted" type="checkbox">
                                        <span class="ml-2 text-1">
                                            {{'clientTechnicalPermitted' | translate}}
                                        </span>
                                    </label>
                                </div>
                                <div class="mb-1">
                                    <label class="ex-check" style="color: #649a65;">
                                        <input [(ngModel)]="selectedCandidate.hideSensitiveDataForClient" type="checkbox">
                                        <span class="ml-2 text-1">
                                            {{'hideSensitiveDataForClient' | translate}}
                                        </span>

                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="closeChangePositionModal()" class="btn btn-warning"
                            data-dismiss="modal"
                            type="button">{{'cancel' | translate}}</button>
                    <button (click)="addPosition()" class="btn btn-success"
                            type="button">{{'save' | translate}}</button>
                </div>
        </div>
    </div>
</div>
