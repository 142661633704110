<div class="container-fluid">
    <div class="row">
        <div (click)="setTab('candidate')"
             [class]="!candidateComponentState ? ' gradient-bg':'top-line'"
             class="col menu-item">
            <a class="nav-link active">{{'candidate' | translate}}</a>
        </div>
        <div (click)="setTab('pipeline')" [class]="!pipelineComponentState ? ' gradient-bg':'top-line'"
             class="col menu-item">
            <a class="nav-link active">{{'pipeline' | translate}}</a>
        </div>
        <div (click)="setTab('calender')" [class]="!calenderComponentState ? ' gradient-bg':'top-line'"
             class="col menu-item">
            <a class="nav-link active">{{'calender' | translate}}</a>
        </div>
        <div (click)="setTab('reports')" *ngIf="permissionService.permissionsOnly(['READ_ALL_POSITION_REPORTS'])"
             [class]="!reportsComponentState ? ' gradient-bg':'top-line'"
             class="col menu-item">
            <a class="nav-link active">{{'reports' | translate}}</a>
        </div>
        <div (click)="setTab('history')" *ngIf="permissionService.permissionsOnly(['READ_POSITION_HISTORY'])"
             [class]="!historyComponentState ? ' gradient-bg':'top-line'"
             class="col menu-item">
            <a class="nav-link active">{{'history' | translate}}</a>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row mt-4">
        <div class="col-12">

            <label class="col-auto">
            <span (click)="openPositionStatusModal()" class="btn-group mr-2"
                  role="group" style="cursor: pointer; vertical-align: unset !important;"
                  title="{{position.status | translate}}">
                <p [class]="position.status" class="position-status-color-icon">
                </p>&nbsp;&nbsp;
                <p><i class="fa fa-caret-down"></i></p>
            </span> &nbsp;&nbsp;
                <span class="position-name">
                <b>{{position.name | uppercase}}</b>
            </span>
            </label>
            <label *ngIf="position.client" class="col-auto sub-color cursor-pointer" (click)="openPositionClient(position.client.id)"><i
                    class="fa fa-folder"></i>&nbsp;<span>{{position.client.name}}</span></label>
            <label class="col-auto sub-color"><i
                    class="fas fa-map-marker-alt"></i>&nbsp;<span>{{position.country?.value + '/' + position.city?.value}}</span></label>
        </div>
    </div>
    <div class="container-fluid">
        <app-pipeline *ngIf="pipelineComponentState" [positionId]="id"></app-pipeline>
    </div>
    <app-position-candidate *ngIf="candidateComponentState" [positionId]="id"></app-position-candidate>
    <app-position-history *ngIf="historyComponentState" [positionId]="id"></app-position-history>
    <app-position-calendar *ngIf="calenderComponentState" [positionId]="id"></app-position-calendar>
</div>

<app-position-status-modal [position]="position"></app-position-status-modal>
