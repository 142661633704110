import {CountryModel} from '../../services/parameters/models/country.model';
import {CityModel} from '../../services/parameters/models/city.model';

export class PositionModel {
    id: string;
    name: string;
    country: string;
    city: string;
    positionType: string;
    opening: string;
    created: string;
    users: string;
}

export class PositionFilterModel {
    id: number;
    name: string;
    country: CountryModel;
    city: CityModel;
    positionType: string;
    opening: string;
    created: string;
    users: string;
    status: string;
    client: string;

    constructor() {
        this.country = new CountryModel();
        this.city = new CityModel();
        this.name = '';
        this.positionType = '';
        this.opening = '';
        this.created = '';
        this.users = '';
        this.status = '';
        this.client = '';
    }
}
