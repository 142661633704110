import {Component, OnInit} from '@angular/core';
import {ClientModel} from '../client.model';
import {TagModel} from '../../../services/parameters/models/tag.model';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';
import {Items} from '../../../shared/appItems';
import {server} from '../../../services/server';

@Component({
    selector: 'app-client-details',
    templateUrl: './client-details.component.html',
    styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit {
    tempClientImg: string;
    generalComponentState: boolean;
    attachmentsComponentState: boolean;
    notesComponentState: boolean;
    positionRequestComponentState: boolean;
    usersComponentState: boolean;
    id: string;
    client: ClientModel;
    tags: TagModel[];
    parsedTags: string[];
    tagList: string;
    tagObj = [];
    photoApiUrl: string;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private spinner: NgxSpinnerService,
                private authService: AuthService,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.tempClientImg = Items.tempUserImg;
        this.client = new ClientModel();
    }

    ngOnInit(): void {
        // @ts-ignore
        if (this.route.params.value.id && this.route.params.value.id !== '') {
            // @ts-ignore
            this.id = this.route.params.value.id;
            this.getClient();
        } else {
            this.router.navigate(['/dashboard/client']);
        }

        this.photoApiUrl = server.storageClient + '/' + this.id + '/logo';

        this.getClientProfileImage();
    }

    getClient() {
        this.authService.ServerGet(server.clients + '/' + this.id)
            .subscribe((res: ClientModel) => {
                this.client = res;
                this.client.id = this.id;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.setTab('general');
                this.spinner.hide();
            });
    }

    getClientProfileImage() {
        this.authService.ServerGet(this.photoApiUrl)
            .subscribe((res: { url: string }) => {
                if (res.url && res.url !== '') {
                    this.tempClientImg = res.url;
                }

            }, err => {
                /*
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }*/
            }, () => {
                this.spinner.hide();
            });
    }

    setTab(component) {
        this.generalComponentState = false;
        this.positionRequestComponentState = false;
        this.attachmentsComponentState = false;
        this.notesComponentState = false;
        this.usersComponentState = false;
        switch (component) {
            case 'general':
                this.generalComponentState = true;
                break;
            case 'positionRequest':
                this.positionRequestComponentState = true;
                break;
            case 'attachments':
                this.attachmentsComponentState = true;
                break;
            case 'notes':
                this.notesComponentState = true;
                break;
            case 'users':
                this.usersComponentState = true;
                break;
            default:
                this.generalComponentState = true;
                break;
        }

    }

    openUpdateStatusModal() {
        // @ts-ignore
        $('#statusModal').modal('show');
    }

    openEditClient() {
        // @ts-ignore
        $('#editClient').modal('show');
    }

    openChangePhoto() {
        // @ts-ignore
        $('#photoImport').modal('show');
    }
}
