<!--Edit candidate education Modal -->
<div aria-hidden="true" aria-labelledby="editProjectTitle" class="modal fade" id="editProject" role="dialog"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <form #editCandidateProjectForm="ngForm" class="needs-validation">
                <div class="modal-header gradient-bg">
                    <h5 class="modal-title" id="editUserLongTitle">
                        {{ "editProject" | translate }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3 validate-me">
                                    <label class="modal-input-label" for="name">{{"name" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <input [(ngModel)]="project.name" class="form-control border-radius-0"
                                        id="editProjectName" name="editProjectName" type="text" required appTitleCaseDirective>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3 validate-me">
                                    <label class="modal-input-label"
                                        for="editProjectOrganisation">{{"organisation" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <input [(ngModel)]="project.organisation" class="form-control border-radius-0"
                                        id="editProjectOrganisation" name="editProjectOrganisation" type="text" required appTitleCaseDirective>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="beginningDate">
                                        {{"beginningDate" | translate}}<span class="text-danger">*</span>
                                    </label>
                                    <div class="container-fluid">
                                        <div class="row validate-me" id="beginningDate">
                                            <div class=" mr-2" style="flex:0 1 50%">
                                                <input [(ngModel)]="project.startMonth"
                                                class="form-control col border-radius-0" id="issueMonth" max="12"
                                                min="1" name="issueMonth" placeholder="{{'month' | translate}}..."
                                                type="number" required />
                                            </div>
                                           
                                            <input [(ngModel)]="project.startYear"
                                                class="form-control col border-radius-0 hideInputNumberArrow" id="issueYear" name="issueYear"
                                                min="1" placeholder="{{ 'year' | translate }}..." type="number" required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="endingDate">
                                        {{"endingDate" | translate}}
                                    </label>
                                    <div class="container-fluid">
                                        <div class="row" id="endingDate">
                                            <input [(ngModel)]="project.endMonth"
                                                class="form-control col border-radius-0" id="expirationMonth" max="12"
                                                min="1" name="expirationMonth" placeholder="{{'month' | translate}}..."
                                                type="number" />&nbsp;
                                            <input [(ngModel)]="project.endYear"
                                                class="form-control col border-radius-0 hideInputNumberArrow" id="expirationYear"
                                                name="expirationYear" placeholder="{{ 'year' | translate }}..."
                                                min="1"  type="number" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="summary">
                                        {{"summary" | translate}}
                                    </label>
                                    <div class="container-fluid">
                                        <div class="row" id="summary">
                                            <textarea [(ngModel)]="project.summary" class="form-control border-radius-0"
                                                rows="4" name="summary" appTitlecaseLower></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="closeEditProjectModal()" class="btn btn-warning border-radius-0"
                        data-dismiss="modal" type="button">
                        {{ "cancel" | translate }}
                    </button>
                    <button (click)="saveProject(editCandidateProjectForm)" class="btn btn-success border-radius-0" type="button">
                        {{ "save" | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
