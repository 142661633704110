export class RequestPayload {
    limit: number;
    page: number;

    constructor() {
        this.limit = 5;
    }

    payloadURL(pageNo) {
        this.page = (pageNo - 1);
        return `/?size=${this.limit}&page=${this.page}&filter=status!:DELETED`;
    }

    payloadURLWithOutDelete(pageNo) {
        this.page = (pageNo - 1);
        return `/?size=${this.limit}&page=${this.page}`;
    }
}
