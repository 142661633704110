<div class="dropdown">
  <button (click)="filterState = !filterState"
          class="btn btn-secondary dropdown-toggle border-radius-0 form-control"
          id="filterButton"
          type="button">
      <span class="fas fa-filter float-left mt-1 mr-2"></span>{{'filter' | translate}}
  </button>
  <div (clickOutside)="closeFilterPopup($event)" [style.display]="filterState ? 'block':'none'"
       aria-labelledby="dropdownMenuButton" class="color-dropdown border-radius-0 mt-1 position-absolute"
       style="z-index: 5000; width: 100% !important;">

      <div>
          <a aria-controls="positionStatus" aria-expanded="false"
             class="dropdown-item color-dropdown-item text-center" data-toggle="collapse"
             href="#positionStatus"
             role="button">
              {{'status' | translate}}<span *ngIf="this.reportFilter.status !==''" class="text-danger">*</span>
          </a>
          <div class="collapse" id="positionStatus">
              <app-multiselect-dropdown (selectResult)="selectStatus($event)"
                                        [isSearchable]="true"
                                        [items]="positionStatusList"
                                        dropdownName="status"
                                        translateObjectKey="name">
              </app-multiselect-dropdown>
          </div>
      </div>

      <div>
          <a aria-controls="user"
             aria-expanded="false" class="dropdown-item color-dropdown-item text-center"
             data-toggle="collapse" href="#companyUser"
             role="button">
              {{'companyUser' | translate}}<span *ngIf="this.reportFilter.user !==''" class="text-danger">*</span>
          </a>
          <div class="collapse" id="companyUser">
              <app-multiselect-dropdown (selectResult)="selectCompanyUser($event)"
                                        [isSearchable]="true"
                                        [items]="companyUsers"
                                        dropdownName="companyUser"
                                        translateObjectKey="fullName">
              </app-multiselect-dropdown>
          </div>
      </div>

      <div>
          <a aria-controls="positionType"
             aria-expanded="false" class="dropdown-item color-dropdown-item text-center"
             data-toggle="collapse" href="#client"
             role="button">
              {{'client' | translate}}<span *ngIf="this.reportFilter.client !==''" class="text-danger">*</span>
          </a>
          <div class="collapse" id="client">
              <app-multiselect-dropdown (selectResult)="selectClient($event)"
                                        [isSearchable]="true"
                                        [items]="clients"
                                        dropdownName="client"
                                        translateObjectKey="name">
              </app-multiselect-dropdown>
          </div>
      </div>
      
      <div>
          <button (click)="applyFilter()"
                  class="exactalent-button border-radius-0 apply-filter">{{'applyFilters' | translate}}</button>
      </div>
  </div>
</div>
