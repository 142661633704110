<div id="company-register">
	<div id="country" class="mt-2 mb-2">
		<select class="custom-select" (change)="selectCountry($event)">
			<option selected value="Country*">{{ 'country' | translate }}</option>
			<option *ngFor="let country of countries" [value]="country.id">
				{{ country.defaultLocalization.name }}
			</option>
		</select>
	</div>
	<div class="input-group margin-bottom-sm">
		<div class="input-group-prepend">
			<span class="input-group-text login-input-icon-email">
				<i class="fas fa-building"></i>
			</span>
		</div>
		<input
			[(ngModel)]="registerCompany.name"
			autocomplete="off"
			class="form-control login-input"
			name="email"
			placeholder="Legal Company Name*"
			type="text"
		/>
	</div>
	<div class="input-group margin-bottom-sm mt-2">
		<div class="input-group-prepend">
			<span class="input-group-text login-input-icon-email">
				<i class="fas fa-link"></i>
			</span>
		</div>
		<input
			[(ngModel)]="registerCompany.websiteLink"
			autocomplete="off"
			class="form-control login-input"
			name="websiteLink"
			placeholder="Website Link"
			type="text"
		/>
	</div>
	<div class="input-group margin-bottom-sm mt-2">
		<div class="input-group-prepend">
			<span class="input-group-text login-input-icon-email">
				<i class="fas fa-briefcase"></i>
			</span>
		</div>
		<input
			[(ngModel)]="registerCompany.taxOffice"
			autocomplete="off"
			class="form-control login-input"
			name="websiteLink"
			placeholder="Tax Office"
			type="text"
		/>
	</div>
	<div class="input-group margin-bottom-sm mt-2">
		<div class="input-group-prepend">
			<span class="input-group-text login-input-icon-email">
				<i class="fas fa-sort-numeric-down"></i>
			</span>
		</div>
		<input
			[(ngModel)]="registerCompany.taxNumber"
			autocomplete="off"
			class="form-control login-input"
			name="taxNumber"
			placeholder="Tax Number"
			type="text"
		/>
	</div>
	<div class="input-group margin-bottom-sm mt-2">
		<textarea
			[(ngModel)]="registerCompany.address"
			autocomplete="off"
			class="form-control login-input"
			name="taxNumber"
			placeholder="Address"
			type="text"
			cols="30"
			rows="3"
		></textarea>
	</div>

	<div class="input-group margin-bottom-sm login-button-div mt-2">
		<input
			(click)="saveInfo()"
			class="btn login-btn"
			value="Continue"
			[disabled]="
				!registerCompany.name ||
				!registerCompany.country.id
			"
		/>
	</div>
	<div class="input-group mt-4">
		<a routerLink="/login" class="create-new-account"
			>Already have an account? Log in</a
		>
	</div>
</div>
