<!--Delete candidate language Modal -->
<div aria-hidden="true" aria-labelledby="deleteCandidateLanguageTitle" class="modal fade" id="deleteCandidateLanguage"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title" id="deleteUserLongTitle">
                    {{ "deleteCandidateLanguage" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="languages">{{"languages" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [value]="selectedLanguage.language.value" class="form-control border-radius-0"
                                       disabled id="languages" type="text">
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="readingLevel">{{"readingLevel" | translate}}</label>
                                <input [value]="selectedLanguage.readingLevel" class="form-control border-radius-0"
                                       disabled id="readingLevel" type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="writingLevel">{{"writingLevel" | translate}}</label>
                                <input [value]="selectedLanguage.writingLevel" class="form-control border-radius-0"
                                       disabled id="writingLevel" type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="speakingLevel">{{"speakingLevel" | translate}}</label>
                                <input [value]="selectedLanguage.speakingLevel" class="form-control border-radius-0"
                                       disabled id="speakingLevel" type="text">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closeDeleteLanguageModal()" class="btn btn-warning border-radius-0"
                        data-dismiss="modal"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="deleteCandidateLanguage()" class="btn btn-danger border-radius-0" type="button">
                    {{ "delete" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
