import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UserModel} from '../user.model';
import {server} from '../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';

@Component({
    selector: 'app-user-management-modal',
    templateUrl: './user-management-modal.component.html',
    styleUrls: ['./user-management-modal.component.scss']
})
export class UserManagementModalComponent implements OnInit, OnChanges {

    @Input() operationType;
    @Input() user: UserModel;
    @Output() updateUsers = new EventEmitter();

    tempUser: UserModel;
    isDelete: boolean;
    isEdit: boolean;
    myUserId: string;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.isDelete = false;
        this.isEdit = false;
    }

    ngOnInit(): void {
        this.myUserId = this.authService.myUserId;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.tempUser = {...this.user};
        this.isEdit = false;
        this.isDelete = false;
        this.setOperation();
    }

    setOperation() {
        if (this.operationType === 'add') {
            this.tempUser = new UserModel();
            this.isDelete = false;
            this.isEdit = false;
        } else if (this.operationType === 'edit') {
            this.tempUser = {...this.user};
            this.isDelete = false;
            this.isEdit = true;
        } else {
            this.tempUser = {...this.user};
            this.isDelete = true;
            this.isEdit = false;
        }
    }

    userOperation() {
        if (this.operationType === 'add') {
            this.add();
        } else if (this.operationType === 'edit') {
            this.edit();
        } else {
            this.delete();
        }
    }

    add() {
        this.tempUser.companyId = this.authService.companyId;
        this.tempUser.preferredLanguage = this.authService.getSessionLanguage();
        this.authService.ServerPost(server.users, this.tempUser)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updateTable();
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#userModal').modal('hide');
                this.spinner.hide();
            });
    }

    edit() {
        this.tempUser.companyId = this.authService.companyId;
        this.authService.ServerPut(server.users + '/' + this.tempUser.id, this.tempUser)
            .subscribe(res => {
                this.notification.success(this.translate.transform('editSuccessful', []));
                this.updateTable();
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#userModal').modal('hide');
                this.spinner.hide();
            });
    }

    delete() {
        const companyId = this.authService.companyId;
        this.authService.ServerDelete(server.users + '/' + this.tempUser.id)
            .subscribe(res => {
                this.notification.success(this.translate.transform('deleteSuccessful', []));
                this.updateTable();
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#userModal').modal('hide');
                this.spinner.hide();
            });
    }

    getSelectedRole(e) {
        this.tempUser.role = e.target.text;
    }

    updateTable() {
        this.updateUsers.emit(this.tempUser);
    }

    resetPasswordRequest() {
        const obj = {
            email: this.tempUser.email

        };
        
        this.authService.ServerPost(server.passwordRequest, obj)
            .subscribe(res => {
                this.notification.success(this.translate.transform('passwordRequestSuccessful', []));
                this.updateTable();
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#userModal').modal('hide');
                this.spinner.hide();
            });
    }

    changeStatus(e) {
        this.tempUser.status = e.target.value;
    }

}
