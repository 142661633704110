export class PipelineStepModel {
    id: number;
    candidateId: string;
    name: string;
    email: string;
    phone: string;
    source: string;
    positionId: string;
    positionName: string;
    recruitmentStepId: string;
    recruitmentStepName: string;
    addedAt: string;
    updatedAt: string;
    note: string;
    clientHrPermitted: boolean;
    clientTechnicalPermitted: boolean;
    hideSensitiveDataForClient: boolean;

    constructor() {
        this.clientTechnicalPermitted = false;
        this.clientHrPermitted = false;
        this.hideSensitiveDataForClient = false;
    }
}
