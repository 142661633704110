import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../../services/auth/auth.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {UploadService} from '../../../../services/upload/upload.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../../../services/notification/notification.service';

@Component({
    selector: 'app-attachment-delete-modal',
    templateUrl: './attachment-delete-modal.component.html',
    styleUrls: ['./attachment-delete-modal.component.scss']
})
export class AttachmentDeleteModalComponent implements OnInit {

    @Input() attachmentDeleteUrl: string;
    @Output() emitUpdateAttachmentList = new EventEmitter();

    constructor(private authService: AuthService,
                private translate: TranslatePipe,
                private uploadService: UploadService,
                private spinner: NgxSpinnerService,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
    }

    deleteAttachment() {
        this.authService.ServerDelete(this.attachmentDeleteUrl)
            .subscribe(res => {
                this.notification.success(this.translate.transform('deleteSuccessful', []));
                this.emitUpdateAttachmentList.emit();
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                this.cancelDeleteAttachment();
                this.spinner.hide();
            });
    }

    cancelDeleteAttachment() {
        // @ts-ignore
        $('#deleteAttachmentModal').modal('hide');
    }

}
