<!--Add pool education Modal -->
<div aria-hidden="true" aria-labelledby="calendarInterviewDetailsModal" class="modal fade"
     id="calendarInterviewDetailsModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">
                    {{ "interviewDetails" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div *ngIf="isMonthView" class="row">
                        <div class="col-12" id="accordion">
                            <div *ngFor="let interview of interviews" class="card">
                                <div (click)="collapse('interviewItem_'+interview.id)"
                                     class="card-header cursor-pointer">
                                    <h5 class="mb-0">
                                        <button aria-controls="collapseOne" aria-expanded="true"
                                                class="btn btn-link"
                                                data-target="#collapseOne" data-toggle="collapse">
                                            {{interview.meta.position?.name}} - {{interview.meta.candidate?.name}}
                                        </button>
                                        <i class="fas fa-caret-down float-right"></i>
                                    </h5>
                                </div>

                                <div class="collapse hide" data-parent="#accordion"
                                     id="interviewItem_{{interview.id}}">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-12">
                                                <app-calendar-interview-detail (openRemoveInterview)="openDeleteInterview($event)"
                                                                               (openUpdateInterview)="openUpdateInterview($event)"
                                                        [interview]="interview"></app-calendar-interview-detail>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isMonthView" class="row">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <app-calendar-interview-detail (openRemoveInterview)="openDeleteInterview($event)"
                                                                   (openUpdateInterview)="openUpdateInterview($event)"
                                                                   [interview]="interview"></app-calendar-interview-detail>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
