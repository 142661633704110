<!--Add Item Modal -->
<div aria-hidden="true" aria-labelledby="addItem" class="modal fade" id="addItem"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">
                    {{ "add" | translate }}&nbsp;{{fieldName | translate}}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                           for="preferredTitle">{{fieldName | translate}}</label>
                                    <input [(ngModel)]="newItem"
                                           [placeholder]="fieldName | translate"
                                           class="form-control border-radius-0"
                                           id="preferredTitle" name="preferredTitle"
                                           type="text"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closeAddItemModal()" class="btn btn-warning border-radius-0"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="saveItem()" [disabled]="newItem===''" class="btn btn-success border-radius-0"
                        type="button">
                    {{ "save" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

