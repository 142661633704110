import { Injectable } from '@angular/core';
import { server } from '../server';
import { AuthService } from '../auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndustryModel } from './models/industry.model';
import { Observable } from 'rxjs';
import { CountryModel } from './models/country.model';
import { CityModel } from './models/city.model';
import { map } from 'rxjs/operators';
import { CurrencyModel } from './models/currency.model';
import { DepartmentModel, FacultyModel, UniversityModel } from './models/education.model';
import { CategoryModel } from './models/category.model';
import { ClientModel } from '../../components/clients/client.model';
import { UserModel } from '../../components/user-management/user.model';

@Injectable()
export class ParameterService {

    constructor(private spinner: NgxSpinnerService, private authService: AuthService) {
    }

    get poolCategories(): Observable<CategoryModel[]> {
        return this.authService.ServerGet(server.poolCategories)
            .pipe(
                map((res: { content: CategoryModel[] }) => {
                    return res.content;
                })
            );
    }

    get industries(): Observable<IndustryModel[]> {
        return this.authService.ServerGet(server.industries)
            .pipe(
                map((res: { content: IndustryModel[] }) => {
                    return res.content;
                })
            );
    }

    get universities(): Observable<UniversityModel[]> {
        return this.authService.ServerGet(server.universities)
            .pipe(
                map((res: { content: UniversityModel[] }) => {
                    return res.content;
                })
            );
    }

    get faculties(): Observable<FacultyModel[]> {
        return this.authService.ServerGet(server.faculties)
            .pipe(
                map((res: { content: FacultyModel[] }) => {
                    return res.content;
                })
            );
    }

    get departments(): Observable<DepartmentModel[]> {
        return this.authService.ServerGet(server.departments)
            .pipe(
                map((res: { content: DepartmentModel[] }) => {
                    return res.content;
                })
            );
    }

    get currencies(): Observable<CurrencyModel[]> {
        return this.authService.ServerGet(server.currencies)
            .pipe(
                map((res: { content: CurrencyModel[] }) => {
                    return res.content;
                })
            );
    }

    get countries(): Observable<CountryModel[]> {
        return this.authService.ServerGet(server.countries)
            .pipe(
                map((res: { content: CountryModel[] }) => {
                    return res.content;
                })
            );
    }
    get countriesWithoutToken(): Observable<CountryModel[]> {
        return this.authService.ServerGetWithoutLogin(server.countries)
            .pipe(
                map((res: { content: CountryModel[] }) => {
                    return res.content;
                })
            );
    }
    get companyUsers() {
        return this.authService.ServerGet(server.users)
            .pipe(
                map((res: { content: UserModel[] }) => {
                    return res.content;
                })
            );
    }

    getCountryCities(countryId: string): Observable<CityModel[]> {
        return this.authService.ServerGet(server.countries + '/' + countryId + server.cities)
            .pipe(
                map((res: { content: CityModel[] }) => {
                    return res.content;
                })
            );
    }

    getAllClients() {
        return this.authService.ServerGet(server.clients)
            .pipe(
                map((res: { content: ClientModel[] }) => {
                    return res.content;
                })
            );
    }
    getAllClientsWithoutDeleted() {
        return this.authService.ServerGet(server.clients + '/?filter=status!:DELETED')
            .pipe(
                map((res: { content: ClientModel[] }) => {
                    return res.content;
                })
            );
    }
}
