import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {RecruitmentPreferencesModel} from '../recruitment-preferences.model';

@Component({
    selector: 'app-recruitment-preference-modal',
    templateUrl: './recruitment-preference-modal.component.html',
    styleUrls: ['./recruitment-preference-modal.component.scss']
})
export class RecruitmentPreferenceModalComponent implements OnInit, OnChanges {

    @Input() operationType;
    @Input() preferences: RecruitmentPreferencesModel;
    @Output() addStep = new EventEmitter();
    @Output() deleteStep = new EventEmitter();

    recruitmentPreference: RecruitmentPreferencesModel;
    isDelete: boolean;

    constructor() {
        this.isDelete = false;
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setOperation();
    }

    setOperation() {
        if (this.operationType === 'add') {
            this.recruitmentPreference = new RecruitmentPreferencesModel();
            this.isDelete = false;
        } else if (this.operationType === 'edit') {
            this.recruitmentPreference = {...this.preferences};
            this.isDelete = false;
        } else {
            this.recruitmentPreference = {...this.preferences};
            this.isDelete = true;
        }
    }

    recruitmentPreferenceOperation() {
        if (this.operationType === 'add') {
            this.addRecruitmentPreference();
        } else if (this.operationType === 'edit') {
            this.editRecruitmentPreference();
        } else {
            this.deleteRecruitmentPreference();
        }
    }

    addRecruitmentPreference() {
        this.addStep.emit(this.recruitmentPreference);
    }

    editRecruitmentPreference() {
        this.preferences.name = this.recruitmentPreference.name;
        this.preferences.hideOnMobile = this.recruitmentPreference.hideOnMobile;
        this.preferences.successfulLastStep = this.recruitmentPreference.successfulLastStep;
        // @ts-ignore
        $('#recruitmentPreferencesModal').modal('hide');
    }

    deleteRecruitmentPreference() {
        this.deleteStep.emit(this.preferences);
        // @ts-ignore
        $('#recruitmentPreferencesModal').modal('hide');
    }

}
