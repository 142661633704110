import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationService} from '../../../../../services/notification/notification.service';
import {StepModel} from '../../../../candidates/edit-candidate/candidate-profile-operations/candidate-change-step/step.model';
import {server} from '../../../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {ParameterService} from '../../../../../services/parameters/parameter.service';
import {AuthService} from '../../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PipelineStepModel} from '../../../../../services/parameters/models/pipeline-step.model';
import {TranslatePipe} from '../../../../../pipes/translate/translate.pipe';

@Component({
    selector: 'app-pipeline-change-candidate-step',
    templateUrl: './pipeline-change-candidate-step.component.html',
    styleUrls: ['./pipeline-change-candidate-step.component.scss']
})
export class PipelineChangeCandidateStepComponent implements OnInit {

    @Input() candidate: PipelineStepModel;
    @Output() updateStepInfo = new EventEmitter();

    steps: StepModel[];
    tempStep: string;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                private notification: NotificationService) {
        this.candidate = new PipelineStepModel();
    }

    ngOnInit(): void {
        this.getSteps();
    }

    selectStep(e) {
        this.tempStep = e.target.value;
    }

    updateStep() {

        const obj = {
            recruitmentStepId: this.tempStep,
            candidateId: this.candidate.candidateId,
            positionId: this.candidate.positionId,
            clientHrPermitted: this.candidate.clientHrPermitted,
            clientTechnicalPermitted: this.candidate.clientTechnicalPermitted
        };
        this.authService.ServerPut(server.pipelineStepCandidates, obj)
            .subscribe(res => {
                this.updateStepInfo.emit();
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.closeChangeStepModal();
                this.spinner.hide();
            });
    }

    getSteps() {
        this.authService.ServerGet(server.pipelineSteps + '?positionId=')
            .subscribe((res: any) => {
                this.steps = res;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    closeChangeStepModal() {
        // @ts-ignore
        $('#changePipelineStepModal').modal('hide');
    }

}
