import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UploadService} from '../../../../services/upload/upload.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';

@Component({
    selector: 'app-attachment-import',
    templateUrl: './attachment-import.component.html',
    styleUrls: ['./attachment-import.component.scss']
})
export class AttachmentImportComponent implements OnInit {

    @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;

    files: any[] = [];
    selectedFileName: string;
    @Input() attachmentUrl: string;
    @Input() fromPage: string;
    @Output() emitParentFunction = new EventEmitter();
    acceptableFileTypes = '.xps, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .png, .jpg, .jpeg, .pdf';

    constructor(private authService: AuthService,
                private translate: TranslatePipe,
                private uploadService: UploadService,
                private spinner: NgxSpinnerService,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
    }

    saveAttachment() {
        this.saveAttachmentNameAndGetUploadUrl();
    }

    saveAttachmentNameAndGetUploadUrl() {
        const obj = {
            fileName: this.selectedFileName
        };
        let uploadWhere$;
        if (this.fromPage == 'edit-candidate'){
            uploadWhere$ = this.authService.ServerPut(this.attachmentUrl, obj);
        }else{
            uploadWhere$ = this.authService.ServerPost(this.attachmentUrl, obj);
        }
        uploadWhere$.subscribe((res: { url: string }) => {
                this.importAttachment(res.url);
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                 this.spinner.hide();
            });
    }


    uploadFile(url: string) {
        this.uploadService.upload(this.files[0].data, {url}).subscribe(res => {
            this.notification.success(this.translate.transform('saveSuccessful', []));
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
           if (this.fromPage !== 'edit-candidate') { this.emitParentFunction.emit(); }

           this.cancelImportCandidate();
           this.spinner.hide();
        });
    }

    onClick() {
        const fileUpload = this.fileUpload.nativeElement;
        fileUpload.onchange = () => {
            const file = fileUpload.files[0];
            this.selectedFileName = file.name;
            this.files.push({data: file, inProgress: false, progress: 0});
        };
        fileUpload.click();
    }

    cancelImportCandidate() {
        this.files = [];
        this.selectedFileName = '';
        // @ts-ignore
        $('#importAttachment').modal('hide');
    }

    importAttachment(url) {
        this.fileUpload.nativeElement.value = '';
        this.uploadFile(url);
    }

}
