export class NoteModel {
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    id: number;
    message: string;
    privateNote: boolean;

    constructor() {
        this.privateNote = true;
    }
}
