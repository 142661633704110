import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../../services/permission/permission.service';
import {NotificationService} from '../../../../../../services/notification/notification.service';
import {server} from '../../../../../../services/server';
import {PoolModel} from '../../../../../pools/pool.model';

@Component({
    selector: 'app-remove-candidate-pool',
    templateUrl: './remove-candidate-pool.component.html',
    styleUrls: ['./remove-candidate-pool.component.scss']
})
export class RemoveCandidatePoolComponent implements OnInit {

    @Input() pool: PoolModel;
    @Input() candidateId: string;
    @Output() updateCandidatePools = new EventEmitter();

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.pool = new PoolModel();
    }

    deletePool() {
        this.authService.ServerDelete(server.pools + '/' + this.pool.id + '/members/' + this.candidateId)
            .subscribe(res => {
                this.notification.success(this.translate.transform('deleteSuccessful', []));
                this.updateCandidatePools.emit();
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.closeDeletePoolModal();
                this.spinner.hide();
            });
    }

    closeDeletePoolModal() {
        // @ts-ignore
        $('#deleteCandidatePool').modal('hide');
    }
}
