import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../../services/auth/auth.service';
import {GeneralModel} from './general.model';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {CountryModel} from '../../../../services/parameters/models/country.model';
import {CityModel} from '../../../../services/parameters/models/city.model';
import {server} from '../../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../../../services/notification/notification.service';
import {JobTypeList} from '../../../../shared/lists/job-type.list';
import {EducationDegreeList} from '../../../../shared/lists/education-degree.list';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {ClientModel} from '../../../clients/client.model';
import {CurrencyModel} from '../../../../services/parameters/models/currency.model';
import {NgForm} from '@angular/forms';
import {UtilitiesService} from 'src/app/services/utilities/utilities.service';

@Component({
    selector: 'app-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {

    @Input() addPositionModel: GeneralModel;
    tempAddPositionModel: GeneralModel;

    countries: CountryModel[];
    cities: CityModel[];
    clients: ClientModel[];
    currencies: CurrencyModel[];
    positionTypes = JobTypeList;
    educationDegrees = EducationDegreeList;
    isSaveClick = false;
    @Input() operationType: string;
    @Output() cancelPositionOperation = new EventEmitter();

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService,
                private utilitiesService: UtilitiesService) {
    }

    ngOnInit(): void {
        // @ts-ignore
        $('.datepicker').datepicker({
            todayHighlight: true,
            autoclose: true,
            format: 'yyyy-mm-dd',
            startDate: new Date(),
            language: this.authService.getSessionLanguage(),
        });



        this.tempAddPositionModel = new GeneralModel() ;

        const keys1 =  Object.keys(this.tempAddPositionModel);
        const keys2 =  Object.keys(this.addPositionModel);
        for (let key of keys2) {
            this.tempAddPositionModel[key] = this.addPositionModel[key];

        }
        if (this.operationType === 'add') {
            this.addPositionModel = new GeneralModel();
        } else {
            this.getCountryCities(this.addPositionModel.country.id);
        }

        this.getCountries();
        this.getClients();
        this.getCurrencies();
    }

    getCurrencies() {
        this.parameterService.currencies.subscribe((res: CurrencyModel[]) => {
            this.currencies = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCountries() {
        this.parameterService.countries.subscribe((res: CountryModel[]) => {
            this.countries = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCountryCities(countryId) {
        this.cities = [];
        this.parameterService.getCountryCities(countryId).subscribe((res: CityModel[]) => {
            this.cities = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getClients() {
        this.parameterService.getAllClients().subscribe((res) => {
            this.clients = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    selectCountry(country) {
        country.id ? this.addPositionModel.country.id = country.id : this.addPositionModel.country.id = null;
        this.getCountryCities(this.addPositionModel.country.id);
    }

    selectCity(city) {
        city.id ? this.addPositionModel.city.id = city.id : this.addPositionModel.city.id = null;
    }

    selectPositionType(e) {
        this.addPositionModel.jobType = e.target.value;
    }

    selectCurrency(currency) {
        if (!this.addPositionModel.currency){
            this.addPositionModel.currency = new CurrencyModel();
            this.addPositionModel.currency.value = currency.defaultLocalization.name;
            this.addPositionModel.currency.id = currency.id;
        }else{
            this.addPositionModel.currency.id = currency.id;
        }
    }

    selectEducationDegree(e) {
        this.addPositionModel.educationDegree = e.target.value;
    }

    selectClient(e) {
        this.addPositionModel.client = new ClientModel();
        this.addPositionModel.client.id = e.target.value;
    }

    positionOperation(formPosition: NgForm, openingDate, closingDate) {
        this.isSaveClick = true;

        formPosition.controls.openingDate.setValue(openingDate);

        if (!formPosition.valid) {

            this.utilitiesService.validationClassState('validate-me', 'add');
            this.notification.warning(this.translate.transform('fillRequiredFields', []));
            return;
        }

        if (this.operationType === 'add' || this.operationType === 'clone') {
            this.save(openingDate, closingDate);
        } else if (this.operationType === 'edit') {
            this.edit(openingDate, closingDate);
        }

    }

    save(openingDate, closingDate) {

        if (this.operationType === 'clone') {
            this.addPositionModel.id = '';
        }

        this.addPositionModel.openingDate = openingDate;
        this.addPositionModel.closingDate = closingDate;
        this.authService.ServerPost(server.positions, this.addPositionModel)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.cancel();
                this.spinner.hide();
            });
    }
    shallowEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {

            return false;
        }
        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }

        return true;
    }

    edit(openingDate, closingDate) {


        if(this.shallowEqual(this.addPositionModel, this.tempAddPositionModel)){
            this.notification.warning(this.translate.transform('positionIsNotChanged', []));
            return;
        }
        this.addPositionModel.openingDate = openingDate;
        this.addPositionModel.closingDate = closingDate;
        this.authService.ServerPut(server.positions + '/' + this.addPositionModel.id, this.addPositionModel)
            .subscribe(res => {
                this.notification.success(this.translate.transform('editSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.cancel();
                this.spinner.hide();
            });
    }

    cancel() {
        this.cancelPositionOperation.emit();
    }
}
