import {DefaultLocalization} from './default-localization.model';

export class IndustryModel {
    id: string;
    value: string;
    defaultLocalization: DefaultLocalization;

    constructor() {
        this.id = '';
        this.value = '';
        this.defaultLocalization = new DefaultLocalization();
    }
}
