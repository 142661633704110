export class InterviewRequestFilterModel{
    client: string;
    position: string;
    candidate: string;
    status: string;

    constructor(){
        this.client = '';
        this.position = '';
        this.candidate = '';
        this.status = '';
    }

}
