import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NoteModel} from '../note.model';

@Component({
    selector: 'app-delete-note-modal',
    templateUrl: './delete-note-modal.component.html',
    styleUrls: ['./delete-note-modal.component.scss']
})
export class DeleteNoteModalComponent implements OnInit {

    @Input() note: NoteModel;
    @Output() deleteNote = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

    deleteOperation() {
        this.deleteNote.emit();
    }
}
