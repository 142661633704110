import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CountryModel} from '../../../../../services/parameters/models/country.model';
import {CityModel} from '../../../../../services/parameters/models/city.model';
import {server} from '../../../../../services/server';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../services/permission/permission.service';
import {NotificationService} from '../../../../../services/notification/notification.service';
import {CandidateModel} from '../../../candidate.model';
import {WorkingStatusList} from '../../../../../shared/lists/working-status.list';
import {JobSearchStatusList} from '../../../../../shared/lists/job-search-status.list';
import {IndustryModel} from '../../../../../services/parameters/models/industry.model';
import {JobTypeList} from '../../../../../shared/lists/job-type.list';
import {MilitaryServiceList} from '../../../../../shared/lists/military-service.list';
import {MaritalStatusList} from '../../../../../shared/lists/marital-status.list';
import {CurrencyModel} from '../../../../../services/parameters/models/currency.model';
import {GenderList} from '../../../../../shared/lists/gender.list';

@Component({
    selector: 'app-edit-general-info-modal',
    templateUrl: './edit-general-info-modal.component.html',
    styleUrls: ['./edit-general-info-modal.component.scss']
})
export class EditGeneralInfoModalComponent implements OnInit {

    countries: CountryModel[];
    cities: CityModel[];
    currencies: CurrencyModel[];
    industries: IndustryModel[];
    @Input() generalInfo: CandidateModel;
    @Input() candidateId: string;
    workingStatusList = WorkingStatusList;
    jobSearchStatusList = JobSearchStatusList;
    jobTypes = JobTypeList;
    militaryServiceStatusList = MilitaryServiceList;
    maritalStatusList = MaritalStatusList;
    genderList = GenderList;
    tempGeneralInfo: CandidateModel;
    @Output() updatedContent = new EventEmitter();

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
        this.generalInfo = new CandidateModel();
    }

    ngOnInit(): void {
        this.tempGeneralInfo = new CandidateModel();
        this.tempGeneralInfo = {...this.generalInfo};

        this.getCountries();
        this.getIndustries();
        this.getCurrencies();

        if (this.tempGeneralInfo.preferredCountry != null) {
            this.getCountryCities(this.tempGeneralInfo.preferredCountry.id);
        }
    }

    getCountries() {
        this.parameterService.countries.subscribe((res: CountryModel[]) => {
            this.countries = res;
        }, err => {
            if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCountryCities(countryId) {
        this.cities = [];
        this.parameterService.getCountryCities(countryId).subscribe((res: CityModel[]) => {
            this.cities = res;
        }, err => {
            if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getIndustries() {
        this.parameterService.industries.subscribe((res: IndustryModel[]) => {
            this.industries = res;
        }, err => {
            if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    selectCountry(preferredCountry) {
        this.tempGeneralInfo.preferredCountry = new CountryModel();
        if (preferredCountry.id === '') {
            this.tempGeneralInfo.preferredCountry.id = null;
            this.tempGeneralInfo.preferredCountry.value = null;
            this.cities = [];
        } else {
            this.tempGeneralInfo.preferredCountry.id = preferredCountry.id;
            this.tempGeneralInfo.preferredCountry.value = preferredCountry.defaultLocalization.name;

            this.getCountryCities(this.tempGeneralInfo.preferredCountry.id);
        }
    }

    selectCity(preferredCity) {
        this.tempGeneralInfo.preferredCity = new CityModel();
        if (preferredCity.id === '') {
            this.tempGeneralInfo.preferredCity.id = null;
        } else {
            this.tempGeneralInfo.preferredCity.id = preferredCity.id;
        }
    }

    selectIndustry(preferredIndustry) {
        this.tempGeneralInfo.preferredIndustry = new IndustryModel();

        if (preferredIndustry.id === '') {
            this.tempGeneralInfo.preferredIndustry.id = null;
            this.tempGeneralInfo.preferredIndustry.value = null;
        } else {
            this.tempGeneralInfo.preferredIndustry.id = preferredIndustry.id;
            this.tempGeneralInfo.preferredIndustry.value = preferredIndustry.defaultLocalization.name;
        }
    }

    selectJobTYpe(e) {
        if (e.target.value === '') {
            this.tempGeneralInfo.preferredJobType = null;
        } else {
            this.tempGeneralInfo.preferredJobType = e.target.value;
        }
    }

    selectOutsourcePreference(e) {
        if (e.target.value === '') {
            this.tempGeneralInfo.outsourcePreference = null;
        } else {
            this.tempGeneralInfo.outsourcePreference = e.target.value;
        }
    }

    selectMilitaryService(e) {
        if (e.target.value === '') {
            this.tempGeneralInfo.militaryService = null;
        } else {
            this.tempGeneralInfo.militaryService = e.target.value;
        }
    }

    selectDrivingLicence(e) {
        if (e.target.value === '') {
            this.tempGeneralInfo.drivingLicence = null;
        } else {
            this.tempGeneralInfo.drivingLicence = e.target.value;
        }
    }

    selectSmoking(e) {
        if (e.target.value === '') {
            this.tempGeneralInfo.smoking = null;
        } else {
            this.tempGeneralInfo.smoking = e.target.value;
        }
    }

    selectMaritalStatus(e) {
        if (e.target.value === '') {
            this.tempGeneralInfo.maritalStatus = null;
        } else {
            this.tempGeneralInfo.maritalStatus = e.target.value;
        }
    }

    selectGender(e) {
        if (e.target.value === '') {
            this.tempGeneralInfo.gender = null;
        } else {
            this.tempGeneralInfo.gender = e.target.value;
        }
    }

    selectCurrencies(currency) {
        this.tempGeneralInfo.currency = new CurrencyModel();
        if (currency.id === '') {
            this.tempGeneralInfo.currency.id = null;
            this.tempGeneralInfo.currency.value = null;

        } else {
            this.tempGeneralInfo.currency.id = currency.id;
            this.tempGeneralInfo.currency.value = currency.defaultLocalization.name;

        }
    }

    selectNationality(nationality) {
        this.tempGeneralInfo.nationality = new CountryModel();
        if (nationality.id === '') {
            this.tempGeneralInfo.nationality.id = null;
            this.tempGeneralInfo.nationality.value = null;

        } else {
            this.tempGeneralInfo.nationality.id = nationality.id;
            this.tempGeneralInfo.nationality.value = nationality.defaultLocalization.name;

        }
    }

    selectJobSearchStatus(e) {
        if (e.target.value === '') {
            this.tempGeneralInfo.jobSearchStatus = null;
        } else {
            this.tempGeneralInfo.jobSearchStatus = e.target.value;
        }
    }

    selectWorkingStatus(e) {
        if (e.target.value === '') {
            this.tempGeneralInfo.workingStatus = null;
        } else {
            this.tempGeneralInfo.workingStatus = e.target.value;
        }
    }

    getCurrencies() {
        this.parameterService.currencies.subscribe((res: CurrencyModel[]) => {
            this.currencies = res;
        }, err => {
            if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    saveGeneralInfo() {
        this.authService.ServerPut(server.candidates + '/' + this.candidateId + '/general-info', this.tempGeneralInfo)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updateContent();
                this.closeEditGeneralInfoModal();
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#userModal').modal('hide');
                this.spinner.hide();
            });
    }

    updateContent() {
        this.updatedContent.emit();
    }


    closeEditGeneralInfoModal() {
        // @ts-ignore
        $('#editGeneralInfo').modal('hide');
    }

}
