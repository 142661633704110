import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RequestPayload} from '../../requestPayload';
import {PermissionService} from '../../../services/permission/permission.service';
import {HistoryModel} from './history.model';

@Component({
    selector: 'app-history-list',
    templateUrl: './history-list.component.html',
    styleUrls: ['./history-list.component.scss']
})
export class HistoryListComponent implements OnInit {

    @Input() count: number;
    @Input() historyLogs: HistoryModel[];
    activePage: any;
    currentPage: number;
    requestPayload: RequestPayload;
    @Output() updateHistoryLogs = new EventEmitter();

    constructor(public permissionService: PermissionService) {
        this.requestPayload = new RequestPayload();
    }

    ngOnInit(): void {
    }

    pagination(pageNo) {
        this.updateHistoryLogs.emit(pageNo);
    }

}
