<!--User Modal -->
<div aria-hidden="true" aria-labelledby="userModalTitle" class="modal fade" id="editClientUserModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="addUserLongTitle">{{'editClientUser' | translate}}</h5>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <label class="modal-input-label" for="editName">{{'name' | translate}}<span
                            class="text-danger">*</span></label>
                    <input [(ngModel)]="clientUser.firstName"
                           class="form-control border-radius-0"
                           id="editName"
                           name="editName"
                           required="required" type="text" appTitleCaseDirective>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="editMiddleName">{{'middleName' | translate}}</label>
                    <input [(ngModel)]="clientUser.middleName"
                           class="form-control border-radius-0"
                           id="editMiddleName"
                           name="editMiddleName" type="text" appTitleCaseDirective>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="editSurname">{{'surname' | translate}}<span
                            class="text-danger">*</span></label>
                    <input [(ngModel)]="clientUser.lastName"
                           class="form-control border-radius-0"
                           id="editSurname"
                           name="editSurname"
                           required="required" type="text" appTitleCaseDirective>
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="editEmail">{{'email' | translate}}<span
                            class="text-danger">*</span></label>
                    <input [(ngModel)]="clientUser.email"
                           class="form-control border-radius-0"
                           id="editEmail"
                           name="editEmail"
                           required="required" type="email">
                </div>
                <div class="mb-3">
                    <label class="modal-input-label" for="editUserType">{{'userType' | translate}}<span
                            class="text-danger">*</span></label>
                    <div class="dropdown" id="editUserType">
                        <button aria-expanded="false"
                                aria-haspopup="true"
                                class="btn dropdown-toggle border-radius-0 form-control dropdown-button"
                                data-toggle="dropdown"
                                type="button">
                            {{clientUser.role | translate}}
                        </button>
                        <div (click)="getSelectedRole($event)" aria-labelledby="dropdownMenuButton"
                             class="dropdown-menu width-100 color-dropdown border-radius-0">
                            <a *ngFor="let role of clientUserRoles" [id]="role.value"
                               class="dropdown-item color-dropdown-item">{{ role.name| translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">{{'cancel' | translate}}</button>
                <button (click)="editClientUser()" class="btn btn-success border-radius-0"
                        type="button">{{'edit' | translate}}</button>
            </div>
        </div>
    </div>
</div>
