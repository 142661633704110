<div class="table-responsive">
    <table class="table table-bg">
        <thead class="table-bg">
            <tr>
                <th scope="col">&nbsp;</th>
                <th scope="col">{{'name' | translate}}</th>
                <th scope="col">{{'country' | translate}}</th>
                <th scope="col">{{'city' | translate}}</th>
                <th scope="col">{{'positionType' | translate}}</th>
                <th scope="col">{{'opening' | translate}}</th>
                <th scope="col">{{'created' | translate}}</th>
                <th scope="col">{{'client' | translate}}</th>
                <th scope="col">{{'users' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr (click)="openRowModal($event,position)"
                *ngFor="let position of positions | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count }"
                class="position-row">
                <th scope="row">
                    <span (click)="openPositionStatusModal(position)" class="btn-group mr-2" role="group"
                        style="cursor: pointer;" title="{{position.status | translate}}">
                        <p [class]="position.status" class="position-status-color-icon">
                        </p>&nbsp;&nbsp;
                        <p><i class="fa fa-caret-down"></i></p>
                    </span>
                </th>
                <td>{{position.name}}</td>
                <td>{{position.country.value}}</td>
                <td>{{position.city.value}}</td>
                <td>{{position.jobType}}</td>
                <td>{{position.openingDate | date}}</td>
                <td>{{position.createdAt | date}}</td>
                <td>{{position.client?.name || ''}}</td>
                <td>
                    <span (click)="openPositionAddHiringTeamModal(position)" class="mr-2 addHiringTeam"><i style="margin:0px!important"
                            class="fas fa-plus cursor-pointer ml-3"></i></span>
                    <app-hiring-team-users #childHiringTeamUsers [positionId]="position.id"></app-hiring-team-users>
                </td>
            </tr>
            <tr [hidden]="count>0">
                <td colspan="8">
                    <app-empty-table-info tableInfoTranslateKey="positionNotExistInfo"></app-empty-table-info>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr class="width-100">
                <td class="text-center" colspan="7">
                    <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
                        [autoHide]="count <= requestPayload.limit" nextLabel="{{'next' | translate}}"
                        previousLabel="{{'previous' | translate}}" responsive="true"></pagination-controls>
                </td>
            </tr>
        </tfoot>
    </table>
</div>
<app-delete-position-modal (deletePosition)="deletePosition()"
    *ngIf="permissionService.permissionsOnly(['DELETE_POSITION_REQUEST','DELETE_POSITION'])"
    [position]="selectedPosition"></app-delete-position-modal>
<app-position-status-modal *ngIf="permissionService.permissionsOnly(['UPDATE_POSITION_STATUS'])"
    [position]="selectedPosition"></app-position-status-modal>

<ng-container *ngIf="selectedPosition">
    <app-add-to-position (updateTable)="pagination(currentPage)" *ngIf="permissionService.permissionsOnly(['CREATE_HIRING_TEAM_MEMBER'])"
        [positionId]="selectedPosition.id">
    </app-add-to-position>
</ng-container>


<div (clickOutside)="onClickedOutside($event)" class="operation-list" id="row-modal">
    <ul>
        <li (click)="openPositionDetails()" class="operation-list-item">
            <span>{{'showPositionDetails' | translate}}</span>
        </li>
        <li (click)="openEditPosition()" *ngIf="permissionService.permissionsOnly(['UPDATE_POSITION'])"
            class="mt-2 operation-list-item">
            <span>{{'editPosition' | translate}}</span>
        </li>
        <li (click)="openClonePosition()" *ngIf="permissionService.permissionsOnly(['CREATE_POSITION'])"
            class="mt-2 operation-list-item">
            <span>{{'clonePosition' | translate}}</span>
        </li>
        <li (click)="shareWithEmail()" class="mt-2 operation-list-item">
            <span>{{'sharePosition' | translate}}</span>
        </li>
        <li (click)="viewOnPortal()" class="mt-2 operation-list-item">
            <span>{{'viewOnPortal' | translate}}</span>
        </li>
        <li (click)="openDeletePosition()" *ngIf="permissionService.permissionsOnly(['DELETE_POSITION'])"
            class="mt-2 operation-list-item">
            <span>{{'delete' | translate}}</span>
        </li>
    </ul>
</div>