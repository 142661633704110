export class ReportFilterModel {

    position: string;
    client: string;
    user: string;
    added: string;
    hiredAt: string;
    candidate: string;

    constructor() {
        this.position = '';
        this.client = '';
        this.user = '';
        this.added = '';
        this.hiredAt = '';
        this.candidate = '';
    }
}
