<!--Add candidate education Modal -->
<div aria-hidden="true" aria-labelledby="candidateTagOperationTitle" class="modal fade" id="candidateTagOperation"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">
                    {{ "candidateTagOperation" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="tags">{{"tags" | translate}}<span
                                        class="text-danger">*</span></label>
                                <tag-input (onAdd)="handleError($event)" [(ngModel)]="candidateTags" id="tags"
                                           separatorKeys=",">
                                    <tag-input-dropdown [appendToBody]="false"
                                                        [autocompleteItems]="allTags"
                                                        [displayBy]="'display'"
                                                        [dynamicUpdate]="true"
                                                        [identifyBy]="'value'"
                                                        [showDropdownIfEmpty]="true">
                                        <ng-template let-index="index" let-item="item">
                                            {{ item.display }}
                                        </ng-template>
                                    </tag-input-dropdown>
                                </tag-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closeTagModal()" class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="saveTags()" class="btn btn-success border-radius-0" type="button">
                    {{ "save" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
