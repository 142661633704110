<!--Add pool education Modal -->
<div aria-hidden="true" aria-labelledby="photoImport" class="modal fade" id="photoImport"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">
                    {{ "changePhoto" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class='user-img-div mx-auto mt-5 text-center' (click)="onClick()"
                                 style="max-width: 200px; cursor: pointer;">
                                <img [src]="tempUserImg" class="rounded-circle img-thumbnail img-fluid"
                                     style="width: 200px; height: 200px;">
                                <div class="mt-3 mx-auto">
                                    <p class="logo-title">
                                        <i class="fas fa-plus-circle"></i>&nbsp;{{'choosePhoto' | translate}}
                                    </p>
                                    <input #fileUpload (change)="onFileChange($event)" accept="image/*" id="fileUpload"
                                           name="fileUpload"
                                           style="display: none;" type="file">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div (click)="openConfirmInterviewRequest()" style="text-align:center;" class="mt-4 mx-auto">
                    <p class="logo-titleDelete" >
                        {{'deleteProfilePhoto' | translate}}
                    </p>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closePhotoImportModal()" class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="saveImage()" class="btn btn-success border-radius-0" type="button">
                    {{ "save" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
<app-confirm-modal (operationInit)="deleteUserProfilImage()" [confirmData]="confirmData"></app-confirm-modal>
