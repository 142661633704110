import {Component, Input, OnInit} from '@angular/core';
import {server} from '../../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';
import {StepModel} from '../../../candidates/edit-candidate/candidate-profile-operations/candidate-change-step/step.model';
import {RequestPayload} from '../../../../shared/requestPayload';
import {PipelineStepModel} from '../../../../services/parameters/models/pipeline-step.model';

@Component({
    selector: 'app-pipeline',
    templateUrl: './pipeline.component.html',
    styleUrls: ['./pipeline.component.scss']
})
export class PipelineComponent implements OnInit {

    @Input() positionId = '';
    pipelineSteps: StepModel[];
    requestPayload: RequestPayload;
    candidates: PipelineStepModel[];
    count: number;
    selectedStepId: string;
    selectedCandidate: PipelineStepModel;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.requestPayload = new RequestPayload();
        this.count = 0;
    }

    ngOnInit(): void {
        this.getRecruitmentPreferences();
    }

    getRecruitmentPreferences() {
        this.authService.ServerGet(server.pipelineSteps + '?positionId=' + this.positionId)
            .subscribe((res: StepModel[]) => {
                this.pipelineSteps = res;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    openPipelineUserList(step: StepModel, event) {
        if ((event.target.tagName).toUpperCase() === 'DIV') {
            if (!this.selectedStepId || this.selectedStepId !== step.recruitmentStepId) {
                this.count = 0;
                this.candidates = [];
                if (this.selectedStepId) {
                    document.getElementById('stepId_' + this.selectedStepId).style.display = 'none';
                }
                document.getElementById('stepId_' + step.recruitmentStepId).style.display = 'block';
                if (step.candidateCount > 0) {
                    this.selectedStepId = step.recruitmentStepId;
                    this.getStepUsers('1');
                }
            } else {
                if (document.getElementById('stepId_' + this.selectedStepId).style.display === 'none') {
                    document.getElementById('stepId_' + step.recruitmentStepId).style.display = 'block';
                } else {
                    document.getElementById('stepId_' + step.recruitmentStepId).style.display = 'none';
                }
            }

            this.selectedStepId = step.recruitmentStepId;
        }
    }

    getStepUsers(pageNo) {
        this.authService.ServerGet(server.pipelineStepCandidates + this.requestPayload.payloadURL(pageNo) +
            '&positionId=' + this.positionId +
            '&recruitmentStepId=' + this.selectedStepId)
            .subscribe((res: any) => {
                this.candidates = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    openStepNote(candidate) {
        this.selectedCandidate = candidate;
        // @ts-ignore
        $('#pipelineStepNoteModal').modal('show');
    }

    openChangePipelineCandidateStep(candidate) {
        this.selectedCandidate = candidate;
        // @ts-ignore
        $('#changePipelineStepModal').modal('show');
    }

}
