<div class="container-fluid p-0 height-100">
    <div class="row" style="background-color: white;">
        <div class="col-12" style="padding-top: 13px; padding-bottom: 13px;">
            <p class="mb-0">
                <img [src]="tempUserImg" class="rounded-circle img-thumbnail img-fluid user-img">
                <span class="ml-3"><b
                        *ngIf="conversation.participants && conversation.participants.length > 0">{{conversation.participants[0]?.name}}</b></span><span
                    class="float-right d-xl-none d-lg-none d-md-none d-sm-block d-block"><i class="far fa-arrow-alt-circle-left fa-2x text-info cursor-pointer" (click)="goBack()"></i></span>
            </p>
        </div>
    </div>
    <div class="row" style="background-color: whitesmoke; height: 499px;">
        <div class="col-12 pt-3">
            <div class="container-fluid height-100" id="message-content" style="max-height: 460px;overflow-y: auto;">
                <div *ngFor="let message of messages" [ngClass]="myUserId === message.createdBy ? 'float-right':''"
                     class="row message-card-row mb-2">
                    <img *ngIf="myUserId !== message.createdBy" [src]="participantsProfilePictures[message.createdBy]"
                         class="rounded-circle img-thumbnail img-fluid user-img">
                    <div [ngClass]="myUserId === message.createdBy ? 'float-right my-message-box':'participant-message-box'"
                         class="message-card col-12">
                        {{message.content}}
                    </div>
                    <div class="text-right col-12 text-muted" style="font-size: 13px">
                        <small [ngClass]="myUserId !== message.createdBy ? 'float-left ml-5':''">{{message.createdAt | date:'medium'}}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="background-color: white;">
        <div class="col-12">
            <div class="input-group mb-3 mt-3">
                <input (keyup.enter)="sendMessage()" [(ngModel)]="newMessage" aria-describedby="basic-addon2"
                       class="form-control"
                       id="message-input" placeholder="Type your message"
                       style="border-radius: 21px;" type="text">
                <div class="input-group-append">
                    <button (click)="sendMessage()" [disabled]="newMessage.trim() ===''" class="ml-2 cursor-pointer"
                            style="border: none; background: none;" type="button"><i
                            class="fas fa-paper-plane fa-2x" style="color: #0d6b7b;"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>
