export class CareerPortalSettingsModel {

    title: string;
    subtitle: string;
    description: string;
    headerColor: string;
    websiteLink: string;
    twitterLink: string;
    facebookLink: string;
    instagramLink: string;
    linkedinLink: string;

    constructor() {
    }
}
