<!--Edit client education Modal -->
<div aria-hidden="true" aria-labelledby="editClientOperationTitle" class="modal fade mt-5" data-backdrop="static"
     data-keyboard="false" id="editClient" role="dialog" tabindex="-1">
    <form #formClient="ngForm" class="needs-validation">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header gradient-bg">
                    <h5 class="modal-title">
                        {{ "editClient" | translate }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="mb-3 validate-meEdit">
                                    <label class="modal-input-label"
                                           for="editOrganisationName">{{"organisationName" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <input #editOrganisationName [(ngModel)]="tempClient.name"
                                           class="form-control border-radius-0" id="editOrganisationName"
                                           name="editOrganisationName" required type="text" appTitleCaseDirective>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                           for="editOrganisationWebsiteLink">{{'organisationWebsiteLink' | translate}}
                                        <span class="text-danger">*</span></label>

                                    <div class="input-group validate-meEdit">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text login-input-icon-password">
                                                <i class="fas fa-globe-europe"></i>
                                            </span>
                                        </div>
                                        <input #editOrganisationWebsiteLink [(ngModel)]="tempClient.website"
                                               class="form-control border-radius-0" id="editOrganisationWebsiteLink"
                                               name="editOrganisationWebsiteLink"
                                               placeholder="{{'websiteLinkPlaceholder' | translate}}" required
                                               type="text">
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                           for="editOrganisationPhone">{{'organisationPhone' | translate}}<span
                                            class="text-danger">*</span></label>

                                    <div class="input-group validate-meEdit">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text login-input-icon-password">
                                                <i class="fas fa-phone"></i>
                                            </span>
                                        </div>
                                        <input #editOrganisationPhone [(ngModel)]="tempClient.phone"
                                               class="form-control border-radius-0" id="editOrganisationPhone"
                                               name="editOrganisationPhone"
                                               placeholder="{{'phonePlaceholder' | translate}}" required type="text"
                                               maxlength="11"
                                               (keypress)="numericOnly($event)">
                                    </div>
                                </div>
                                <div class="mb-3 ">
                                    <label class="modal-input-label" for="editCountry">{{"country" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <div>
                                        <app-searchable-dropdown (selectResult)="selectCountry($event);cityChild.selectedItem=null;cityChild.isFiltired=false"
                                                                 [items]="countries"
                                                                 [selectedItem]="tempClient.country?.value"
                                                                 id="editCountry"
                                                                 [isSaveClick]="isSaveClick" 
                                                                 isRequired="true"
                                                                 nameInput="country"
                                                                 >
                                        </app-searchable-dropdown>
                                    </div> 

                                </div>
                                <div class="mb-3 ">
                                    <label class="modal-input-label" for="editCity">{{"city" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <div>
                                        <app-searchable-dropdown #cityChild (selectResult)="selectCity($event)"
                                                                                 [items]="cities"
                                                                                 [selectedItem]="tempClient.city?.value" 
                                                                                 id="editCity"
                                                                                 [isSaveClick]="isSaveClick" 
                                                                                 isRequired="true"
                                                                                 nameInput="city">
                                        </app-searchable-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="editAddress">{{"address" | translate}}</label>
                                    <input [(ngModel)]="tempClient.address" class="form-control border-radius-0"
                                           id="editAddress" name="editAddress" type="text">
                                </div>
                                <div class="mb-3  validate-meEdit">
                                    <label class="modal-input-label"
                                           for="editClientGroup">{{"clientGroup" | translate}}</label>
                                    <select #editClientGroup="ngModel" (change)="selectClientGroup($event)"
                                            [(ngModel)]="tempClient.clientGroup" class="custom-select border-radius-0"
                                            id="editClientGroup" name="editClientGroup" required>
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let clientGroup of clientGroupList"
                                                [selected]="clientGroup.value == tempClient.clientGroup"
                                                [value]="clientGroup.value">{{clientGroup.name}}</option>
                                    </select>
                                </div>
                                <div class="mb-3 ">
                                    <label class="modal-input-label" for="editIndustry">{{"industry" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <div>
                                        <app-searchable-dropdown-with-add (selectResult)="selectIndustry($event)"
                                                                          [items]="industries"
                                                                          [selectedItem]="tempClient.industry?.value"
                                                                          fieldName="industry"
                                                                          id="editIndustry"
                                                                          [isSaveClick]="isSaveClick" 
                                                                          isRequired="true"
                                                                          nameInput="industryFEdit"
                                                                          ></app-searchable-dropdown-with-add>
                                    </div>
                                </div>
                                <div class="mb-3 validate-meEdit">
                                    <label class="modal-input-label" for="editService">{{"service" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <select #editService="ngModel" (change)="selectService($event)"
                                            [(ngModel)]="tempClient.service" class="custom-select border-radius-0"
                                            id="editService" name="editService" required>
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let service of serviceList"
                                                [selected]="service.value == tempClient.service"
                                                [value]="service.value">
                                            {{service.name}}</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label class="modal-input-label"
                                           for="editAccountManagers">{{"accountManagers" | translate}}<span
                                            class="text-danger">*</span></label>
                                    <div
                                            [ngClass]="{'invalid':(!tempClient.accountManagers && isSaveClick),'valid':(tempClient.accountManagers && isSaveClick)}">
                                        <app-multiselect-dropdown (selectResult)="selectAccountManager($event)"
                                                                  [dropdownName]="'selectAccountManager'"
                                                                  [existItems]="tempClient?.accountManagers"
                                                                  [isSearchable]="true"
                                                                  [items]="accountManagers"
                                                                  [translateObjectKey]="'fullName'"
                                                                  id="editAccountManagers"></app-multiselect-dropdown>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="cancel()" class="btn btn-warning border-radius-0" data-dismiss="modal"
                            type="button">
                        {{ "cancel" | translate }}
                    </button>
                    <button (click)="save(formClient)" class="btn btn-success border-radius-0" type="button">
                        {{ "save" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
