import { Injectable } from '@angular/core';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  constructor(private spinner: NgxSpinnerService, ) {}

  getPdfDocument(element: any, docName: string, print) {
    this.spinner.show();
    html2canvas(element)
      .then((canvas) => {

        const imgData = canvas.toDataURL('image/png');
        // @ts-ignore

        const pdf = new jsPDF();

        const imgHeight = (canvas.height * 208) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, 208, imgHeight);

        if (print === 'dpwnloadAsPdf'){
          pdf.save(`${docName}_CV.pdf`);
        }else{
          pdf.autoPrint();
          const cvUrl = pdf.output('bloburl');
          const iframe = '<iframe id=\'printf\' width=\'100%\' height=\'100%\' src=\'' + cvUrl + '\'></iframe>';
          const printDiv = document.getElementById('printDiv');
          printDiv.innerHTML = iframe;
          const pdfCV = document.getElementById('iframe') as HTMLIFrameElement;
          pdfCV.contentWindow.print();

        }

      })
      .then(() => {
        this.spinner.hide();
      })
      .catch((e) => {
        this.spinner.hide();
      });
  }

  validationClassState(className, operation){
    const validateGroup = document.getElementsByClassName(className);
    for (let i = 0; i < validateGroup.length; i++) {
      if (operation === 'add') { validateGroup[i].classList.add('was-validated'); }
      if (operation === 'remove') { validateGroup[i].classList.remove('was-validated'); }
    }
  }


  compareHours(hours1,hours2)
  {
      var hours1Array = hours1.split(":");
      var hours2Array = hours2.split(":");
      var date1=new Date();
      var date2=new Date();
      date1.setHours(hours1Array[0]);
      date1.setMinutes(hours1Array[1]);
      date1.setSeconds(hours1Array[2]);

      date2.setHours(hours2Array[0]);
      date2.setMinutes(hours2Array[1]);
      date2.setSeconds(hours2Array[2]);
      if(date1 > date2){
          return true;
      }else{
          return false;
      }
  }

  getDate(){
    var todayDate = new Date();
    var dd = String(todayDate.getDate()).padStart(2, '0');
    var mm = String(todayDate.getMonth() + 1).padStart(2, '0');
    var yyyy = todayDate.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  }

  getHour(){
    var todayDate = new Date();
    var hour = todayDate.getHours();
    var minutes = todayDate.getMinutes();
    var seconds = todayDate.getSeconds();
    return hour + ':' + minutes + ':' + seconds;
  }

}
