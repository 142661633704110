import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RecommendationModel} from '../../recommendation.model';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../../services/permission/permission.service';
import {NotificationService} from '../../../../../../services/notification/notification.service';
import {server} from '../../../../../../services/server';

@Component({
    selector: 'app-delete-candidate-recommendation',
    templateUrl: './delete-candidate-recommendation.component.html',
    styleUrls: ['./delete-candidate-recommendation.component.scss']
})
export class DeleteCandidateRecommendationComponent implements OnInit {

    @Output() setRecommendationScreen = new EventEmitter();
    @Input() candidateId: string;
    @Input() recommendation: RecommendationModel;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
        this.recommendation = new RecommendationModel();
    }

    ngOnInit(): void {
    }

    cancel() {
        // @ts-ignore
        $('#deleteRecommendation').modal('hide');
    }

    save() {
        this.authService.ServerDelete(server.candidates + '/' + this.candidateId + '/recommendations/' + this.recommendation.id)
            .subscribe((res: any) => {
                this.notification.success(this.translate.transform('deleteSuccessful', []));
                this.updateRecommendations();
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.cancel();
                this.spinner.hide();
            });
    }

    updateRecommendations() {
        this.setRecommendationScreen.emit();
    }

}
