import {Component, Input, OnInit} from '@angular/core';
import {server} from '../../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';
import {UserModel} from '../../../user-management/user.model';

@Component({
    selector: 'app-hiring-team-member-icon-container',
    templateUrl: './hiring-team-member-icon-container.component.html',
    styleUrls: ['./hiring-team-member-icon-container.component.scss']
})
export class HiringTeamMemberIconContainerComponent implements OnInit {

    @Input() positionId: string;
    hiringTeamMembers: UserModel[] = [];
    companyId: string;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.getHiringTeamMembers();
    }

    getHiringTeamMembers() {
        this.authService.ServerGet(server.positions + '/' + this.positionId + '/hiring-team-members')
            .subscribe((res: { content: [] }) => {
                this.hiringTeamMembers = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

}
