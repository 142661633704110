import {Component, OnInit} from '@angular/core';
import {GeneralModel} from '../positions/position-operations/general/general.model';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    position: GeneralModel;

    constructor() {
    }

    ngOnInit(): void {
    }

    getPositionCandidate(position) {
        this.position = position;
    }

}
