import {Component, OnInit} from '@angular/core';
import {server} from '../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../services/auth/auth.service';
import {Router} from '@angular/router';
import {TranslatePipe} from '../../pipes/translate/translate.pipe';
import {NotificationService} from '../../services/notification/notification.service';
import {RecruitmentPreferencesModel} from './recruitment-preferences.model';
import {PermissionService} from '../../services/permission/permission.service';

@Component({
    selector: 'app-recruitment-preferences',
    templateUrl: './recruitment-preferences.component.html',
    styleUrls: ['./recruitment-preferences.component.scss']
})
export class RecruitmentPreferencesComponent implements OnInit {
    recruitmentPreferences = [];
    operationType;
    preferences: RecruitmentPreferencesModel;
    storedSteps: { name: string, position: number }[];

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                private translate: TranslatePipe,
                public permissionService: PermissionService,
                private notification: NotificationService) {
        this.preferences = new RecruitmentPreferencesModel();
    }

    ngOnInit(): void {
        this.getRecruitmentPreferences();
    }

    getRecruitmentPreferences() {
        const companyId = this.authService.companyId;
        this.authService.ServerGet(server.companies + '/' + companyId + '/recruitment-steps')
            .subscribe((res: { content: [] }) => {
                this.recruitmentPreferences = res.content;
                this.storedSteps = [...this.recruitmentPreferences];
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    addStepModal() {
        this.operationType = 'add';
        // @ts-ignore
        $('#recruitmentPreferencesModal').modal('show');
    }

    editStepModal(recruitmentPreference) {
        this.preferences = recruitmentPreference;
        this.operationType = 'edit';
        // @ts-ignore
        $('#recruitmentPreferencesModal').modal('show');
    }

    deleteStepModal(recruitmentPreference) {
        this.preferences = recruitmentPreference;
        this.operationType = 'delete';
        // @ts-ignore
        $('#recruitmentPreferencesModal').modal('show');
    }

    addNewStep(step) {
        this.preferences = new RecruitmentPreferencesModel();
        this.preferences = step;
        this.preferences.position = this.recruitmentPreferences.length;
        this.recruitmentPreferences.push(this.preferences);
    }

    deleteStep(step) {
        this.preferences = new RecruitmentPreferencesModel();
        this.preferences = step;
        const index = this.recruitmentPreferences.indexOf(this.preferences);
        if (index > -1) {
            this.recruitmentPreferences.splice(index, 1);
        }
    }

    saveRecruitmentPreferences() {
        this.setNewPositions();
        const companyId = this.authService.companyId;
        this.authService.ServerPut(server.companies + '/' + companyId + '/recruitment-steps', this.recruitmentPreferences)
            .subscribe(res => {
                this.storedSteps = [];
                this.storedSteps = [...this.recruitmentPreferences];
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#recruitmentPreferencesModal').modal('hide');
                this.spinner.hide();
            });
    }

    cancelRecruitmentPreferences() {
        this.recruitmentPreferences = [...this.storedSteps];
    }

    setNewPositions() {
        for (let i = 0; i < this.recruitmentPreferences.length; i++) {
            this.recruitmentPreferences[i].position = i;
        }
    }
}
