import {Component, Input, OnInit} from '@angular/core';
import {Items} from '../../../../shared/appItems';
import {ConversationModel} from './conversation.model';

@Component({
    selector: 'app-message-row',
    templateUrl: './message-row.component.html',
    styleUrls: ['./message-row.component.scss']
})
export class MessageRowComponent implements OnInit {

    @Input() conversation: ConversationModel;
    tempUserImg: string;

    constructor() {
        this.conversation = new ConversationModel();
        this.tempUserImg = Items.tempUserImg;
    }

    ngOnInit(): void {
    }

}
