import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../../services/permission/permission.service';
import {NotificationService} from '../../../../../../services/notification/notification.service';
import {server} from '../../../../../../services/server';

@Component({
    selector: 'app-soft-skill-operations',
    templateUrl: './soft-skill-operations.component.html',
    styleUrls: ['./soft-skill-operations.component.scss']
})
export class SoftSkillOperationsComponent implements OnInit {

    @Output() updateSoftSkills = new EventEmitter();
    @Input() candidateId: string;
    @Input() softSkills: string[] ;
    skillsObj = [];

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
    }

    saveSoftSkills() {
        this.skillsObj = [];
        const object1 = Object.assign({}, this.softSkills);
        for (const [key, value] of Object.entries(object1)) {
            // @ts-ignore
            if (value.value) {
                // @ts-ignore
                this.skillsObj.push({name: value.value.trim()});
            } else {
                // @ts-ignore
                this.skillsObj.push({name: value.trim()});
            }
        }
        this.authService.ServerPut(server.candidates + '/' + this.candidateId + '/soft-skills', this.skillsObj)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updateSoftSkills.emit();
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.closeSoftSkillModal();
                this.spinner.hide();
            });
    }

    closeSoftSkillModal() {
        // @ts-ignore
        $('#candidateSoftSkillOperation').modal('hide');
    }

}
