import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {InterviewModel} from '../../../../services/parameters/models/interview.model';
import {RequestPayload} from '../../../../shared/requestPayload';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';
import {CalendarEvent} from 'angular-calendar';


@Component({
    selector: 'app-upcoming-event-list',
    templateUrl: './upcoming-event-list.component.html',
    styleUrls: ['./upcoming-event-list.component.scss']
})

export class UpcomingEventListComponent implements OnInit {

    interviews: InterviewModel[];
    count: number;
    activePage: any;
    currentPage: number;
    requestPayload: RequestPayload;
    event: CalendarEvent;
    @Output() setEventCount = new EventEmitter();

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.requestPayload = new RequestPayload();
    }

    ngOnInit(): void {
        this.getInterviews(1);
    }
    addZero(i) {
        if (i < 10) {
            i = '0' + i;
        }
        return i;
    }
    getInterviews(pageNo): void {
        const dateobj = new Date();
        const date = (dateobj).toISOString().split('T')[0];
        const hour = this.addZero(dateobj.getHours()) + ':' + this.addZero(dateobj.getMinutes()) + ':' + this.addZero(dateobj.getSeconds());
        this.authService.ServerGet(server.interviews + this.requestPayload.payloadURL(pageNo) + '&filter=date>:' + date)
            .subscribe((res: any) => {
                this.interviews = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
                this.setEventCount.emit(this.count);
            });
    }

    operationDialogController(event) {
        this.event = event.interview;
        if (event.type === 'edit') {
            this.openEditInterview();
        } else if (event.type === 'delete') {
            this.openDeleteInterview();
        } else if (event.type === 'view') {
            this.openViewInterview();
        }
    }

    openDeleteInterview() {
        // @ts-ignore
        $('#deleteInterviewModal').modal('show');
    }

    openEditInterview() {
        // @ts-ignore
        $('#editInterviewModal').modal('show');
    }

    openViewInterview() {
        // @ts-ignore
        $('#calendarInterviewDetailsModal').modal('show');
    }

}
