<!-- Modal -->
<div aria-hidden="true" aria-labelledby="positionModalCenterTitle" class="modal fade"
     id="candidatePositionModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="container-fluid">
                <div class="row">
                    <div (click)="setTab('candidatePositions')" [ngClass]="{'gradient-bg':!detailsTab,
                         'top-line':detailsTab}"
                         class="menu-item col">
                        <a class="nav-link active">{{'candidatePositions' | translate}}</a>
                    </div>
                    <div (click)="setTab('addToCandidate')" [ngClass]="{'gradient-bg':!addTab,
                         'top-line':addTab}"
                         class="menu-item col">
                        <a class="nav-link">{{'addToCandidate' | translate}}</a>
                    </div>
                </div>
            </div>
                <div class="modal-body">
                    <div *ngIf="detailsTab" class="container-fluid">
                        <div *ngIf="pipelineSteps && pipelineSteps.length>0" class="row">
                            <div (click)="openRemovePositionModal(pipelineStep)"
                                 *ngFor="let pipelineStep of pipelineSteps"
                                 class="col-auto mr-2 mt-2 cursor-pointer"
                                 style="padding:7px 14px; border: 1px solid #efefef; background-color: #efefef; border-radius: 21px; color: #444;">
                                <span>{{pipelineStep.positionName}}</span>
                                <span class="ml-2">
                                    <i class="fas fa-times"></i>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="candidatePositions && candidatePositions.length<=0" class="row">
                            Any position assigned yet!
                        </div>
                    </div>
                    <div *ngIf="addTab" class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="modal-input-label" for="positionDrp">{{'position' | translate}}</label>
                                    <select (change)="selectPosition($event)" class="custom-select border-radius-0"
                                            id="positionDrp">
                                        <option selected value="">{{'unspecified' | translate}}</option>
                                        <option *ngFor="let position of positions"
                                                [value]="position.id">{{position.name | translate}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="closeChangePositionModal()" class="btn btn-warning border-radius-0"
                            data-dismiss="modal"
                            type="button">{{'cancel' | translate}}</button>
                    <button (click)="addPosition()" *ngIf="addTab" class="btn btn-success border-radius-0"
                            type="button">{{'save' | translate}}</button>
                </div>
        </div>
    </div>
</div>
<app-remove-candidate-position (updateCandidatePositions)="getCandidatePositions()"
                           [candidateId]="candidateId" [position]="selectedPosition"></app-remove-candidate-position>
