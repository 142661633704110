import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserModel} from '../../../../../user-management/user.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../../../services/notification/notification.service';
import {server} from '../../../../../../services/server';

@Component({
    selector: 'app-delete-client-user',
    templateUrl: './delete-client-user.component.html',
    styleUrls: ['./delete-client-user.component.scss']
})
export class DeleteClientUserComponent implements OnInit {

    @Input() clientId: string;
    @Output() emitUpdateTable = new EventEmitter();
    @Input() clientUser: UserModel;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.clientUser = new UserModel();
    }

    deleteClientUser() {
        this.authService.ServerDelete(server.users + '/' + this.clientUser.id)
            .subscribe(res => {
                this.notification.success(this.translate.transform('deleteSuccessful', []));
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.updateTable();
                this.closeModal();
                this.spinner.hide();
            });
    }

    updateTable() {
        this.emitUpdateTable.emit();
    }

    closeModal() {
        // @ts-ignore
        $('#deleteClientUserModal').modal('hide');
    }

}
