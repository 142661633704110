import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CandidateModel} from '../../../../candidates/candidate.model';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../services/notification/notification.service';
import {server} from '../../../../../services/server';

@Component({
    selector: 'app-remove-from-position-modal',
    templateUrl: './remove-from-position-modal.component.html',
    styleUrls: ['./remove-from-position-modal.component.scss']
})
export class RemoveFromPositionModalComponent implements OnInit {

    @Input() positionId: string;
    @Input() candidate: CandidateModel;
    @Output() updateCandidates = new EventEmitter();

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.candidate = new CandidateModel();
    }

    removeFromPosition() {
        this.authService.ServerDelete(server.pipelineStepCandidates +
            '?positionId=' + this.positionId +
            '&candidateId=' + this.candidate.id)
            .subscribe(res => {
                this.notification.success(this.translate.transform('deleteSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.updateCandidates.emit();
                this.closeRemoveFromPositionModal();
                this.spinner.hide();
            });
    }

    closeRemoveFromPositionModal() {
        // @ts-ignore
        $('#removeFromPosition').modal('hide');
    }

}
