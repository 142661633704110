<!-- Modal -->
<div aria-hidden="true" aria-labelledby="candidateStatusModalCenterTitle" class="modal fade"
     id="candidateStatusModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="candidateStatusModalLongTitle">{{'candidateStatus' | translate}}</h5>
            </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label class="modal-input-label" for="candidateStatus">{{'candidateStatus' | translate}}</label>
                        <select (change)="selectCandidateStatus($event)" class="custom-select border-radius-0"
                                id="candidateStatus">
                            <option *ngFor="let candidateStatus of candidateStatusList"
                                    [selected]="candidateStatus.value === candidate.status"
                                    [value]="candidateStatus.value">{{candidateStatus.name | translate}}</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-warning border-radius-0" data-dismiss="modal"
                            type="button">{{'cancel' | translate}}</button>
                    <button (click)="updateStatus()" class="btn btn-success border-radius-0"
                            type="button">{{'save' | translate}}</button>
                </div>
        </div>
    </div>
</div>
