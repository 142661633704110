import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-pools',
    templateUrl: './pools.component.html',
    styleUrls: ['./pools.component.scss']
})
export class PoolsComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
