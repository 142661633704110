import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth/auth.service';
import {ParameterService} from '../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../services/permission/permission.service';
import {NotificationService} from '../../../services/notification/notification.service';
import {UserModel} from '../../user-management/user.model';
import {server} from '../../../services/server';
import {InterviewModel} from '../../../services/parameters/models/interview.model';

@Component({
    selector: 'app-add-interview-modal',
    templateUrl: './add-interview-modal.component.html',
    styleUrls: ['./add-interview-modal.component.scss']
})
export class AddInterviewModalComponent implements OnInit {

    @Input() InterviewInfo: any;
    interviewers: UserModel[];
    minSearchValueCount: number;
    selectedUser: UserModel;
    scheduleInterviewData: InterviewModel;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
        this.interviewers = [];
        this.scheduleInterviewData = new InterviewModel();
    }

    ngOnInit(): void {
        // @ts-ignore
        $('.datepicker').datepicker({
            todayHighlight: true,
            autoclose: true,
            format: 'yyyy-mm-dd',
            startDate: new Date(),
            language: this.authService.getSessionLanguage(),
        });
        this.getInterviewers();
    }

    getInterviewers() {
        const companyId = this.authService.companyId;
        this.authService.ServerGet(server.users + '/' + '?filter=companyId::' +companyId)
            .subscribe((res: { content: [] }) => {
                this.interviewers = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    selectInterviewers(users) {
        this.scheduleInterviewData.attendees = users;
    }

    scheduleInterview(date, startTime, endTime) {
        this.scheduleInterviewData.date = date;
        this.scheduleInterviewData.startTime = startTime;
        this.scheduleInterviewData.endTime = endTime;
        this.scheduleInterviewData.candidate.id = this.InterviewInfo.candidateId;
        this.scheduleInterviewData.position.id = this.InterviewInfo.positionId;
        this.scheduleInterviewData.recruitmentStep.id = this.InterviewInfo.recruitmentStepId;

        this.authService.ServerPost(server.interviews, this.scheduleInterviewData)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                // @ts-ignore
                $('#addInterviewModal').modal('hide');
                this.spinner.hide();
            });
    }

}
