<div class="container-fluid">
    <div class="row mt-5 mb-5">
        <div *ngIf="permissionService.permissionsOnly(['CREATE_COMPANY_USER','CREATE_USER'])" class="col-12">
            <button (click)="addUserModal()"
                    class="btn btn-blue border-radius-0 float-right exactalent-button col-xl-auto col-lg-auto col-md-auto col-sm-12"
                    data-target="#addUserCenter"
                    data-toggle="modal">
                {{'addUser' | translate}}
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-bg">
                    <tbody>
                    <tr *ngFor="let user of users | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count }">
                        <td>
                            <i class="far fa-user-circle fa-2x user-icon-color"></i>
                            <span class="ml-2 vertical-align-super user-name light-black">
                                {{user.fullName}}
                        </span>
                        </td>
                        <td>
                            <i class="far fa-envelope vertical-align-sub gray-text"></i>
                            <span class="ml-2 vertical-align-sub gray-text">
                                {{user.email}}
                        </span>
                        </td>
                        <td>
                        <span [class]="user.status === 'ACTIVE' ? 'text-primary':(user.status === 'PASSIVE'? 'text-secondary':'text-danger')"
                              class="vertical-align-sub">
                            {{user.status}}
                        </span>
                        </td>
                        <td>
                        <span class="vertical-align-sub">
                            {{user.role}}
                        </span>
                        </td>
                        <td>
                        <span *ngIf="user.status !== 'DELETED'">
                            <span *ngIf="permissionService.permissionsOnly(['UPDATE_USER','UPDATE_COMPANY_USER'])">
                                <span (click)="editUserModal(user)"
                                      class="ml-4 vertical-align-sub cursor-pointer text-success">
                                    <i class="fas fa-pen"></i>
                                </span>
                            </span>
                            <span *ngIf="permissionService.permissionsOnly(['DELETE_USER','DELETE_COMPANY_USER'])">
                                <span (click)="deleteUserModal(user)" *ngIf="user.id !== myUserId"
                                      class="ml-4 vertical-align-sub cursor-pointer text-danger">
                                     <i class="fas fa-times"></i>
                                </span>
                            </span>
                        </span>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="width-100">
                        <td class="text-center" colspan="5">
                            <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
                                                 [autoHide]="count <= requestPayload.limit"
                                                 nextLabel="{{'next' | translate}}"
                                                 previousLabel="{{'previous' | translate}}"
                                                 responsive="true"></pagination-controls>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
<app-user-management-modal
        (updateUsers)="getUsers(1)"
        [operationType]="operationType"
        [user]="user"
></app-user-management-modal>
