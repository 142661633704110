import {Component, OnInit} from '@angular/core';
import {PasswordResetModel} from './password-reset.model';
import {server} from '../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../services/auth/auth.service';
import {Router} from '@angular/router';
import {TranslatePipe} from '../../pipes/translate/translate.pipe';
import {NotificationService} from '../../services/notification/notification.service';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

    passwordResetUser: PasswordResetModel;
    url: any;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.passwordResetUser = new PasswordResetModel();
    }

    ngOnInit(): void {
        this.checkUrlIsValid();
    }

    passwordReset() {
        this.authService.ServerPostWithoutLogin(server.me + server.passwordReset, this.passwordResetUser)
            .subscribe(res => {
                this.notification.success(this.translate.transform('resetPasswordSuccess', []));
                this.router.navigate(['/']);
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.spinner.hide();
            });

    }

    checkUrlIsValid() {
        const url = document.location.search;
        this.url = new URLSearchParams(url.substring(1));
        this.passwordResetUser.search = url;
        this.passwordResetUser.email = this.url.get('email');
        this.passwordResetUser.resetCode = this.url.get('resetCode');

        this.authService.ServerGetWithoutLogin(server.passwordRequest + this.passwordResetUser.search)
            .subscribe((res: any) => {
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(this.translate.transform('resetUrlNotValid', []));
                    this.spinner.hide();
                    this.router.navigate(['/']);
                }
            }, () => {
                this.spinner.hide();
            });

    }
}
