<!--User Modal -->
<div aria-hidden="true" aria-labelledby="userModalTitle" class="modal fade" id="userModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="addUserLongTitle">{{operationType | translate}}&nbsp;{{'user' | translate}}</h5>
            </div>
            <div class="modal-body">
                    <div class="mb-3">
                        <label class="modal-input-label" for="name">{{'name' | translate}}</label>
                        <input [(ngModel)]="tempUser.firstName"
                               [disabled]="isDelete"
                               class="form-control border-radius-0"
                               id="name"
                               name="name"
                               required="required" type="text">
                    </div>
                    <div class="mb-3">
                        <label class="modal-input-label" for="middleName">{{'middleName' | translate}}</label>
                        <input [(ngModel)]="tempUser.middleName"
                               [disabled]="isDelete"

                               class="form-control border-radius-0"
                               id="middleName"
                               name="middleName" type="text">
                    </div>
                    <div class="mb-3">
                        <label class="modal-input-label" for="surname">{{'surname' | translate}}</label>
                        <input [(ngModel)]="tempUser.lastName"
                               [disabled]="isDelete"
                               class="form-control border-radius-0"
                               id="surname"
                               name="surname"
                               required="required" type="text">
                    </div>
                    <div class="mb-3">
                        <label class="modal-input-label" for="email">{{'email' | translate}}</label>
                        <input [(ngModel)]="tempUser.email"
                               [disabled]="isDelete"
                               class="form-control border-radius-0"
                               id="email"
                               name="email"
                               required="required" type="email">
                    </div>
                    <div class="mb-3">
                        <label class="modal-input-label" for="userType">{{'userType' | translate}}</label>
                        <div class="dropdown" id="userType">
                            <button [disabled]="isDelete || (user.id === myUserId)"
                                    aria-expanded="false"
                                    aria-haspopup="true"
                                    class="btn dropdown-toggle border-radius-0 form-control dropdown-button"
                                    data-toggle="dropdown"
                                    type="button">
                                {{tempUser.role | translate}}
                            </button>
                            <div (click)="getSelectedRole($event)" aria-labelledby="dropdownMenuButton"
                                 class="dropdown-menu width-100 color-dropdown border-radius-0">
                                <a class="dropdown-item color-dropdown-item">{{ 'HR_MANAGER'| translate}}</a>
                                <a class="dropdown-item color-dropdown-item">{{ 'RECRUITER'| translate}}</a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isEdit" class="mb-3">
                        <div class="row">
                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                <label class="modal-input-label" for="status">{{'status' | translate}}</label>
                                <div class="user-status-area" id="status">
                                    <label>{{'active' | translate}}</label>
                                    <div class="float-right">
                                        <label class="radio-inline radio mr-4">
                                            <input (click)="changeStatus($event)"
                                                   [checked]="tempUser.status ==='ACTIVE'"
                                                   [disabled]="user.id === myUserId"
                                                   name="optradio"
                                                   type="radio" value="ACTIVE">
                                            {{'yes' | translate}}
                                        </label>
                                        <label class="radio-inline radio">
                                            <input (click)="changeStatus($event)"
                                                   [checked]="tempUser.status !=='ACTIVE'"
                                                   [disabled]="user.id === myUserId"
                                                   name="optradio"
                                                   type="radio" value="PASSIVE">
                                            {{'no' | translate}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <label class="modal-input-label">&nbsp;</label><br>
                                <button (click)="resetPasswordRequest()"
                                        class="btn btn-danger border-radius-0 width-100 reset-button"
                                        type="button">{{'resetPassword' | translate}}</button>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">{{'cancel' | translate}}</button>
                <button (click)="userOperation()" class="btn btn-danger border-radius-0"
                        type="button">{{'delete' | translate}}</button>
            </div>
        </div>
    </div>
</div>
