import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CountryModel} from '../../../../services/parameters/models/country.model';
import {CityModel} from '../../../../services/parameters/models/city.model';
import {SourceList} from '../../../../shared/lists/source.list';
import {PermissionService} from '../../../../services/permission/permission.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../../../services/notification/notification.service';
import {IndustryModel} from '../../../../services/parameters/models/industry.model';
import {ClientFilterModel} from '../../client.model';
import {StatusList} from '../../../../shared/lists/status.list';
import {ClientGroupList} from '../../../../shared/lists/client-group.list';
import {ServiceList} from '../../../../shared/lists/service.list';

@Component({
    selector: 'app-client-list-filter',
    templateUrl: './client-list-filter.component.html',
    styleUrls: ['./client-list-filter.component.scss']
})
export class ClientListFilterComponent implements OnInit {

    countries: CountryModel[];
    cities: CityModel[] = [];
    sources = SourceList;
    industries: IndustryModel[] = [];

    statusList = StatusList;
    clientGroups = ClientGroupList;
    serviceList = ServiceList;
    filterState: boolean;

    positionFilter: ClientFilterModel;
    filterData: ClientFilterModel;
    @Output() applyFilterOperation = new EventEmitter();

    constructor(public permissionService: PermissionService, private authService: AuthService,
                private spinner: NgxSpinnerService, private parameterService: ParameterService, private route: ActivatedRoute,
                private notification: NotificationService) {
        this.positionFilter = new ClientFilterModel();
    }

    ngOnInit(): void {
        this.getCountries();
        this.getIndustries();
    }

    getCountries() {
        this.parameterService.countries.subscribe((res: CountryModel[]) => {
            this.countries = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getIndustries() {
        this.parameterService.industries.subscribe((res: IndustryModel[]) => {
            this.industries = res;
        }, err => {
            if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCountryCities(countryId) {
        this.cities = [];
        this.parameterService.getCountryCities(countryId).subscribe((res: CityModel[]) => {
            this.cities = res;
        }, err => {
            if (err.status >= 400 || err.status < 500) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    selectStatus(selectedStatusList) {
        this.positionFilter.status = '';
        for (const selectedStatus of selectedStatusList) {
            if (this.positionFilter.status === '') {
                this.positionFilter.status = (selectedStatus).value;
            } else {
                this.positionFilter.status = this.positionFilter.status + ',' + selectedStatus.value;
            }
        }
    }

    selectCountry(e) {
        if (e.target.value !== 'all') {
            this.positionFilter.country = e.target.value;
            this.getCountryCities(this.positionFilter.country);
        } else {
            this.positionFilter.country = '';
        }
    }

    selectCity(selectedCityList) {
        this.positionFilter.city = '';
        for (const selectedCity of selectedCityList) {
            if (this.positionFilter.city === '') {
                this.positionFilter.city = selectedCity.id;
            } else {
                this.positionFilter.city = this.positionFilter.city + ',' + selectedCity.id;
            }
        }
    }

    selectIndustry(selectedIndustryList) {
        this.positionFilter.industry = '';
        for (const selectedIndustry of selectedIndustryList) {
            if (this.positionFilter.industry === '') {
                this.positionFilter.industry = selectedIndustry.id;
            } else {
                this.positionFilter.industry = this.positionFilter.industry + ',' + selectedIndustry.id;
            }
        }
    }

    selectClientGroup(selectedClientGroupList) {
        this.positionFilter.clientGroup = '';
        for (const selectedClientGroup of selectedClientGroupList) {
            if (this.positionFilter.clientGroup === '') {
                this.positionFilter.clientGroup = selectedClientGroup.value;
            } else {
                this.positionFilter.clientGroup = this.positionFilter.clientGroup + ',' + selectedClientGroup.value;
            }
        }
    }

    selectService(selectedServiceList) {
        this.positionFilter.service = '';
        for (const selectedService of selectedServiceList) {
            if (this.positionFilter.service === '') {
                this.positionFilter.service = selectedService.value;
            } else {
                this.positionFilter.service = this.positionFilter.service + ',' + selectedService.value;
            }
        }
    }

    closeFilterPopup(e) {
        if (e.target.id !== 'filterButton') {
            this.filterState = false;
        }
    }


    applyFilter() {
        if (JSON.stringify(this.filterData) === JSON.stringify(this.positionFilter)) {
            return false;
        }
        this.filterData = new ClientFilterModel();
        this.filterData = {...this.positionFilter};
        this.filterState = false;
        this.applyFilterOperation.emit(this.filterData);
    }

}
