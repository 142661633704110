import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TagModel} from '../../../../../services/parameters/models/tag.model';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../services/permission/permission.service';
import {NotificationService} from '../../../../../services/notification/notification.service';
import {server} from '../../../../../services/server';

@Component({
    selector: 'app-candidate-update-tags',
    templateUrl: './candidate-update-tags.component.html',
    styleUrls: ['./candidate-update-tags.component.scss']
})
export class CandidateUpdateTagsComponent implements OnInit {

    @Input() candidateTags: [];
    @Input() candidateId: string;
    @Output() updateTags = new EventEmitter();
    tags: TagModel[];
    allTags = [];
    tagObj = [];

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.getTags();
    }

    saveTags() {
        this.setResponseObject();
        this.authService.ServerPut(server.candidates + '/' + this.candidateId + '/tags', this.tagObj)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updateTags.emit();
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.closeTagModal();
                this.spinner.hide();
            });
    }

    handleError(e) {
    }

    setResponseObject() {
        this.tagObj = [];
        if (this.candidateTags.length) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.candidateTags.length; i++) {
                // @ts-ignore
                if (typeof this.candidateTags[i].value === 'number') {
                    this.tagObj.push({
                        // @ts-ignore
                        name: this.candidateTags[i].display.trim(),
                        // @ts-ignore
                        id: this.candidateTags[i].value
                    });
                } else {
                    this.tagObj.push({
                        // @ts-ignore
                        name: this.candidateTags[i].display.trim()
                    });
                }
            }
        }
    }

    getTags() {
        this.authService.ServerGet(server.tags)
            .subscribe((res: any) => {
                this.tags = res.content;
                if (this.tags.length > 0) {
                    for (let i = 0; i < this.tags.length; i++) {
                        this.allTags.push({
                            display: this.tags[i].name.trim(),
                            value: this.tags[i].id
                        });
                    }
                }
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    closeTagModal() {
        // @ts-ignore
        $('#candidateTagOperation').modal('hide');
    }

}
