<!-- Modal -->
<div aria-hidden="true" aria-labelledby="removeTeamMemberModalCenterTitle" class="modal fade"
     id="removeTeamMemberModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="removeTeamMemberModalLongTitle">{{'removeTeamMember' | translate}}</h5>
            </div> 
                <div class="modal-body">
                    <div class="mb-3">
                        <label class="modal-input-label" for="fullName">{{'fullName' | translate}}</label>
                        <input class="form-control border-radius-0"
                               disabled
                               id="fullName"
                               name="fullName"
                               required="required"
                               type="text" value="{{hiringTeamMember?.firstName}}&nbsp;{{hiringTeamMember?.lastName}}">
                    </div>
                    <div class="mb-3">
                        <label class="modal-input-label" for="email">{{'email' | translate}}</label>
                        <input [value]="hiringTeamMember?.email"
                               class="form-control border-radius-0"
                               disabled
                               id="email"
                               name="email"
                               required="required" type="text">
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-warning border-radius-0" data-dismiss="modal"
                            type="button">{{'cancel' | translate}}</button>
                    <button (click)="removeUser(positionId,hiringTeamMember)" class="btn btn-success border-radius-0"
                            type="button">{{'remove' | translate}}</button>
                </div>
        </div>
    </div>
</div>
