import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CandidateModel} from '../candidate.model';
import {server} from '../../../services/server';
import {AuthService} from '../../../services/auth/auth.service';
import {ParameterService} from '../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../services/permission/permission.service';
import {NotificationService} from '../../../services/notification/notification.service';
import {CountryModel} from '../../../services/parameters/models/country.model';
import {CityModel} from '../../../services/parameters/models/city.model';
import { NgForm } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
    selector: 'app-add-candidate',
    templateUrl: './add-candidate.component.html',
    styleUrls: ['./add-candidate.component.scss']
})
export class AddCandidateComponent implements OnInit {

    positions: any;
    candidate: CandidateModel;
    pools: any;
    addManuallyState: boolean;
    importCandidateState: boolean;
    countries: CountryModel[];
    cities: CityModel[];
    @Output() setCandidateScreen = new EventEmitter();

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService,
                private utilitiesService: UtilitiesService) {
        this.addManuallyState = false;
        this.importCandidateState = false;
        this.candidate = new CandidateModel();
    }

    ngOnInit(): void {
        this.getCountries();
        // @ts-ignore
        $('.datepicker').datepicker({
            todayHighlight: true,
            autoclose: true,
            format: 'yyyy-mm-dd',
            language: this.authService.getSessionLanguage(),
        });
    }

   /* selectPosition(e): void {

    }

    selectPool(e): void {

    }*/

    selectCountry(e) {
        this.candidate.country = new CountryModel();
        if (e.target.value === '') {
            this.candidate.country.id = null;
            this.cities = [];
        } else {
            this.candidate.country.id = e.target.value;
            this.getCountryCities(this.candidate.country.id);
        }
    }

    selectCity(e) {
        this.candidate.city = new CityModel();
        if (e.target.value === '') {
            this.candidate.city.id = null;
        } else {
            this.candidate.city.id = e.target.value;
        }
    }

    getCountries() {
        this.parameterService.countries.subscribe((res: CountryModel[]) => {
            this.countries = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    getCountryCities(countryId) {
        this.cities = [];
        this.parameterService.getCountryCities(countryId).subscribe((res: CityModel[]) => {
            this.cities = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }

    save(formAddCandidate: NgForm) {
         // validation
        if (!formAddCandidate.valid ) {
            this.utilitiesService.validationClassState('validate-me', 'add');
            this.notification.warning(this.translate.transform('fillRequiredFields', []));
            if (!formAddCandidate.controls.secondaryEmail.valid){
                const secondaryEmail = document.getElementById('secondaryEmail');
                secondaryEmail.classList.add('is-invalid');
            }
            return;
          }

        this.authService.ServerPost(server.candidates, this.candidate)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.goCandidates();
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.spinner.hide();
            });
    }

    openAddManually() {
        this.addManuallyState = true;
    }

    importCandidateModal() {
        this.importCandidateState = true;
    }

    goCandidates() {
        this.setCandidateScreen.emit();
    }

    secondaryEmailValidaiton(){
        const secondaryEmail = document.getElementById('secondaryEmail');
        secondaryEmail.classList.remove('is-invalid');
    }

}
