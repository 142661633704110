<app-header></app-header>
<ng-sidebar-container>
    <ng-sidebar [(opened)]="sidebarIsOpen" [dock]="sidebarOpen" dockedSize="67" mode="push">
        <div class="app-sidebar-container">
            <app-sidebar [opened]="sidebarIsOpen"></app-sidebar>
        </div>
    </ng-sidebar>
    <div [style.margin-left]="sidebarIsOpen ? '0':'-17px'" ng-sidebar-content style="height: 100%;">
        <button (click)="_toggleSidebar()" class="demo-header__toggle" hidden id="toggle_sidebar_button"></button>
        <br><br><br>
        <router-outlet></router-outlet>
    </div>
</ng-sidebar-container>
<ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        color="#339CFF"
        size="default"
        type="ball-spin-clockwise">
</ngx-spinner>
