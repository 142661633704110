import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PositionFilterModel} from '../../../positions/position.model';
import {RequestPayload} from '../../../../shared/requestPayload';
import {PermissionService} from '../../../../services/permission/permission.service';
import {InterviewModel} from '../../../../services/parameters/models/interview.model';

@Component({
    selector: 'app-calendar-interview-user-list',
    templateUrl: './calendar-interview-user-list.component.html',
    styleUrls: ['./calendar-interview-user-list.component.scss']
})
export class CalendarInterviewUserListComponent implements OnInit {

    @Input() count: number;
    activePage: any;
    @Input() filterData: PositionFilterModel;
    currentPage: number;
    requestPayload: RequestPayload;
    @Input() interviews: InterviewModel[];
    @Output() updateCandidates = new EventEmitter();
    @Output() openPipelineCandidateStepNote = new EventEmitter();
    @Output() openPipelineCandidateStepChange = new EventEmitter();

    constructor(public permissionService: PermissionService) {
        this.requestPayload = new RequestPayload();
    }

    ngOnInit(): void {
    }

    pagination(pageNo) {
        this.updateCandidates.emit(pageNo);
    }

}
