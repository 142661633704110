import { Component, OnInit, EventEmitter, Output, Input, ElementRef, ViewChild } from '@angular/core';
import { CompanyRegisterModel } from './company-register-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Items } from 'src/app/shared/appItems';
import { ParameterService } from 'src/app/services/parameters/parameter.service';
import { CountryModel } from 'src/app/services/parameters/models/country.model';
import { NotificationService } from 'src/app/services/notification/notification.service';
@Component({
    selector: 'app-company-register',
    templateUrl: './company-register.component.html',
    styleUrls: ['./company-register.component.scss']
})
export class CompanyRegisterComponent implements OnInit {
    @Output() saveInfoEvent = new EventEmitter<string>();
    typeOfComponent: string = 'company';
    @Input() registerCompany: CompanyRegisterModel;
    countries: CountryModel[];

 

    constructor(private spinner: NgxSpinnerService, private notification: NotificationService, private parameterService: ParameterService) {
        this.registerCompany = new CompanyRegisterModel();
        console.log(this.registerCompany);
    }

    ngOnInit(): void {
        this.getCountries();
        console.log(this.registerCompany);
        
    }

    saveInfo(): void {
        this.saveInfoEvent.emit(this.typeOfComponent);
    }

    getCountries() {
        this.parameterService.countriesWithoutToken.subscribe((res: CountryModel[]) => {
            this.countries = res;
        }, err => {
            if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                this.notification.error(err.message);
                this.spinner.hide();
            }
        }, () => {
            this.spinner.hide();
        });
    }
    selectCountry(e) {
        this.registerCompany.country = new CountryModel();
        if (e.target.value === '') {
            this.registerCompany.country.id = null;
        } else {
            this.registerCompany.country.id = e.target.value;
        }
    }




}
