import {Component, Input, OnInit} from '@angular/core';
import {server} from '../../../../services/server';
import {AuthService} from '../../../../services/auth/auth.service';
import {ParameterService} from '../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../services/notification/notification.service';
import {UserModel} from '../../../user-management/user.model';
import {PermissionService} from '../../../../services/permission/permission.service';

@Component({
    selector: 'app-hiring-team',
    templateUrl: './hiring-team.component.html',
    styleUrls: ['./hiring-team.component.scss']
})
export class HiringTeamComponent implements OnInit {

    @Input() positionId: string;
    hiringTeamMembers: UserModel[];
    companyId: string;
    selectedMember: UserModel;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.companyId = this.authService.companyId;
        this.getHiringTeamMembers();
    }

    getHiringTeamMembers() {
        this.authService.ServerGet(server.positions + '/' + this.positionId + '/hiring-team-members')
            .subscribe((res: { content: [] }) => {
                this.hiringTeamMembers = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    addTeamMemberModalOpen() {
        // @ts-ignore
        $('#addToPositionHiringTeamMemberModal').modal('show');
    }

    removeMemberModalOpen(hiringTeamMember) {
        this.selectedMember = hiringTeamMember;
        // @ts-ignore
        $('#removeTeamMemberModal').modal('show');
    }

}
