<!-- Modal -->
<div aria-hidden="true" aria-labelledby="addToPositionModalCenterTitle" class="modal fade"
     id="addToPositionHiringTeamMemberModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title"
                    id="addToPositionModalLongTitle">{{'addToPosition' | translate}}</h5>
            </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label class="modal-input-label" for="names">{{'hiringTeamMember' | translate}}</label>
                        <div class="input-group">
                            <input #search
                                   (keyup)="searchUsers(search.value)" [(ngModel)]="selectedUserFullName"
                                   autocomplete="off"
                                   autofocus
                                   class="form-control border-radius-0" id="names"
                                   name="name"
                                   placeholder="{{'addToPositionPlaceholder' | translate}}" required="required"
                                   type="text">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button"><i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <ul *ngIf="users" class="search-list">
                            <li (click)="selectUser(user)"
                                *ngFor="let user of users">{{user.fullName}}&nbsp;--&nbsp;{{user.email}}</li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-warning border-radius-0" data-dismiss="modal"
                            type="button">{{'cancel' | translate}}</button>
                    <button (click)="addToPosition()"
                            [disabled]="selectedUser.id ==='' || selectedUser.id === undefined || selectedUser.id === 'undefined'"
                            class="btn btn-success border-radius-0"
                            type="button">{{'add' | translate}}</button>
                </div>
        </div>
    </div>
</div>
