import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ExperienceModel} from '../../experience.model';
import {server} from '../../../../../../services/server';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {ParameterService} from '../../../../../../services/parameters/parameter.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../../../../pipes/translate/translate.pipe';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../../../services/permission/permission.service';
import {NotificationService} from '../../../../../../services/notification/notification.service';
import {EmploymentTypeList} from '../../../../../../shared/lists/employment-type.list';
import { NgForm } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
    selector: 'app-edit-work-experience',
    templateUrl: './edit-work-experience.component.html',
    styleUrls: ['./edit-work-experience.component.scss']
})
export class EditWorkExperienceComponent implements OnInit {

    @Input() workExperience: ExperienceModel;
    @Input() candidateId: string;
    @Output() updateWorkExperiences = new EventEmitter();
    positionTypes = EmploymentTypeList;

    constructor(private authService: AuthService, private parameterService: ParameterService, private spinner: NgxSpinnerService,
                private translate: TranslatePipe,
                private router: Router,
                public permissionService: PermissionService,
                private notification: NotificationService,
                private utilitiesService: UtilitiesService) {
    }

    ngOnInit(): void {
        this.workExperience = new ExperienceModel();
    }

    saveWorkExperience(editCandidateWorkExperience: NgForm) {
        if (!editCandidateWorkExperience.valid) {
            this.utilitiesService.validationClassState('validate-me', 'add');
            this.notification.warning(this.translate.transform('fillRequiredFields', []));
            return;
          }
        this.authService.ServerPut(server.candidates + '/' + this.candidateId + '/employments/' + this.workExperience.id, this.workExperience)
            .subscribe(res => {
                this.notification.success(this.translate.transform('saveSuccessful', []));
                this.updateWorkExperiences.emit();
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.closeEditWorkExperienceModal();
                this.spinner.hide();
                this.utilitiesService.validationClassState('validate-me', 'remove');
            });
    }

    closeEditWorkExperienceModal() {
        // @ts-ignore
        $('#editWorkExperience').modal('hide');
    }

    selectPositionType(e) {
        this.workExperience.employmentType = e.target.value;
    }

}
