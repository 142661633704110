<!--View Note Modal -->
<div aria-hidden="true" aria-labelledby="viewNoteTitle" class="modal fade" id="viewNote"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title" id="addUserLongTitle">{{'note' | translate}}</h5>
            </div>
            <div class="modal-body">
                    <div class="mb-3">
                        <label class="modal-input-label" for="viewName">{{'note' | translate}}</label>
                        <textarea [(ngModel)]="note.message"
                                  class="form-control border-radius-0"
                                  disabled
                                  id="viewName"
                                  name="viewName"
                                  rows="7" appTitlecaseLower></textarea>
                    </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning border-radius-0" data-dismiss="modal"
                        type="button">{{'close' | translate}}</button>
            </div>
        </div>
    </div>
</div>
