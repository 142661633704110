<div class="form-group mt-4">
    <p class="forgot-head">{{'forgotYourPassword' | translate}} </p>
</div>
<div class="form-group mt-4">
    <p class="forgot-body">{{'forgotPasswordTitle' | translate}} </p>
</div>
<div class="input-group margin-bottom-sm mt-4">
    <div class="input-group-prepend">
        <span class="input-group-text login-input-icon-email">
            <i class="fas fa-envelope"></i>
        </span>
    </div>
    <input [(ngModel)]="passwordRequestUser.email" autocomplete="off" class="form-control login-input" name="email"
           placeholder="{{'emailAddress' | translate}}" type="text">
</div>
<div class="input-group margin-bottom-sm login-button-div mt-2">
    <input (click)="passwordRequest()" [disabled]="passwordRequestUser.email === ''" class="btn login-btn" type="submit"
           value="{{'send'| translate}}">
</div>
<div class="input-group mt-4">
    <a [routerLink]="['/']"
       class="forgot-password">{{'goBack' | translate}}</a>
</div>
