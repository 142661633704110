import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslatePipe } from 'src/app/pipes/translate/translate.pipe';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { server } from 'src/app/services/server';
import { CandidateModel } from '../../candidate.model';

@Component({
  selector: 'app-candidate-view-attachment-cv',
  templateUrl: './candidate-view-attachment-cv.component.html',
  styleUrls: ['./candidate-view-attachment-cv.component.scss']
})
export class CandidateViewAttachmentCvComponent implements OnInit {

  @Input()candidate: CandidateModel;
  attachmentUrlCv: string;
  candidateCvUrl: string;


  constructor(private authService: AuthService,
              private translate: TranslatePipe,
              private spinner: NgxSpinnerService,
              private notification: NotificationService) {

  }

  ngOnInit(): void {
    this.attachmentUrlCv = server.storageCandidate + '/' + this.candidate.id + '/cv';
    this.getCandidateUploadCv(this.attachmentUrlCv);
  }



  getCandidateUploadCv(attachmentUrlCv){
    this.authService.ServerGet(attachmentUrlCv)
    .subscribe((res: {url: string}) => {
      this.candidateCvUrl = res.url;
    }, err => {
      if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
        this.spinner.hide();
      }
  }, () => {
      this.spinner.hide();
  });


  }

  onProgress(progressData: any) {
    // do anything with progress data. For example progress indicator
        this.spinner.show();
        if (progressData.loaded == progressData.total) { this.spinner.hide(); }
        setTimeout(() => {
          this.spinner.hide();
        }, 3000);

  }


}
