<ng-container *ngIf="candidateCvUrl;else noDataCv" >

    <pdf-viewer [src]="candidateCvUrl" [render-text]="true" style="display: block;" (on-progress)="onProgress($event)">
    </pdf-viewer>

</ng-container>
<ng-template #noDataCv >
    <div class="alert alert-warning"  >
        {{"noCvUploaded" | translate}}
      </div>

</ng-template>
