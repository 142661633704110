

<div class="container-fluid">
    <div class="row">
        <div class="col-auto mb-1 mt-4">
            <app-interview-requests-filter (applyFilterOperation)="applyFilter($event)"></app-interview-requests-filter>
        </div>
      
    </div>
</div>

<div class="container-fluid mt-2">
    <div class="row">
        <div class="col-12">
            <app-interview-requests-list [filterData]="filterData"
                ></app-interview-requests-list>
        </div>
    </div>
</div>