<br><br><br>
<ul [style.min-width]="opened ? '230px':'0px'" class="list-group menu-container mt-1">
    <li [routerLinkActiveOptions]="{exact: true}"
        [routerLink]="['/dashboard']" [style.padding-left]="opened ? '30px':'15px'"
        class="list-group-item" routerLinkActive="gradient-bg">
        <span aria-hidden="true" class="fas fa-home icon-tag"></span>
        <span *ngIf="opened" class="sidebar-content-text">{{ 'home' | translate }}</span>
    </li>
    <li *ngIf="permissionService.permissionsOnly(['READ_ALL_INTERVIEWS','READ_INTERVIEW'])" [routerLink]="['calendar']"
        [style.padding-left]="opened ? '30px':'15px'" class="list-group-item"
        routerLinkActive="gradient-bg">
        <span aria-hidden="true" class="far fa-calendar-alt icon-tag"></span>
        <span *ngIf="opened" class="sidebar-content-text">{{ 'calendar' | translate }}</span>
    </li>
    <li [routerLink]="['reports']"
        [style.padding-left]="opened ? '30px':'15px'" class="list-group-item"
        routerLinkActive="gradient-bg">
        <span aria-hidden="true" class="far fa-file icon-tag"></span>
        <span *ngIf="opened" class="sidebar-content-text">{{ 'reports' | translate }}</span>
    </li>
    <li *ngIf="permissionService.permissionsOnly(['READ_ALL_POSITIONS','READ_MY_POSITIONS'])"
        [routerLink]="['positions']" [style.padding-left]="opened ? '30px':'15px'" class="list-group-item"
        routerLinkActive="gradient-bg">
        <span aria-hidden="true" class="far fa-clipboard icon-tag"></span>
        <span *ngIf="opened" class="sidebar-content-text">{{ 'positions' | translate }}</span>
    </li>
    <li *ngIf="permissionService.permissionsOnly(['READ_ALL_CANDIDATES','READ_CANDIDATE'])"
        [routerLink]="['candidates']" [style.padding-left]="opened ? '30px':'15px'" class="list-group-item"
        routerLinkActive="gradient-bg">
        <span aria-hidden="true" class="fas fa-user-clock icon-tag"></span>
        <span *ngIf="opened" class="sidebar-content-text">{{ 'candidates' | translate }}</span>
    </li>
    <li *ngIf="permissionService.permissionsOnly(['READ_ALL_POOLS','READ_POOL'])" [routerLink]="['pools']"
        [style.padding-left]="opened ? '30px':'15px'" class="list-group-item"
        routerLinkActive="gradient-bg">
        <span aria-hidden="true" class="fas fa-th icon-tag"></span>
        <span *ngIf="opened" class="sidebar-content-text">{{ 'pools' | translate }}</span>
    </li>
    <li *ngIf="permissionService.permissionsOnly(['READ_ALL_CLIENTS','READ_CLIENT'])"
        [routerLink]="['clients']"
        [style.padding-left]="opened ? '30px':'15px'" class="list-group-item"
        routerLinkActive="gradient-bg">
        <span aria-hidden="true" class="far fa-folder icon-tag"></span>
        <span *ngIf="opened" class="sidebar-content-text">{{ 'clients' | translate }}</span>
    </li>
</ul>
