import {Component, Input, OnInit} from '@angular/core';
import {server} from '../../../../services/server';
import {RequestPayload} from '../../../../shared/requestPayload';
import {CandidateFilterModel, CandidateModel} from '../../../candidates/candidate.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';

@Component({
    selector: 'app-pool-candidate',
    templateUrl: './pool-candidate.component.html',
    styleUrls: ['./pool-candidate.component.scss']
})
export class PoolCandidateComponent implements OnInit {

    @Input() poolId: string;

    positionFilter: CandidateFilterModel;
    filterData: CandidateFilterModel;
    requestPayload: RequestPayload;
    candidates: CandidateModel[];
    selectedCandidate: CandidateModel;
    count: number;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.requestPayload = new RequestPayload();
    }

    ngOnInit(): void {
        this.filterData = new CandidateFilterModel();
        this.selectedCandidate = new CandidateModel();
        this.getCandidates('1');
    }

    getCandidates(pageNo) {
        this.authService.ServerGet(server.candidates + this.requestPayload.payloadURL(pageNo) +
            '&filter=poolMemberships.pool.id::' + this.poolId + this.requestFilter())
            .subscribe((res: any) => {
                this.candidates = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    addToPoolCandidate() {
        // @ts-ignore
        $('#addToPoolModal').modal('show');
    }

    removeFromPool(candidate) {
        this.selectedCandidate = candidate;
        // @ts-ignore
        $('#removeFromPool').modal('show');
    }

    removeFromPosition(candidate) {
        this.selectedCandidate = candidate;
        // @ts-ignore
        $('#removeFromPosition').modal('show');
    }

    applyFilter(filter) {
        this.positionFilter = filter;
        if (JSON.stringify(this.filterData) === JSON.stringify(this.positionFilter)) {
            return false;
        }
        this.filterData = new CandidateFilterModel();
        this.filterData = {...this.positionFilter};
        this.getCandidates(1);
    }

    requestFilter() {
        return '&filter=countryId**' + this.filterData.country +
            '&filter=cityId**' + this.filterData.city +
            '&filter=pipelineSteps.recruitmentStepId**' + this.filterData.step +
            '&filter=status**' + this.filterData.status +
            '&filter=createdAt**' + this.filterData.users +
            '&filter=source**' + this.filterData.source;
    }
}
