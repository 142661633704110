import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GeneralModel} from './general/general.model';
import {PermissionService} from '../../../services/permission/permission.service';

@Component({
    selector: 'app-position-operations',
    templateUrl: './position-operations.component.html',
    styleUrls: ['./position-operations.component.scss']
})
export class PositionOperationsComponent implements OnInit {

    generalComponentState: boolean;
    hiringTeamComponentState: boolean;
    @Input() positionDetail: GeneralModel;
    @Input() operationType: string;
    @Output() cancelOperation = new EventEmitter();

    constructor(public permissionService: PermissionService) {
        this.setTab('general');
    }

    ngOnInit(): void {
    }

    setTab(component) {
        switch (component) {
            case 'general':
                this.generalComponentState = true;
                this.hiringTeamComponentState = false;
                break;
            case 'hiringTeam':
                this.generalComponentState = false;
                this.hiringTeamComponentState = true;
                break;
            default:
                this.generalComponentState = true;
                break;
        }

    }

    cancelPositionOperation() {
        this.cancelOperation.emit();
    }
}
