import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {CandidateFilterModel, CandidateModel} from './candidate.model';
import {PermissionService} from '../../services/permission/permission.service';
import {AuthService} from '../../services/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../services/notification/notification.service';
import {server} from '../../services/server';
import {RequestPayload} from '../../shared/requestPayload';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-candidates',
    templateUrl: './candidates.component.html',
    styleUrls: ['./candidates.component.scss']
})
export class CandidatesComponent implements OnInit {

    positionFilter: CandidateFilterModel;
    filterData: CandidateFilterModel;
    addCandidateState: boolean;
    requestPayload: RequestPayload;
    candidates: CandidateModel[];
    count: number;
    @Input() dropdownName = 'unspecified';
    @Input() isSearchable = true;
    @Output() selectResult = new EventEmitter();
    loadItems$: Observable<any>;
    @Input() minLengthForSearch = 3;
    @Input() selectedItem: string = null;
    noSearch = false;
    @Input() showElement: string;
    @Input() resGetElement: string;
    @Input() apiUrlForGetData: string;
    @Input() isRequired = false;
    isShow = true;
    searchValue: any;


    constructor(public permissionService: PermissionService, private authService: AuthService, private spinner: NgxSpinnerService,
                private notification: NotificationService,
    ) {
        this.requestPayload = new RequestPayload();
        this.addCandidateState = false;
    }

    ngOnInit(): void {
        this.filterData = new CandidateFilterModel();
        this.getCandidates('1');
    }

    search(searchKey: string) {
        if (searchKey.length >= 1 && searchKey.length < 3) {
            this.noSearch = true;
        } else if (searchKey.length === 0 || searchKey.length >= 3) {
            this.isShow = false;
            this.noSearch = false;
            this.addCandidateState = false;
            this.authService.ServerGet(server.candidates + this.requestPayload.payloadURL(1) + '&filter=freeText~~' + searchKey)
                .subscribe((res: any) => {
                    this.candidates = res.content;
                    this.count = res.totalElements;
                }, err => {
                    if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                        this.notification.error(err.message);
                        this.spinner.hide();
                    }
                }, () => {
                    this.spinner.hide();
                });
        } else {
            console.log('loading');
        }
        // this.noSearch = false;
    }

    selectData(item): void {
        if (!item) {
            this.selectedItem = null;
            this.selectResult.emit('');
            return;
        }

        this.selectedItem = item[this.showElement];
        this.selectResult.emit(item);

    }

    loadItems(search = ''): Observable<any> {
        this.spinner.hide();
        return this.authService.ServerGet(`${server.candidates}?filter=freeText~~${search}`)
            .pipe(map((res: any) => {
                this.spinner.hide();
                return res[this.resGetElement];
            }));
    }

    addCandidateModal() {
        this.addCandidateState = true;
    }

    getCandidates(pageNo) {
        this.addCandidateState = false;
        this.authService.ServerGet(server.candidates + this.requestPayload.payloadURL(pageNo) + this.requestFilter())
            .subscribe((res: any) => {
                this.candidates = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    applyFilter(filter) {
        this.positionFilter = filter;
        if (JSON.stringify(this.filterData) === JSON.stringify(this.positionFilter)) {
            return false;
        }
        this.filterData = new CandidateFilterModel();
        this.filterData = {...this.positionFilter};
        this.getCandidates(1);
    }

    requestFilter() {
        return '&filter=countryId**' + this.filterData.country +
            '&filter=cityId**' + this.filterData.city +
            '&filter=pipelineSteps.recruitmentStepId**' + this.filterData.step +
            '&filter=status**' + this.filterData.status +
            '&filter=createdAt**' + this.filterData.users +
            '&filter=source**' + this.filterData.source;
    }

    resetle(searchKey: string) {
        this.noSearch = false;
        this.addCandidateState = false;
        this.authService.ServerGet(server.candidates + this.requestPayload.payloadURL(1) + '&filter=freeText~~' + searchKey)
            .subscribe((res: any) => {
                this.candidates = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
        this.isShow = !this.isShow;
        this.searchValue = ' ';

    }

    /*  searching() {
          console.log('çalışmıyor');
         // this.isShow = !this.isShow;
      }*/
}

