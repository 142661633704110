import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {server} from '../../../services/server';
import {PermissionService} from '../../../services/permission/permission.service';
import {AuthService} from '../../../services/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../../services/notification/notification.service';
import {ConversationModel} from './message-row/conversation.model';

@Component({
    selector: 'app-message-users-area',
    templateUrl: './message-users-area.component.html',
    styleUrls: ['./message-users-area.component.scss']
})
export class MessageUsersAreaComponent implements OnInit {

    conversations: ConversationModel[];
    storedItems: ConversationModel[] = [];
    @Output() emitOpenContent = new EventEmitter();
    @Output() isEmptyCheck = new EventEmitter();

    selectedConversation: ConversationModel;

    constructor(public permissionService: PermissionService, private authService: AuthService, private spinner: NgxSpinnerService,
                private notification: NotificationService) {
        this.selectedConversation = new ConversationModel();
    }

    ngOnInit(): void {
        this.getConversations();
    }


    getConversations() {
        this.authService.ServerGet(server.conversations + '?status=ACTIVE')
            .subscribe((res: { content: ConversationModel[] }) => {
                this.conversations = res.content;

            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.storedItems = [...this.conversations];
                this.spinner.hide();
                if (this.conversations.length <= 0){
                    this.isEmptyCheck.emit('empty');
                }else{
                    this.isEmptyCheck.emit('full');
                }
            });
    }

    opnContent(conversation: ConversationModel) {
        conversation.unread = false;
        this.setCommentAsRead(conversation);
        this.emitOpenContent.emit(conversation);
    }

    setCommentAsRead(conversation) {
        this.authService.ServerPost(server.conversations + '/' + conversation.id + '/read', {})
            .subscribe(res => {
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                this.spinner.hide();
            });
    }

    search(data) {
        // tslint:disable-next-line:max-line-length
        this.conversations = ((this.storedItems).filter((item: any) => ((item.participants[0].name).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1));
    }
}
