<div class="container-fluid p-0">
    <div class="row" style="background-color: whitesmoke; margin-right: 0">
        <div class="col-12 pt-3">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text"
                          id="basic-addon1" style="border-top-left-radius: 18px; border-bottom-left-radius: 18px;">
                        <i class="fas fa-search text-muted"></i>
                    </span>
                </div>
                <input #searchText (keyup)="search(searchText.value)"
                       aria-describedby="basic-addon1" class="form-control" placeholder="Search in messages"
                       style="border-top-right-radius: 18px; border-bottom-right-radius: 18px;" type="text">
            </div>
        </div>
    </div>
    <div class="container-fluid" style="overflow-y: auto; padding-right: 35px !important;
    max-height: 585px;
    overflow-x: hidden;">
        <app-message-row (click)="opnContent(conversation)" *ngFor="let conversation of conversations"
                         [conversation]="conversation"></app-message-row>
    </div>
</div>
