import {Component, Input, OnInit} from '@angular/core';
import {StepModel} from '../../candidates/edit-candidate/candidate-profile-operations/candidate-change-step/step.model';
import {RequestPayload} from '../../../shared/requestPayload';
import {InterviewModel} from '../../../services/parameters/models/interview.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';
import {server} from '../../../services/server';
import {ReportFilter} from './report-position-filter/report-filter.model';
import {GeneralModel} from '../../positions/position-operations/general/general.model';
import {ReportPositionModel} from './report-position.model';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';


@Component({
    selector: 'app-report-position',
    templateUrl: './report-position.component.html',
    styleUrls: ['./report-position.component.scss']
})

export class ReportPositionComponent implements OnInit {
    @Input() positionId: string;
    @Input() candidateId: string;
    pipelineSteps: StepModel[];
    requestPayload: RequestPayload;
    interviews: InterviewModel[];
    count: number;
    selectedStepId: string;
    positions: ReportPositionModel[];
    currentPage: number;
    isShow = true;
    positionFilter: ReportFilter;
    filterData: ReportFilter;
    index: number;
    sortFlag: number;
    showingBetween: string;


    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.requestPayload = new RequestPayload();
        this.filterData = new ReportFilter();
        this.positionFilter = new ReportFilter();
        this.count = 0;
        this.positionId = '';
        this.candidateId = '';
        this.selectedStepId = '';
        this.sortFlag = 0;
    }

    ngOnInit(): void {
        this.getRecruitmentPreferences();
        this.getPositions(1);
        this.showingBetweenUpdate();
    }

    showingBetweenUpdate() {
        if (this.count < ((this.index * 5) + 5)) {
            this.showingBetween = 'Showing Between ' + ((this.index * 5) + 1) + '-' + this.count + ' of Total ' + this.count + ' Records';
        } else {
            this.showingBetween = 'Showing Between ' + ((this.index * 5) + 1) + '-' + ((this.index * 5) + 5) + ' of Total ' + this.count + ' Records';
        }
    }

    clearDateFilter() {
        this.positionFilter.openingDate = '';
        this.positionFilter.closingDate = '';
        this.applyFilter(this.positionFilter);
        this.isShow = true;

        //@ts-ignore
        $('#openingPickerText').val('');
        //@ts-ignore
        $('#closingPickerText').val('');
    }

    openingDateFilter(event: MatDatepickerInputEvent<Date>) {

        this.positionFilter.openingDate = event.value.toISOString().split('T')[0];
        this.applyFilter(this.positionFilter);
        this.isShow = false;
    }

    closingDateFilter(event: MatDatepickerInputEvent<Date>) {

        this.positionFilter.closingDate = event.value.toISOString().split('T')[0];
        this.applyFilter(this.positionFilter);
        this.isShow = false;
    }

    getRecruitmentPreferences() {
        this.authService.ServerGet(server.pipelineSteps + '?positionId=' + this.positionId)
            .subscribe((res: StepModel[]) => {
                this.pipelineSteps = res;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    openPipelineUserList(step: StepModel, event) {
        if ((event.target.tagName).toUpperCase() === 'DIV') {
            if (!this.selectedStepId || this.selectedStepId !== step.recruitmentStepId) {
                this.count = 0;
                this.interviews = [];
                if (this.selectedStepId) {
                    document.getElementById('stepId_' + this.selectedStepId).style.display = 'none';
                }
                document.getElementById('stepId_' + step.recruitmentStepId).style.display = 'block';
                this.selectedStepId = step.recruitmentStepId;
                this.getStepUsers('1');
            } else {
                if (document.getElementById('stepId_' + this.selectedStepId).style.display === 'none') {
                    document.getElementById('stepId_' + step.recruitmentStepId).style.display = 'block';
                } else {
                    document.getElementById('stepId_' + step.recruitmentStepId).style.display = 'none';
                }
            }

            this.selectedStepId = step.recruitmentStepId;
        }
    }

    work() {
        console.log('it was clicked');
    }

    getStepUsers(pageNo) {
        let url: string;

        if (this.selectedStepId === '') {
            url = server.interviews + this.requestPayload.payloadURL(pageNo) + this.requestFilter();
        } else {
            url = server.interviews + this.requestPayload.payloadURL(pageNo) + '&filter=recruitmentStepId::' + this.selectedStepId + this.requestFilter();
        }


        this.authService.ServerGet(url)
            .subscribe((res: any) => {
                this.interviews = res.content;
                this.count = res.totalElements;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }


    logVer(mesaj: string) {
        console.log(mesaj);
    }

    applyFilter(filter) {
        this.positionFilter = filter;
        console.log(this.positionFilter);
        if (JSON.stringify(this.filterData) === JSON.stringify(this.positionFilter)) {
            return false;
        }
        this.filterData = new ReportFilter();
        this.filterData = {...this.positionFilter};
        this.getPositions(1);
    }

    requestFilter() {
        return '&filter=clientId**' + this.filterData.client +
            '&filter=createdBy**' + this.filterData.user +
            '&filter=status**' + this.filterData.status +
            '&filter=openingDate>:' + this.filterData.openingDate +
            '&filter=closingDate<:' + this.filterData.closingDate;
    }

    requestSort() {
        switch (this.sortFlag) {
            case 0:
                return '';
                break;
            case 1:
                return '&sort=createdAt,ASC';
                break;
            case 2:
                return '&sort=createdAt,DESC';
                break;
        }


    }

    ExportPosition() {
        // @ts-ignore
        $('#exportModalPosition').modal('show');
    }

    getPositions(pageNo) {
        this.authService.ServerGet(server.positions + this.requestPayload.payloadURL(pageNo) + this.requestFilter() + this.requestSort())
            .subscribe((res: any) => {
                this.positions = res.content;
                this.count = res.totalElements;
                this.index = res.pageIndex;
                this.showingBetweenUpdate();
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    pagination(pageNo) {
        this.getPositions(pageNo);
    }

    dateSort() {
        switch (this.sortFlag) {
            case 0:
                this.sortFlag = 1;
                break;
            case 1:
                this.sortFlag = 2;
                break;
            case 2:
                this.sortFlag = 0;
                break;
        }
        this.getPositions(1);


    }
}

