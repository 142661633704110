<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-12 p-0">
            <div class="table-responsive">
                <table class="table">
                    <thead class="table-head">
                    <tr style="border: none">
                        <th scope="col">{{'Date' | translate}}</th>
                        <th scope="col">{{'Candidate' | translate}}</th>
                        <th scope="col">{{'Position' | translate}}</th>
                        <th scope="col">{{'Client' | translate}}</th>
                        <th scope="col">{{'User' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let interview of interviews | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count }"
                        [hidden]="count<1"
                        class="history-row">
                        <td>{{interview.date}}</td>
                        <td>{{interview.candidate.name}}</td>
                        <td>{{interview.position.name}}</td>
                        <td>{{interview.client?.name}}</td>
                        <td>
                            <app-user [userId]="interview.updatedBy"></app-user>
                        </td>
                    </tr>
                    <tr [hidden]="count>0">
                        <td colspan="5">
                            <app-empty-table-info tableInfoTranslateKey="interviewNotExistInfo"></app-empty-table-info>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot *ngIf="count > requestPayload.limit">
                    <tr class="width-100">
                        <td class="text-center" colspan="6">
                            <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
                                                 [autoHide]="count <= requestPayload.limit"
                                                 nextLabel="{{'next' | translate}}"
                                                 previousLabel="{{'previous' | translate}}"
                                                 responsive="true"></pagination-controls>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
