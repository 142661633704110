<div class="container-fluid mt-5">
    <div class="row mb-2" *ngIf="permissionService.permissionsOnly(['CREATE_CLIENT_NOTE'])">
        <div class="col-12">
            <button (click)="addNote()"
                    class="btn btn-blue exactalent-button border-radius-0 float-right">{{'addNote' | translate}}</button>
        </div>
    </div>
    <div *ngIf="inputAreaState" class="row mt-5">
        <div *ngIf="note.privateNote" class="col-12">
            <div class="col-12 alert alert-warning border-radius-0" role="alert">
                <i class="fas fa-lock"></i>
                <span class="ml-2">{{'privateNoteInfo' | translate}}</span>&nbsp; <span>{{'(Can only be seen and edited by attached users)' | translate}}</span>
            </div>
        </div>
        <div class="col-12">
            <textarea [(ngModel)]="note.message" class="form-control border-radius-0"
                      placeholder="{{'notesPlaceholder' | translate}}&nbsp;&nbsp;{{'here' | translate | lowercase}}"
                      rows="9" appTitlecaseLower></textarea>
        </div>
        <div class="col-12 p-0" *ngIf="permissionService.permissionsOnly(['CREATE_CLIENT_NOTE','UPDATE_CLIENT_NOTE'])">
            <div class="modal-footer border-0">
                <span class="float-left col" style="color: #649a65; display:none">
                    <i class="fas fa-paperclip cursor-pointer"></i>
                    <span class="ml-2 cursor-pointer" style="text-decoration: underline #649a65;">
                        {{'attachFile' | translate}}
                    </span>
                </span>
                <span class="float-left col" style="color: #649a65;">
                    <label>
                        <input [(ngModel)]="note.privateNote" type="checkbox">
                        <span class="ml-2">
                            {{'privateNote' | translate}}
                        </span>
                    </label>
                </span>
                <button (click)="cancel()"
                        class="btn btn-warning border-radius-0 col-xl-auto col-lg-auto col-md-auto col-sm-12"
                        type="button">{{'cancel' | translate}}</button>
                <button (click)="save()"
                        class="btn btn-success border-radius-0 col-xl-auto col-lg-auto col-md-auto col-sm-12"
                        type="button">{{'save' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row mt-1">
        <div class="col-12">
            <div class="row">
                <p class="col" style="border-bottom: 3px solid #f6f6f6"></p>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div *ngFor="let note of notes" class="row mt-1">
        <div class="col-12">
            <div class="row step-row">
                <div class="col-12 border-radius-0 step-col mt-2">
                    <div class="row">
                        <span class="float-left ml-4 step-text col"
                              style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden !important;">                                     
                            <ng-template [ngIf]="note.privateNote" [ngIfElse]="elseBlock">
                                <i class="fas fa-lock" style="font-size: 20px;"></i>
                              </ng-template>
                              <ng-template #elseBlock>
                                <i class="fas fa-file-alt" style="font-size: 20px;"></i>
                              </ng-template>
                            <span class="ml-2">{{note.message}}</span>
                        </span>
                        <span class="float-right control-panel col">
                            <span *ngIf="note.createdBy === sessionUserId || permissionService.permissionsOnly(['ROLE_HR_MANAGER','DELETE_CLIENT_NOTE'])"
                                  class="ml-4 float-right">
                                <i (click)="openDeleteModal(note)" class="fas fa-times"></i>
                            </span>
                            <span *ngIf="permissionService.permissionsOnly(['UPDATE_CLIENT_NOTE'])"
                                  class="ml-4 float-right">
                                <i (click)="editNote(note)" class="fas fa-pen"></i>
                            </span>
                            <span *ngIf="permissionService.permissionsOnly(['READ_CLIENT_NOTE'])"
                                  class="ml-4 float-right">
                                <i (click)="openViewModal(note)" class="far fa-eye"></i>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="notes && notes.length<1" class="container-fluid text-center">
    <p>
        <i class="fas fa-file-alt" style="font-size: 65px; color: #d6d6d6"></i>
    </p>
    <p class="mt-2" style="color: #d6d6d6 !important;">{{'noteInfo' | translate}}</p>
</div>
<app-delete-note-modal *ngIf="permissionService.permissionsOnly(['DELETE_CLIENT_NOTE'])" (deleteNote)="deleteNote()" [note]="note"></app-delete-note-modal>
<app-view-note-modal [note]="note"></app-view-note-modal>
