import {Component, OnInit} from '@angular/core';
import {server} from '../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../services/permission/permission.service';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';
import {SearchModel} from './search.model';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

    searchDatas: SearchModel[];
    searchModel: SearchModel;
    isSearchClick: boolean;
    isLessThanMinimum: boolean;
    searchKey: string;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.searchModel = new SearchModel();
    }

    ngOnInit(): void {    
                // @ts-ignore
       $('#searchModal').on('hidden.bs.modal', () => {
        // @ts-ignore
        $('#search-input').val("");
         // @ts-ignore
         this.searchDatas = [];
         this.isSearchClick=false;
      });      
    }

    search() {
        this.searchDatas = [];
        this.isSearchClick = true;
        this.isLessThanMinimum = false;

        if (this.searchModel.value.length < 3){
            this.isLessThanMinimum = true;
            this.notification.warning(this.translate.transform('leastThreeCharacterWarning', []));
            this.closeSearchModal();
            return;
        }

        if (this.searchModel.value === '') {
            return false;
        }

        this.searchKey = this.searchModel.value;
        this.authService.ServerGet(server.globalSearch + '?q=' + this.searchModel.value + '&size=7&page=0')
            .subscribe((res: any) => {
                this.searchDatas = res.content;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    openSearchedElement(element: SearchModel) {
        this.searchDatas = [];
        this.searchModel = new SearchModel();
        const dataType = element.type;
        switch (dataType) {
            case 'USER':
                this.router.navigate(['/dashboard/company-settings']);
                break;
            case 'COMPANY':
                this.router.navigate(['/dashboard/company-settings']);
                break;
            case 'CLIENT':
                this.router.navigate(['/dashboard/client-details', {id: element.id}]);
                break;
            case 'POSITION':
                this.router.navigate(['/dashboard/position-details', {id: element.id}]);
                break;
            case 'CANDIDATE':
                this.router.navigate(['/dashboard/edit-candidate', {id: element.id}]);
                break;
            default:
                break;
        }
        this.closeSearchModal();
    }

    closeSearchModal() {
        // @ts-ignore
        $('#searchModal').modal('hide');
    }

}
