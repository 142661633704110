<!--Edit contactPerson education Modal -->
<div aria-hidden="true" aria-labelledby="editContactPersonOperationTitle" class="modal fade" id="editContactPerson"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">
                    {{ "editContactPerson" | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="editFirstName">{{"firstName" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [(ngModel)]="contactPerson.firstName"
                                       class="form-control border-radius-0"
                                       id="editFirstName"
                                       name="firstName"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="editMiddleName">{{"middleName" | translate}}</label>
                                <input [(ngModel)]="contactPerson.middleName"
                                       class="form-control border-radius-0"
                                       id="editMiddleName"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="editLastName">{{"lastName" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [(ngModel)]="contactPerson.lastName"
                                       class="form-control border-radius-0"
                                       id="editLastName"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="editTitle">{{"title" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [(ngModel)]="contactPerson.title"
                                       class="form-control border-radius-0"
                                       id="editTitle"
                                       type="text">
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="editEmail">{{"email" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [(ngModel)]="contactPerson.email"
                                       class="form-control border-radius-0"
                                       id="editEmail"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="editPhone">{{"phone" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [(ngModel)]="contactPerson.phone"
                                       class="form-control border-radius-0"
                                       id="editPhone"
                                       type="text">
                            </div>
                            <div class="mb-3">
                                <label class="modal-input-label"
                                       for="editMobile">{{"mobile" | translate}}<span
                                        class="text-danger">*</span></label>
                                <input [(ngModel)]="contactPerson.mobile"
                                       class="form-control border-radius-0"
                                       id="editMobile"
                                       type="text">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="cancel()" class="btn btn-warning border-radius-0"
                        type="button">
                    {{ "cancel" | translate }}
                </button>
                <button (click)="save()" class="btn btn-success border-radius-0" type="button">
                    {{ "save" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>