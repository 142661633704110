<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="dropdown">
                <button aria-expanded="false" aria-haspopup="true"
                        class="btn btn-secondary dropdown-toggle border-radius-0 filter-button"
                        data-toggle="dropdown" id="dropdownMenuButton" type="button">
                    <i class="fas fa-bars"></i>&nbsp;&nbsp;{{dropdownViewTitle | translate}}
                </button>
                <div aria-labelledby="dropdownMenuButton" class="dropdown-menu">
                    <a (click)="changeView('onList')"
                       class="dropdown-item cursor-pointer">{{'onList' | translate}}</a>
                    <a (click)="changeView('onCalendar')"
                       class="dropdown-item cursor-pointer">{{'onCalendar' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid mt-2">
    <div class="row">
        <div class="col-12">
            <app-calendar-view (getInterviews)="getInterviews($event)" *ngIf="isCalendarView"
                               [interviews]="interviews"></app-calendar-view>
            <app-onlist-view *ngIf="isOnListView" [positionId]="positionId"></app-onlist-view>
        </div>
    </div>
</div>