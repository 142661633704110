import {Component, Input, OnInit} from '@angular/core';
import {StepModel} from '../../candidates/edit-candidate/candidate-profile-operations/candidate-change-step/step.model';
import {RequestPayload} from '../../../shared/requestPayload';
import {InterviewModel} from '../../../services/parameters/models/interview.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';
import {server} from '../../../services/server';
import {ReportFilterModel} from '../report-filter/report-filter.model';

@Component({
    selector: 'app-report-event',
    templateUrl: './report-event.component.html',
    styleUrls: ['./report-event.component.scss']
})

export class ReportEventComponent implements OnInit {

    @Input() positionId: string;
    @Input() candidateId: string;
    pipelineSteps: StepModel[];
    requestPayload: RequestPayload;
    interviews: InterviewModel[];
    count: number;
    selectedStepId: string;
    // isShow = false;
    positionFilter: ReportFilterModel;
    filterData: ReportFilterModel;

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.positionId = '';
        this.candidateId = '';
        this.requestPayload = new RequestPayload();
        this.filterData = new ReportFilterModel();
        this.count = 0;
    }

    ngOnInit(): void {
        this.getRecruitmentPreferences();
    }

    getRecruitmentPreferences() {
        this.authService.ServerGet(server.pipelineSteps + '?positionId=' + this.positionId)
            .subscribe((res: StepModel[]) => {
                this.pipelineSteps = res;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    openPipelineUserList(step: StepModel, event) {
        if ((event.target.tagName).toUpperCase() === 'DIV') {
            if (!this.selectedStepId || this.selectedStepId !== step.recruitmentStepId) {
                this.count = 0;
                this.interviews = [];
                if (this.selectedStepId) {
                    document.getElementById('stepId_' + this.selectedStepId).style.display = 'none';
                }
                document.getElementById('stepId_' + step.recruitmentStepId).style.display = 'block';
                this.selectedStepId = step.recruitmentStepId;
                this.getStepUsers('1');
            } else {
                if (document.getElementById('stepId_' + this.selectedStepId).style.display === 'none') {
                    document.getElementById('stepId_' + step.recruitmentStepId).style.display = 'block';
                } else {
                    document.getElementById('stepId_' + step.recruitmentStepId).style.display = 'none';
                }
            }

            this.selectedStepId = step.recruitmentStepId;
        }
    }

    getStepUsers(pageNo) {
        let url: string;

        if (this.selectedStepId == '') {
            url = server.interviews + this.requestPayload.payloadURL(pageNo) + this.requestFilter();
        } else {
            url = server.interviews + this.requestPayload.payloadURL(pageNo) + '&filter=recruitmentStepId::' + this.selectedStepId + this.requestFilter();
        }


        this.authService.ServerGet(url)
            .subscribe((res: any) => {
                this.interviews = res.content;
                this.count = res.totalElements;
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    applyFilter(filter) {
        this.positionFilter = filter;
        if (JSON.stringify(this.filterData) === JSON.stringify(this.positionFilter)) {
            return false;
        }
        this.filterData = new ReportFilterModel();
        this.filterData = {...this.positionFilter};
        this.getStepUsers(1);
    }

    requestFilter() {
        return '&filter=positionId**' + this.filterData.position +
            '&filter=clientId**' + this.filterData.client +
            '&filter=createdBy**' + this.filterData.user +
            '&filter=hiredAt**' + this.filterData.hiredAt +
            '&filter=candidateId**' + this.filterData.candidate;
    }


    ExportCandidate() {
        // @ts-ignore
        $('#exportModalCandidate').modal('show');
    }
}
