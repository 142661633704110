export const LANG_TR_NAME = 'tr';

export const LANG_TR_TRANS = {

    appName: 'App Name',
    welcome: 'Welcome',
    user: 'User',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    logOut: 'Sign Out',
    loginErrorInfo: 'Username or password is incorrect. Please check your credentials',
    loginErrorGeneralInfo: 'A general problem occurred. Please try again later',
    updateSuccessful: 'Update is successful',
    createSuccessful: 'Successfully created',
    deleteSuccessful: 'Successfully deleted',
    passwordRequestSuccessful: 'Password Request is sent successfully',
    saveSuccessful: 'Successfully saved',
    previous: 'Previous',
    next: 'Next',

    dashboard: 'Home',
    home: 'Home',
    calendar: 'Calendar',
    positions: 'Positions',
    reports: 'Reports',
    candidates: 'Candidates',
    pools: 'Pools',
    clients: 'Clients',
    profile: 'Profile',
    userSettings: 'User Settings',
    companySettings: 'Company Settings',
    welcomeTo: 'Welcome to',

    emailAddress: 'Email Address',
    password: 'Password',
    passwordAgain: 'Password Again',
    forgotMyPassword: 'I Forgot My Password!',
    forgotYourPassword: 'Forgot your password?',
    forgotPasswordTitle: 'Please enter your email address for your account and follow the link that will be sent via email to reset your password.',
    resetPasswordTitle: 'You have been added as a user to company’s team. After you set your password below, you can sign in to your account.',
    goBack: 'Go back',
    send: 'Send',
    save: 'Save',

    userManagement: 'User Management',
    recruitmentPreferences: 'Recruitment Preferences',
    careerPortalSettings: 'Career Portal Settings',

    fullName: 'Full Name',
    name: 'Name',
    middleName: 'Middle Name',
    surname: 'Surname',
    username: 'Username',

    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
    clone: 'Clone',
    email: 'E-mail',

    addUser: 'Add User',
    userType: 'User Type',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    newPasswordAgain: 'New Password Again',

    stepName: 'Step Name',
    addNewStep: 'Add New Step',

    companyLogo: 'Company Logo',
    headerImage: 'Header Image',
    header: 'Header',
    title: 'Title',
    subtitle: 'Subtitle',
    description: 'Description',
    headerColor: 'Header Color',

    // social

    websiteLink: 'Website Link',
    websiteLinkPlaceholder: 'https://example.com',
    twitterAccount: 'Twitter Account',
    twitterAccountPlaceholder: 'Twitter Account Name',
    facebookAccount: 'Facebook Account',
    facebookAccountPlaceholder: 'Facebook Account Name',
    linkedinAccount: 'Linkedin Account',
    linkedinAccountPlaceholder: 'Linkedin Account Name',
    instagramAccount: 'Instagram Account',
    instagramAccountPlaceholder: 'Instagram Account Name',
    githubAccount: 'Github Account',
    githubAccountPlaceholder: 'Github Account Name',
    youtubeAccount: 'Youtube Account',
    youtubeAccountPlaceholder: 'Youtube Account Name',
    behanceAccount: 'Behance Account',
    behanceAccountPlaceholder: 'Behance Account Name',
    stackoverflowAccount: 'Stackoverflow Account',
    stackoverflowAccountPlaceholder: 'Stackoverflow Account Name',

    // color

    black: 'Black',
    white: 'White',
    green: 'Green',
    blue: 'Blue',
    yellow: 'Yellow',
    gray: 'Gray',
    red: 'Red',
    orange: 'Orange',
    language: 'Language',
    languages: 'Languages',

    //
    changeProfilePhoto: 'Change Profile Photo',
    deleteProfilePhoto: 'Delete Photo',
    confirmdeleteProfilePhoto: 'The profile photo will be removed, do you want to continue',


    active: 'Active',
    passive: 'Passive',
    yes: 'Yes',
    true: 'Yes',
    no: 'No',
    false: 'No',

    status: 'Status',
    resetPassword: 'Reset Password',

    // position table
    country: 'Country',
    city: 'City',
    positionType: 'Position Type',
    opening: 'Opening',
    created: 'Created',
    users: 'Users',
    myPositions: 'My Positions',
    allPositions: 'All Positions',
    addNewPosition: 'Add New Position',
    general: 'General',
    hiringTeam: 'Hiring Team',
    forCandidate: 'For Candidate',
    positionName: 'Position Name',
    jobTitle: 'Job Title',
    jobType: 'Job Type',
    educationDegree: 'Education Degree',
    closingDateForJobPost: 'Closing Date For Job Post',
    positionOpeningDate: 'Position Opening Date',
    client: 'Client',
    maximumSalary: 'Maximum Salary',
    numberOfHiring: 'Number Of Hiring',
    disabilityOption: 'Disability Option',
    jobDescription: 'Job Description',
    requirements: 'Requirements',
    showOnThePost: 'Show On The Post',
    forRecruiter: 'For Recruiter',
    service: 'Service',
    positionFee: 'Position Fee',
    currency: 'Currency',
    commissionFee: 'Commission Fee',
    estimatedInvoiceAmount: 'Estimated Invoice Amount',
    jobPostDuration: 'Job Post Duration (in days)',
    attachmentsFromClient: 'Attachments From Client',
    upload: 'Upload',
    addToPosition: 'Add To Position',
    addToPositionPlaceholder: 'Name, Surname, Email',
    removeTeamMember: 'Remove Team Member',
    remove: 'Remove',
    editSuccessful: 'Position is successfully saved',

    // education degree
    highSchool: 'High School',
    graduate: 'Graduate',
    postGraduate: 'Post Graduate',
    doctorate: 'Doctorate',

    // job title
    permanent: 'Permanent',
    temporary: 'Temporary',
    fullTime: 'Full Time',
    partTime: 'Part Time',
    remote: 'Remote',

    // position status list
    draft: 'Draft',
    closed: 'Closed',
    cancelled: 'Cancelled',
    paused: 'Paused',
    archived: 'Archived',

    filter: 'Filter',

    showPositionDetails: 'Position Details',
    editPosition: 'Edit Position',
    clonePosition: 'Clone Position',
    sharePosition: 'Share Position',
    viewOnPortal: 'View On Portal',

    positionStatus: 'Position Status',
    pipeline: 'Pipeline',
    candidate: 'Candidate',
    calender: 'Calender',
    history: 'History',

    moreTitle: 'and more',
    person: 'Person',

    changePassword: 'Change Password',
    currentPassword: 'Current Password',

    resetPasswordMessage: 'We have sent you an email, please check your inbox!',
    resetUrlNotValid: 'Reset URL is not valid',
    resetPasswordSuccess: 'Password reset was successful',
    applyFilters: 'Apply Filters',
    removeFilters: 'Remove Filters',
    all: 'All',

    phone: 'Phone',
    position: 'Position',
    step: 'Step',
    source: 'Source',
    added: 'Added',
    lastActivity: 'Last Activity',
    addNewCandidate: 'Add New Candidate',
    newCandidate: 'New Candidate',
    importFromCv: 'Import From CV',
    addManually: 'Add Manually',
    pool: 'Pool',
    goCandidates: 'Go To Candidates',
    viewCv: 'View CV',
    noCvUploaded: 'No resume was uploaded',
    downloadCv: 'Download CV',
    viewCandidateCard: 'View Candidate Card',
    printCandidateCard: 'Print Candidate Card',
    downloadPdf: 'Download as PDF',
    candidateCv: 'Candidate CV',
    candidateCard: 'Candidate Card',
    address: 'Address',
    dateOfBirth: 'Date Of Birth',
    editProfileInfo: 'Edit Profile Info',

    workingStatus: 'Working Status',
    jobSearch: 'Job Search',
    minSalaryExpectation: 'Min. Salary Expectation',
    maxSalaryExpectation: 'Max. Salary Expectation',
    salaryExpectationUpdateDate: 'Salary Expectation Update Date',
    preferredWorkingLocation: 'Preferred Working Location',
    preferredIndustry: 'Preferred Industry',
    preferredTitle: 'Preferred Title',
    preferredJobType: 'Preferred Job Type',
    outsourcePreferences: 'Outsource Preferences',
    gender: 'Gender',
    militaryService: 'Military Service',
    dateOfPostponement: 'Date Of Postponement',
    nationality: 'Nationality',
    drivingLicence: 'Driving License',
    smoking: 'Smoking',

    education: 'Education',
    experience: 'Experience',
    attachments: 'Attachments',
    notes: 'Notes',

    university: 'University',
    faculty: 'Faculty',
    department: 'Department',
    beginningDate: 'Beginning Date',
    endingDate: 'Ending Date',
    gpa: 'GPA',
    reading: 'Reading',
    writing: 'Writing',
    speaking: 'Speaking',
    certificates: 'Certificates',
    certificateTitle: 'Certificate Title',
    organisation: 'Organization',
    year: 'Year',
    technicalSkill: 'Technical Skill',

    workExperience: 'Work Experience',
    total: 'Total',
    company: 'Company',
    years: 'Years',
    experienceSummary: 'Experience Summary',
    projects: 'Projects',
    projectSummary: 'Project Summary',

    notesPlaceholder: 'Add your personal notes about',
    here: 'Here',
    addNote: 'Add Note',
    attachFile: 'Attach File',
    noteInfo: 'You haven\'t added any notes yet',
    privateNote: 'Private Note',
    privateNoteInfo: 'This note is private',
    deleteNote: 'Delete Note',
    note: 'Note',
    editGeneralInfo: 'Edit General Info',
    jobSearching: 'Job Searching',
    salaryExpectation: 'Salary Expectation',
    maritalStatus: 'Marital Status',
    preferredWorkingCountry: 'Preferred Working Country',
    preferredWorkingCity: 'Preferred Working City',
    outsourcePreference: 'Outsource Preference',
    min: 'Min',
    max: 'Max',
    currentlyWorking: 'Currently Working',
    currentlyNotWorking: 'Currently Not Working',
    industry: 'Industry',
    completed: 'Completed',
    postponed: 'Postponed',
    excused: 'Excused',
    single: 'Single',
    married: 'Married',

    unspecified: 'Unspecified',
    male: 'Male',
    female: 'Female',

    addCandidateEducation: 'Add Candidate Education',
    editCandidateEducation: 'Edit Candidate Education',
    deleteCandidateEducation: 'Delete Candidate Education',
    beginningEndingDate: 'Beginning*/Ending Date ',
    startYear: 'Start Year',
    endYear: 'End Year',
    native: 'Native',
    advanced: 'Advanced',
    upperIntermediate: 'Upper Intermediate',
    intermediate: 'Intermediate',
    elementary: 'Elementary',
    beginner: 'Beginner',
    readingLevel: 'Reading',
    writingLevel: 'Writing',
    speakingLevel: 'Speaking',
    addCandidateLanguage: 'Add Candidate Language',
    editCandidateLanguage: 'Edit Candidate Language',
    deleteCandidateLanguage: 'Delete Candidate Language',
    addCandidateCertificate: 'Add Candidate Certificate',
    editCandidateCertificate: 'Edit Candidate Certificate',
    deleteCandidateCertificate: 'Delete Candidate Certificate',
    certificateName: 'Certificate Name',
    candidateTechnicalSkillOperation: 'Candidate Technical Skill Operations',
    candidateSoftSkillOperation: 'Candidate Soft Skill Operation',
    technicalSkills: 'Technical Skills',
    addWorkExperience: 'Add Work Experience',
    editWorkExperience: 'Edit Work Experience',
    deleteWorkExperience: 'Delete Work Experience',
    month: 'Month',
    summary: 'Summary',
    employmentType: 'Employment Type',
    addProject: 'Add Project',
    editProject: 'Edit Project',
    deleteProject: 'Delete Project',
    updateStatus: 'Update Status',
    editCandidateProfile: 'Edit Candidate Profile',
    jobSearchingStatusUpdateDate: 'Job Searching Status Update Date',
    tags: 'Tags',
    primaryPhone: 'Primary Phone',
    secondaryPhone: 'Secondary Phone',
    primaryEmail: 'Primary Email',
    secondaryEmail: 'Secondary Email',
    showCandidateDetails: 'Show Candidate Details',
    addTag: 'Add Tag',
    candidateTagOperation: 'Edit Tags',

    category: 'Category',
    addNewPool: 'Add New Pool',
    organisationName: 'Organization Name',
    organisationWebsiteLink: 'Organization Website',
    organisationPhone: 'Organization Phone',
    contactPerson: 'Contact Person',
    contactPersonTitle: 'Contact Person Title',
    contactEmail: 'Contact Email',
    contactPhone: 'Contact Phone',
    clientGroup: 'Client Group',
    addNewClient: 'Add New Client',

    poolDetails: 'Pool Details',
    addToPool: 'Add To Pool',
    candidatePools: 'Current Pools',
    editPool: 'Edit Pool',
    deletePool: 'Delete Pool',
    date: 'Date',
    details: 'Details',
    addedDate: 'Added Date',
    lastActivityDate: 'Last Activity Date',
    candidateStatus: 'Candidate Status',
    deleteCandidate: 'Delete Candidate',
    removeFromPosition: 'Remove From Position',
    removeFromPool: 'Remove From Pool',
    removeFromPoolConfirmation: 'This candidate will be removed from the pool, do you want to continue?',
    removeFromPositionConfirmation: 'This candidate will be removed from the position, do you want to continue?',
    removeCandidateConfirmation: 'This candidate will be removed, do you want to continue?',
    candidateName: 'Candidate Name',
    addStepNote: 'Add Step Note',
    startTime: 'Start Time',
    endTime: 'End Time',
    location: 'Location',
    week: 'Week',
    day: 'Day',
    today: 'Today',
    interviewDetails: 'Interview Details',
    viewCandidate: 'View Candidate',
    editEvent: 'Edit Event',
    deleteEvent: 'Delete Event',
    attendee: 'Attendee',
    deleteInterview: 'Delete Interview',
    deleteInterviewConfirmation: 'This interview will be deleted, do you want to continue?',
    selectInterviewers: 'Select Interviewers',
    interviewRequests: 'Interview Requests',
    interviewRequestStatus: 'Interview Request Status',
    createInterview: 'Create Interview',
    rejectInterviewRequest: 'Reject Interview Request',
    confirmInterviewRequest: '\'s interview request will be rejected, do you want to continue?',
    addtoPositionInfo: 'added to position',
    createInterviewInfo: 'interview created',
    warnInterviewRequestOperation: 'you can not change the status of the request which is already',
    accepted: 'accepted',
    declined: 'rejected',
    stepSetted: 'step is set',
    onList: 'On List',
    time: 'Time',
    with: 'With',
    onCalendar: 'On Calendar',
    firstName: 'First Name',
    lastName: 'Last Name',
    addNewContactPerson: 'Add New Contact Person',
    editContactPerson: 'Edit Contact Person',
    deleteContactPerson: 'Delete Contact Person',
    showClientDetails: 'Show Client Details',
    editClientInfo: 'Edit Client Info',
    editClient: 'Edit Client',
    deleteClient: 'Delete Client',
    accountManagers: 'Account Managers',
    selectAccountManager: 'Select Account Manager',
    successfulLastStep: 'Successful Last Step',
    hideOnMobile: 'Hide On Mobile',
    recommendation: 'Recommendation',
    content: 'Content',
    addNewRecommendation: 'Add New Recommendation',
    editRecommendation: 'Edit Recommendation',
    deleteRecommendation: 'Delete Recommendation',
    upcomingEvents: 'Upcoming Events',
    scheduleInterview: 'Schedule Interview',
    interviewers: 'Interviewers',
    interview: 'Interview',
    addCandidate: 'Add Candidate',
    hiringProcess: 'Hiring Process',
    hiringTeamMember: 'Hiring Team Member',
    seeAllPositions: 'See All Positions',
    seeAllCandidates: 'See All Candidates',
    apply: 'Apply',
    events: 'OnCandidate',
    timeToFill: 'Time To Fill',
    changeStep: 'Change Step',
    softSkill: 'Soft Skill',
    pipelineSteps: 'Pipeline Steps',
    positionCandidates: 'Position Candidates',
    clientHrPermitted: 'Client Hr Permitted',
    clientTechnicalPermitted: 'Client Technical Permitted',
    hideSensitiveDataForClient: 'Hide Sensitive Data for Client',
    search: 'Search',
    searchNoResult: 'No results for ',
    companyUser: 'Company User',

    // Empty Table Info Begin
    interviewNotExistInfo: 'No interviews yet',
    poolNotExistInfo: 'No pools yet',
    clientNotExistInfo: 'No clients yet',
    candidateNotExistInfo: 'No candidates yet',
    historyNotExistInfo: 'No history yet',
    positionNotExistInfo: 'No positions yet',
    recommendationNotExistInfo: 'No recommendations yet',
    userNotExistInfo: 'No users yet',
    timeToFillDataNotExist: 'Time to fill data does not exist yet',
    interviewRequestNotExistInfo: 'No interview requests yet',
    createInterviewRequestNotExistInfo: 'No interview requests yet',
    positionRequestNotExistInfo: 'No position requests yet',
    clientUserNotExistInfo: 'No client users yet',
    messageNotExistInfo: 'No messages yet',
    // Empty Table Info End

    // Client Group List Items Begin
    public: 'Public',
    enterprise: 'Enterprise',
    foundation: 'Foundation',
    sme: 'SME',
    educational_institution: 'Educational Institution',
    // Client Group List Items End

    // Source List Items Begin
    manual: 'Manual',
    import: 'Import',
    companyPortal: 'Company Portal',
    mobile: 'Mobile',
    indeed: 'Indeed',
    linkedin: 'Linkedin',
    kariyerNet: 'Kariyer Net',
    // Source List Items End

    expirationDate: 'Expiration Date',
    issueDate: 'Issue Date',
    issuedAt: 'Issued At',
    contact: 'Contact',
    twitter: 'Twitter',
    github: 'Github',
    generalInfo: 'General Info',
    chooseFile: 'Choose File',
    changePhoto: 'Change Photo',
    choosePhoto: 'Choose Photo',
    softSkills: 'Soft Skills',
    requestDate: 'Request Date',
    openPositionRequestDetail: 'Open Position Request Detail',
    positionRequestDetail: 'Position Request Detail',
    positionRequest: 'Position Request',
    createPosition: 'Create Position',
    role: 'Role',

    // warn users
    fillRequiredFields: 'Please fill in all the required fields',
    leastThreeCharacter: 'At least 3 characters',
    leastThreeCharacterWarning: 'The number of characters must be at least 3',
    emailValidation: 'Please check the email format',

    // client users
    addClientUser: 'Add Client User',
    editClientUser: 'Edit Client User',
    deleteClientUser: 'Delete Client User',
    CLIENT_TECHNICAL: 'CLIENT TECHNICAL',
    CLIENT_HR: 'CLIENT HR',

    importAttachment: 'Import File',

    // candidate detail bottom icon tooltips
    candidateDetailBottomPdfTooltip: 'Download CV',
    candidateDetailBottomUploadTooltip: 'Upload CV',
    candidateDetailBottomPrintCv: 'Print CV',
    candidateDetailBottomDeleteTooltip: 'Delete Candidate',
    candidateDetailBottomMailTooltip: 'Send Mail To Candidate',

    // messages
    messages: 'Messages',

    // server error list
    INTERNAL_SERVER_ERROR: 'An error has occurred. We are working to fix the problem. Please try again later.',
    FEIGN_SERVER_ERROR: 'A server error has occurred. Please try again later.', // Servisler arası iletişimde sunucu bazlı hata
    FEIGN_CLIENT_ERROR: 'A client error has occurred. Please try again later.', // Servisler arası isteklerde 4xx hatası
    // UAA
    INVALID_GRANT: 'Username or password is invalid. Please check your credentials.', // Kullanıcı adı veya parola hatalı
    INVALID_BEARER_TOKEN: 'An error has occurred. Please try again later.', // Geçersiz token
    INVALID_JWT: 'An error has occurred. Please try again later.', // Geçersiz JWT
    ACCESS_DENIED: 'You do not have access to perform this operation.', // Kullanıcının bu kaynağa erişim yetkisi yok
    USER_NOT_FOUND: 'The user does not exist.', // Kullanıcı bulunamadı
    USER_HAS_NO_PASSWORD: 'You have not defined your password yet. Please follow the instructions in the email we have sent you.',
    WRONG_PASSWORD: 'The password is not correct. Please check and try again.', // Kullanıcı parola değiştirmede mevcut parolasını yanlış girmiş

    // Resource
    RESOURCE_NOT_FOUND: 'The resource is not available', // Ulaşılmak istenen obje bulunamadı
    RESOURCE_DELETED: 'The resource was deleted', // Ulaşılmak istenen obje DELETED statüsünde
    RESOURCE_ALREADY_EXISTS: 'The resource already exists', // Kullanılan business id (email, mobile, vs.) ile halihazırda obje mevcut

    // JPA
    DATA_INTEGRITY_VIOLATION: 'An error has occurred. We are working to fix the problem. Please try again later.', // Veritabanında UQ, FK, Constraint Violation hatası

    // Request validation
    INVALID_REQUEST_FORMAT: 'An error has occurred. We are working to fix the problem. Please try again later.', // Request body'deki json geçersiz. Misal UUID olması gereken bir alan farklı format.
    VALIDATION_ERROR: 'Some of the parameters are invalid.', // Request body olarak gelen objede geçersiz alan(lar) var. Suberror listesine bakmak gerek.

    // General
    INVALID_VALUE: 'The value is invalid.', // Geçersiz değer

    // code=VALIDATION_ERROR olduğunda subErrors[n].code değerleri:
    INVALID_FIELD: 'The field is invalid.',
    MUST_BE_NULL: 'The value must be null.',
    MUST_NOT_BE_NULL: 'Please fill in all the required fields',
    MUST_BE_TRUE: 'The value must be true.',
    MUST_BE_FALSE: 'The value must be false.',
    MIN_VALUE_EXCEEDED: 'The value is below the expected minimum value.',
    MAX_VALUE_EXCEEDED: 'The value is above the expected maximum value.',
    DECIMAL_MIN_VALUE_EXCEEDED: 'The value is below the expected minimum value.',
    DECIMAL_MAX_VALUE_EXCEEDED: 'The value is above the expected maximum value.',
    MUST_BE_NEGATIVE: 'The value must be negative.',
    MUST_BE_NEGATIVE_OR_ZERO: 'The value must be either negative or zero.',
    MUST_BE_POSITIVE: 'The value must be positive',
    MUST_BE_POSITIVE_OR_ZERO: 'The value must be either positive or zero.',
    INVALID_SIZE: 'The size defined is invalid.',
    INVALID_DIGITS: 'The digits defined are invalid.',
    MUST_BE_IN_THE_PAST: 'The value must be past dated.',
    MUST_BE_IN_THE_PAST_OR_PRESENT: 'The value must be either past date or future date.',
    MUST_BE_IN_THE_FUTURE: 'The value must be future date.',
    MUST_BE_IN_THE_FUTURE_OR_PRESENT: 'The value must be either present or future date.',
    MUST_MATCH_THE_REGEX: 'The format of the value is not correct.',
    MUST_NOT_BE_EMPTY: 'The field must not be empty',
    MUST_NOT_BE_BLANK: 'The field cannot be empty',
    INVALID_EMAIL: 'The email is invalid',

    // email
    shareEmailSubject: 'A Position You May Be Interested In',
    GENERAL_ERROR: 'An error has occurred. We are working to fix the problem. Please try again later.',

    // Resul

    currentlyWorkings: ' Currently Working',
    addToPools: 'Add To Pool',
    addToPositions: 'Add To Position',
    warningNotes: 'can only be seen and edited by attached users',
    deleteProfilePhotos: 'Delete Profile Photo',
    ClearSearchResults: 'Clear Search Results',
    Search: 'Search',

    // Görkem
    theUserIsAlreadyInTheHiringTeam: 'The user is already in the hiring team',
    congratulations: 'Congratulations',
    registerSuccessMessage: 'You have successfully created your account. Please follow the instructions that we have sent to your email. ',
    oopsAnErrorOccurred: 'Oops. An Error Occurred',
    haveanaccount: 'Have an account? Log in',
    backToLogin: 'Back To Login',
    register: 'Register',
    legalCompanyName: 'Legal Company Name',
    taxOffice: 'Tax Office',
    taxNumber: 'Tax Number',
    continue: 'Continue',


    // Onur
    createAccount: 'Create A New Account',
    freelance: 'Freelance',
    deletePosition: 'Delete Position',

    // BugünEklediklerim-Resul
    positionss: 'On Positions',
    ExportOnCandidate: 'Export',
    ExportOnPosition: 'Export',
    ExportAsPdf: 'Export As PDF',
    ExportAsExcel: 'Export As Excel',
    Export: 'Export',
    ExportToPdf: 'Export To PDF',
    ExportToExcel: 'Export To Excel',

    // yeni
    currentStatusDate: 'Current Status Date',


    //  bugünEklenenler-Şubat
    takeNote: 'Take Note',
    viewHistory: 'View History',
    candidateHistory: 'Candidate History',
    companyInfo: 'Company Info',
    subscriptionInfo: 'Subscription Info',
    currentPackageInfo: 'Current Package Info',
    subscriptionDate: 'Subscription Date',
    cancelSubscription: 'Cancel Subscription',
    confirmCancelSubscription: 'Your subscription will be canceled, do you want to continue?',
    close: 'Close',
    fromStep: 'From Step',
    toStep: 'To Step',
    passwordsCannotBeSame: 'Current password and new password can not be same.',
    

    //Yeni
    deleteAttachment: 'Delete Attachment',
    deleteAttachmentConfirmation: 'This attachment will be deleted, do you want to continue?',

};
