<!-- Modal -->
<div aria-hidden="true" aria-labelledby="candidateStatusModalCenterTitle" class="modal fade"
    id="createInterviewFromRequest" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header gradient-bg">
                <h5 class="modal-title">{{'createInterview' | translate}}</h5>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="tempInterviewRequest;else noData">
                    <div class="mb-3">
                        <label for="selectedPosition">{{'position' | translate}}:</label>
                        <input [(ngModel)]="tempInterviewRequest?.position.name" type="text" class="form-control"
                            id="selectedPosition" readonly>
                    </div>
                    <div class="mb-3">
                        <label for="selectedCandidate">{{'candidate' | translate}}:</label>
                        <input [(ngModel)]="tempInterviewRequest?.candidate.name" type="text" class="form-control"
                            id="selectedCandidate" readonly>
                    </div>
                    <div class="mb-3">
                        <label class="modal-input-label" for="step">{{'step' | translate}}<span class="text-danger">*</span></label>
                        <select (change)="selectStep($event)" class="custom-select border-radius-0" id="step">
                            <option selected value="">{{'unspecified' | translate}}</option>
                            <option *ngFor="let step of steps" [value]="step.recruitmentStepId">
                                {{step.recruitmentStepName | translate}}</option>
                        </select>
                    </div>
                    <div class="mb-1">
                        <label class="ex-check" style="color: #649a65;">
                            <input [(ngModel)]="pipelineStep.clientHrPermitted" type="checkbox">
                            <span class="ml-2">
                                {{'clientHrPermitted' | translate}}
                            </span>
                        </label>
                    </div>
                    <div class="mb-1">
                        <label class="ex-check" style="color: #649a65;">
                            <input [(ngModel)]="pipelineStep.clientTechnicalPermitted" type="checkbox">
                            <span class="ml-2">
                                {{'clientTechnicalPermitted' | translate}}
                            </span>
                        </label>
                    </div>
                    <div class="mb-1">
                        <label class="ex-check" style="color: #649a65;">
                            <input [(ngModel)]="pipelineStep.hideSensitiveDataForClient" type="checkbox">
                            <span class="ml-2">
                                {{'hideSensitiveDataForClient' | translate}}
                            </span>
                        </label>
                    </div>
                </ng-container>
                <ng-template #noData>
                    <div class="alert alert-warning">
                        {{'createInterviewRequestNotExistInfo' | translate}}
                    </div>
                </ng-template>
            </div>

            <div class="modal-footer">
                <button (click)="closeThisModal()" class="btn btn-warning border-radius-0" data-dismiss="modal"
                    type="button">{{'cancel' | translate}}</button>
                <button (click)="createInterview()" [disabled]="!tempStep" class="btn btn-success border-radius-0"
                    type="button">{{'save' | translate}}</button>
            </div>

        </div>


    </div>


</div>
<app-add-interview-modal *ngIf="permissionService.permissionsOnly(['CREATE_INTERVIEW'])"
                         [InterviewInfo]="requestObject"></app-add-interview-modal>