<div class="container-fluid">
    <div class="row">
        <div class="col-auto mb-1">
            <app-candidate-list-filter (applyFilterOperation)="applyFilter($event)"></app-candidate-list-filter>
        </div>
        <div class="col-auto mb-1" *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE_TO_POOL'])">
            <button (click)="addToPoolCandidate()"
                    class="exactalent-button btn btn-blue border-radius-0">{{'addToPool' | translate}}</button>
        </div>
    </div>
</div>
<div class="container-fluid mt-3">
    <div class="row">
        <div class="col-12">
            <app-candidate-list (removeFromPoolOperation)="removeFromPool($event)"
                                (updateCandidates)="getCandidates($event)"
                                [candidates]="candidates"
                                [count]="count"
                                [referencePage]="'pool'"></app-candidate-list>
        </div>
    </div>
</div>
<app-remove-from-pool-modal *ngIf="permissionService.permissionsOnly(['DELETE_CANDIDATE_TO_POOL'])" (updateCandidates)="getCandidates(1)" [candidate]="selectedCandidate"
                            [poolId]="poolId"></app-remove-from-pool-modal>
<app-add-to-pool-candidate-modal *ngIf="permissionService.permissionsOnly(['CREATE_CANDIDATE_TO_POOL'])" (updateCandidates)="getCandidates(1)"
                                 [poolId]="poolId"></app-add-to-pool-candidate-modal>
