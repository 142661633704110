import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GeneralModel} from '../../../positions/position-operations/general/general.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../../services/permission/permission.service';
import {TranslatePipe} from '../../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../../services/notification/notification.service';
import {server} from '../../../../services/server';

@Component({
    selector: 'app-positions-dashboard-view-list',
    templateUrl: './positions-dashboard-view-list.component.html',
    styleUrls: ['./positions-dashboard-view-list.component.scss']
})
export class PositionsDashboardViewListComponent implements OnInit, OnChanges {

    positions: GeneralModel[];
    selectedPositionId: string;
    @Output() positionDetail = new EventEmitter();
    @Input() tabState: string;
    operationType: string;
    count: number;
    maxItemCount: number;
    @Output() getPositionCandidatesOperation = new EventEmitter();

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
        this.maxItemCount = 7;
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.positions = [];
        this.checkTab();
    }

    checkTab() {
        switch (this.tabState) {
            case 'allPositions':
                this.getPositions();
                break;
            case 'myPositions':
                this.getMyPositions();
                break;
            default:
                this.getPositions();
                break;
        }
    }

    getPositions() {
        this.authService.ServerGet(server.positions + `?size=${this.maxItemCount}&filter=status!:DELETED`)
            .subscribe((res: any) => {
                this.positions = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    getMyPositions() {
        this.authService.ServerGet(server.positions + `/my?size=${this.maxItemCount}&filter=status!:DELETED`)
            .subscribe((res: any) => {
                this.positions = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

    openAddToPositionCandidate(position) {
        this.selectedPositionId = position.id;
        // @ts-ignore
        $('#addToPositionModal').modal('show');
    }

    openAddToPositionHiringTeamMember(position) {
        this.selectedPositionId = position.id;
        // @ts-ignore
        $('#addToPositionHiringTeamMemberModal').modal('show');
    }

    emitPositionCandidateOperation(position) {
        this.getPositionCandidatesOperation.emit(position);
    }

}
