import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InterviewModel } from '../../../../services/parameters/models/interview.model';
import { CalendarEvent } from 'angular-calendar';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
const colors: any = {
    org: {
        primary: '#eba73d',
    },
};

@Component({
    selector: 'app-upcoming-event-list-item',
    templateUrl: './upcoming-event-list-item.component.html',
    styleUrls: ['./upcoming-event-list-item.component.scss']
})

export class UpcomingEventListItemComponent implements OnInit {

    @Input() interview: InterviewModel;
    today: string;
    hour: string;
    event: CalendarEvent;
    @Output() openOperationDialog = new EventEmitter();
    selectedDay: CalendarEvent[];
    constructor(public utilitiesService: UtilitiesService) {
        this.interview = new InterviewModel();
    }

    ngOnInit(): void {
        this.today = this.utilitiesService.getDate();
        this.hour = this.utilitiesService.getHour();
    }

    setInterviewObject(selectedInterview): CalendarEvent {
        return this.event = {
            id: selectedInterview.id,
            start: new Date(selectedInterview.date + ' ' + selectedInterview.startTime),
            end: new Date(selectedInterview.date + ' ' + selectedInterview.endTime),
            title: 'Interview',
            meta: selectedInterview
        };
    }

    openDeleteInterview(selectedInterview) {
        const interview = this.setInterviewObject(selectedInterview);
        const responseObject = {interview, type: 'delete'};
        this.openOperationDialog.emit(responseObject);
    }

    openEditInterview(selectedInterview) {
        const interview = this.setInterviewObject(selectedInterview);
        const responseObject = {interview, type: 'edit'};
        this.openOperationDialog.emit(responseObject);
    }

    openViewInterview(selectedInterview) {
        const interview = this.setInterviewObject(selectedInterview);
        const responseObject = {interview, type: 'view'};
        this.openOperationDialog.emit(responseObject);
    }
}
