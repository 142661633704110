import {Component, Input, OnInit} from '@angular/core';
import {server} from '../../../../services/server';
import {PermissionService} from '../../../../services/permission/permission.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../../../services/notification/notification.service';
import {RequestPayload} from '../../../../shared/requestPayload';
import {HistoryModel} from '../../../../shared/components/history-list/history.model';

@Component({
    selector: 'app-pool-history',
    templateUrl: './pool-history.component.html',
    styleUrls: ['./pool-history.component.scss']
})
export class PoolHistoryComponent implements OnInit {
    @Input() poolId: string;
    requestPayload: RequestPayload;
    historyLogs: HistoryModel[];
    count: number;

    constructor(public permissionService: PermissionService, private authService: AuthService, private spinner: NgxSpinnerService,
                private notification: NotificationService) {
        this.requestPayload = new RequestPayload();
    }

    ngOnInit(): void {
        this.getHistoryLogs(1);
    }

    getHistoryLogs(pageNo) {
        this.authService.ServerGet(server.pools + '/' + this.poolId + '/history' + this.requestPayload.payloadURL(pageNo))
            .subscribe((res: { content: HistoryModel[], totalElements: number }) => {
                this.historyLogs = res.content;
                this.count = res.totalElements;
            }, err => {
                if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                    this.notification.error(err.message);
                    this.spinner.hide();
                }
            }, () => {
                this.spinner.hide();
            });
    }

}
