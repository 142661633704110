import {CountryModel} from '../../services/parameters/models/country.model';
import {CityModel} from '../../services/parameters/models/city.model';
import {CategoryModel} from '../../services/parameters/models/category.model';

export class PoolModel {

    id: string;
    name: string;
    status: string;
    category: CategoryModel;
    createdBy: string;
    country: CountryModel;
    city: CityModel;
    updatedAt: string;

    constructor() {
        this.country = new CountryModel();
        this.city = new CityModel();
        this.category = new CategoryModel();
    }
}

export class PoolFilterModel {
    name: string;
    status: string;
    category: string;
    createdBy: string;
    country: string;
    city: string;
    updatedAt: string;

    constructor() {
        this.country = '';
        this.city = '';
        this.name = '';
        this.status = '';
        this.category = '';
        this.createdBy = '';
        this.updatedAt = '';
    }
}

