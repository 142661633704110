<div id="hr-manager-register">
	<div class="input-group margin-bottom-sm">
		<input
			[(ngModel)]="registerHrManager.firstName"
			autocomplete="off"
			class="form-control login-input"
			name="firstName"
			placeholder="First Name*"
			type="text"
		/>
	</div>

	<div class="input-group margin-bottom-sm mt-2">
		<input
			[(ngModel)]="registerHrManager.middleName"
			autocomplete="off"
			class="form-control login-input"
			name="middleName"
			placeholder="Middle Name"
			type="text"
		/>
	</div>

	<div class="input-group margin-bottom-sm mt-2">
		<input
			[(ngModel)]="registerHrManager.lastName"
			autocomplete="off"
			class="form-control login-input"
			name="lastName"
			placeholder="Last Name*"
			type="text"
		/>
	</div>

	<div class="input-group margin-bottom-sm mt-2">
		<input
			[(ngModel)]="registerHrManager.email"
			autocomplete="off"
			class="form-control login-input"
			name="email"
			placeholder="{{ 'emailAddress' | translate }}*"
			type="text"
		/>
	</div>
	<div class="d-flex justify-content-between w-100">
		<div class="input-group margin-bottom-sm back-button-div mt-2">
			<input class="btn back-btn" (click)="goBack()" value="Back"/> 
		</div>
		<div class="input-group margin-bottom-sm save-button-div mt-2">
			<input (click)="saveInfo()"
				class="btn login-btn"
				value="Save and Continue"
				[disabled]="
					!registerHrManager.firstName ||
					!registerHrManager.lastName ||
					!registerHrManager.email
				"
			/>
		</div>
	</div>
</div>
