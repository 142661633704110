import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-empty-table-info',
    templateUrl: './empty-table-info.component.html',
    styleUrls: ['./empty-table-info.component.scss']
})
export class EmptyTableInfoComponent implements OnInit {

    @Input() tableInfoTranslateKey: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
