import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CandidateModel} from '../candidate.model';
import {server} from '../../../services/server';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../services/permission/permission.service';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../../services/notification/notification.service';

@Component({
    selector: 'app-delete-candidate',
    templateUrl: './delete-candidate.component.html',
    styleUrls: ['./delete-candidate.component.scss']
})
export class DeleteCandidateComponent implements OnInit {

    @Input() candidate: CandidateModel;
    @Input() fromPage: string;

    @Output() updateCandidate = new EventEmitter();

    constructor(private spinner: NgxSpinnerService,
                private authService: AuthService,
                private router: Router,
                public permissionService: PermissionService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
    }

    ngOnInit(): void {
        this.candidate = new CandidateModel();
    }

    closeDeleteCandidateModal() {
        // @ts-ignore
        $('#deleteCandidate').modal('hide');
    }

    deleteCandidate() {
        this.authService.ServerDelete(server.candidates + '/' + this.candidate.id)
            .subscribe(res => {
                this.notification.success(this.translate.transform('deleteSuccessful', []));
            }, err => {
                if (err.status >= 400 || err.status < 500) {
                    this.notification.warning(err.message);
                }
                this.spinner.hide();
            }, () => {
                if (!this.fromPage) { this.updateCandidate.emit(); }
                // @ts-ignore
                $('#deleteCandidate').modal('hide');
                this.spinner.hide();
                if (this.fromPage == 'edit-candidate') { this.router.navigate(['/dashboard/candidates']); }
            });
    }

}
